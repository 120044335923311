import React, { useEffect, useRef } from 'react';

import { scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import AgentSettingDesk from '@assets/images/mlm-levalti/new-agents/new-lovely-agent-sitting-at-desk.jpg';
import worldClassHotel from '@assets/images/mlm-levalti/new-agents/01-world-class-hotels.jpg';
import luxuryResort from '@assets/images/mlm-levalti/new-agents/02-luxury-resort-condos.jpg';
import villasHome from '@assets/images/mlm-levalti/new-agents/03-villas-and-homes.jpg';
import oceanCruising from '@assets/images/mlm-levalti/new-agents/04-ocean-cruising.jpg';
import fantasyGetaways from '@assets/images/mlm-levalti/new-agents/06-fantasy-getaways.jpg';
import getawaysResort from '@assets/images/mlm-levalti/new-agents/getaways-resorts.jpg';
import travNow from '@assets/images/mlm-levalti/new-agents/travnow.jpg';
import travNow2 from '@assets/images/mlm-levalti/new-agents/travnow2.jpg';
import travNow3 from '@assets/images/mlm-levalti/new-agents/travnow3.jpg';
import SupplierLogo from '@assets/images/mlm-levalti/new-agents/supplier-list-of-logos.png';
import IconPig from '@assets/images/mlm-levalti/new-agents/icon-piggy-bank.png';
import IconKeyboard from '@assets/images/mlm-levalti/new-agents/icon-keyboard.png';
import IconHandshake from '@assets/images/mlm-levalti/new-agents/icon-handshake.png';
import IconSupplier from '@assets/images/mlm-levalti/new-agents/icon-supplier.png';
import ShutterShock from '@assets/images/mlm-levalti/new-agents/shutterstock_1106799740.jpg';
import { FormattedMessage } from 'react-intl';

export default function MLMNewAgents() {
  useEffect(() => {
    scrollTop();
  }, []);

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="new-agents">
      {/*Header*/}
      <header className="new-agents__header">
        <section className="new-agents__header-title">
          <h1>
            <FormattedMessage id="agents.welcome" />
          </h1>
        </section>
        <section className="new-agents__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="new-agents__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="new-agents__main-section-started">
          <section className="new-agents__main-section-started-one">
            <h2 className="new-agents__main-section-started-one-subtitle">
              <span className="new-agents__main-section-started-one-subtitle-making">
                <FormattedMessage id="agents.started.title" />
              </span>{' '}
              <span className="new-agents__main-section-started-one-subtitle-fun">
                <FormattedMessage id="agents.started.subtitle" />
              </span>
            </h2>
            <p className="new-agents__main-section-started-one-paragraph">
              <FormattedMessage id="agents.started.paragraph" />
            </p>
            <ul className="new-agents__main-section-started-one-list">
              <li>
                <FormattedMessage id="agents.started.list.one" />
              </li>
              <li>
                <FormattedMessage id="agents.started.list.two" />
              </li>
              <li>
                <FormattedMessage id="agents.started.list.third" />
              </li>
              <li>
                <FormattedMessage id="agents.started.list.four" />
              </li>
              <li>
                <FormattedMessage id="agents.started.list.five" />
              </li>
            </ul>
            <section className="new-agents__main-section-started-one-btn">
              <button className="started-btn">
                <FormattedMessage id="agents.started.btn" />
              </button>
            </section>
          </section>
          <section className="new-agents__main-section-started-two">
            <img
              src={AgentSettingDesk}
              alt="agent setting in a desk"
              className="new-agents__main-section-started-two-img"
            />
            <div className="new-agents__main-section-started-two-paragraph">
              <p className="new-agents__main-section-started-two-paragraph-quote">
                <FormattedMessage id="agents.started.quote" />
              </p>
              <p className="new-agents__main-section-started-two-paragraph-author">
                {' '}
                <FormattedMessage id="agents.started.author" />
              </p>
            </div>
          </section>
        </section>
        {/*Second Section*/}
        <section className="new-agents__main-section-condominium">
          <section className="new-agents__main-section-condominium-container">
            <section className="new-agents__main-section-condominium-container-text">
              <h2 className="new-agents__main-section-condominium-container-text-title">
                <FormattedMessage id="agents.condominium.title" />
              </h2>
              <p className="new-agents__main-section-condominium-container-text-paragraphs">
                <FormattedMessage id="agents.condominium.paragraph.one" />
              </p>
              <p className="new-agents__main-section-condominium-container-text-paragraphs">
                <FormattedMessage id="agents.condominium.paragraph.two" />
              </p>
            </section>
            <section className="new-agents__main-section-condominium-container-imgs">
              <img src={worldClassHotel} alt="worldClassHotel" />
              <img src={luxuryResort} alt="luxuryResort" />
              <img src={villasHome} alt="villasHome" />
              <img src={oceanCruising} alt="oceanCruising" />
              <img src={fantasyGetaways} alt="fantasyGetaways" />
              <img src={getawaysResort} alt="getawaysResort" />
            </section>
          </section>
        </section>
        {/*Third Section*/}
        <section className="new-agents__main-section-benefits">
          <section className="new-agents__main-section-benefits-container">
            <section className="new-agents__main-section-benefits-container-paragraph">
              <h2 className="new-agents__main-section-benefits-container-paragraph-title">
                <FormattedMessage id="agents.benefits.title" />
                <span className="new-agents__main-section-benefits-container-paragraph-title-features">
                  {' '}
                  <FormattedMessage id="agents.benefits.subtitle" />
                </span>
              </h2>
              <p className="new-agents__main-section-benefits-container-paragraph-subtitle">
                <FormattedMessage id="agents.benefits.subtitle2" />
              </p>
            </section>
            <section className="new-agents__main-section-benefits-container-imgs">
              <section className="new-agents__main-section-benefits-container-imgs-one">
                <img
                  src={travNow}
                  alt="travNow"
                  className="new-agents__main-section-benefits-container-imgs-one-img"
                />
                <h3 className="new-agents__main-section-benefits-container-imgs-one-title">
                  <FormattedMessage id="agents.benefits.img.one.title" />
                </h3>
                <p className="new-agents__main-section-benefits-container-imgs-one-paragraph">
                  <FormattedMessage id="agents.benefits.img.one.paragraph" />
                </p>
              </section>
              <section className="new-agents__main-section-benefits-container-imgs-two">
                <div>
                  <img
                    src={travNow3}
                    alt="travNow2"
                    className="new-agents__main-section-benefits-container-imgs-two-img"
                  />
                  <button>
                    <FormattedMessage id="agents.benefits.img.two.btn" />
                  </button>
                </div>
                <h3 className="new-agents__main-section-benefits-container-imgs-two-title">
                  <FormattedMessage id="agents.benefits.img.two.title" />
                </h3>
                <p className="new-agents__main-section-benefits-container-imgs-two-paragraph">
                  <FormattedMessage id="agents.benefits.img.two.paragraph" />
                </p>
              </section>
              <section className="new-agents__main-section-benefits-container-imgs-three">
                <img
                  src={travNow2}
                  alt="travNow3"
                  className="new-agents__main-section-benefits-container-imgs-three-img"
                />
                <h3 className="new-agents__main-section-benefits-container-imgs-three-title">
                  <FormattedMessage id="agents.benefits.img.third.title" />
                </h3>
                <p className="new-agents__main-section-benefits-container-imgs-three-paragraph">
                  <FormattedMessage id="agents.benefits.img.third.paragraph" />
                </p>
              </section>
            </section>
          </section>
        </section>
        {/*Four Section*/}
        <section className="new-agents__main-section-brands">
          <section className="new-agents__main-section-brands-container">
            <section className="new-agents__main-section-brands-container-paragraph">
              <h2>
                <FormattedMessage id="agents.brands.title" />
              </h2>
              <p>
                <FormattedMessage id="agents.brands.subtitle" />
              </p>
            </section>
            <section className="new-agents__main-section-brands-container-imgs">
              <div className="new-agents__main-section-brands-container-imgs-brand">
                <img src={SupplierLogo} alt="Supplier Logo" />
              </div>
              <div className="new-agents__main-section-brands-container-imgs-btn">
                <button>
                  <FormattedMessage id="agents.brands.learnmore" />
                </button>
              </div>
            </section>
          </section>
        </section>
        {/*Fifth Section*/}
        <section className="new-agents__main-section-questions">
          <section className="new-agents__main-section-questions-container">
            <section className="new-agents__main-section-questions-container-title">
              <h2>
                <FormattedMessage id="agents.question.title" />
              </h2>
            </section>
            <section className="new-agents__main-section-questions-container-asked">
              <div className="new-agents__main-section-questions-container-asked-one">
                <section className="new-agents__main-section-questions-container-asked-one-img">
                  <img src={IconPig} alt="Icon Pig" />
                </section>
                <section className="new-agents__main-section-questions-container-asked-one-title">
                  <h2>
                    <FormattedMessage id="agents.question.subtitle" />
                  </h2>
                  <p className="new-agents__main-section-questions-container-asked-one-paragraph">
                    <FormattedMessage id="agents.question.paragraph" />
                  </p>
                </section>
              </div>
              <div className="new-agents__main-section-questions-container-asked-two">
                <section className="new-agents__main-section-questions-container-asked-two-img">
                  <img src={IconHandshake} alt="Icon Hanshake" />
                </section>
                <section className="new-agents__main-section-questions-container-asked-two-title">
                  <h2>
                    <FormattedMessage id="agents.question.subtitle2" />
                  </h2>
                  <p className="new-agents__main-section-questions-container-asked-two-paragraph">
                    <FormattedMessage id="agents.question.paragraph2" />
                  </p>
                </section>
              </div>
              <div className="new-agents__main-section-questions-container-asked-third">
                <section className="new-agents__main-section-questions-container-asked-third-img">
                  <img src={IconSupplier} alt="Icon Supplier" />
                </section>
                <section className="new-agents__main-section-questions-container-asked-third-title">
                  <h2>
                    <FormattedMessage id="agents.question.subtitle3" />
                  </h2>
                  <p className="new-agents__main-section-questions-container-asked-third-paragraph">
                    <FormattedMessage id="agents.question.paragraph3" />
                  </p>
                </section>
              </div>
              <div className="new-agents__main-section-questions-container-asked-four">
                <section className="new-agents__main-section-questions-container-asked-four-img">
                  <img src={IconKeyboard} alt="Icon Keyboard" />
                </section>
                <section className="new-agents__main-section-questions-container-asked-four-title">
                  <h2>
                    <FormattedMessage id="agents.question.subtitle4" />
                  </h2>
                  <p className="new-agents__main-section-questions-container-asked-four-paragraph">
                    <FormattedMessage id="agents.question.paragraph4" />
                  </p>
                </section>
              </div>
            </section>
          </section>
        </section>
        {/*Sixth Section*/}
        <section className="new-agents__main-section-host">
          <section className="new-agents__main-section-host-container">
            <section className="new-agents__main-section-host-container-img">
              <img src={ShutterShock} alt="Host Img" />
            </section>
            <section className="new-agents__main-section-host-container-text">
              <h2 className="new-agents__main-section-host-container-text-title">
                <FormattedMessage id="agents.host.title" />
              </h2>
              <p className="new-agents__main-section-host-container-text-paragraph">
                <FormattedMessage id="agents.host.paragraph" />
              </p>
              <button className="new-agents__main-section-host-container-text-btn">
                <FormattedMessage id="agents.started.btn" />
              </button>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
