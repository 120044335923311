import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RootState } from '@share/utils';
import { IMenuState, getAllInclusiveDestinations } from '@share/store/slices';
import { FUNJET_LINK } from '@constants';
import { Routes } from '@share/constants';
import { BlueButton, SearchWrapper } from '@share/components';
import { GetawayList, Membership, ModalMessage } from '@components';
import { inclusivesGalery, AllInclusivesCompanyLogos } from '@common-types';

import InclusivesBackground from '@assets/images/inclusives/bg-inclusives.png';
import ConciergeTeam from '@assets/images/concierge-team.png';
import { ILoginState } from '@share/store/slices';

import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
import { GetawaysLocationEnum } from '@share/common-types';
import { GetawayListSkeleton } from '../getaway/getaway-list/getaway-list-skeleton';
import { IGetawaysState, SubCategory, getawaysActions } from '@store/slices';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  getawaysStore: IGetawaysState;
}

interface IMapDispatchToProps {
  getAllInclusiveDestinations: () => void;
  setLoading: (value: boolean, subCategory: SubCategory) => void;
  setActiveSubCategory: (subCategory: SubCategory) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

interface IState {
  isModalVisible: boolean;
}

class AllInclusivesWrapperComponent extends React.Component<IProps, IState> {
  state: IState = { isModalVisible: false };

  componentDidMount() {
    this.props.setLoading(true, GetawaysLocationEnum.AllInclusive);
    this.props.setActiveSubCategory(GetawaysLocationEnum.AllInclusive);
    this.props.getAllInclusiveDestinations();
  }

  render(): React.ReactNode {
    const { menuStore, loginStore, intl, getawaysStore } = this.props;
    const { account, user } = loginStore;
    const { items } = menuStore;
    const { loadingGetaways } = getawaysStore[GetawaysLocationEnum.AllInclusive];

    const phone = items?.phone;

    return (
      <div className="inclusives">
        <div
          className="inclusives__header"
          style={{ backgroundImage: `url(${InclusivesBackground})` }}
        >
          <div className="inclusives__container">
            <h1 className="inclusives__header_title">
              <FormattedMessage id="inclusives.title" />
            </h1>

            <SearchWrapper
              isWidget={false}
              widgetBackgroundColor={'none'}
              hideTitle
              hideMessage
              openOnClick
            />
          </div>
        </div>

        {!user || loadingGetaways ? <GetawayListSkeleton isSlider={false} /> : null}

        <GetawayList
          getaway={GetawaysLocationEnum.AllInclusive}
          condoSubcategory={null}
          isFromProperty={true}
          isInternal={true}
          isHome={true}
          isSlider={false}
          isAllInclusive
        />

        <div
          className="inclusives__header"
          style={{ backgroundImage: `url(${InclusivesBackground})`, marginTop: '30px' }}
        >
          <div className="inclusives__container">
            <h1 className="inclusives__header_title">
              <FormattedMessage id="inclusives.vacation.text.title" />
            </h1>

            <p className="inclusives__header_subtitle">
              <FormattedMessage id="inclusives.subtitle" />
            </p>

            <div className="inclusives__header_button">
              <div className="inclusives__header_phone-container">
                <FontAwesomeIcon icon={faPhone} size="2x" />
                <div className="inclusives__header_phone-message">
                  <p className="inclusives__header_subtitle" style={{ marginTop: '0px' }}>
                    <FormattedMessage id="speak.specialist" />
                  </p>
                  <p className="inclusives__header_subtitle" style={{ marginTop: '5px' }}>
                    <FormattedMessage id="start.planning" />
                    &nbsp;&nbsp;
                    <a href={`tel:${items?.phone}`} target="_blank" rel="noreferrer">
                      {items?.phone}
                    </a>
                  </p>
                </div>
              </div>

              <p
                className="inclusives__header_subtitle"
                style={{ marginLeft: '20px', marginRight: '20px' }}
              >
                <FormattedMessage id="or" />
              </p>

              <div className="inclusives__header_button_container">
                <div className="inclusives__vacation_bottom header">
                  <a href={FUNJET_LINK} target="_blank" rel="noreferrer">
                    <BlueButton>
                      <FormattedMessage id="book.online" />
                    </BlueButton>
                  </a>
                </div>

                <p className="inclusives__header_subtitle code" style={{ marginTop: '5px' }}>
                  <FormattedMessage
                    id="inclusives.vacation.text.header"
                    values={{ code: intl.formatMessage({ id: 'inclusives.vacation.text.code' }) }}
                  />
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    onClick={() => this.setState({ isModalVisible: true })}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="inclusives__container inclusives__book">
            <div className="inclusives__book_info">
              <h2 className="inclusives__book_title">
                <FormattedMessage id="inclusives.book.title" />
              </h2>
              <p className="inclusives__book_subtitle">
                <FormattedMessage id="inclusives.book.subtitle" />
              </p>
              <p className="inclusives__book_text">
                <FormattedMessage id="inclusives.book.text" />
              </p>
              <p className="inclusives__all_subtitle" style={{ marginTop: '30px' }}>
                <FormattedMessage id="inclusives.all.subtitle" />
              </p>
              <p className="inclusives__all_text">
                <FormattedMessage id="inclusives.all.text" />
              </p>
            </div>
            <img className="inclusives__book_img" src={ConciergeTeam} alt="inclusives" />
          </div>
        </div>

        <div className="inclusives__gallery">
          {inclusivesGalery.map((item, index) => (
            <img
              key={`inclusives galery ${index}`}
              className="inclusives__gallery_img"
              src={item.url}
              alt="gallery-img"
            />
          ))}
        </div>
        <div className="inclusives__tell">
          <div className="inclusives__container">
            <p className="inclusives__tell_title">
              <FormattedMessage id="inclusives.tell.title" />
            </p>
            <div className="inclusives__tell_info">
              <p className="inclusives__tell_text">
                <FormattedMessage id="inclusives.tell.text" />
              </p>
              <Link
                className="inclusives__tell_link"
                to={`/${account?.name}${Routes.AllInclusive}/submit-request`}
              >
                <BlueButton>
                  <FormattedMessage id="inclusives.tell.us" />
                </BlueButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="inclusives__all">
          <div className="inclusives__container">
            <h2 className="inclusives__all_title">
              <FormattedMessage id="inclusives.all.title" />
            </h2>
            <p className="inclusives__all_subtitle">
              <FormattedMessage id="inclusives.all.subtitle.bottom" />
            </p>
            <p className="inclusives__all_text">
              <FormattedMessage id="inclusives.all.text.bottom" />
            </p>
            <p className="inclusives__all_link">
              <FormattedMessage id="inclusives.all.link" />
            </p>
          </div>
        </div>
        <div className="inclusives__partners">
          <div className="inclusives__container">
            <h2 className="inclusives__partners_title">
              <FormattedMessage id="treat.yourself.book.with.our.team" />
              {': '}
              <a href={`tel:${phone}`}>{phone}</a>
            </h2>
            <p className="inclusives__partners_subtitle">
              <FormattedMessage id="your.award.winning.travel" />
            </p>
            <p className="inclusives__partners_text">
              <FormattedMessage id="travel.partners.and.cruise.lines" />
            </p>
            <div className="inclusives__partners_divider" />
            <p className="inclusives__partners_subtitle">
              <FormattedMessage id="travel.partners.and.cruise.partners" />
            </p>
            <div className="inclusives__partners_wrapper">
              {AllInclusivesCompanyLogos.map((item, index) => (
                <div key={`${index}-${item.name}`} className="inclusives__partners_wrapper_item">
                  <img
                    src={item.image}
                    key={`inclusives company galery ${item.name}`}
                    alt={`inclusives gallery-img ${item.name}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <Membership />

        <ModalMessage
          text="inclusives.code.message"
          title="inclusives.promo.code"
          visible={this.state.isModalVisible}
          onCancel={() => this.setState({ isModalVisible: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    getawaysStore: state.getawaysStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getAllInclusiveDestinations: () => {
    dispatch(getAllInclusiveDestinations());
  },
  setLoading: (value: boolean, subCategory: SubCategory) => {
    dispatch(getawaysActions.setLoadingGetaways({ loading: value, subCategory }));
  },
  setActiveSubCategory: (subCategory: SubCategory) => {
    dispatch(getawaysActions.setActiveGetawaySubCategory({ subCategory }));
  },
});

export const AllInclusivesWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AllInclusivesWrapperComponent));
