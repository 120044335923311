import React from 'react';
import capitalize from 'lodash/capitalize';

import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { RootState, UrlUtils } from '@share/utils';
import {
  StayForEnum,
  SearchTypeEnum,
} from '@share/common-types';
import {
  SearchHandlerArgs,
} from '@common-types';
import {
  ICondoLocation,
  DateSearchTypeEnum,
} from '@share/common-types';
import {
  getCondos,
  condosActions,
  ICondoGuestsState,
  condoGuestsActions,
  condoStrictDatesActions,
  condosLocationPickerActions,
  condoFlexibleDatePickerActions,
} from '@share/store/slices';
import {
  ILoginState,
  LoginType,
  condoFiltersActions,
} from '@share/store/slices';
import { CONDO_DATES_FLEXIBLE_LABEL, CONDO_DATES_LABEL, CONDO_FILTERS_LABEL, CONDO_RECENT_SEARCHES_LABEL, C_H_CLICK_RECENT_SEARCH, DEFAULT_PAGE_NUMBER } from '@share/constants';

import { CondoRecentSearches } from '../condo-recent-searches';

import './style.scss';
import ReactGA from 'react-ga4';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getCondos: (isReuse: boolean) => void;
  setIsSearch: () => void;
  resetFilters: () => void;
  setPageNumber: () => void;
  setSearchType: (type: DateSearchTypeEnum) => void;
  selectLocation: (location: ICondoLocation) => void;
  setGuestsState: (state: ICondoGuestsState) => void;
  setStrictDates: (dates: { startDate: string; endDate: string }) => void;
  setFlexibleMonths: (months: string[]) => void;
  selectLocationLabel: (label: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

class CondoRecentSearchesWrapperComponent extends React.Component<IProps> {

  onSearchHandler: SearchHandlerArgs = (
    condoLocation,
    searchType,
    stayDuration,
    guestsState,
    months,
    startDate,
    endDate,
  ) => {
    const {
      loginStore,
      getCondos,
      setIsSearch,
      resetFilters,
      setPageNumber,
      setSearchType,
      setStrictDates,
      setGuestsState,
      selectLocation,
      setFlexibleMonths,
      selectLocationLabel,
    } = this.props;
    const modifiedSearchType = capitalize(searchType) as DateSearchTypeEnum;
    const modifiedStayFor = capitalize(stayDuration) as keyof typeof StayForEnum;

    setPageNumber();
    setIsSearch();

    resetFilters();
    UrlUtils.setUrl(CONDO_FILTERS_LABEL, null);

    selectLocationLabel(condoLocation.name);
    selectLocation(condoLocation);
    setSearchType(modifiedSearchType);

    if (modifiedSearchType === DateSearchTypeEnum.Flexible) {
      setFlexibleMonths(months);
      UrlUtils.setUrl(CONDO_DATES_FLEXIBLE_LABEL, {
        selectedMonths: months,
        stayFor: StayForEnum[modifiedStayFor],
      });
      UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
    }

    if (modifiedSearchType === DateSearchTypeEnum.Anytime) {
      setFlexibleMonths([]);
      UrlUtils.setUrl(CONDO_DATES_FLEXIBLE_LABEL, {
        selectedMonths: [],
        stayFor: StayForEnum[modifiedStayFor],
      });
      UrlUtils.removeFromUrl(CONDO_DATES_LABEL);
    }

    if (modifiedSearchType === DateSearchTypeEnum.Strict) {
      setStrictDates({ startDate, endDate });
    }

    setGuestsState({ ...guestsState, bedroomsCount: !guestsState?.bedroomsCount ? 1 : guestsState?.bedroomsCount });

    getCondos(false);

    const { account } = loginStore;

    ReactGA.event({
      category: account.name,
      action: `${C_H_CLICK_RECENT_SEARCH}_${account.name.toUpperCase()}`,
      label: `User clicked recent search`,
      nonInteraction: false,
    });
  };

  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { loading, account } = loginStore;

    const recentSearchesStorage = localStorage.getItem(CONDO_RECENT_SEARCHES_LABEL);
    let recentSearches = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];
    if (recentSearches?.length) {
      recentSearches = recentSearches.slice(0, 4);
    }

    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

    if (!hasPublicValidToken) {
      return null;
    }

    return recentSearches.length ? (
      <div className="condo-recents">
        <h2 className="condo-recents__title">
          <FormattedMessage id="recents.title" />
        </h2>
        <div className="condo-recents__wrapper">
          {recentSearches?.map((item: any, index: number) => (
            <CondoRecentSearches
              data={item}
              disabled={loading || !hasPublicValidToken}
              key={`${item.location.id} ${index}`}
              onSearchHandler={this.onSearchHandler}
            />
          ))}
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  resetFilters: () => {
    dispatch(condoFiltersActions.resetFilters());
  },
  selectLocation: (location: ICondoLocation) => {
    dispatch(condosLocationPickerActions.selectLocation(location));
  },
  selectLocationLabel: (label: string) => {
    dispatch(condosLocationPickerActions.selectLocationLabel(label));
  },
  setGuestsState: (state: ICondoGuestsState) => {
    dispatch(condoGuestsActions.setState(state));
  },
  getCondos: (isReuse: boolean) => {
    dispatch(getCondos(SearchTypeEnum.NewSearch, isReuse));
  },
  setPageNumber: () => {
    dispatch(condosActions.setPageNumber(DEFAULT_PAGE_NUMBER));
  },
  setFlexibleMonths: (months: string[]) => {
    dispatch(condoFlexibleDatePickerActions.setMonths(months));
  },
  setStrictDates: (dates: { startDate: string; endDate: string }) => {
    dispatch(condoStrictDatesActions.setDates(dates));
  },
  setIsSearch: () => {
    dispatch(condosActions.setIsSearch(true));
  },
  setSearchType: (type: DateSearchTypeEnum) => {
    dispatch(condoStrictDatesActions.setSearchType(type));
  },
});

export const CondoRecentSearchesWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondoRecentSearchesWrapperComponent);
