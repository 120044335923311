import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ITreatYourselfLogos } from '@common-types';
import './style.scss';

interface IProps {
  logos: ITreatYourselfLogos[];
}

export class CruiseLineHotLinks extends React.Component<IProps> {
  render(): React.ReactNode {
    const { logos } = this.props;

    return (
      <div className="cruise-line-hot-links">
        <div className="cruise-line-hot-links__wrapper">
          <p className="cruise-line-hot-links__title">
            <FormattedMessage id="cruise.line.hot.links" />
          </p>
          <div className="cruise-line-hot-links__logos-wrapper">
            {logos.map((item, index) => (
              <div key={`${index}-${item.name}`}>
                <div className="cruise-line-hot-links__img-wrapper">
                  <img className="cruise-line-hot-links__img" src={item.image} alt={item.name} />
                </div>
                <div className="cruise-line-hot-links__text-wrapper">
                  <p className="cruise-line-hot-links__logo-name">
                    <FormattedMessage id={item.name} />
                  </p>
                  <p className="cruise-line-hot-links__logo-reward">
                    <FormattedMessage id={item.reward} />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
