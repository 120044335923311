import React, { Component } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';
import { DailyBenefitsItems, DailyBenefitsHeading } from '@components';
import { INavigationItem } from '@share/common-types';

import { SkeletonItems } from '../skeleton-daily-benefits-items';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

const DailyBenefits = 'Daily Benefits';
const MoreBenefits = 'More Benefits';
const ZERO = 0;

export class DailyBenefitsWrapperComponent extends Component<IProps> {
  findDailyBenefits = (): INavigationItem => {
    const headerDailyBenefits = this.props.menuStore?.items?.header.filter(
      (item) => item.title === DailyBenefits
    )[ZERO];

    if (headerDailyBenefits && !headerDailyBenefits.childItems.length) {
      const mainDailyBenefits = this.props.menuStore?.items?.main
        .filter((item) => item.title === MoreBenefits)
        [ZERO].childItems.filter((item) => item.title === DailyBenefits)[ZERO];

      return mainDailyBenefits;
    }

    return headerDailyBenefits;
  };

  render(): React.ReactNode {
    const { loading, items } = this.props.menuStore;
    const benefits = this.findDailyBenefits()?.childItems;

    return (
      <>
        <DailyBenefitsHeading />
        {loading ? <SkeletonItems /> : <DailyBenefitsItems isMLM={items?.isMLM} items={benefits} />}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const DailyBenefitsWrapper = connect(mapStateToProps)(DailyBenefitsWrapperComponent);
