import omit from 'lodash/omit';
import {
  CANADA_STATES,
  CardType,
  USA_STATES,
  CONDO_BOOKING_GUEST_INFO_LABEL,
  CONDO_BOOKING_CARD_INFO_LABEL,
  HOTEL_BOOKING_ADDRESS_INFO_LABEL,
  WEEKS_BOOKING_GUEST_INFO_LABEL,
  WEEKS_BOOKING_CARD_INFO_LABEL,
} from '@constants';
import {
  IBookingSummary,
  ICardBooking,
  ICondoCardBooking,
  ICondoGuestBooking,
  ICountryState,
  IErrorField,
  IHotelInfo,
  IPackage
} from '@common-types';
import { checkForEmptyCharacter, insertSpacesInNumber, insertSpacesInPhone } from '@share/utils';
import { ICondoReviewBookState, IReviewBookState, IWeeksReviewBookState } from '@store/slices';
import { get } from 'lodash';
import { IHotel } from '@share/common-types';

const THREE = 3;
const FOUR = 4;

export const detectCardType = (cardNumber: string): string => {
  const re: { [key: string]: RegExp } = {
    [CardType.Electron]: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    [CardType.Maestro]: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    [CardType.Dankort]: /^(5019)\d+$/,
    [CardType.Interpayment]: /^(636)\d+$/,
    [CardType.Unionpay]: /^(62|88)\d+$/,
    [CardType.Visa]: /^4[0-9]{12}(?:[0-9]{3})?$/,
    [CardType.Mastercard]: /^5[1-5][0-9]{14}$/,
    [CardType.Amex]: /^3[47][0-9]{13}$/,
    [CardType.Diners]: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    [CardType.Discover]: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    [CardType.Jcb]: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (const key in re) {
    if (re[key].test(cardNumber)) {
      return key;
    }
  }
};

export const getErrorMessage = (errorArray: IErrorField[], name: string): string => {
  if (!errorArray) {
    return;
  }

  const errorField = errorArray.find((field) => field.field === name);

  if (errorField) {
    return errorField.errorMessage;
  } else {
    return;
  }
};
//TRAV23-1493
export const setValidator = (
  errorsField: IErrorField[],
  name: string,
  checkError: boolean,
): Promise<void> => {
  if (getErrorMessage(errorsField, name) === undefined || !checkError) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error(getErrorMessage(errorsField, name)));
  }
};

export const setNumberPhone = (data: string): string => {
  const THREE = 3;
  const value = checkForEmptyCharacter(data);
  const matchPhone = insertSpacesInPhone(value);

  if (value?.length > THREE && matchPhone !== null) {
    return matchPhone.join('-').trim();
  } else if (!Number(value)) {
    return '';
  } else {
    return value;
  }
};

export const updateCondoGuest = (
  guestObject: ICondoGuestBooking | ICardBooking | ICondoCardBooking,
  value: { [key: string]: string | number | boolean },
  isGuest = true,
): ICondoGuestBooking | ICardBooking | ICondoCardBooking => {
  return updateGuest(guestObject, value, isGuest);
};

export const updateWeeksGuest = (
  guestObject: ICondoGuestBooking | ICardBooking | ICondoCardBooking,
  value: { [key: string]: string | number | boolean },
  isGuest = true,
): ICondoGuestBooking | ICardBooking | ICondoCardBooking => {
  return updateGuest(guestObject, value, isGuest, true);
};

export const updateGuest = (
  guestObject: ICondoGuestBooking | ICardBooking | ICondoCardBooking,
  value: { [key: string]: string | number | boolean },
  isGuest = true,
  isWeeks = false,
): ICondoGuestBooking | ICardBooking | ICondoCardBooking => {
  const newGuest = {
    ...guestObject,
    ...value,
  };

  if (isGuest) {
    localStorage.setItem(
      isWeeks ? WEEKS_BOOKING_GUEST_INFO_LABEL : CONDO_BOOKING_GUEST_INFO_LABEL,
      JSON.stringify(omit(newGuest, ['zipCode', 'stateCode', 'country', 'countryCode'])),
    );
  } else {
    localStorage.setItem(
      isWeeks ? WEEKS_BOOKING_CARD_INFO_LABEL : CONDO_BOOKING_CARD_INFO_LABEL,
      JSON.stringify(
        omit(newGuest, [
          'id',
          'country',
          'zipCode',
          'stateCode',
          'cvv',
          'cardNumber',
          'cardType',
          'expireDate',
          'holderName',
        ]),
      ),
    );
  }

  return newGuest;
};

export const showCardNumber = (data: ICondoReviewBookState | IReviewBookState | IWeeksReviewBookState): string => {
  const { card } = data;

  if (!card.cardNumber) {
    return;
  }
  const value = checkForEmptyCharacter(card.cardNumber);

  return insertSpacesInNumber(value).join(' ').trim();
};

export const getHiddenFields = (codeCVV: string): string => {
  if (codeCVV.length === THREE) {
    return '***';
  } else if (codeCVV.length === FOUR) {
    return '****';
  }
};

export const getStates = (data: ICondoReviewBookState | IReviewBookState): ICountryState[] => {
  const { card } = data;

  if (card?.country === 'US') {
    return USA_STATES;
  }

  if (card?.country === 'CA') {
    return CANADA_STATES;
  }

  return [];
};

export const getHotelAddressFromStorage = (): any => {
  const addressInfo = localStorage.getItem(HOTEL_BOOKING_ADDRESS_INFO_LABEL);
  if (addressInfo) {
    try {
      return JSON.parse(addressInfo);
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};

export const getCondoAddressFromStorage = (): any => {
  const cardInfo = localStorage.getItem(CONDO_BOOKING_CARD_INFO_LABEL);
  if (cardInfo) {
    try {
      return JSON.parse(cardInfo);
    } catch (error) {
      console.error(error);
    }
  }

  return null;
};

export const removingDashFromString = (data: string): string => {
  return data.split('-').join('');
};

export const getPriceDiffDetails = (bookingSummary: IBookingSummary, hotel: IHotelInfo, marginator: number, breakdownTaxes: boolean) => {
  const newPriceInt = breakdownTaxes ?
                          get(bookingSummary, 'bookingCard.bookingPrice.totalFarePrice', 0) : 
                          get(bookingSummary, 'bookingCard.balance', 0);
  const newPrice = Math.floor(newPriceInt * 100) / 100;

  const selectedPackageId = get(bookingSummary, 'bookingCard.package.packageId', '');
  const groupPackages: IPackage[] = hotel?.adjustedPackageGroups.map(a => a.groupedPackages).flat();
  const selectedPackage = groupPackages?.filter(({ packageId }) => packageId === selectedPackageId);
  const oldPriceInt = get(selectedPackage, '[0].price', newPrice);
  let oldPrice = Math.floor(oldPriceInt * 100) / 100;

  let isIncrease = false;
  let diff = 0;
  if (marginator !== 0) {
    oldPrice = Math.ceil(oldPrice / (1 - marginator) * 100) / 100;
    diff = Math.abs(newPrice - oldPrice);
    isIncrease = diff > 0.9;
    diff = isIncrease ? diff : 0;
  } else {
    diff = Math.abs(newPrice - oldPrice);
    isIncrease = newPrice > oldPrice;
  }

  return {
    newPrice,
    oldPrice,
    isIncrease,
    diff
  };
}

export const getHotelDetailsPriceDiffDetails = (hotel: IHotel, hotelDetails: IHotelInfo, marginator: number, isDeal: boolean) => {
  let newPrice = 0;
  let oldPrice = 0;
  let isIncrease = false;
  let diff = 0;
  
  let newSavings = null;
  let oldSavings = null;
  let diffSavings = 0;

  if (!!hotelDetails) {
    const groupPackages: IPackage[] = hotelDetails?.adjustedPackageGroups.map(a => a.groupedPackages).flat();
    const selectedPackage = groupPackages?.reduce((prev, current) => (prev.price < current.price) ? prev : current);
    
    newPrice = get(selectedPackage, 'price', 0);
    oldPrice = get(hotel, 'price', (isDeal && !!hotelDetails?.hotelDetails?.dealPrice) ? hotelDetails?.hotelDetails?.dealPrice : newPrice);

    if (marginator !== 0) {
      oldPrice = Math.ceil(oldPrice / (1 - marginator) * 100) / 100;
      newPrice = Math.ceil(newPrice / (1 - marginator) * 100) / 100;
      diff = Math.abs(newPrice - oldPrice);
      isIncrease = diff > 0.9;
      diff = isIncrease ? diff : 0;
    } else {
      diff = Math.abs(newPrice - oldPrice);
      isIncrease = newPrice > oldPrice;
    }

    if (isDeal) {
      newSavings = get(selectedPackage, 'savingPercent', 0);
      oldSavings = !!hotelDetails?.hotelDetails?.dealSavingPercentage ? hotelDetails?.hotelDetails?.dealSavingPercentage : newSavings;
      diffSavings = Math.abs(newSavings - oldSavings);
    }

  }

  return {
    newPrice,
    oldPrice,
    isIncrease,
    diff,

    newSavings,
    oldSavings,
    diffSavings
  };
}