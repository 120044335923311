import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

export class CondoSpecialsComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { menuStore } = this.props;
    const { items } = menuStore;

    const customerServiceNumber = items?.customerServiceNumber;
    
    return (
      <div className="condo-specials">
        <div className="condo-specials__wrapper">
          <p className="condo-specials__title">
            <FormattedMessage id="condo.specials" />
          </p>
          <div className="condo-specials__descriptions-wrapper">
            <p className="condo-specials__description">
              <FormattedMessage id="every.week.we.will.attempt.to.find.deeply.discounted" />
            </p>
            <p className="condo-specials__description">
              <FormattedMessage id="these.are.call-in.and.they.go.fast" />{' '}
              <a href={`tel:${customerServiceNumber}`} className="condo-specials__contact-phone">
                {customerServiceNumber}
              </a>{' '}
              <FormattedMessage id="to.book.today" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return { menuStore: state.navigationMenuStore };
};

export const CondoSpecials = connect(mapStateToProps)(CondoSpecialsComponent);
