import React from 'react';
import parse from 'html-react-parser';
import { FormattedMessage } from 'react-intl';
import { IHotelDetails } from '@common-types';
import { CheckSvg } from '@assets';
import './style.scss';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

export class AdditionalDescription extends React.Component<IProps> {
  render(): React.ReactNode {
    const { hotel, refAnchor } = this.props;

    return hotel.additionalDescriptions?.length
      ?
        <div ref={refAnchor} className="additional-description pb-0">
          {hotel.isVacationRental
            ?
              <div className="rooms-search__list-text vacation mb-0">
                <div className="rooms-search__header" style={{ fontSize: '24px' }}><FormattedMessage id="additional.descriptions" /></div>
              </div>
            : <h4 className="additional-description__title"><FormattedMessage id="additional.descriptions" /></h4>}

          <div className="additional-description__text-wrapper">
            <div className="additional-description__text">
              {hotel.additionalDescriptions.map((hd, i) => <div key={i}><span style={{ marginRight: '0.5em' }}><CheckSvg /></span>{parse(hd)}</div>)}
            </div>
          </div>
        </div>
      : null;
  }
}