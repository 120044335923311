import { IAccount } from "@share/common-types";
import jwt_decode from "jwt-decode";
 
export const IMP_TOKEN_LABEL = 'imp_token';

export interface IImpersonate {
    IsTravelAgentPro: boolean;
    ImpersonationUser: string;
    ImpersonationStoreType: string;
    AccountName: string;
    MemberId: string;
}

const initialState: IImpersonate = {
    IsTravelAgentPro: false,
    ImpersonationUser: '',
    ImpersonationStoreType: '',
    AccountName: '',
    MemberId: ''
};

export const DecodeToken = (token: string): string => {
    let cleaned = token.trim();
    cleaned = cleaned.includes(" ") ? cleaned.split(" ")[1] : cleaned;

    try {
        return JSON.stringify(jwt_decode(cleaned), undefined, 2);
    } catch (error) {
        return null;
    }
};

export const GetToken = (): IImpersonate => {
    const token = window.sessionStorage.getItem(IMP_TOKEN_LABEL);
    if (!token)
        {return initialState;}

    try {
        return JSON.parse(token);
    } catch (error) {
        return initialState;
    }
};

export const CanImpersonate = (account: IAccount): boolean => {
    return account?.isTravelAgentPro && !account?.disableImpersonate;
};

export const IsImpersonating = (): boolean => {
    const obj = GetToken();
    return !!obj.ImpersonationUser;
};

export const IsImpersonatingStore = (): string => {
    const obj = GetToken();
    return obj?.ImpersonationStoreType;
};

export const SaveToken = (accessToken: string): void => {
    const token = DecodeToken(accessToken);
    window.sessionStorage.setItem(IMP_TOKEN_LABEL, token);
};

export const RemoveToken = (): void => {
    window.sessionStorage.removeItem(IMP_TOKEN_LABEL);
};