import React from 'react';

import { connect } from 'react-redux';

import { IBenefitsItems } from '@share/common-types';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState, isExternalUrl, getLocalURI } from '@share/utils';
import { Link } from 'react-router-dom';

import { LinkSvg } from '@assets';

import './style.scss';
import { FormattedMessage } from 'react-intl';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  benefit: IBenefitsItems;
}

class BenefitComponent extends React.Component<IProps> {
  render() {
    const { benefit, loginStore } = this.props;
    const { type, title, url, description, imageUrl } = benefit;
    const { account } = loginStore;

    return isExternalUrl(url) ? (
      <a href={url} className="benefit" target="_blank" rel="noreferrer">
        <div className="benefit__img-wrapper">
          <img src={imageUrl} alt={type} />
        </div>
        <div className="benefit__text-wrapper">
          <p className="benefit__title"><FormattedMessage id={title} /></p>
          <p className="benefit__description"><FormattedMessage id={description} /></p>
        </div>
        <LinkSvg />
      </a>
    ) : (
      <Link 
        to={`/${account?.name}${getLocalURI(url)}`}
        className="benefit"
      >
        <div className="benefit__img-wrapper">
          <img src={imageUrl} alt={type} />
        </div>
        <div className="benefit__text-wrapper">
          <p className="benefit__title"><FormattedMessage id={title} /></p>
          <p className="benefit__description"><FormattedMessage id={description} /></p>
        </div>
      </Link>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const Benefit = connect(mapStateToProps)(BenefitComponent);
