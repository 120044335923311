import React from 'react';

import moment from 'moment';
import debounce from 'lodash/debounce';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import addDays from 'date-fns/addDays';
import subYears from 'date-fns/subYears';
import setMonth from 'date-fns/setMonth';
import differenceInDays from 'date-fns/differenceInDays';

import { DateRangePicker, OnChangeProps, Range, RangeFocus } from 'react-date-range';
import { FormattedMessage } from 'react-intl';
import { Action } from 'redux';
import { SelectValue } from 'antd/lib/select';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { isEmpty } from 'lodash';

import { BlueButton, MonthSelect } from '@share/components';
import { ILoginState, ICarsDatesState, carsDatesActions, CarsErrorTypes } from '@share/store/slices';
import { CarsMaxDaysRange, formatDateCheckInOut, getDateCheckInOut, RootState } from '@share/utils';
import { MonthsEnum } from '@share/common-types';
import { DATE_FORMAT, DEFAULT_MEDIA_POINT } from '@share/constants';

import { CloseSvg, ArrowLeftSvg, ArrowRightSvg } from '@share/assets';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CarsTimePicker } from '../cars-time-picker';

import './style.scss';

interface IMapStateToProps {
  carsDatesStore: ICarsDatesState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDatesSelected: (dates: { startDate: Date; endDate: Date }) => void;
  setKey: (key: string) => void;
  clearError: () => void;
  setIsFocused?: (value: boolean) => void;
  setStartDateTimeSelected: (time: string) => void;
  setEndDateTimeSelected: (time: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  isUpdateSearch?: boolean;
}

interface IState {
  isFocused: boolean;
  startDate: Date;
  initialStartDate: Date;
  endDate: Date;
  initialEndDate: Date;
  minDate: Date;
  maxDate: Date;
  key: string;
  isEndDateSelected: boolean;
  focusedRange: RangeFocus;
  isMobile: boolean;
  isInitial: boolean;
  changed: boolean;
}

const oneItem = 1;
const resizeDebounceTime = 300;
const mobileMonthCount = 12;
const fullMonthCount = 2;
const defaultRange = 0;
const isStart = 0;

class CarsDatePickerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  isInitial = true;

  constructor(props: IProps) {
    super(props);

    const { carsDatesStore } = props;

    const now = new Date();
    const startDate = carsDatesStore.startDate
      ? getDateCheckInOut(carsDatesStore.startDate)
      : new Date();
    const endDate = carsDatesStore.endDate
      ? getDateCheckInOut(carsDatesStore.endDate)
      : new Date();

    this.state = {
      isFocused: false,
      startDate,
      initialStartDate: startDate,
      initialEndDate: endDate,
      endDate,
      minDate: now,
      maxDate: addYears(now, oneItem),
      key: carsDatesStore.key,
      isEndDateSelected: true,
      focusedRange: [defaultRange, isStart],
      isMobile: document.body.offsetWidth <= DEFAULT_MEDIA_POINT,
      isInitial: true,
      changed: false
    };
  }

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.onResize);
  }

  static getDerivedStateFromProps(props: IProps, state: IState): IState {
    const { startDateSelected, endDateSelected } = props.carsDatesStore;

    if (
      state.isInitial &&
      startDateSelected &&
      endDateSelected
    ) {
      const start = getDateCheckInOut(startDateSelected);
      const end = getDateCheckInOut(endDateSelected);

      return {
        ...state,
        startDate: start,
        endDate: end,
        isInitial: false,
      };
    }

    return null;
  }

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true, changed: false });
    }
  };

  blur = (): void => {
    const { isFocused, startDate, endDate } = this.state;

    if (isFocused) {

      if (!this.state.isEndDateSelected && startDate.toISOString() === endDate.toISOString()) {
        this.setState(
          {
            startDate,
            initialStartDate: startDate,
            endDate: addDays(new Date(startDate), oneItem),
            initialEndDate: addDays(new Date(startDate), oneItem),
          },
          this.updateProps,
        );
      }

      this.setState({
        isFocused: false,
        isEndDateSelected: true,
        focusedRange: [defaultRange, isStart],
      });
    }
  };

  setInitialValues = (): void => {
    const { setDatesSelected } = this.props;
    const { endDate, startDate, initialEndDate, initialStartDate } = this.state;
    const isMoreThenMaxDays = differenceInDays(endDate, startDate) > CarsMaxDaysRange;
    const areInitialDatesEqual = initialStartDate.toString() === initialEndDate.toString();
    const equalAfterDateAdd = areInitialDatesEqual
      ? addDays(new Date(startDate), oneItem)
      : initialEndDate;

    if (isMoreThenMaxDays) {
      this.setState({
        startDate: initialStartDate,
        endDate: equalAfterDateAdd,
      });

      setDatesSelected({
        startDate: initialStartDate,
        endDate: equalAfterDateAdd,
      });
    }

    this.blur();
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      (this.wrapperRef && !this.wrapperRef.current.contains(event.target as Node)) ||
      (event.target as HTMLElement).classList.contains('date-picker__fixed-bg')
    ) {
      this.setInitialValues();
    }
  };

  onResize = debounce(() => {
    if (document.body.offsetWidth <= DEFAULT_MEDIA_POINT && !this.state.isMobile) {
      const { startDate, endDate } = this.state;
      this.setState({ isMobile: true, startDate: new Date(), endDate: new Date() });

      setTimeout(() => {
        this.setState({ startDate, endDate });
      });
    } else if (document.body.offsetWidth > DEFAULT_MEDIA_POINT && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }, resizeDebounceTime);

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.carsDatesStore.isFocused !== this.props.carsDatesStore.isFocused) {
      this.setState({ isFocused: true });
      this.props.setIsFocused(false);
    }

    if ((!!prevProps.carsDatesStore.endDate && !this.props.carsDatesStore.endDate) ||
        (!!prevProps.carsDatesStore.startDate && !this.props.carsDatesStore.startDate)) {
      this.setState({ startDate: new Date(), endDate: new Date() });
    }
  }

  onDone = (): void => {
    this.setState({ changed: true }, this.blur);
  };

  onSelect = (ranges: OnChangeProps): void => {
    this.props.clearError();
    const range = ranges as { selection: Range };
    this.isInitial = false;

    if (range.selection) {
      const { startDate, endDate } = range.selection;
      const endDateCalculated = 
        (!this.state.isEndDateSelected && startDate.toString() === endDate.toString())?
          addDays(new Date(startDate), oneItem) :
          endDate;
      this.setState(
        {
          startDate,
          endDate: endDateCalculated,
          isInitial: false,
          changed: true,
        },
        this.updateProps,
      );
  }
  };

  updateProps = (): void => {
    const { setDatesSelected } = this.props;

    setDatesSelected({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    });
  };

  onRangeFocusChange = (focusedRange: RangeFocus): void => {
    this.setState({ focusedRange, isEndDateSelected: !focusedRange[oneItem] });
  };

  dayContentRenderer = (date: Date) => {
    const { carsDatesStore } = this.props;
    const { startDate } = this.state;

    const start = new Date(startDate);
    const startMoment = moment(start);
    const dateMoment = moment(date);

    const day = moment(date).date();
    const daysDiff = start && !this.state.isInitial && carsDatesStore.startDateSelected ? dateMoment.diff(startMoment, 'days') : 0;

    if (daysDiff > 0) {
      return (
        <Tooltip placement="top" color="#0d99d6" title={`${daysDiff} day${daysDiff > 1 ? 's' : ''}`}>
          <span>{day}</span>
        </Tooltip>
      );
    }

    return (<span>{day}</span>);
  }

  getNavigatorRender = (
    currentFocusedDate: Date,
    changeShownDate: (count: number | Date, type: string) => void,
  ): React.ReactElement => {
    const today = new Date();

    const { loginStore } = this.props;
    const { account, originalUser } = loginStore;

    const leadTimeAccount = account?.leadTime;
    const leadTimeExcludeUsers = account?.leadTimeExcludeUsers;
    const leadTimeExcludeUserList = account?.leadTimeExcludeUserList;

    const leadTime = (!leadTimeExcludeUsers || !leadTimeExcludeUserList?.includes(originalUser?.userId)) ? leadTimeAccount : 0;

    return (
      <div onMouseUp={(e) => e.stopPropagation()}>
        <div className="date-picker__arrows">
          <div
            className="date-picker__prev"
            onClick={() => changeShownDate(-oneItem, 'monthOffset')}
          >
            <ArrowLeftSvg />
          </div>
          <div
            className="date-picker__next"
            onClick={() => changeShownDate(+oneItem, 'monthOffset')}
          >
            <ArrowRightSvg />
          </div>
        </div>

        {leadTime > 0 ? (
          <div className="date-picker__lead-time-message">
            <FormattedMessage id="lead.time.message" values={{ leadTime }} />
          </div>) : null}

        <div className="date-picker__months">
          <div className="date-picker__months-left">
            <MonthSelect
              onChange={(val: SelectValue) => changeShownDate(val as number, 'setMonth')}
              value={currentFocusedDate.getMonth()}
              date={currentFocusedDate}
              minMonth={today.getMonth()}
            />
          </div>
          <div className="date-picker__months-right">
            <MonthSelect
              onChange={(val: SelectValue) => {
                if (val === MonthsEnum.January) {
                  changeShownDate(
                    setMonth(subYears(currentFocusedDate, oneItem), MonthsEnum.December),
                    'set',
                  );
                } else {
                  if (currentFocusedDate.getMonth() === MonthsEnum.December) {
                    changeShownDate(
                      setMonth(addYears(currentFocusedDate, oneItem), val as number),
                      'set',
                    );
                  } else {
                    changeShownDate((val as number) - oneItem, 'setMonth');
                  }
                }
              }}
              minMonth={today.getMonth() + oneItem}
              value={addMonths(currentFocusedDate, oneItem).getMonth()}
              date={addMonths(currentFocusedDate, oneItem)}
            />
          </div>
        </div>
      </div>
    );
  };

  render(): React.ReactNode {
    const { carsDatesStore, loginStore, isUpdateSearch } = this.props;
    const { error, errorType, startDateTimeSelected, endDateTimeSelected } = carsDatesStore;
    const { account, originalUser } = loginStore;
    const { isFocused, endDate, startDate, key, focusedRange, isEndDateSelected, isMobile } = this.state;
    const start = new Date(startDate);
    const end = new Date(endDate);
    const selectionRange = {
      startDate: start,
      endDate: end,
      key,
    };
    const hideSelection: boolean = isEndDateSelected && start?.toISOString() === end?.toISOString();
    const focusZIndex = 4;
    const blurZIndex = 1;
    const isMoreThenMaxDays = differenceInDays(end, start) > CarsMaxDaysRange;
    const locale = account?.locale;
    const leadTimeAccount = account?.leadTime;
    const leadTimeExcludeUsers = account?.leadTimeExcludeUsers;
    const leadTimeExcludeUserList = account?.leadTimeExcludeUserList;

    const color: any = account?.searchIconsColor;

    const leadTime = (!leadTimeExcludeUsers || !leadTimeExcludeUserList.includes(originalUser?.userId)) ? leadTimeAccount : 0;

    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div className={`date-picker cars-dates ${!!error ? 'cars-error' : ''}`} ref={this.wrapperRef}>
        <div
          className={`
            date-picker__wrapper dates-selection
            ${isFocused && isEndDateSelected ? 'selected' : ''}
            ${isFocused ? 'open' : ''}
            ${!!error ? 'error' : ''}
            ${!!error && errorType === CarsErrorTypes.Date ? 'error-date' : ''}
          `}
          onClick={this.focus}
          style={{ zIndex: isFocused ? focusZIndex : blurZIndex }}
        >
          <div className="date-picker__left">
            <div className="date-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </div>
          <div className="date-picker__right">
            <div className="date-picker__label">
              {isFocused ? (
                <FormattedMessage id="cars.pickup" />) : (
                <FormattedMessage id="cars.dates" />)}
            </div>
            <div className="date-picker__header">
              {isFocused ? (
                start && !this.state.isInitial && carsDatesStore.startDateSelected ? (
                  <span className="date-picker__date">
                    {formatDateCheckInOut(carsDatesStore.startDateSelected, DATE_FORMAT, locale)}
                  </span>) : (
                  <FormattedMessage id="select.date" />)
              ) : (
                carsDatesStore.startDateSelected && carsDatesStore.endDateSelected && !this.state.isInitial ? (
                  <span className="date-picker__date">
                    {formatDateCheckInOut(carsDatesStore.startDateSelected, DATE_FORMAT, locale)} -{' '}
                    {formatDateCheckInOut(carsDatesStore.endDateSelected, DATE_FORMAT, locale)}
                  </span>) : (
                  <FormattedMessage id="add.dates" />)
              )}
            </div>
          </div>
        </div>

        <div className="date-picker__time-selection">
          <CarsTimePicker timeSelected={startDateTimeSelected} type="pickUp" setTimeSelected={this.props.setStartDateTimeSelected} />
          <CarsTimePicker timeSelected={endDateTimeSelected} type="dropOff" setTimeSelected={this.props.setEndDateTimeSelected} />
        </div>

        <div className="date-picker__dropdown" style={{ display: isFocused ? 'block' : 'none' }}>
          <div
            className="date-picker__mobile-header"
            style={{ display: isMobile ? 'block' : 'none' }}
          >
            {isEndDateSelected ? (
              <FormattedMessage id="cars.pickup" />) : (
              <FormattedMessage id="cars.dropoff" />)}
          </div>

          <div className={`date-picker__wrapper cars-dates right ${!isEndDateSelected ? 'selected' : ''}`}>
            <div className="date-picker__left">
              <div className="date-picker__icon" style={styleColor}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </div>
            <div className="date-picker__right">
              <div className="date-picker__label">
                <FormattedMessage id="cars.dropoff" />
              </div>
              <div className="date-picker__header">
                {end && !this.state.isInitial && isEndDateSelected && carsDatesStore.endDateSelected ? (
                  <span className="date-picker__date">
                    {formatDateCheckInOut(carsDatesStore.endDateSelected, DATE_FORMAT, locale)}
                  </span>) : (
                  <FormattedMessage id="select.date" />)}
              </div>
            </div>
          </div>

          <div className="date-picker__close-icon" onClick={this.setInitialValues}>
            <CloseSvg />
          </div>

          <div className={`date-picker__picker cars-dates ${hideSelection ? 'hide-selection' : ''} ${isEndDateSelected ? '' : 'show-checkout'}`}>
            <div className="date-picker__picker-wrapper">
              <DateRangePicker
                onChange={this.onSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={isMobile ? mobileMonthCount : fullMonthCount}
                monthDisplayFormat="MMMM, yyyy"
                ranges={[selectionRange]}
                locale={locale}
                direction={isMobile ? 'vertical' : 'horizontal'}
                weekStartsOn={0}
                weekdayDisplayFormat="EEEEEE"
                preventSnapRefocus
                minDate={addDays(new Date(), leadTime)}
                maxDate={addYears(new Date(), oneItem)}
                focusedRange={focusedRange}
                onRangeFocusChange={this.onRangeFocusChange}
                dayContentRenderer={this.dayContentRenderer}
                // @ts-ignore
                navigatorRenderer={this.getNavigatorRender}
              />
            </div>
          </div>

          <div className={`date-picker__footer ${isMoreThenMaxDays ? 'right-button' : ''}`}>
            {isMoreThenMaxDays ? (
              <div className="date-picker__error-message">
                <FormattedMessage id="cars.maximum.date.range.search.custom" values={{ number: CarsMaxDaysRange }} />
              </div>) : null}

            <BlueButton onClick={this.onDone} disabled={isMoreThenMaxDays}>
              {!isUpdateSearch ? (<FormattedMessage id="done" />) : null}
              {isUpdateSearch ? (<FormattedMessage id="search" />) : null}
            </BlueButton>
          </div>
        </div>

        {isFocused ? <div className="date-picker__fixed-bg" /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsDatesStore: state.carsDatesStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setDatesSelected: (dates: { startDate: Date; endDate: Date; }) => {
    dispatch(
      carsDatesActions.setDatesSelected({
        startDate: moment(dates.startDate).format('yyyy-MM-DD'),
        endDate: moment(dates.endDate).format('yyyy-MM-DD'),
      }),
    );
  },
  setKey: (key: string) => {
    dispatch(carsDatesActions.setKey(key));
  },
  clearError: () => {
    dispatch(carsDatesActions.setError(''));
    dispatch(carsDatesActions.setErrorType(undefined));
  },
  setIsFocused: () => {
    dispatch(carsDatesActions.setIsFocused());
  },
  setStartDateTimeSelected: (time: string) => {
    dispatch(carsDatesActions.setStartDateTimeSelected(time));
  },
  setEndDateTimeSelected: (time: string) => {
    dispatch(carsDatesActions.setEndDateTimeSelected(time));
  },
});

export const CarsDatePicker = connect(mapStateToProps, mapDispatchToProps)(CarsDatePickerComponent);
