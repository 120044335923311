import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { renderNumber } from '@share/utils';
import { RootState } from '@share/utils';
import { IHotelDetailsState, IReviewBookState, IRoomsSearchState } from '@store/slices';
import { Currency } from '@components';
import { NoImageSvg } from '@assets';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  hotelDetailsStore: IHotelDetailsState;
  roomsSearchStore: IRoomsSearchState;
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps { }

const ZERO = 0;

class RoomImageComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { roomsSearchStore, reviewBookStore, hotelDetailsStore, loginStore } = this.props
    const { bookingComplete, bookingSummary } = reviewBookStore;
    const { account } = loginStore;

    const { roomsTitle, titleImage } = bookingSummary.bookingCard;
   
    const hotel = roomsSearchStore.hotel ? roomsSearchStore.hotel : hotelDetailsStore.hotel;
  
    const pricePerNight = bookingSummary.bookingCard.package.pricePerNight;

    const packageId = bookingSummary.bookingCard.package.packageId;
    const currency = bookingSummary.bookingCard.package.currency;
    
    const adjustedPackageGroups = hotel?.adjustedPackageGroups;
    const groupPackages = adjustedPackageGroups?.map(({ groupedPackages }) => get(groupedPackages, `[0]`, null)).filter(p => !!p);
    const cheapestRoom = groupPackages?.reduce((prev, current) => (+current.pricePerNight >= +prev.pricePerNight)? prev :  current);
    const preferredRoomSavingsAmount = get(cheapestRoom, `savings`, 0);
    const preferredRoomPrice = get(cheapestRoom, `price`, 1);
    const preferredRoomPackageId = get(cheapestRoom, `packageId`, null);
    const preferredRoomSavingPct = preferredRoomSavingsAmount / (preferredRoomPrice + preferredRoomSavingsAmount) * 100;
    const isPreferredRoomPackageId = preferredRoomPackageId === packageId;

    return (
      <div className="room-image">

        <div className="room-image__wrapper">
          {bookingComplete && (
            <div className="room-image__label-info">
              <p className="room-image__label-text-per-night">
                <FormattedMessage id="avg.rate" />
              </p>
              <p className="room-image__label-price"><Currency currency={currency} />{renderNumber(pricePerNight)}</p>
              <p className="room-image__label-text-per-night">
                <FormattedMessage id="per.night" />
              </p>
            </div>
          )}

          {(preferredRoomSavingPct && preferredRoomSavingPct > 0) ? (
            <div className={`room-image__room-preferred-savings ${isPreferredRoomPackageId? 'preferred' : 'non-preferred' }`}>
              <div className="hotel-card-wrapper__label-container">
                <div className="hotel-card-wrapper__label">
                  <p className="hotel-card-wrapper__label-title">
                    <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {Math.floor(preferredRoomSavingPct).toFixed(0)} %
                  </p>
                </div>
                <div className="hotel-card-wrapper__label-corner"></div>
              </div>
            </div>) : null}

          {titleImage.length !== ZERO ? (
            <div className={`room-image__room-image`}>
              {isPreferredRoomPackageId && (<div className={`room-image__room-preferred`}>Popular room</div>)}
              <img className="room-image__room-img" src={titleImage} alt={roomsTitle} />
            </div>
          ) : (
            <div className="room-image__room-img no-image">
              <NoImageSvg />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    roomsSearchStore: state.roomsSearchStore,
    hotelDetailsStore: state.hotelDetailsStore,
    loginStore: state.loginStore,
  };
};

export const RoomImage = connect(mapStateToProps)(withRouter(RoomImageComponent));

