import React from 'react';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Button } from 'antd';
import { Action } from 'redux';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Radio } from 'antd';
import { SelectValue } from 'antd/lib/select';

import {
  IDatesState,
  IRoomsState,
  ILocationsState,
  filtersActions,
  IHotelsState,
  IFiltersState,
  hotelsActions,
  ILoginState,
  LoginType,
} from '@share/store/slices';
import {
  SESSION_KEY_LABEL,
  HomesTypes,
  R_CLICK_VAC_RENTAL_BUTTON,
  R_CLICK_HOTEL_BUTTON,
  MAIN_BLUE_COLOR,
  VACATIONS_SELECTION_TYPE,
  HOTELS_SELECTION_TYPE,
  DEFAULT_MEDIA_POINT,
} from '@share/constants';
import { BACKGROUND_PHOTO_FIELD_KEY, BACKGROUND_PHOTO_MOBILE_FIELD_KEY, ISessionKey, VACATION_RENTALS_SEARCH_MESSAGE_FIELD_KEY, VACATION_RENTALS_SEARCH_TITLE_FIELD_KEY } from '@share/common-types';
import { BACKGROUND_COLOR_FIELD_KEY, SEARCH_MESSAGE_FIELD_KEY, SEARCH_TITLE_FIELD_KEY } from '@share/common-types';
import { UrlUtils, getHomesOptions, getTimeout, getPreferenceFromAccount, RootState, getPreferenceFileUrlFromAccount, IsImpersonating, CanImpersonate, isAllInclusiveFromPath } from '@share/utils';

import HotelBackground from '@share/assets/images/hotel/bg-hotels.png';
import TravcodingBackground from '@share/assets/images/travcoding-background.jpg';

import { SearchInfo } from '../search-info';
import { SearchHotel } from '../search-hotel';
import { SearchSelection } from '../search-selection';
import { AccessToken } from '../access-token';
import { Impersonator } from '../../impersonator';

import './style.scss';

interface IMapStateToProps {
  datesStore: IDatesState;
  roomsStore: IRoomsState;
  locationsStore: ILocationsState;
  hotelsStore: IHotelsState;
  filtersStore: IFiltersState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setIsWidget: (isWidget: boolean) => void;
  setSessionKey: (session: ISessionKey) => void;
  setSearchHomes: (value: string) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  title?: string;
  message?: string;
  hideTitle?: boolean;
  hideMessage?: boolean;
  hideSearchSelection?: boolean;
  widgetBackgroundColor?: string;
  widgetOriginalType?: string;
  borderRadius?: string;

  excludeCondosSelection?: boolean;
  excludeHotelsSelection?: boolean;
  excludeVacationsSelection?: boolean;
  disableLocationSearch?: boolean;

  isWidget?: boolean;
  isExperiences?: boolean;
  isVacationRentals?: boolean;

  forceVertical?: boolean;
  onExperience?: () => void;
  onWidgetSelectionChange?: (value: string) => void;

  openOnClick?: boolean;
}

interface IState {
  isCollapse: boolean;
}

const zero = 0;

class SearchWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    isCollapse: true,
  };

  componentDidMount() {
    if (this.props.isWidget) {
      this.props.setIsWidget(this.props.isWidget);
    }

    const values = UrlUtils.getValues();
    const session: ISessionKey = values[SESSION_KEY_LABEL] as ISessionKey;
    if (session && session.expireDate && getTimeout(session.expireDate) > zero) {
      this.props.setSessionKey(session);
    }

    if (this.props.isVacationRentals) {
      this.props.setSearchHomes(HomesTypes.VacationRentalOnly);
    }
  }

  onToggle = () => {
    this.setState({ isCollapse: !this.state.isCollapse });
  };

  handleSearchHomes = (value: SelectValue): void => {
    const valueStr = value as string;
    this.props.setSearchHomes(valueStr);

    const typesToLog = [HomesTypes.HotelOnly.toString(), HomesTypes.VacationRentalOnly.toString()];
    if (typesToLog.includes(valueStr)) {

      const { loginStore } = this.props;
      const { account } = loginStore;

      ReactGA.event({
        category: account.name,
        action: `${value === HomesTypes.HotelOnly ? R_CLICK_HOTEL_BUTTON : R_CLICK_VAC_RENTAL_BUTTON}_${account.name.toUpperCase()}`,
        label: `User clicked ${value === HomesTypes.HotelOnly ? 'Hotel' : 'Vacation rental'} button on search`,
        nonInteraction: false,
      });
    }
  }

  render(): React.ReactNode {
    const {
      hotelsStore,
      locationsStore,
      datesStore,
      loginStore,
      isWidget,
      isExperiences,
      forceVertical,
      filtersStore,
      isVacationRentals,
      hideTitle,
      title,
      hideMessage,
      message,
      hideSearchSelection,
      widgetBackgroundColor,
      excludeCondosSelection,
      excludeHotelsSelection,
      excludeVacationsSelection,
      widgetOriginalType,
      borderRadius,
      disableLocationSearch,
      openOnClick
    } = this.props;
    const { isSearch, hotels, loading } = hotelsStore;
    const { account } = loginStore;
    const isAfterSearchLoad =
      (!isWidget && !isExperiences) && ((isSearch && !(!!locationsStore.error || !!datesStore.error)) || (hotels && !!hotels.length));

    const background = getPreferenceFromAccount(account, BACKGROUND_COLOR_FIELD_KEY);
    const searchTitle = getPreferenceFromAccount(account, isVacationRentals ? VACATION_RENTALS_SEARCH_TITLE_FIELD_KEY : SEARCH_TITLE_FIELD_KEY);
    const searchMessage = getPreferenceFromAccount(account, isVacationRentals ? VACATION_RENTALS_SEARCH_MESSAGE_FIELD_KEY : SEARCH_MESSAGE_FIELD_KEY);
    const backgroundImage = getPreferenceFileUrlFromAccount(account, BACKGROUND_PHOTO_FIELD_KEY);
    const backgroundImageMobile = getPreferenceFileUrlFromAccount(account, BACKGROUND_PHOTO_MOBILE_FIELD_KEY);
    
    const isRSITemplate = account?.isRSITemplate;

    const styleAccount: any = {};
    const mainStyle: any = {};
    let withImage = false;

    const isAllInclusivePage = isAllInclusiveFromPath(this.props.history);

    if ((isWidget && widgetBackgroundColor) || isAllInclusivePage) {
      styleAccount.background = widgetBackgroundColor;
      mainStyle.background = 'none';
    } else {
      const isMobile = document.body.offsetWidth <= DEFAULT_MEDIA_POINT;

      const logoImage = backgroundImage ? 
        (isMobile && backgroundImageMobile) ? 
          backgroundImageMobile : 
          backgroundImage : 
        isRSITemplate ? 
            HotelBackground : 
            background ? 
                null : 
                isWidget ? null : TravcodingBackground;

      if (logoImage) {
        withImage = true;
        styleAccount.backgroundImage = isAfterSearchLoad ?
            isRSITemplate && `url(${logoImage})` :
            isWidget ?
              `url(${logoImage})` :
              `linear-gradient(rgba(255,255,255,0) 80%, rgba(255,255,255,1)), url(${logoImage})`;

        if (!isAfterSearchLoad) {
          mainStyle.background = 'none';
        }
      }
      
      if (background && !logoImage) {
        styleAccount.background = background;
        mainStyle.background = 'none';
      }  
    }

    const sectionClassname = `search-wrapper__search-section ${(isWidget || isExperiences)? 'widget' : ''} ${forceVertical? 'vertical' : ''} ${(isWidget && hideTitle && hideMessage)? 'widget-hide' : ''}`;
    const headerClassname = `search-wrapper__header ${(isWidget || isExperiences)? 'widget' : ''} ${(isWidget && hideMessage)? 'widget-hide' : ''}`;

    const hasPublicAccessToken = account?.hasPublicAccessToken;
    const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

    const isCollapse = this.state.isCollapse && (loading || !!hotels?.length);

    const color: any = account?.buttonColor ? account?.buttonColor : MAIN_BLUE_COLOR;
    const styleColor = !isEmpty(color)? { background: color, borderRightColor: color, borderColor: color } : {};  
    
    const searchTitleFinal = isWidget && !isEmpty(title) ? title : searchTitle;
    const searchMessageFinal = isWidget && !isEmpty(message) ? message : searchMessage;
    
    if (!isEmpty(borderRadius)) {
      try {
        const border = parseInt(borderRadius);
        mainStyle.borderRadius = `${border}px`;
        styleAccount.borderRadius = `${border}px`;
      } catch(e) {
        console.error(e);
      }
    }

    const showImpersonate = CanImpersonate(account) && !IsImpersonating() && !isAfterSearchLoad && !account?.disableImpersonate;
    const disableOption = !isVacationRentals && hasPublicValidToken && !isWidget && !isExperiences && !isAfterSearchLoad && !isAllInclusivePage;
    const forceDisableVacationRentals = account?.forceDisableVacationRentals;
    const forceDisableHotelSearchOptions = account?.forceDisableHotelSearchOptions;
    
    return (
      <div
        className={`search-wrapper ${isAfterSearchLoad ? 'after-search' : ''} ${isExperiences ? 'search-experiences' : ''} ${isCollapse ? 'toggle-search-info' : ''} ${(isWidget || isExperiences)? 'widget' : ''} ${backgroundImage? 'background-image' : ''}`}
        style={mainStyle}
      >
        {!isExperiences ? (<div className={`search-wrapper__image ${isWidget ? 'widget' : ''} ${(withImage && !isAfterSearchLoad) ? 'with-image' : ''}`} style={styleAccount}></div>) : null}

        <div className="search-wrapper__wrapper">
          {(!isExperiences && !hideTitle) ? (
            <h1 className={headerClassname} style={{ minHeight: '42px' }}>
              {isEmpty(searchTitleFinal)? <FormattedMessage id={isVacationRentals ? 'main.vacations.header' : 'main.header'} /> : searchTitleFinal}
            </h1>) : null}

          {(!isExperiences && !hideMessage) ? (
            <p className={`search-wrapper__header-small ${!isRSITemplate ? 'travcoding-template' : ''} ${isWidget ? 'widget' : ''} ${(isWidget && hideTitle) ? 'widget-hide' : ''}`}>
              {isEmpty(searchMessageFinal)? <FormattedMessage id={isVacationRentals ? 'main.vacations.header.small' : 'main.header.small'} /> : searchMessageFinal}
            </p>) : null}
          
          <div className={sectionClassname} style={{ flexDirection: 'column', height: 'auto' }}>
            {(hasPublicValidToken || isWidget) && !isExperiences && !isAfterSearchLoad && !hideSearchSelection && (
              <SearchSelection
                type={isVacationRentals ? VACATIONS_SELECTION_TYPE : HOTELS_SELECTION_TYPE}
                isWidget={isWidget}
                widgetOriginalType={widgetOriginalType}
                onWidgetSelectionChange={this.props.onWidgetSelectionChange}
                excludeCondosSelection={excludeCondosSelection}
                excludeHotelsSelection={excludeHotelsSelection}
                excludeVacationsSelection={excludeVacationsSelection}
              />)}

            <div className={`search-wrapper__search-section content ${forceVertical? 'vertical' : ''}`}>
              {(hasPublicValidToken || isWidget) && (
                <SearchHotel 
                  isWidget={isWidget}
                  isExperiences={isExperiences}
                  isAfterSearchLoad={isAfterSearchLoad}
                  isVacationRentals={isVacationRentals}
                  disableLocationSearch={disableLocationSearch}
                  onExperience={this.props.onExperience}
                  onSearch={() => {this.setState({ isCollapse: true })}}
                  openOnClick={openOnClick}
                />)}
                
              {(!hasPublicValidToken && !isWidget) && (
                <AccessToken />)}
            </div>

            {(disableOption && !forceDisableVacationRentals && !forceDisableHotelSearchOptions) ? (
              <div className="row pt-3 result-wrapper__style-home-search-container" style={{ marginTop: (locationsStore.error || datesStore.error) ? '10px' : '0px' }}>
                <div className={showImpersonate ? "col-9" : "col-12"}>
                  <Radio.Group value={filtersStore.searchHomes} optionType="button" buttonStyle="solid" size="large" onChange={(e) => this.handleSearchHomes(e.target.value)}>
                    {getHomesOptions(false).map((item, index) => (
                      <Radio.Button 
                        key={`${index}-${item}`}
                        value={item.value}
                        className="result-wrapper__style-home-search"
                        style={item.value === filtersStore.searchHomes ? styleColor : {}}
                      >
                        {item.icon} {item.label}
                      </Radio.Button>))}
                  </Radio.Group>
                </div>
              </div>) : null}

            {showImpersonate && 
              <>
                {account?.forceImpersonate ? (
                  <div className="row pt-2 result-wrapper__style-home-search-container" style={{ flexFlow: (disableOption && !forceDisableVacationRentals) ? 'initial' : 'row-reverse' }}>
                    <div className="col-12" style={{alignSelf: 'center', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', color: 'red' }}><FormattedMessage id="please.impersonate.continue" /></div>
                  </div>) : null}
                <div className="row pt-2 result-wrapper__style-home-search-container" style={{ flexFlow: (disableOption && !forceDisableVacationRentals) ? 'initial' : 'row-reverse' }}>
                  <div className="col-lg-4" style={{alignSelf: 'center'}}><Impersonator /></div>
                </div>
              </>}
          </div>

          {isCollapse && !isWidget && !isExperiences && <SearchInfo onToggle={this.onToggle} />}
          {isAfterSearchLoad && !isCollapse && (
            <Button
              className="search-wrapper__toggle-search-info"
              onClick={this.onToggle}
              type="text"
            >
              <FormattedMessage id="cancel" />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    datesStore: state.datesStore,
    roomsStore: state.roomsStore,
    locationsStore: state.locationsStore,
    hotelsStore: state.hotelsStore,
    filtersStore: state.filtersStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setSessionKey: (session: ISessionKey) => {
    dispatch(hotelsActions.setSessionKey(session));
  },
  setIsWidget: (isWidget: boolean) => {
    dispatch(hotelsActions.setIsWidget(isWidget));
  },
  setSearchHomes: (value: string) => {
    dispatch(filtersActions.setSearchHomes(value as HomesTypes));
  },
});

export const SearchWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(SearchWrapperComponent)));
