import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tabs, Spin, TabsProps } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEmpty } from 'lodash';

import { Reservations, MyTravelHistory, MyProfile, ChangePassword, MyQuotes, MySideKickRewards, MyAccount } from '@components';
import { ILoginState, IMenuState, LoginType } from '@share/store/slices';
import { RootState, isQuotesFromPath, getAccountUsernameFromPath, isTestDriveManagerFromPath, isReservationsFromPath } from '@share/utils';
import { Loading } from '@components';
import { Routes } from '@share/constants';
import { LoadingSise } from '@common-types';
import { ISideKickStatus } from '@share/common-types';

import HotelBackground from '@assets/images/hotel/bg-hotels.png';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps { }

function AccountPageComponent(props: IProps) {
  const { history, menuStore, loginStore, intl } = props;
  const { account, user } = loginStore;
  const { items } = menuStore;

  const [activeKey, setActiveKey] = useState('1');

  const styleAccount: any = { backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 100%, rgb(255, 255, 255)), url(${HotelBackground})` };
  const isParentSidekick = items?.sideKickStatus === ISideKickStatus.PARENT;

  const isQuotes = useMemo(() => isQuotesFromPath(history), [history]);
  const isTestDriveManager = useMemo(() => isTestDriveManagerFromPath(history), [history]);
  const isReservations = useMemo(() => isReservationsFromPath(history), [history]);

  useEffect(() => {
    handleChange();
  }, []);

  const handleChange = () => {
    if (!account || account.type !== LoginType.PrivateWithToken) {
      history.push(Routes.NotAuthorized);
    }
    
    if (isQuotes) {
      setActiveKey('6');
    } else if (isTestDriveManager) {
      setActiveKey('7');
    } else if (isReservations) {
      setActiveKey('3');
    } else {
      setActiveKey('1');
    }
  }

  const handleTab = (key: string) => {
    setActiveKey(key);
    const account = getAccountUsernameFromPath(props.history);
    if (key !== '6') {
      history.push(`/${account}${Routes.RsiAccount}${location.search}`);
    } else {
      history.push(`/${account}${Routes.RsiQuotes}`);
    }
  }

  const isPrivateWithToken = account?.type === LoginType.PrivateWithToken;
  const privateTokenFromUrl = account?.privateTokenFromUrl;

  const getItemsCapital = useCallback(() => {
    const tabsItems: TabsProps['items'] = [
      { key: '1', label: <FormattedMessage id="my.account.title" />, children: <MyAccount /> },
      { key: '2', label: <FormattedMessage id="reservation.search.title" />, children: <Reservations /> }
    ];
    return tabsItems;
  }, []);

  if (isPrivateWithToken && privateTokenFromUrl) {
    return (
      <div className="account-page" style={{ flex: '1' }}>
        {account?.isCapitalVacations ? (
          <div className="account-page__background-wrapper">
            <div className="account-page__account-background" style={styleAccount}></div>
            <div className="account-page__wrapper">
              <h1 className="account-page__header"><FormattedMessage id={account?.isCapitalVacationsCondoRes ? 'reservation.search.title' : 'my.account.title'} /></h1>
            </div>
          </div>) : null}

        <Spin spinning={props.loginStore.loading} indicator={<Loading size={LoadingSise.Small} />} >
          <div className="container account-page__account-container" style={{ paddingTop: '20px' }}>
            {(account?.isCapitalVacations && !account?.isCapitalVacationsCondoRes) ? (
              <Tabs defaultActiveKey="1" items={getItemsCapital()} activeKey={activeKey} onTabClick={handleTab}>
              </Tabs>) : (
              <Reservations />)}
          </div>
        </Spin>
      </div>
    );
  }

  const isRSITemplate = account?.isRSITemplate;
  const showMyAccount = items?.showMyAccount;

  const notAuth = isRSITemplate && user && items && !loginStore?.loading && !menuStore?.loading && !showMyAccount;
  
  if (notAuth && !isQuotes) {
    history.push({
      pathname: Routes.CustomError,
      state: {
        data: 'UNAUTHORIZED',
      },
    });

    return null;
  }
  
  const getItems = useCallback(() => {
    const tabsItems: TabsProps['items'] = [
      { key: '1', label: <FormattedMessage id="profile" />, children: <MyProfile /> }
    ];

    if (!items?.isMLM) {
      tabsItems.push({ key: '2', label: <FormattedMessage id="manage.password" />, children: <ChangePassword /> });
    }

    tabsItems.push({ key: '3', label: <FormattedMessage id={items?.isMLM ? 'reservation.search.title' : 'my.active.hotel.reservations'} />, children: <Reservations /> });
    
    if (!items?.isMLM) {
      tabsItems.push({ key: '4', label: <FormattedMessage id="my.travel.history" />, children: <MyTravelHistory /> });
      tabsItems.push({ key: '5', label: <FormattedMessage id="my.quotes" />, children: <MyQuotes /> });
    }

    if (isParentSidekick) {
      tabsItems.push({
        key: '6',
        label: !isEmpty(items?.sideKickName) ? items.sideKickName : <FormattedMessage id={!items?.isMLM ? 'my.sidekicks' : 'test.drive.manager'} />,
        children: <MySideKickRewards /> });
    }

    return tabsItems;
  }, [menuStore?.items]);

  return (
    <div className="account-page">
      <div className="account-page__background-wrapper">
        <div className="account-page__account-background" style={styleAccount}></div>
        <div className="account-page__wrapper">
          <h1 className="account-page__header"><FormattedMessage id={(notAuth && isQuotes) ? 'my.quotes' : 'my.account.title'} /></h1>
        </div>
      </div>

      <Spin spinning={props.loginStore.loading} indicator={<Loading size={LoadingSise.Small} />} >
        <div className="container account-page__account-container">
          {!notAuth ? (
            <Tabs defaultActiveKey="1" items={getItems()} activeKey={activeKey} onTabClick={handleTab}>
            </Tabs>) : (
            <MyQuotes />)}
        </div>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const AccountPage = connect(mapStateToProps)(withRouter(injectIntl(AccountPageComponent)));