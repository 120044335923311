import React from 'react';
import { Checkbox } from 'antd';
import { StarSvg } from '@assets';
import { FormattedMessage } from 'react-intl';
import './style.scss';

interface IOptions {
  value: number;
}

interface IProps {
  title: string;
  options: IOptions[];
  onChange: (checkedValues: number[]) => void;
  value: number[];
  disabled?: boolean;
}

export class FilterStars extends React.Component<IProps> {
  props: IProps;

  render(): React.ReactNode {
    const { title, options, onChange, value, disabled = false } = this.props;

    return (
      <div className="filter-starts">
        <p className="filter-starts__title">
          <FormattedMessage id={title} />
        </p>
        <Checkbox.Group value={value} onChange={onChange} disabled={disabled}>
          {options.map((item: IOptions) => (
            <div key={item.value}>
              <Checkbox value={item.value} disabled={disabled}>
                <span className="filter-starts__option-name">
                  {item.value} <StarSvg />
                </span>
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </div>
    );
  }
}
