
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import './style.scss';

interface IProps extends ButtonProps {}

export function WhiteButton(props: IProps) {
  return (
    <Button {...props} className={`white-button ${props.className ? props.className : ''}`}>
      {props.children}
    </Button>
  );
}
