
import React from 'react';
import moment from 'moment';

import { FilterDropdownProps } from 'antd/lib/table/interface';
import { get, isEmpty, toNumber } from 'lodash';
import { Tooltip } from 'antd';

import { IFilterField, InputCommonFilterOption, InputFilterOption, InputFilterType } from '@share/common-types';
import { InputTableFilter } from '@components';
import { DATE_COMPLET_FORMAT_FOR_BOOKINGS, DATE_COMPLET_FORMAT_FULL_DATA, IColumnMenu } from '@constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { formatMoneyCurrency } from '@share/utils';

const zero = 0;
const one = 1;

export function SetFilterField<T>(field: any, setField: any): void {
  if (field && field[zero]) {
    const strList = field[zero].toString().split('~');
    const value = strList[zero] as unknown as T;
    const condition = strList[one] as InputFilterOption;
    if (condition) {
      if (condition === InputCommonFilterOption.Between) {
        const valueSplit = value.toString().split('&');
        const value1 = valueSplit?.length ? valueSplit[0] : null;
        const value2 = valueSplit?.length === 2 ? valueSplit[1] : null;
        setField({ value: value1, value2, condition });
      } else {
        setField({ value, condition });
      }
    }
  } else {
    setField({ value: null });
  }
}

export function GetTableDateColumn(
    title: string,
    filterField: IFilterField<any>,
    fieldName: string,
    width: number,
    displayConditions: boolean,
    incomingDateFormat: string,
    dateformat: string,
    defaultOrder?: string,
    render?: (value: string, record: any) => React.ReactNode) {
  const column: any = {
    title,
    dataIndex: fieldName,
    key: fieldName,
    render: function dateColumnRenderFunction(dateString: string, record: any) {
      const value = isEmpty(dateString) ? '' : moment(dateString, !isEmpty(incomingDateFormat) ? incomingDateFormat : 'YYYY-MM-DDThh:mm:ss').format(dateformat);
      return (
        <div className="all-bookings__dates">
          <div className="all-bookings__dates-left">
            {render ? render(value, record) : value}
          </div>
        </div>
      )},
    width: `${width}px`,
    widthOriginal: width,
    sorter: {
      compare: (a: any, b: any) => a[fieldName].localeCompare(b[fieldName])
    }
  };

  if (defaultOrder) {
    column.defaultSortOrder = defaultOrder;
  }
  
  if (filterField) {
    column.filtered = !!filterField?.value;
    column.filteredValue = filterField?.value ? [filterField.value] : null;
    column.filterIcon = <FontAwesomeIcon icon={faFilter} size="sm" />;
    column.filterDropdown = function dateColumnFilterFunction(props: FilterDropdownProps) {
      return (
        <InputTableFilter 
          {...props}
          displayConditions={displayConditions}
          field={filterField}
          type={InputFilterType.DateTime} />
      )};
  }

  return column;
}

export function GetTableTextColumn(
  title: string,
  filterField: IFilterField<any>,
  fieldName: string,
  width: number,
  displayConditions: boolean,
  sortable = true,
  defaultOrder?: string,
  render?: (value: string, record: any) => React.ReactNode) {
  const column: any = {
    title,
    dataIndex: fieldName,
    key: fieldName,
    render: function textColumnRenderFunction(value: string, record: any) {
      if (render) {
        return render(value, record);
      }
      return <Tooltip placement="top" title={value}><div>{value}</div></Tooltip>;
    },
    width: `${width}px`,
    widthOriginal: width,
  };

  if (sortable !== false) {
    column.sorter = {
      compare: (a: any, b: any) => a[fieldName]?.localeCompare(b[fieldName])
    };
  }

  if (defaultOrder) {
    column.defaultSortOrder = defaultOrder;
  }

  if (filterField) {
    column.filtered = !!filterField?.value;
    column.filteredValue = filterField?.value ? [filterField.value] : null;
    column.filterIcon = <FontAwesomeIcon icon={faFilter} size="sm" />;
    column.filterDropdown = function textColumnFilterFunction(props: FilterDropdownProps) {
      return (
        <InputTableFilter 
          {...props}
          displayConditions={displayConditions}
          field={filterField}
          type={InputFilterType.Text} />
      )};
  }

  return column;
};

export function GetTableBooleanColumn(
  title: string,
  filterField: IFilterField<any>,
  fieldName: string,
  width: number,
  displayConditions: boolean,
  sortable = true,
  defaultOrder?: string,
  render?: (value: boolean, record: any) => React.ReactNode) {
  const column: any = {
    title,
    dataIndex: fieldName,
    key: fieldName,
    render: function booleanColumnRenderFunction(value: boolean, record: any) {
      if (render) {
        return render(value, record);
      }
      return <div>{value ? 'true' : 'false'}</div>
    },
    width: `${width}px`,
    widthOriginal: width,
  };

  if (sortable !== false) {
    column.sorter = {
      compare: (a: any, b: any) => (a[fieldName] === b[fieldName])? 0 : a[fieldName]? -1 : 1
    };
  }

  if (defaultOrder) {
    column.defaultSortOrder = defaultOrder;
  }

  if (filterField) {
    column.filtered = !!filterField?.value;
    column.filteredValue = filterField?.value ? [filterField.value] : null;
    column.filterIcon = <FontAwesomeIcon icon={faFilter} size="sm" />;
    column.filterDropdown = function booleanColumnFilterFunction(props: FilterDropdownProps) { 
      return (
        <InputTableFilter 
          {...props}
          displayConditions={displayConditions}
          field={filterField}
          type={InputFilterType.Boolean}
          dropdownOptions={['true', 'false']} />
      )};
  }

  return column;
};

export function GetTableDropdownColumn(
    title: string,
    filterField: IFilterField<any>,
    fieldName: string,
    width: number,
    dropdownOptions: string[],
    displayConditions: boolean,
    defaultOrder: string,
    sortable = true,
    render?: (value: string) => React.ReactNode) {
  const column: any = {
    title,
    dataIndex: fieldName,
    key: fieldName,
    render: function dropdownColumnRenderFunction(value: string) {
      if (render) {
        return render(value);
      }
      return <div>{value}</div>;
    },
    width: `${width}px`,
    widthOriginal: width,
  }
  
  if (sortable !== false) {
    column.sorter = {
      compare: (a: any, b: any) => a[fieldName].localeCompare(b[fieldName])
    };
  }

  if (defaultOrder) {
    column.defaultSortOrder = defaultOrder;
  }

  if (filterField) {
    column.filtered = !!filterField?.value;
    column.filteredValue = filterField?.value ? [filterField.value] : null;
    column.filterIcon = <FontAwesomeIcon icon={faFilter} size="sm" />;
    column.filterDropdown = function dropdownColumnFilterFunction(props: FilterDropdownProps) {
      return (
        <InputTableFilter 
          {...props}
          displayConditions={displayConditions}
          field={filterField}
          type={InputFilterType.Dropdown}
          dropdownOptions={dropdownOptions} />
      )};
  }

  return column;
};

export function GetTableNumberColumn(
    title: string,
    filterField: IFilterField<any>,
    fieldName: string,
    width: number,
    displayConditions: boolean,
    type: InputFilterType,
    sortable = true,
    fixed?: string,
    render?: (value: number) => React.ReactNode,
    decimals?: number,
    defaultOrder?: string) {
  const column: any = {
    title,
    dataIndex: fieldName,
    key: fieldName,
    render: function numberColumnRenderFunction(value: number, record: any) {
      if (render) {
        return render(value);
      }
      const currency = get(record, 'currency', null);
      if (!value && value !== 0) {
        return null;
      }

      return (
        <div className={type === InputFilterType.Currency ? 'table-currency' : ''}>
          {type === InputFilterType.Currency ?
            `${formatMoneyCurrency(currency).format(toNumber(value))}` :
            type === InputFilterType.Percentage ? 
              `% ${toNumber(value).toFixed(2)}` :
              decimals ?
                toNumber(value).toFixed(decimals) :
                value}
        </div>
      );
    },
    width: `${width}px`,
    widthOriginal: width,
  };

  if (sortable !== false) {
    column.sorter = {
      compare: (a: any, b: any) => a[fieldName] - b[fieldName]
    };
  }

  if (fixed) {
    column.fixed = fixed;
  }

  if (defaultOrder) {
    column.defaultSortOrder = defaultOrder;
  }

  if (filterField) {
    column.filtered = !!filterField?.value;
    column.filteredValue = filterField?.value ? [filterField.value] : null;
    column.filterIcon = <FontAwesomeIcon icon={faFilter} size="sm" />;
    column.filterDropdown = function dateColumnFilterFunction(props: FilterDropdownProps) {
      return (
        <InputTableFilter 
          {...props}
          displayConditions={displayConditions}
          field={filterField}
          placeholder={title}
          type={type} />
      )};
  }

  return column;
};

export const  GetTableGroupColumn = <T extends any>(description: string, key: string, children: IColumnMenu[], filters: T) => {
  const column: any = {
    title: description,
    key
  }

  column.children = getColumns(children, filters);

  return column;
}

export const  GetTableCustomColumn = (title: string, key: string, width: number, fixed?: string, render?: (value: any, record: any) => React.ReactNode) => {
  const column: any = {
    title,
    key,
    render: function customColumnRenderFunction(value: any, record: any) {
      if (render) {
        return render(value, record);
      }
      return null;
    },
    width: `${width}px`,
    widthOriginal: width,
  };

  if (fixed) {
    column.fixed = fixed;
  }

  return column;
}

export const getColumns = <T extends any>(selectedColumns: IColumnMenu[], filters: T) => {
  return selectedColumns
      .map(({ description, key, width, type, fixed, filterFieldName, dropdowmOptions, decimals, displayConditions, defaultOrder, incomingDateFormat, sortable, children, component }) => {
        const keyName: keyof T = filterFieldName as keyof T;
        const filterField: any = (isEmpty(filterFieldName) || !filters) ? null : filters[keyName];
        if (type === InputFilterType.Group) {
          return GetTableGroupColumn(description, key, children, filters);
        }

        if (type === InputFilterType.Custom) {
          return GetTableCustomColumn(description, key, width, fixed, component);
        }

        if (type === InputFilterType.Dropdown) {
          return GetTableDropdownColumn(description, filterField, key, width, dropdowmOptions, displayConditions, defaultOrder, sortable, component);
        }
        if (type === InputFilterType.IntegerNumber ||
            type === InputFilterType.DecimalNumber ||
            type === InputFilterType.Currency ||
            type === InputFilterType.Percentage) {
          return GetTableNumberColumn(description, filterField, key, width, displayConditions, type, sortable, fixed, component, decimals, defaultOrder);
        }
        if (type === InputFilterType.Text) {
          return GetTableTextColumn(description, filterField, key, width, displayConditions, sortable, defaultOrder, component);
        }
        if (type === InputFilterType.DateTime) {
          return GetTableDateColumn(description, filterField, key, width, displayConditions, incomingDateFormat, DATE_COMPLET_FORMAT_FULL_DATA, defaultOrder, component);
        }
        if (type === InputFilterType.Date) {
          return GetTableDateColumn(description, filterField, key, width, displayConditions, incomingDateFormat, DATE_COMPLET_FORMAT_FOR_BOOKINGS, defaultOrder, component);
        }
        if (type === InputFilterType.Boolean) {
          return GetTableBooleanColumn(description, filterField, key, width, displayConditions, sortable, defaultOrder, component);
        }
      });
}
