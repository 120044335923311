
import React from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ILoginState, IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';

import GetawayBanner from '@assets/images/getaways/getaway-banner.png';
import CruiseBanner from '@assets/images/getaways/cruises-banner.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import { Routes } from '@share/constants';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps, RouteComponentProps {
  display: boolean;
}

function SpecialBannersComponent(props: IProps) {
  const { loginStore, menuStore, history, display } = props;
  const { account, user, loading } = loginStore;
  const { items } = menuStore;

  const handleGetaways = () => {
    history.push(`/${account?.name}${Routes.Getaways}`);
  }

  const handleBogo = () => {
    window.open(`https://membergetaways.com/odtest1.php?id=${user?.keyid}&t=BOGO`, '_blank');
  }

  if (!display || loading) {
    return null;
  }

  return (
    <div className={`special-banners-container ${items?.isMLM ? 'mlm' : ''}`}>
      <div className="special-banners-container__item" onClick={handleBogo}>
        <div className="special-banners-container__item-message bogo">
          <label className="bogo-title"><FormattedMessage id="bogo.offer" values={{ strong: (message: string) => (<strong>{message}</strong>) }} /></label>
          <label className="bogo-message"><FormattedMessage id="bogo.message" values={{ strong: (message: string) => (<strong>{message}</strong>), yellow: (message: string) => (<label style={{ color: '#FFE500' }}>{message}</label>)}} /></label>
        </div>

        <div className="special-banners-container__item-sub-message">
          <label className="sub-message1"><strong>1,410</strong></label>
          <label className="sub-message2"><FormattedMessage id="itineraries.today" /></label>
        </div>

        <img src={CruiseBanner} />
        <div className="special-banners-container__item-view-details blue">
          <FormattedMessage id="see.details" />
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
      <div className="special-banners-container__item" onClick={handleGetaways}>
        <img src={GetawayBanner} />
        <div className="special-banners-container__item-message getaways">
          <label className="getaways-title"><FormattedMessage id="more.than" /></label>
          <label className="getaways-message"><FormattedMessage id="everyday.deeply" /></label>
          <label className="getaways-message"><FormattedMessage id="discounted.vacations" /></label>
          <label className="getaways-off"><FormattedMessage id="getaways.off" /></label>
        </div>
        <div className="special-banners-container__item-view-details red">
          <FormattedMessage id="see.details" />
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    menuStore: state.navigationMenuStore,
  };
};

export const SpecialBanners = connect(mapStateToProps)(withRouter(injectIntl(SpecialBannersComponent)));
