import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { InfoSvg, LocationMapSvg } from '@assets';
import { IAdminHotelBookingDetails } from '@common-types';
import { DATE_FORMAT_CHECK_IN_OUT_YEAR } from '@constants';
import { HotelStars } from '@components';
import { formatDateCheckInOut, formatMoney, RootState } from '@share/utils';
import { Routes } from '@share/constants';

import './style.scss';
import { ILoginState } from '@share/store/slices';
import { connect } from 'react-redux';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, RouteComponentProps {
  details: IAdminHotelBookingDetails;
}

const zero = 0;

class BookingHotelUnitComponent extends React.Component<IProps> {
  showInMap = (): void => {
    const { location } = this.props.details.hotelModel;
    window.open(`https://maps.google.com?q=${location.latitude},${location.longitude}`);
  };

  getTotalPayAtPropertyFees = (): number => {
    return this.props.details.payAtPropertyFees.reduce((acc, { value }) => acc + value, 0);
  };

  render(): React.ReactNode {
    const {
      hotelModel,
      checkIn,
      checkOut,
      bookingPrice,
      roomName,
      roomDescription,
      averageNightPrice,
      roomsCount,
      numberOfNights,
      payAtPropertyFees,
    } = this.props.details;

    const { loginStore } = this.props;
    const { account } = loginStore;

    const locale = account?.locale;

    return (
      <div className="booking-hotel-reservation">
        <div className="booking-hotel-reservation__image">
          <img src={hotelModel.imageUrl} className="booking-hotel-reservation__img" />
        </div>
        <div className="booking-hotel-reservation__main-wrapper">
          <div className="booking-hotel-reservation__header">
            {hotelModel.name}
            <span> </span> <HotelStars stars={hotelModel.stars} />
          </div>
          <div className="booking-hotel-reservation__address" onClick={this.showInMap}>
            <LocationMapSvg /> {hotelModel.address}
          </div>
          <div className="booking-hotel-reservation__bedrooms-count">{roomName}</div>
          <div className="booking-hotel-reservation__guest-description">{roomDescription}</div>
          <div className="booking-hotel-reservation__dates">
            <div className="booking-hotel-reservation__dates-value">
              <div className="booking-hotel-reservation__dates-left">
                <FormattedMessage id="check.in" />:
              </div>
              <div className="booking-hotel-reservation__dates-right">
                {formatDateCheckInOut(checkIn, DATE_FORMAT_CHECK_IN_OUT_YEAR, locale)}
              </div>
            </div>
            <div className="booking-hotel-reservation__dates-value">
              <div className="booking-hotel-reservation__dates-left">
                <FormattedMessage id="check.out" />:
              </div>
              <div className="booking-hotel-reservation__dates-right">
                {formatDateCheckInOut(checkOut, DATE_FORMAT_CHECK_IN_OUT_YEAR, locale)}
              </div>
            </div>
          </div>
          <div className="booking-hotel-reservation__price-details">
            <div className="booking-hotel-reservation__label">
              <FormattedMessage id="price.details" />
            </div>
            <div className="booking-hotel-reservation__detail">
              <div className="booking-hotel-reservation__detail-left">
                <div className="booking-hotel-reservation__detail-label">
                  <FormattedMessage id="avg.nightly.rate" />
                </div>
                <div className="booking-hotel-reservation__detail-description">
                  <FormattedMessage id="per.night" />
                </div>
              </div>
              <div className="booking-hotel-reservation__detail-right">
                {formatMoney.format(averageNightPrice)}
              </div>
            </div>

            <div className="booking-hotel-reservation__detail">
              <div className="booking-hotel-reservation__detail-left">
                <div className="booking-hotel-reservation__detail-label">
                  <FormattedMessage id="public.price" /> {roomsCount}{' '}
                  <FormattedMessage id="room" values={{ count: roomsCount }} /> x{' '}
                  <FormattedMessage id="nights" values={{ count: numberOfNights }} />
                </div>
                <div className="booking-hotel-reservation__detail-description">
                  <FormattedMessage id="including.taxes.fees" />
                </div>
              </div>
              <div className="booking-hotel-reservation__detail-right">
                {bookingPrice.publicPrice ? formatMoney.format(bookingPrice.publicPrice) : ''}
              </div>
            </div>

            <div className="booking-hotel-reservation__detail">
              <div className="booking-hotel-reservation__detail-left">
                <div className="booking-hotel-reservation__detail-label">
                  <FormattedMessage id="member.savings" />
                </div>
              </div>
              <div className="booking-hotel-reservation__detail-right">
                {bookingPrice && (
                  <>
                    {bookingPrice.savings === zero ? (
                      <FormattedMessage id="not.applied" />
                    ) : (
                      `- ${formatMoney.format(bookingPrice.savings)}`
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="booking-hotel-reservation__detail border-bottom">
              <div className="booking-hotel-reservation__detail-left">
                <div className="booking-hotel-reservation__detail-label">
                  <FormattedMessage id="coupon.credit.or.promotion.code" />
                </div>
              </div>
              <div className="booking-hotel-reservation__detail-right">
                <div className="booking-hotel-reservation__detail-coupon-status">
                  {bookingPrice && (
                    <>
                      {bookingPrice.couponAmount === zero ? (
                        <FormattedMessage id="not.applied" />
                      ) : (
                        formatMoney.format(bookingPrice.couponAmount)
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="booking-hotel-reservation__total">
              <div className="booking-hotel-reservation__total-text">
                <FormattedMessage id="total.paid" />:
                <div className="booking-hotel-reservation__detail-description">
                  <div>
                    {bookingPrice.totalSupplierTaxes === zero ? (
                      <FormattedMessage id="zero.charge.for.taxes.fees" />
                    ) : (
                      <>
                        <FormattedMessage id="abbreviated.price.taxes.fees" />:{' '}
                        {formatMoney.format(bookingPrice.totalSupplierTaxes)}
                      </>
                    )}
                  </div>
                  <div>
                    <FormattedMessage id="markup" />: {formatMoney.format(bookingPrice.markup)}
                  </div>
                  <div>
                    {bookingPrice.commissionFeeAmount === zero ? (
                      <FormattedMessage id="markup.payment.zero.charge.transaction" />
                    ) : (
                      <>
                        <FormattedMessage id="markup.payment.transaction.fee" />:{' '}
                        {formatMoney.format(bookingPrice.commissionFeeAmount)}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="booking-hotel-reservation__total-number-wrapper">
                <div className="booking-hotel-reservation__total-number">
                  {formatMoney.format(bookingPrice.payNowPrice)}
                </div>
              </div>
            </div>
            <div className="booking-hotel-reservation__detail booking-hotel-reservation__detail_pay-property border-bottom">
              <div className="booking-hotel-reservation__detail-left">
                <div className="booking-hotel-reservation__detail-label booking-hotel-reservation__detail_pay-property-label">
                  <FormattedMessage id="pay.property" />:
                </div>
                <div className="booking-hotel-reservation__detail-description">
                  {!!payAtPropertyFees.length &&
                    payAtPropertyFees.map(
                      ({ title, currency, formattedValue, remarks }) =>
                        `${title}  ${currency}${formattedValue} ${remarks}`,
                    )}
                </div>
              </div>
              <div className="booking-hotel-reservation__detail-right">
                <div className="booking-hotel-reservation__detail_pay-property-value">
                  {formatMoney.format(this.getTotalPayAtPropertyFees())}
                </div>
              </div>
            </div>
            <div className="booking-hotel-reservation__warning">
              <InfoSvg /> <FormattedMessage id="charges.may.apply" />
            </div>
            <div className="booking-hotel-reservation__view-hotel">
              <Link
                to={`${Routes.AdminHotel}/${hotelModel.hotelId}`}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="view.hotel.details" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const BookingHotelUnitRouter = withRouter(BookingHotelUnitComponent);

export const BookingHotelUnit = connect(mapStateToProps)(BookingHotelUnitRouter);


