import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FormInstance } from 'antd/lib/form';
import { getawayBookingActions, IGetawayBookingState } from '@store/slices';
import { IMenuState } from '@share/store/slices';
import { IGetawayBookInfo } from '@share/common-types';
import { RootState } from '@share/utils';
import { Responsive } from '@share/utils';
import { GetawayGuestsPiker } from '../getaway-guests-picker';
import { GetawayDatePicker } from '../getaway-date-picker';
import './style.scss';

interface IMapStateToProps {
  getawayBookingStore: IGetawayBookingState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setGetawayBookInfo: (getawayBookInfo: IGetawayBookInfo) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
}

class GetawayTravelingInfoComponent extends React.Component<IProps> {
  render() {
    const { formRef } = this.props;

    return (
      <div className="getaway-traveling-info">
        <p className="getaway-traveling-info__header-text">
          <FormattedMessage id="traveling.info" />
        </p>
        <div className="getaway-traveling-info__wrapper">
          <GetawayGuestsPiker />
          <Responsive>
            <GetawayDatePicker formRef={formRef} />
          </Responsive>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    getawayBookingStore: state.getawayBookingStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setGetawayBookInfo: getawayBookingActions.setGetawayBookInfo,
};

export const GetawayTravelingInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GetawayTravelingInfoComponent));
