import React, { useEffect, useRef } from 'react';

import { scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import SmillingWoman from '@assets/images/mlm-levalti/experienced-agents/smiling-woman-at-table.jpg';
import WordClass from '@assets/images/mlm-levalti/experienced-agents/01-world-class-hotels.jpg';
import LuxuryResort from '@assets/images/mlm-levalti/experienced-agents/02-luxury-resort-condos.jpg';
import VillasHomes from '@assets/images/mlm-levalti/experienced-agents/03-villas-and-homes.jpg';
import OceanCruising from '@assets/images/mlm-levalti/experienced-agents/04-ocean-cruising.jpg';
import FantasyGetaways from '@assets/images/mlm-levalti/experienced-agents/05-fantasy-getaways.jpg';
import SectionCondos from '@assets/images/mlm-levalti/experienced-agents/06-section4-condo1.jpg';
import SectionLakecomo from '@assets/images/mlm-levalti/experienced-agents/07-section4-lakecomo.jpg';
import travNow from '@assets/images/mlm-levalti/new-agents/travnow.jpg';
import travNow2 from '@assets/images/mlm-levalti/new-agents/travnow2.jpg';
import travNow3 from '@assets/images/mlm-levalti/new-agents/travnow3.jpg';
import IconPig from '@assets/images/mlm-levalti/new-agents/icon-piggy-bank.png';
import IconKeyboard from '@assets/images/mlm-levalti/new-agents/icon-keyboard.png';
import IconHandshake from '@assets/images/mlm-levalti/new-agents/icon-handshake.png';
import IconSupplier from '@assets/images/mlm-levalti/new-agents/icon-supplier.png';
import IconBanking from '@assets/images/mlm-levalti/experienced-agents/icon-banking.png';
import BlondeImg from '@assets/images/mlm-levalti/experienced-agents/travel-agent-blonde.png';
import IconNetwork from '@assets/images/mlm-levalti/experienced-agents/icon-networking.png';
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'antd';
import 'antd/dist/antd.css'; // Importa el CSS de Ant Design

export default function MLMExperiencedAgents() {
  useEffect(() => {
    scrollTop();
  }, []);
  const contentStyle: React.CSSProperties = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    width: '240px',
  };

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="experienced">
      {/*Header*/}
      <header className="experienced__header">
        <section className="experienced__header-title">
          <h1>
            <FormattedMessage id="experienced.welcome" />
          </h1>
        </section>
        <section className="experienced__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="experienced__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="experienced__main-section-started">
          <section className="experienced__main-section-started-one">
            <h2 className="experienced__main-section-started-one-subtitle">
              <span className="experienced__main-section-started-one-subtitle-making">
                <FormattedMessage id="experienced.started.title" />
              </span>{' '}
              <span className="experienced__main-section-started-one-subtitle-fun">
                <FormattedMessage id="experienced.started.subtitle" />
              </span>
            </h2>
            <p className="experienced__main-section-started-one-paragraph">
              <FormattedMessage id="experienced.started.paragraph1" />
            </p>
            <p className="experienced__main-section-started-one-paragraph">
              <FormattedMessage id="experienced.started.paragraph2" />
            </p>

            <p className="experienced__main-section-started-one-paragraph">
              <FormattedMessage id="experienced.started.paragraph3" />
            </p>

            <section className="experienced__main-section-started-one-btn">
              <button className="started-btn">
                <FormattedMessage id="experienced.started.btn" />
              </button>
            </section>
          </section>
          <section className="experienced__main-section-started-two">
            <img
              src={SmillingWoman}
              alt="agent setting in a desk"
              className="experienced__main-section-started-two-img"
            />
            <div className="experienced__main-section-started-two-paragraph">
              <p className="experienced__main-section-started-two-paragraph-quote">
                <FormattedMessage id="experienced.started.quote" />
              </p>
              <p className="experienced__main-section-started-two-paragraph-author">
                {' '}
                <FormattedMessage id="experienced.started.author" />
              </p>
            </div>
          </section>
        </section>
        {/*Second Section*/}
        <section className="experienced__main-section-condominium">
          <section className="experienced__main-section-condominium-container">
            <section className="experienced__main-section-condominium-container-text">
              <h2 className="experienced__main-section-condominium-container-text-title">
                <FormattedMessage id="experienced.condominium.title" />
              </h2>
              <p className="experienced__main-section-condominium-container-text-paragraphs">
                <FormattedMessage id="experienced.condominium.paragraph.one" />
              </p>
              <p className="experienced__main-section-condominium-container-text-paragraphs">
                <FormattedMessage id="experienced.condominium.paragraph.two" />
              </p>
            </section>
            <section className="experienced__main-section-condominium-container-imgs">
              <Carousel autoplay autoplaySpeed={2000} arrows>
                <div>
                  <img src={WordClass} alt="WordClass Img" style={contentStyle} />
                </div>
                <div>
                  <img src={LuxuryResort} alt="LuxuryResort Img" style={contentStyle} />
                </div>
                <div>
                  <img src={VillasHomes} alt="VillasHomes Img" style={contentStyle} />
                </div>
                <div>
                  <img src={OceanCruising} alt="OceanCruising Img" style={contentStyle} />
                </div>
                <div>
                  <img src={FantasyGetaways} alt="FantasyGetaways Img" style={contentStyle} />
                </div>
                <div>
                  <img src={SectionCondos} alt="SectionCondos Img" style={contentStyle} />
                </div>
                <div>
                  <img src={SectionLakecomo} alt="SectionLakecomo Img" style={contentStyle} />
                </div>
              </Carousel>
            </section>
          </section>
        </section>
        {/*Third Section*/}
        <section className="experienced__main-section-benefits">
          <section className="experienced__main-section-benefits-container">
            <section className="experienced__main-section-benefits-container-paragraph">
              <h2 className="experienced__main-section-benefits-container-paragraph-title">
                <FormattedMessage id="experienced.benefits.title" />
                <span className="experienced__main-section-benefits-container-paragraph-title-features">
                  {' '}
                  <FormattedMessage id="experienced.benefits.subtitle" />
                </span>
              </h2>
              <p className="experienced__main-section-benefits-container-paragraph-subtitle">
                <FormattedMessage id="experienced.benefits.subtitle2" />
              </p>
            </section>
            <section className="experienced__main-section-benefits-container-imgs">
              <section className="experienced__main-section-benefits-container-imgs-one">
                <img
                  src={travNow}
                  alt="travNow"
                  className="experienced__main-section-benefits-container-imgs-one-img"
                />
                <h3 className="experienced__main-section-benefits-container-imgs-one-title">
                  <FormattedMessage id="experienced.benefits.img.one.title" />
                </h3>
                <p className="experienced__main-section-benefits-container-imgs-one-paragraph">
                  <FormattedMessage id="experienced.benefits.img.one.paragraph" />
                </p>
              </section>
              <section className="experienced__main-section-benefits-container-imgs-two">
                <div>
                  <img
                    src={travNow3}
                    alt="travNow2"
                    className="experienced__main-section-benefits-container-imgs-two-img"
                  />
                  <button>
                    <FormattedMessage id="experienced.benefits.img.two.btn" />
                  </button>
                </div>
                <h3 className="experienced__main-section-benefits-container-imgs-two-title">
                  <FormattedMessage id="experienced.benefits.img.two.title" />
                </h3>
                <p className="experienced__main-section-benefits-container-imgs-two-paragraph">
                  <FormattedMessage id="experienced.benefits.img.two.paragraph" />
                </p>
              </section>
              <section className="experienced__main-section-benefits-container-imgs-three">
                <img
                  src={travNow2}
                  alt="travNow3"
                  className="experienced__main-section-benefits-container-imgs-three-img"
                />
                <h3 className="experienced__main-section-benefits-container-imgs-three-title">
                  <FormattedMessage id="experienced.benefits.img.third.title" />
                </h3>
                <p className="experienced__main-section-benefits-container-imgs-three-paragraph">
                  <FormattedMessage id="experienced.benefits.img.third.paragraph" />
                </p>
              </section>
            </section>
          </section>
        </section>
        {/*Four Section*/}
        <section className="experienced__main-section-questions">
          <section className="experienced__main-section-questions-container">
            <section className="experienced__main-section-questions-container-title">
              <h2>
                <FormattedMessage id="experienced.question.title" />
              </h2>
            </section>
            <section className="experienced__main-section-questions-container-asked">
              <div className="experienced__main-section-questions-container-asked-one">
                <section className="experienced__main-section-questions-container-asked-one-img">
                  <img src={IconPig} alt="Icon Pig" />
                </section>
                <section className="experienced__main-section-questions-container-asked-one-title">
                  <h2>
                    <FormattedMessage id="experienced.question.subtitle" />
                  </h2>
                  <p className="experienced__main-section-questions-container-asked-one-paragraph">
                    <FormattedMessage id="experienced.question.paragraph" />
                  </p>
                </section>
              </div>
              <div className="experienced__main-section-questions-container-asked-two">
                <section className="experienced__main-section-questions-container-asked-two-img">
                  <img src={IconHandshake} alt="Icon Hanshake" />
                </section>
                <section className="experienced__main-section-questions-container-asked-two-title">
                  <h2>
                    <FormattedMessage id="experienced.question.subtitle2" />
                  </h2>
                  <p className="experienced__main-section-questions-container-asked-two-paragraph">
                    <FormattedMessage id="experienced.question.paragraph2" />
                  </p>
                </section>
              </div>
              <div className="experienced__main-section-questions-container-asked-third">
                <section className="experienced__main-section-questions-container-asked-third-img">
                  <img src={IconSupplier} alt="Icon Supplier" />
                </section>
                <section className="experienced__main-section-questions-container-asked-third-title">
                  <h2>
                    <FormattedMessage id="experienced.question.subtitle3" />
                  </h2>
                  <p className="experienced__main-section-questions-container-asked-third-paragraph">
                    <FormattedMessage id="experienced.question.paragraph3" />
                  </p>
                </section>
              </div>
              <div className="experienced__main-section-questions-container-asked-four">
                <section className="experienced__main-section-questions-container-asked-four-img">
                  <img src={IconKeyboard} alt="Icon Keyboard" />
                </section>
                <section className="experienced__main-section-questions-container-asked-four-title">
                  <h2>
                    <FormattedMessage id="experienced.question.subtitle4" />
                  </h2>
                  <p className="experienced__main-section-questions-container-asked-four-paragraph">
                    <FormattedMessage id="experienced.question.paragraph4" />
                  </p>
                </section>
              </div>
              <div className="experienced__main-section-questions-container-asked-five">
                <section className="experienced__main-section-questions-container-asked-five-img">
                  <img src={IconBanking} alt="Icon Banking" />
                </section>
                <section className="experienced__main-section-questions-container-asked-five-title">
                  <h2>
                    <FormattedMessage id="experienced.question.subtitle5" />
                  </h2>
                  <p className="experienced__main-section-questions-container-asked-five-paragraph">
                    <FormattedMessage id="experienced.question.paragraph5" />
                  </p>
                </section>
              </div>
              <div className="experienced__main-section-questions-container-asked-six">
                <section className="experienced__main-section-questions-container-asked-six-img">
                  <img src={IconNetwork} alt="Icon Network" />
                </section>
                <section className="experienced__main-section-questions-container-asked-six-title">
                  <h2>
                    <FormattedMessage id="experienced.question.subtitle6" />
                  </h2>
                  <p className="experienced__main-section-questions-container-asked-six-paragraph">
                    <FormattedMessage id="experienced.question.paragraph6" />
                  </p>
                </section>
              </div>
            </section>
          </section>
        </section>
        {/*Fifth Section*/}
        <section className="experienced__main-section-host">
          <section className="experienced__main-section-host-container">
            <section className="experienced__main-section-host-container-img">
              <img src={BlondeImg} alt="Blonde Img" />
            </section>
            <section className="experienced__main-section-host-container-text">
              <h2 className="experienced__main-section-host-container-text-title">
                <FormattedMessage id="agents.host.title" />
              </h2>
              <p className="experienced__main-section-host-container-text-paragraph">
                <FormattedMessage id="agents.host.paragraph" />
              </p>
              <button className="experienced__main-section-host-container-text-btn">
                <FormattedMessage id="agents.started.btn" />
              </button>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
