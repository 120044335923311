
import React from 'react';
import isUndefined from 'lodash/isUndefined';

import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { Modal } from 'antd';

import {
  getReviewBook,
  getHotelDetails,
  setExpiredSession,
  setLoadingBook,
  IReviewBookState,
  setReviewBook,
  resetState,
  IHotelDetailsState,
  IRoomsSearchState,
} from '@store/slices';
import { IBookingSummary } from '@common-types';
import { ISessionKey } from '@share/common-types';
import { UrlUtils, getTimeout, getSessionObj, getHotelRoutePath, getHotelIdFromPath, getPackageIdFromPath, RootState } from '@share/utils';
import { SESSION_KEY_LABEL_ROOMS, SESSION_KEY_LABEL, QUOTE_LABEL, LIFE_STYLE_NAME_PARAM } from '@share/constants';
import { HOTEL_SESSION_KEY, NULL_VALUE, ROOMS_UNAVAILABLE_ERROR } from '@constants';
import { SkeletonReviewBook, PriceChangeMessage, GeneralMessage, MessageType } from '@components';
import { IHotelsState, ILoginState, IMenuState } from '@share/store/slices';

import { ReviewBook } from '../review-book/review-book';
import { RoomInfo } from '../room-info';
import { RoomImage } from '../room-info/room-image';
import { Notification } from '../notification';
import { AvailabilityError } from '../notification/availability-error';

import './style.scss';

interface IMapStateToProps {
  hotelsStore: IHotelsState;
  reviewBookStore: IReviewBookState;
  hotelDetailsStore: IHotelDetailsState;
  roomsSearchStore: IRoomsSearchState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getReviewBook: ( hotelId: number, packageId: string, sessionKey: ISessionKey, lifeType: string, quote?: string) => void;
  setExpiredSession: (expiredSession: boolean) => void;
  setLoadingBook: (loadingBook: boolean) => void;
  setReviewBook: (data: IBookingSummary) => void;
  resetState: () => void;
  getHotelDetails: (hotelId: number, sessionKey: ISessionKey, dealId?: number, quote?: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isVacationRentals: boolean;
}

interface IState {
  expirationTimeout: number;
}

const ZERO = 0;
const MODAL_WIDTH = 400;

class ReviewBookWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    expirationTimeout: NULL_VALUE,
  };

  hotelId = getHotelIdFromPath(this.props.history);
  packageId = getPackageIdFromPath(this.props.history);

  onExpiredSession = (sessionData: ISessionKey) => {
    const expireDate = sessionData.expireDate;

    if (getTimeout(expireDate) < ZERO) {
      this.props.setExpiredSession(true);
    } else {
      this.setState({
        // @ts-ignore
        expirationTimeout: setTimeout(() => {
          this.props.setExpiredSession(true);
        }, getTimeout(expireDate)),
      });
    }
  };

  componentDidMount(): void {
    const { menuStore } = this.props;
    const { items } = menuStore;

    const values = UrlUtils.getValues();
    const sessionKey = getSessionObj(
      values[SESSION_KEY_LABEL_ROOMS] as ISessionKey,
      values[SESSION_KEY_LABEL] as ISessionKey,
    );

    if (!isUndefined(sessionKey)) {
      localStorage.setItem(HOTEL_SESSION_KEY, JSON.stringify(sessionKey));
    }

    const sessionStorage = localStorage.getItem(HOTEL_SESSION_KEY);
    const currentSessionKey = !isUndefined(sessionKey)
      ? sessionKey
      : sessionStorage ? JSON.parse(sessionStorage) : null;

    if (items) {
      this.getBookingSummary();
    }

    this.onExpiredSession(currentSessionKey);
    
    window.scrollTo(ZERO, ZERO);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { loginStore, menuStore, reviewBookStore } = this.props;
    const { account, user, userWalletData } = loginStore;
    const { items } = menuStore;
    const { loading, bookingSummary } = reviewBookStore;

    const userLoaded = !prevProps.loginStore.user && !!user;
    const walletLoaded = !prevProps.loginStore.userWalletData && !!userWalletData && account?.hasClientCash;
    const menuLoaded = !prevProps.menuStore.items && !!items && !loading && !bookingSummary;

    if (userLoaded || walletLoaded || menuLoaded) {
      this.getBookingSummary();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.expirationTimeout);
    this.props.resetState();
  }

  getBookingSummary = () => {
    const { loginStore } = this.props;
    const { user, account } = loginStore;

    if (!!user && !!account) {
      const values = UrlUtils.getValues();
      const sessionKey = getSessionObj(
        values[SESSION_KEY_LABEL_ROOMS] as ISessionKey,
        values[SESSION_KEY_LABEL] as ISessionKey,
      );
  
      const sessionStorage = localStorage.getItem(HOTEL_SESSION_KEY);
      const currentSessionKey = !isUndefined(sessionKey)
        ? sessionKey
        : sessionStorage ? JSON.parse(sessionStorage) : null;

      const { roomsSearchStore, hotelDetailsStore } = this.props;
      const hotel = roomsSearchStore.hotel ? roomsSearchStore.hotel : hotelDetailsStore.hotel;
      if (!hotel) {
        this.props.getHotelDetails(this.hotelId, currentSessionKey);
      }

      let quote;
      if (values[QUOTE_LABEL]) {
        quote = (values[QUOTE_LABEL] as string);
      }

      let lifeType = 'none';
      if (UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM)) {
        lifeType = UrlUtils.getSearchParam(LIFE_STYLE_NAME_PARAM) as string;
      }

      this.props.setLoadingBook(true);
      this.props.setReviewBook(NULL_VALUE);
      this.props.getReviewBook(this.hotelId, this.packageId, currentSessionKey, lifeType, quote as string);  
    }
  }

  render(): React.ReactNode {
    const { isVacationRentals, reviewBookStore } = this.props;
    const {
      loading,
      bookingSummary,
      expiredSession,
      errorBooking,
      bookingComplete,
      isExternalError,
      isPriceChangedError,
      isSoldOutError,
      isBookingInProgress,
      isSavingsMismatch,
      error
    } = reviewBookStore;
    const { loginStore } = this.props;
    const loadingLogin = loginStore.loading;

    const isNotification =
      bookingComplete ||
      isPriceChangedError ||
      isSoldOutError ||
      isBookingInProgress ||
      loading;

    const isRoomUnavaliableError = error === ROOMS_UNAVAILABLE_ERROR;
    const hotelPath = getHotelRoutePath(this.hotelId, this.props.history, isVacationRentals);
    return ((expiredSession || isExternalError) && !isRoomUnavaliableError) ? (
      <Notification
        expiredSession={expiredSession}
        isSavingsMismatch={isSavingsMismatch}
        isExternalError={isExternalError}
        isVacationRentals={isVacationRentals}
        errorCode={error}
      />
    ) : (
      <div className="review-book-wrapper">
        {!isNotification && (
          <div className="review-book-wrapper__header">
            <div className="review-book-wrapper__back-link">
              <Link to={hotelPath}>
                <FormattedMessage id="back.to.all.rooms" />
              </Link>
            </div>
            <h4 className="review-book-wrapper__title">
              <FormattedMessage id="review.book" />
            </h4>
            <p className="review-book-wrapper__description">
              <FormattedMessage id="enter.your.details" />
            </p>

          </div>
        )}
        
        {(loading || loadingLogin) && (<SkeletonReviewBook />)}
        
        {!bookingComplete && !isEmpty(errorBooking) && (<GeneralMessage type={MessageType.Failure} message={errorBooking}></GeneralMessage>)}
        
        <PriceChangeMessage />
        
        {bookingSummary && !loading && (
          <div className={`review-book-wrapper__content ${isNotification ? 'wrapper-notification' : ''}`}>
            <ReviewBook isVacationRentals={isVacationRentals} />
            <div className="review-book-wrapper__right">
              <RoomImage />
              <RoomInfo />
            </div>
          </div>
        )}

        <Modal
          className="price-change-modal-wrapper"
          wrapClassName="price-change-modal-wrapper__wrap"
          visible={isRoomUnavaliableError && isExternalError}
          footer={null}
          title={null}
          closable={false}
          keyboard={false}
          destroyOnClose={true}
          width={MODAL_WIDTH}
        >
          <AvailabilityError isVacationRentals={isVacationRentals} />
        </Modal>
      </div>
    );
  }
}


const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    hotelsStore: state.hotelsStore,
    reviewBookStore: state.reviewBookStore,
    loginStore: state.loginStore,
    hotelDetailsStore: state.hotelDetailsStore,
    roomsSearchStore: state.roomsSearchStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getReviewBook,
  getHotelDetails,
  setExpiredSession,
  setLoadingBook,
  setReviewBook,
  resetState,
};

export const ReviewBookWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ReviewBookWrapperComponent));
