import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { ILoginState, IRoomsState, roomsActions } from '@share/store/slices';
import { IGuest, IRoom } from '@share/common-types';
import { RootState } from '@share/utils';
import { BlueButton } from '@share/components';

import { CloseSvg } from '@share/assets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { Room } from '../room';

import './style.scss';

interface IMapStateToProps {
  roomsStore: IRoomsState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  addEmptyRoom: () => void;
  updateAdultsCount: (roomId: string, count: number) => void;
  addKid: (roomId: string, kid: IGuest) => void;
  updateKidAge: (roomId: string, kidId: string, age: number) => void;
  removeRoom: (roomId: string) => void;
  removeKid: (roomId: string, kidId: string) => void;
}

interface IProps extends IMapDispatchToProps, IMapStateToProps {
  isUpdateSearch?: boolean;
  onSelect?: () => void;
}

interface IState {
  isFocused: boolean;
  haveScroll: boolean;
  changed: boolean;
}

const MAX_LIST_HEIGHT = 312;
const MAX_ROOMS = 4;
const ZERO = 0;
const ONE = 1;

export class RoomPickerComponent extends React.Component<IProps, IState> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  listRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: IState = {
    isFocused: false,
    haveScroll: false,
    changed: false
  };

  focus = (): void => {
    if (!this.state.isFocused) {
      this.setState({ isFocused: true, changed: false });
    }
  };

  blur = (): void => {
    if (this.state.isFocused) {
      this.setState({ isFocused: false }, () => {
        if (this.state.changed) {
          this.props.onSelect();
        }
      });
    }
  };

  handleClickOutside = (event: MouseEvent): void => {
    if (
      ((this.wrapperRef && !this.wrapperRef.current.contains(event.target as Node)) ||
        (event.target as HTMLElement).classList.contains('room-picker__fixed-bg')) &&
      !(event.target as HTMLElement).closest('.ant-select-dropdown')
    ) {
      this.blur();
    }
  };

  checkIsListHaveScroll = (index?: number): void => {
    const { roomsSelected } = this.props.roomsStore;

    if (this.listRef && this.listRef.current) {
      setTimeout(() => {
        const haveScroll: boolean = this.listRef.current.scrollHeight > MAX_LIST_HEIGHT;

        this.setState({ haveScroll });

        if (index === roomsSelected.length - ONE) {
          this.listRef.current.scrollTo(ZERO, this.listRef.current.scrollHeight);
        }
      });
    }
  };

  componentDidMount(): void {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onDone = (): void => {
    this.setState({ changed: true }, this.blur);
  };

  addRoom = (): void => {
    this.props.addEmptyRoom();
    this.checkIsListHaveScroll();
    this.setState({ changed: true });
  };

  render(): React.ReactNode {
    const { isFocused, haveScroll } = this.state;
    const {
      roomsStore,
      loginStore,
      isUpdateSearch,
      updateAdultsCount,
      removeKid,
      addKid,
      removeRoom,
      updateKidAge,
    } = this.props;
    const { roomsSelected, error } = roomsStore;
    const startCount = 0;

    const adultsCount: number = roomsSelected.reduce((res: number, { adultsCount }) => {
      return res + adultsCount;
    }, startCount);
    const childrenCount: number = roomsSelected.reduce((res: number, { kids }) => {
      return res + kids.length;
    }, startCount);

    const account = loginStore?.account;
    const color: any = account?.searchIconsColor;

    const styleColor = !isEmpty(color)? { color } : {};  

    return (
      <div className={`room-picker ${!!error ? 'error' : ''}`} ref={this.wrapperRef}>
        <div className={`room-picker__wrapper ${isFocused ? 'selected' : ''}`} onClick={this.focus}>
          <div className="room-picker__left">
            <div className="room-picker__icon" style={styleColor}>
              <FontAwesomeIcon icon={faUserPlus} />
            </div>
          </div>
          <div className="room-picker__right">
            <div className="room-picker__label">
              <FormattedMessage id="room" values={{ count: 2 }} /> &{' '}
              <FormattedMessage id="guest" values={{ count: 2 }} />
            </div>
            <div className="room-picker__header">
              {adultsCount} <FormattedMessage id="adult" values={{ count: adultsCount }} />,{' '}
              {childrenCount ? (
                <>
                  {childrenCount} <FormattedMessage id="child" values={{ count: childrenCount }} />,{' '}
                </>
              ) : null}
              {roomsSelected.length} <FormattedMessage id="room" values={{ count: roomsSelected.length }} />
            </div>
          </div>
        </div>
        <div className="room-picker__dropdown" style={{ display: isFocused ? 'block' : 'none' }}>
          <div className="room-picker__close-icon" onClick={this.blur}>
            <CloseSvg />
          </div>
          <div className="room-picker__list-wrapper" ref={this.listRef}>
            {roomsSelected.map((room: IRoom, index: number) => {
              return (
                <Room
                  haveScroll={haveScroll}
                  room={room}
                  index={index}
                  key={room.id}
                  addKid={(roomId: string, kid: IGuest) => {
                    addKid(roomId, kid);
                    this.checkIsListHaveScroll(index);
                    this.setState({ changed: true });
                  }}
                  removeKid={(roomId: string, kidId: string) => {
                    removeKid(roomId, kidId);
                    this.checkIsListHaveScroll();
                    this.setState({ changed: true });
                  }}
                  updateAdultsCount={(roomId: string, count: number) => {
                    updateAdultsCount(roomId, count);
                    this.setState({ changed: true });
                  }}
                  removeRoom={(roomId: string) => {
                    removeRoom(roomId);
                    this.checkIsListHaveScroll();
                    this.setState({ changed: true });
                  }}
                  updateKidAge={(roomId: string, kidId: string, age: number) => {
                    updateKidAge(roomId, kidId, age);
                    this.setState({ changed: true });
                  }}
                />
              );
            })}
          </div>
          <div className="room-picker__add">
            {roomsSelected.length < MAX_ROOMS ? (
              <span className="room-picker__add-button" onClick={this.addRoom}>
                + <FormattedMessage id="add.room" />
              </span>
            ) : null}
          </div>
          <div className="room-picker__footer">
            <BlueButton onClick={this.onDone}>
              {!isUpdateSearch ? (<FormattedMessage id="done" />) : null}
              {isUpdateSearch ? (<FormattedMessage id="search" />) : null}
            </BlueButton>
          </div>
        </div>
        {isFocused && <div className="room-picker__fixed-bg" />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    roomsStore: state.roomsStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  addEmptyRoom: () => {
    dispatch(roomsActions.addEmptyRoom());
  },
  updateAdultsCount: (roomId: string, count: number) => {
    dispatch(roomsActions.updateAdultsCount({ roomId, count }));
  },
  addKid: (roomId: string, kid: IGuest) => {
    dispatch(roomsActions.addKid({ roomId, kid }));
  },
  updateKidAge: (roomId: string, kidId: string, age: number) => {
    dispatch(roomsActions.updateKidAge({ roomId, kidId, age }));
  },
  removeRoom: (roomId: string) => {
    dispatch(roomsActions.removeRoom(roomId));
  },
  removeKid: (roomId: string, kidId: string) => {
    dispatch(roomsActions.removeKid({ roomId, kidId }));
  },
});

export const RoomPicker = connect(mapStateToProps, mapDispatchToProps)(RoomPickerComponent);
