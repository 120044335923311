import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AWARDS } from '@constants';
import ConciergeTeamImageLarge from '@assets/images/concierge-team-large.png';
import ConciergeTeamImageSmaller from '@assets/images/concierge-team-smaller.png';
import './style.scss';
import { connect } from 'react-redux';
import { RootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {
  backgroundColor: string;
  isImageSizeSmaller: boolean;
}

class ConciergeTeamComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { backgroundColor, isImageSizeSmaller, menuStore } = this.props;
    const { items } = menuStore;

    const customerServiceNumber = items?.customerServiceNumber;

    return (
      <div className={`concierge-team ${backgroundColor}`}>
        <div className="concierge-team__wrapper">
          <div className="concierge-team__text-wrapper">
            <p className="concierge-team__title">
              <FormattedMessage id="treat.yourself" /> <FormattedMessage id="book.with.our.team" />
              <a href={`tel:${customerServiceNumber}`} className="concierge-team__contact-phone">
                {customerServiceNumber}
              </a>
            </p>
            <div className="concierge-team__descriptions-wrapper">
              <p className="concierge-team__description">
                <FormattedMessage id="your.award.winning.travel" />
              </p>
              <p className="concierge-team__description">
                <FormattedMessage id="we.have.been.recognized.by.dozens" />
              </p>
            </div>
            <div className="concierge-team__awards-wrapper">
              {AWARDS.map((item, i) => (
                <div key={`${item.name} ${i}`} className="concierge-team__award-wrapper">
                  <p className="concierge-team__award-title">
                    <FormattedMessage id={item.name} />
                  </p>
                  <p className="concierge-team__award">
                    <FormattedMessage id={item.reward} />
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="concierge-team__img-wrapper">
            <div className="concierge-team__img">
              <img
                src={isImageSizeSmaller ? ConciergeTeamImageSmaller : ConciergeTeamImageLarge}
                alt="concierge-team"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return { menuStore: state.navigationMenuStore };
};

export const ConciergeTeam = connect(mapStateToProps)(ConciergeTeamComponent);
