import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';

import { ILoginState } from '@share/store/slices';
import { isAdminFromPath, RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps, ButtonProps, RouteComponentProps {}

class BlueButtonComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const isAdminPage = isAdminFromPath(this.props.history);
    let styleButton = !!this.props?.style ? { ...this.props?.style } : {};
    if (!isAdminPage) {
      const account = this.props.loginStore?.account;
      const background: any = account? account?.buttonColor : null;
      styleButton = !isEmpty(background)? { ...styleButton, background } : { ...styleButton };  
    }

    const { htmlType, onClick } = this.props;
    const props = { htmlType, onClick };
    const className = `blue-button ${this.props.className ? this.props.className : ''}`;
    return (
      <Button {...props} disabled={this.props.disabled} className={className} style={styleButton}>
        {this.props.children}
      </Button>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};


export const BlueButton = connect(mapStateToProps)(withRouter(BlueButtonComponent));