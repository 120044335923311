import React, { RefObject } from 'react';

import { Form, Input, Select } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { ICarsDriver } from '@common-types';
import { RootState, checkInLatinLetters, insertSpacesInPhone } from '@share/utils';
import { DATE_FORMAT_FILTER, countriesCode } from '@share/constants';

import { ICarDetails } from '@share/common-types';
import { FormInstance } from 'antd/lib/form';
import { connect } from 'react-redux';
import { ICarsReviewBookState, setCarsDriver } from '@store/slices';
import { ILoginState } from '@share/store/slices';

import { CustomDatePicker } from '@components';

import './style.scss';
import moment from 'moment';

interface IMapStateToProps {
  carsReviewBookStore: ICarsReviewBookState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDriver: (driver: ICarsDriver) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  car: ICarDetails;

  formRef: RefObject<FormInstance>;

  disabled?: boolean;
}

const MAX_LENGTH_NUMBER = 12;
const MIN_LENGTH_NAME = 2;

class DriverInformationComponent extends React.Component<IProps> {
  
  setDriverData = (value: string, key: string) => {
    const item = { ...this.props.carsReviewBookStore.driver, [key]: value };
    this.props.setDriver(item);
  };

  render(): React.ReactNode {
    const { Option } = Select;
    const {
      formRef,
      intl,
      disabled,
      carsReviewBookStore,
    } = this.props;

    const { driver } = carsReviewBookStore;

    return (
      <div className="hotel-info cars-driver-information">
        <div className="cars-driver-information__container">
          <div className="guest__info">
            <p className="guest__title">
              <FormattedMessage id="tab.driver" />
            </p>
            <Form
                name="driver_information_rule"
                className="driver_information__rule"
                ref={formRef}
              >
              <div className="guest__rule">
                <div className="guest__info-wrapper">
                  <Form.Item
                    name="mainDriverGivenName"
                    className="given-name"
                    rules={[
                      {
                        required: true,
                        pattern: checkInLatinLetters,
                        message: intl.formatMessage({ id: 'error.message.contact.name' }),
                      },
                      {
                        min: MIN_LENGTH_NAME,
                        message: intl.formatMessage({ id: 'error.message.min' }, { min: MIN_LENGTH_NAME }),
                      },
                    ]}
                  >
                    <div className="guest__name-input-wrapper">
                      <span className="guest__input-label">
                        <FormattedMessage id="first.name" />
                      </span>
                      <div className="guest__input">
                        <Input
                          placeholder={intl.formatMessage({ id: 'first.name' })}
                          value={driver?.givenName}
                          maxLength={255}
                          disabled={disabled}
                          onChange={(e) => this.setDriverData(e.target.value, 'givenName')}
                        />
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="mainDriverSurname"
                    className="last-name"
                    rules={[
                      {
                        required: true,
                        pattern: checkInLatinLetters,
                        message: intl.formatMessage({ id: 'error.message.contact.name' }),
                      },
                      {
                        min: MIN_LENGTH_NAME,
                        message: intl.formatMessage({ id: 'error.message.min' }, { min: MIN_LENGTH_NAME }),
                      },
                  ]}
                  >
                    <div className="guest__name-input-wrapper">
                      <span className="guest__input-label">
                        <FormattedMessage id="last.name" />
                      </span>
                      <div className="guest__input">
                        <Input
                          placeholder={intl.formatMessage({ id: 'last.name' })}
                          value={driver?.surname}
                          maxLength={255}
                          disabled={disabled}
                          onChange={(e) => this.setDriverData(e.target.value, 'surname')}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </div>

                <div className="guest__info-wrapper cars-driver-information__info-wrapper">
                  <Form.Item
                    name="mainDriverCountry"
                    className="country-address"
                  >
                    <div className="guest__name-input-wrapper">
                      <span className="guest__input-label">
                        <FormattedMessage id="country" />
                      </span>
                      <div className="guest__input">
                        <Select value={driver?.country} onChange={(e) => this.setDriverData(e, 'country')} disabled={disabled}>
                          {countriesCode.map((code, index) => (
                            <Option key={index} value={code.isoCode}>
                              {code.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="mainDriverBirthDate"
                    className="birth-date"
                    initialValue={driver?.birthDate}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'error.message.birth_date' }),
                      },
                    ]}
                  >
                    <div className="guest__name-input-wrapper">
                      <span className="guest__input-label">
                        <FormattedMessage id="birth.date" />
                      </span>
                      <div className="guest__input">
                        <CustomDatePicker
                          value={driver?.birthDate}
                          placeholder={DATE_FORMAT_FILTER}
                          format={DATE_FORMAT_FILTER}
                          disabled={disabled}
                          onDateChange={(mainDriverBirthDate) => {
                            this.setDriverData(mainDriverBirthDate, 'birthDate');
                            formRef.current.setFieldsValue({ mainDriverBirthDate });
                          }}
                          maxDate={moment().add(-15, 'years').toDate()}
                          allowClear
                        />
                      </div>
                    </div>
                  </Form.Item>
                </div>

                <div className="guest__info-phone-wrapper">
                  <Form.Item
                    name="mainDriverPhone"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: intl.formatMessage({ id: 'error.message.phone' }),
                      },
                      {
                        max: MAX_LENGTH_NUMBER,
                        message: intl.formatMessage({ id: 'error.message.number.must.be' }),
                      },
                    ]}
                  >
                    <div className="guest__name-input-wrapper test-test">
                      <span className="guest__input-label">
                        <FormattedMessage id="mobile.phone" />
                      </span>
                      <div className="guest__input">
                        <Input.Group compact>
                          <Select defaultValue="United States" showSearch disabled={disabled} style={{ width: '115px' }} >
                            {countriesCode.map((code, index) => (
                              <Option key={index} value={code.name}>
                                {code.isoCode} {code.dialCode}
                              </Option>
                            ))}
                          </Select>
                          <Input
                            style={{ width: '50%' }}
                            placeholder={intl.formatMessage({ id: 'phone.number' })}
                            disabled={disabled}
                            onChange={(e) => {
                              const matchPhone = insertSpacesInPhone(e.target.value);
                              const num = matchPhone.join('-').trim();
                              this.setDriverData(num, 'phone');
                            }}
                            value={driver?.phone}
                            maxLength={12}
                          />
                        </Input.Group>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsReviewBookStore: state.carsReviewBookStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setDriver: setCarsDriver,
};

export const DriverInformation = connect(mapStateToProps, mapDispatchToProps)( injectIntl(DriverInformationComponent));
