import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import {
  IReviewBookState,
  ICondoReviewBookState,
  setExpiredSession,
  setCondoExpiredSession,
  setSavingsMismatch,
  setCondoSavingsMismatch,
} from '@store/slices';
import { ILoginState } from '@share/store/slices';
import { getAccountUsernameFromPath, getHotelIdFromPath, RootState } from '@share/utils';

import ExpiredSessionImgage from '@assets/images/expired_session.png';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
  condoReviewBookStore: ICondoReviewBookState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setExpiredSession: (expiredSession: boolean) => void;
  setCondoExpiredSession: (expiredSession: boolean) => void;
  setSavingsMismatch: (savingsMismatch: boolean) => void;
  setCondoSavingsMismatch: (savingsMismatch: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  isFromCondo?: boolean;
  isSavingsMismatch?: boolean;
}

export class ExpiredSessionComponent extends React.Component<IProps> {
  redirectToHotelPage = (): void => {
    const { history, loginStore, setExpiredSession, setCondoExpiredSession, isFromCondo = false } = this.props;
    const { account } = loginStore;

    const id = getHotelIdFromPath(history);

    history.push(`${isFromCondo ? `/${account?.name}${Routes.Condo}` : `/${account?.id}${Routes.Hotel}`}/${id}${location.search}`);

    if (isFromCondo) {
      setCondoExpiredSession(false);
    } else {
      setExpiredSession(false);
    }
  };

  redirectToSearchPage = (): void => {
    const { history, loginStore, setExpiredSession, setCondoExpiredSession, isFromCondo = false } = this.props;
    const { account } = loginStore;

    history.push(`${isFromCondo ? `/${account?.name}${Routes.Condo}` : `/${account?.id}${Routes.Hotel}`}${location.search}`);

    if (isFromCondo) {
      setCondoExpiredSession(false);
      setCondoSavingsMismatch(false);
    } else {
      setExpiredSession(false);
      setSavingsMismatch(false);
    }
  };

  render(): React.ReactNode {
    const { isFromCondo, isSavingsMismatch } = this.props;

    return (
      <div className="expired-session">
        <img src={ExpiredSessionImgage} alt="" />
        <p className="expired-session__title">
          <FormattedMessage id="expired.session" />
        </p>
        <p className="expired-session__description">
          {isFromCondo ? (
            <FormattedMessage id="check.unit.availability" />
          ) : (
            <FormattedMessage id="check.room.availability" />
          )}
        </p>
        <div className="expired-session__btn-wrapper">
          {isSavingsMismatch ? (
            <BlueButton onClick={this.redirectToSearchPage}>
              {isFromCondo ? (
                <FormattedMessage id="search.condos" />
              ) : (
                <FormattedMessage id="search.hotel" />
              )}
            </BlueButton>
          ) : (
            <BlueButton onClick={this.redirectToHotelPage}>
              <FormattedMessage id="check.availability" />
            </BlueButton>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
    condoReviewBookStore: state.condoReviewBookStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setExpiredSession,
  setCondoExpiredSession,
  setSavingsMismatch,
  setCondoSavingsMismatch,
};

export const ExpiredSession = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ExpiredSessionComponent));
