import React from 'react';

import { connect } from 'react-redux';

import { Map } from '@utils';
import { IGeoLocation } from '@share/common-types';
import { CloseBlueSvg } from '@assets';

import { MapWrapper } from '../../map';

import MapMarker from '@assets/images/map-bed-marker.svg';

import './style.scss';

interface IProps {
  title: string;
  location: IGeoLocation;
  isMapModalOpen: boolean;

  marker?: string;

  onCloseMap: () => void;
}

const BIG_MAP_ZOOM = 15;

class BigMapComponent extends React.Component<IProps> {

  render(): React.ReactNode {
    const { isMapModalOpen, location, title, marker, onCloseMap } = this.props;
    
    if (!isMapModalOpen) {
      return null;
    }

    return (
      <div
        className="hotel-details-wrapper__big-map-wrapper"
        style={{ display: isMapModalOpen ? 'block' : 'none' }}
      >
        <div className="hotel-details-wrapper__big-map-top-section">
          <span className="hotel-details-wrapper__big-map-close" onClick={onCloseMap}>
            <CloseBlueSvg />
          </span>
          {title}
        </div>
        <MapWrapper
          locations={[
            { location, id: null },
          ]}
          center={Map.getGoogleLocation(location)}
          icon={marker ? marker : MapMarker}
          mapOptions={{ zoom: BIG_MAP_ZOOM, disableDefaultUI: true }}
        />
      </div>
    );
  }
}

export const BigMap = connect()(BigMapComponent);

