
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';

import { INavigation, ISideKickStatus, IUserSidekick } from '@share/common-types';
import { Routes, Urls, USER_BASE_URL } from '@share/constants';
import { AppThunk, axiosInstanceExternal, DecodeTokenData, getToken } from '@share/utils';
import { getHeaders, axiosInstance } from '@share/utils';
import { forceSetLoginAccount, getUserWallet, reservationCancellationAction } from '@share/store/slices';

export interface IMenuState {
  items: INavigation | null;
  userSidekick: IUserSidekick | null;
  loading: boolean;
  error: string;
  visible: boolean;
  isUpdateMenu: boolean;
}

const initialState: IMenuState = {
  items: null,
  userSidekick: null,
  loading: false,
  error: '',
  visible: false,
  isUpdateMenu: false,
};

const navigationMenuSlice = createSlice({
  name: 'navigationMenu',
  initialState,
  reducers: {
    setLoading: (state: IMenuState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state: IMenuState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setMenu: (state: IMenuState, { payload }: PayloadAction<INavigation>) => {
      state.items = payload;
    },
    setVisible: (state: IMenuState, { payload }: PayloadAction<boolean>) => {
      state.visible = payload;
    },
    setIsUpdateMenu: (state: IMenuState, { payload }: PayloadAction<boolean>) => {
      state.isUpdateMenu = payload;
    },
    setUserSidekick: (state: IMenuState, { payload }: PayloadAction<IUserSidekick>) => {
      state.userSidekick = payload;
    }
  },
});

export const { setLoading, setError, setMenu, setVisible, setIsUpdateMenu, setUserSidekick } =
  navigationMenuSlice.actions;

export const navigationMenuReducer = navigationMenuSlice.reducer;

export const navigationMenuSelector = (state: { navigationMenuStore: IMenuState }): IMenuState => {
  return state.navigationMenuStore;
};

export const getNavigationMenu = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
      const { loginStore } = getState();
      const { user, account } = loginStore;

      const data = getToken(account);
      const res = await axiosInstance.post(`${Urls.NavigationItems}/${account.name}/${user.userId}`, data? data : {}, {
        ...getHeaders(),
      });

      const items = { ...res.data };

      const dataDecoded = DecodeTokenData();
      if (dataDecoded) {
        items.renewalAgency = dataDecoded.renewalAgency;
        items.sideKickStatus = dataDecoded.sideKickStatus;
      }
 
      const siteId = items?.siteId;
      const organizationId = items?.organizationId;
    
      const loyaltyRewardsTextSiteidList = account?.loyaltyRewardsTextSiteidList;
      const loyaltyRewardsTextOrgidList = account?.loyaltyRewardsTextOrgidList;

      const removeAuthorizedUsersRsiSiteidList = account?.removeAuthorizedUsersRsiSiteidList;
      const removeAuthorizedUsersRsiOrgidList = account?.removeAuthorizedUsersRsiOrgidList;

      const removeInsuranceRsiSiteidList = account?.removeInsuranceRsiSiteidList;
      const removeInsuranceRsiOrgidList = account?.removeInsuranceRsiOrgidList;
      
      const removeMasterclassRsiSiteidList = account?.removeMasterclassRsiSiteidList;
      const removeMasterclasssRsiOrgidList = account?.removeMasterclasssRsiOrgidList;

      const removeMemberVideosRsiSiteidList = account?.removeMemberVideosRsiSiteidList;
      const removeMemberVideosRsiOrgidList = account?.removeMemberVideosRsiOrgidList;

      const removeConciergeMessageRsiSiteidList = account?.removeConciergeMessageRsiSiteidList;
      const removeConciergeMessageRsiOrgidList = account?.removeConciergeMessageRsiOrgidList;
    
      const removeChatRsiSiteidList = account?.removeChatRsiSiteidList;
      const removeChatRsiOrgidList = account?.removeChatRsiOrgidList;

      const removePriceMatchRsiSiteidList = account?.removePriceMatchRsiSiteidList;
      const removePriceMatchRsiOrgidList = account?.removePriceMatchRsiOrgidList;
    
      const removeResourcesRsiSiteidList = account?.removeResourcesRsiSiteidList;
      const removeResourcesRsiOrgidList = account?.removeResourcesRsiOrgidList;
    
      items.isPromoSite = loyaltyRewardsTextSiteidList?.includes(siteId?.toString()) || loyaltyRewardsTextOrgidList?.includes(organizationId?.toString());
      items.isRemoveAuthorizedUsers = removeAuthorizedUsersRsiSiteidList?.includes(siteId?.toString()) || removeAuthorizedUsersRsiOrgidList?.includes(organizationId?.toString());
      items.isRemoveInsurance = removeInsuranceRsiSiteidList?.includes(siteId?.toString()) || removeInsuranceRsiOrgidList?.includes(organizationId?.toString());
      items.isRemoveMasterclass = removeMasterclassRsiSiteidList?.includes(siteId?.toString()) || removeMasterclasssRsiOrgidList?.includes(organizationId?.toString());
      items.isRemoveMemberVideos = removeMemberVideosRsiSiteidList?.includes(siteId?.toString()) || removeMemberVideosRsiOrgidList?.includes(organizationId?.toString());
      items.isRemoveConciergeMessage = removeConciergeMessageRsiSiteidList?.includes(siteId?.toString()) || removeConciergeMessageRsiOrgidList?.includes(organizationId?.toString());
      items.isRemoveChat = removeChatRsiSiteidList?.includes(siteId?.toString()) || removeChatRsiOrgidList?.includes(organizationId?.toString());
      items.isRemovePriceMatch = removePriceMatchRsiSiteidList?.includes(siteId?.toString()) || removePriceMatchRsiOrgidList?.includes(organizationId?.toString());
      items.isRemoveResources = removeResourcesRsiSiteidList?.includes(siteId?.toString()) || removeResourcesRsiOrgidList?.includes(organizationId?.toString());
            
      let main = [ ...items.main ];
      if (main?.length && account?.levartiOrgIds?.includes(items.organizationId)) {
        const moreBenefits = main?.filter((m: any) => m.title === 'More Benefits');
        if (!items.isTravelAgent) {
          main = [
            { isPopup: false, title: "Travel Agent", childItems: [
              { isPopup: false, title: "Become Travel Agent", url: Routes.MLMBecomeAgent },
              { isPopup: false, excludeCheck: true, title: "New Agents", url: `https://travnowadvisors.com/new-agents` },
              { isPopup: false, excludeCheck: true, title: "Experienced Agents", url: `https://travnowadvisors.com/experienced-agents` },
              { isPopup: false, excludeCheck: true, title: "Exclusive access", url: `https://travnowadvisors.com/exclusive-access` },
              { isPopup: false, excludeCheck: true, title: "Training", url: `https://travnowadvisors.com/training` },
              { isPopup: false, excludeCheck: true, title: "Tools", url: `https://travnowadvisors.com/technology` },
            ] },
            { isPopup: false, title: "Hotels", url: `/search` },
            { isPopup: false, title: "Condos", url: `/condo/deals` },
            { isPopup: false, title: "Cruises", url: `/cruise` },
            { isPopup: false, title: "Cars", url: `/cars/search` },
            { isPopup: false, title: "Insurance", url: `/insurance/search` },
          ];
          if (moreBenefits?.length) {
            const childItems = moreBenefits[0].childItems.filter((a: any) => a.title !== 'Car Rentals');
            const moreBenefitsFiltered = { ...moreBenefits[0], childItems };
            main.push(moreBenefitsFiltered);
          }
        } else {
          main = [
            { isPopup: false, title: "my.dashboard", url: `/dashboard` },
            { isPopup: false, title: "book.travel", childItems: [
              { isPopup: false, title: "Book Hotels", url: `/search` },
              { isPopup: false, title: "Book Condos", url: `/condo/deals` },
              { isPopup: false, title: "Book Cruises", url: `/cruise` },
              { isPopup: false, title: "Book Cars", url: `/cars/search` },
              { isPopup: false, title: "Book Insurance", url: `/insurance/search` },
              { isPopup: false, title: "Book All Inclusive", url: `/all-inclusives` },
              { isPopup: false, title: "Book Getaways", url: `/getaway` },
            ] },
            { isPopup: false, excludeCheck: true, title: "training.material", url: `https://travelagents.travcoding.com/wp-admin/?access_token=${user?.accessToken}&email=${user?.email}` },
            { isPopup: false, title: "support.page", url: `/contact-us` },
          ];
        }
      }
      
      dispatch(setMenu({ ...items, main }));

      if (items?.sideKickStatus === ISideKickStatus.PARENT) {
        dispatch(getUserSidekick());
      }

      if (items?.isMLM) {
        dispatch(getUserWallet(user));


        dispatch(forceSetLoginAccount({
          ...account,
          walletUseSlider: true,
          hasClientCash: items?.isRewardsBased,
          walletIsExternal: true,
          walletNoDecimals: false,
          // TODO delete this
          //walletNoPassEarnings: true,
          //walletWalletSavings: true,
          isMLM: true,
          walletClientCashName: 'Points'
        }));

      }

      dispatch(setLoading(false));

      if (!isEmpty(items?.logoutUrl)) {
        localStorage.setItem(USER_BASE_URL, items.logoutUrl);
      }

    } catch (error: any) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setLoading(false));
    }
  };
};

export const getNavigationMenutCancelDetail = (tempKey: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(reservationCancellationAction.setLoading(true));

    try {
      const params = { tempKey };
      const res = await axiosInstance.post(Urls.reservationCancellationDetails, null, {
        params,
        ...getHeaders(),
      });

      dispatch(setMenu({
        logoUrl: get(res.data, 'reservationDetails.logoUrl', null)
      } as INavigation));
      dispatch(reservationCancellationAction.setReservationCancellationDetails(res.data));

      dispatch(setLoading(false));
      dispatch(reservationCancellationAction.setLoading(false));

    } catch (error: any) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setLoading(false));
    }
  };
};

export const getUserSidekick = (): AppThunk => {
  return async (dispatch, getState) => {
    const { loginStore } = getState();
    const { user } = loginStore;

    dispatch(setLoading(true));
    dispatch(setLoading(true));

    try {
      const responseAuth = await axiosInstance.post(`${Urls.SSO}/token-sidekick`, null, {
        ...getHeaders(),
      });

      const accessToken = responseAuth.data.accessToken;
      const response = await axiosInstanceExternal.get(`https://svc.accessrsi.com/api/sidekick/${user?.keyid}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }      
      });
      
      const items = response.data.items;
      const pending = items?.length ? items.map((i: any) => i.pendingRewards).reduce((p: number, n: number) => p + n) : 0;
      const available = items?.length ? items.map((i: any) => i.activeRewards).reduce((p: number, n: number) => p + n) : 0;
      const sidekicks = items.map((i: any) => ({
        key: i.rsiId,
        rsiId: i.rsiId,
        name: i.fullName,
        email: i.email,
        pendingTravel: i.pendingRewards,
        availableRewards: i.activeRewards,
        dateAdded: i.activationDate,
        status: i.isActive ? 'Active' : 'Pending'
      }));

      const sidekick = {
        pending,
        available,
        sidekicks
      };
      dispatch(setUserSidekick(sidekick));
      dispatch(setLoading(false));

    } catch (error: any) {
      console.error(error);
      dispatch(setError(error.toString()));
      dispatch(setLoading(false));
    }
  };
};

