
import React from 'react';

import { Select } from 'antd';
import { IGetawaysLocations, GetawaysDefaultLocation, GetawaysDefaultLocationCountry } from '@share/common-types';

import './style.scss';

interface IProps {
  value: string;

  options: IGetawaysLocations[];

  disabled?: boolean;
  isCountryBased?: boolean;
  disableSort?:boolean;

  onChange: (value: string) => void;

  getOptionValue?: (obj: any) => string;
}

export class FilterLocations extends React.Component<IProps> {
  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  getPopupContainer = (): HTMLElement => {
    return this.wrapperRef ? this.wrapperRef.current : document.body;
  };

  render(): React.ReactNode {
    const { onChange, value, disabled = false, disableSort, options, isCountryBased, getOptionValue } = this.props;
    const { Option } = Select;

    const optionsFiltered = options.reduce((arr, item) => {
      const removed = isCountryBased ? arr.filter(i => i.country !== item.country) : arr.filter(i => i.city !== item.city);
      return [...removed, item];
    }, []);

    if (!disableSort) {
      optionsFiltered.sort((a: IGetawaysLocations, b: IGetawaysLocations) => {
        if (isCountryBased) {
          return a.country?.localeCompare(b.country);
        }
        return a.city.localeCompare(b.city);
      });
    }

    const valueSelected = value || (isCountryBased ? GetawaysDefaultLocationCountry.country : GetawaysDefaultLocation.city);

    return (
      <div className="filter-locations" ref={this.wrapperRef}>
        <Select
          disabled={disabled}
          value={valueSelected}
          onChange={onChange}
          getPopupContainer={this.getPopupContainer}
        >
          {optionsFiltered.map((obj: any) => {
            const valueKey = getOptionValue ? getOptionValue(obj) : isCountryBased ? obj?.country : obj?.city;
            
            const value = isCountryBased ? obj?.country : obj?.city;
            const description = value;
            return (
              <Option key={`${valueKey}${obj?.state ? `-${obj?.state}` : ''}`} value={valueKey}>
                <div className="filter-locations__option">
                  {description}
                  {!isCountryBased ? (
                    <div>{obj?.country && obj?.state && `${obj?.country}, ${obj?.state}`}</div>) : null}
                </div>
              </Option>)})}
        </Select>
      </div>
    );
  }
}
