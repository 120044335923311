import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { RootState } from '@share/utils';
import { ILoginState } from '@share/store/slices';

import SunHappy from '@share/assets/images/sun-happy.jpg';
import Enjoy from '@share/assets/images/enjoy.jpg';
import VIP from '@share/assets/images/vip.jpg';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

class SearchBenefitsComponent extends React.Component<IProps> {

  render() {
    const { loginStore } = this.props;
    const { account } = loginStore;

    if(account?.hideThingsMatterSection) {
      return null;
    }

    //TODO check if the account has benefits information
    return (
      <div className="search-benefits">
        <p className="search-benefits__title">
          <FormattedMessage id="search.benefits.title" />
        </p>
        
        <div className="search-benefits__wrapper">
          <div className="search-benefits-item__container">
            <div className="search-benefits-item">
              <div className="image">
                <img src={SunHappy} alt="" />
              </div>
              <p className="title"><FormattedMessage id="search.benefits.message.1.title" /></p>
              <p className="message"><FormattedMessage id="search.benefits.message.1.message" /></p>
            </div>
          </div>
          <div className="search-benefits-item__container">
            <div className="search-benefits-item">
              <div className="image">
                <img src={Enjoy} alt="" />
              </div>
              <p className="title"><FormattedMessage id="search.benefits.message.2.title" /></p>
              <p className="message"><FormattedMessage id="search.benefits.message.2.message" /></p>
            </div>
          </div>
          <div className="search-benefits-item__container">
            <div className="search-benefits-item">
              <div className="image">
                <img src={VIP} alt="" />
              </div>
              <p className="title"><FormattedMessage id="search.benefits.message.3.title" /></p>
              <p className="message"><FormattedMessage id="search.benefits.message.3.message" /></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

export const SearchBenefits = connect(mapStateToProps)(SearchBenefitsComponent);
