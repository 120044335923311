export class IUser {
  userId?: string;
  keyid?: number;
  accessToken: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  active: number;
  accountId: number;
  admin: number;
  countryCode: string;
  role: string;
  state: string;
  permissions?: string[];
  userAdditionalData?: {
    webSite?: string;
    webSiteIsActive?: boolean;
  };
}

export class IUserCard {
  id?: string;
  addressLine: string;
  cvv: string;
  cardNumber: string;
  cardType: string;
  city: string;
  country: string;
  state: string;
  expireDate: string;
  holderName: string;
  phone: string;
  zipCode: string;
  addPaymentMethod: boolean;
}

export class ISidekick {
  key: number;
  rsiId: number;
  name: string;
  email: string;
  dateAdded: string;
  pendingTravel: number;
  availableRewards: number;
  status: string;
}

export class IUserSidekick {
  pending: number;
  available: number;

  sidekicks: ISidekick[];
}

export class IUserWallet {
  walletCode: string;
  userId: string;
  balance: number;
  pendingBalance: number;
  expiredBalance: number;
  canceledBalance: number;
  usedBalance: number;

  convertionRate: number;
  maxClientCashPercentageAllow: number;
}
