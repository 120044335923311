import { EQUAL_FILTER, GREATER_FILTER, ICondoWeeksFilters, LOWER_FILTER, RANGE_FILTER } from "@common-types";
import { isEmpty } from "lodash";

export const INITIAL_FITLERS: ICondoWeeksFilters = {
  checkInCondition: EQUAL_FILTER,
  fromCheckIn: null,
  toCheckIn: null,
  checkOutCondition: EQUAL_FILTER,
  fromCheckOut: null,
  toCheckOut: null,
  lengthOfStay: null,
  occupancy: null,
  bedrooms: null,
  bathrooms: null,
  locationName: null,
  priceCondition: EQUAL_FILTER,
  startPrice: null,
  endPrice: null,

  applied: false,
  loading: false,
  locations: []
};

export const ProcessFilterParams = (filters: ICondoWeeksFilters) => {
  const checkInCondition = filters?.checkInCondition;
  const fromCheckIn = filters?.fromCheckIn;
  const toCheckIn = filters?.toCheckIn;
  const checkOutCondition = filters?.checkOutCondition;
  const fromCheckOut = filters?.fromCheckOut;
  const toCheckOut = filters?.toCheckOut;
  const locationName = filters?.locationName;
  const lengthOfStay = filters?.lengthOfStay;
  const priceCondition = filters?.priceCondition;
  const startPrice = filters?.startPrice;
  const endPrice = filters?.endPrice;
  const maxOccupancy = filters?.occupancy;
  const bedrooms = filters?.bedrooms;
  const bathrooms = filters?.bathrooms;

  let filterApplied = false;

  let checkInParam = '';
  let checkOutParam = '';
  let locationNameParam = '';
  let lengthOfStayParam = '';
  let priceParam = '';
  let maxOccupancyParam = '';
  let bathroomsParam = '';
  let bedroomsParam = '';

  if (!isEmpty(fromCheckIn)) {
    if (checkInCondition === EQUAL_FILTER) {
      checkInParam = `fromCheckIn=${fromCheckIn}&toCheckIn=${fromCheckIn}`;
      filterApplied = true;
    } else if (checkInCondition === GREATER_FILTER) {
      checkInParam = `fromCheckIn=${fromCheckIn}`;
      filterApplied = true;
    } else if (checkInCondition === LOWER_FILTER) {
      checkInParam = `toCheckIn=${fromCheckIn}`;
      filterApplied = true;
    } else if (checkInCondition === RANGE_FILTER && !isEmpty(toCheckIn)) {
      checkInParam = `fromCheckIn=${fromCheckIn}&toCheckIn=${toCheckIn}`;
      filterApplied = true;
    }
  }

  if (!isEmpty(fromCheckOut)) {
    if (checkOutCondition === EQUAL_FILTER) {
      checkOutParam = `${filterApplied ? '&' : ''}fromCheckOut=${fromCheckOut}&toCheckOut=${fromCheckOut}`;
      filterApplied = true;
    } else if (checkOutCondition === GREATER_FILTER) {
      checkOutParam = `${filterApplied ? '&' : ''}fromCheckOut=${fromCheckOut}`;
      filterApplied = true;
    } else if (checkOutCondition === LOWER_FILTER) {
      checkOutParam = `${filterApplied ? '&' : ''}toCheckOut=${fromCheckOut}`;
      filterApplied = true;
    } else if (checkOutCondition === RANGE_FILTER && !isEmpty(toCheckOut)) {
      checkOutParam = `${filterApplied ? '&' : ''}fromCheckOut=${fromCheckOut}&toCheckOut=${toCheckOut}`;
      filterApplied = true;
    }
  }

  if (!isEmpty(locationName)) {
    locationNameParam = `${filterApplied ? '&' : ''}locationName=${locationName}`;
    filterApplied = true;
  }

  if (lengthOfStay) {
    lengthOfStayParam = `${filterApplied ? '&' : ''}lengthOfStay=${lengthOfStay}`;
    filterApplied = true;
  }

  if (maxOccupancy) {
    maxOccupancyParam = `${filterApplied ? '&' : ''}maxOccupancy=${maxOccupancy}`;
    filterApplied = true;
  }

  if (bathrooms) {
    bathroomsParam = `${filterApplied ? '&' : ''}bathrooms=${bathrooms}`;
    filterApplied = true;
  }

  if (bedrooms) {
    bedroomsParam = `${filterApplied ? '&' : ''}bedrooms=${bedrooms}`;
    filterApplied = true;
  }

  if (startPrice) {
    if (priceCondition === EQUAL_FILTER) {
      priceParam = `${filterApplied ? '&' : ''}startPrice=${startPrice}&endPrice=${startPrice}`;
      filterApplied = true;
    } else if (priceCondition === GREATER_FILTER) {
      priceParam = `${filterApplied ? '&' : ''}startPrice=${startPrice}`;
      filterApplied = true;
    } else if (priceCondition === LOWER_FILTER) {
      priceParam = `${filterApplied ? '&' : ''}endPrice=${startPrice}`;
      filterApplied = true;
    } else if (priceCondition === RANGE_FILTER && !endPrice) {
      priceParam = `${filterApplied ? '&' : ''}startPrice=${startPrice}&endPrice=${endPrice}`;
      filterApplied = true;
    }
  }

  return {
    params: `${filterApplied ? '&' : ''}${checkInParam}${checkOutParam}${locationNameParam}${lengthOfStayParam}${priceParam}${bedroomsParam}${bathroomsParam}${maxOccupancyParam}`,
    filterApplied
  };
}
