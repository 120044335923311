import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { Checkbox } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { Action } from 'redux';
import { RootState } from '@share/utils';
import { ICondoFiltersState, ICondosState, ILoginState, condoFiltersActions } from '@share/store/slices';
import { ICondosStaticState } from '@share/store/slices';
import { CloseSvg } from '@share/assets';
import { FilterCheckbox, FilterDistance } from '@components';
import { CustomSelect, BlueButton } from '@share/components';
import {
  getCondoSortOptions,
  isCondoFiltersEmpty,
  getCondoFiltersCount,
  HasPermission,
  ViewCondoSupplierPermission,
} from '@utils';
import { getBudgetRangeLabel } from '@share/utils';
import { CondoLocationsEnum } from '@share/common-types';
import { CondoRequestTypeEnum } from '@share/common-types';
import { C_R_FILTER_ACCESSIBILITIES, C_R_FILTER_ACTIVITIES, C_R_FILTER_ALL_INCLUSIVE, C_R_FILTER_AMENITIES, C_R_FILTER_BATHROOM, C_R_FILTER_BUDGET, C_R_FILTER_CONDO_TYPE, C_R_FILTER_DAY, C_R_FILTER_DISNTACE, C_R_FILTER_FACILITIES, C_R_FILTER_GUEST_RATING, C_R_FILTER_KITCHEN, C_R_FILTER_MONTH, C_R_FILTER_NEIGHBORHOODS, C_R_FILTER_PLACES, C_R_FILTER_SUPPLIER, SortTypes } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  condoFiltersStore: ICondoFiltersState;
  condosStore: ICondosState;
  condosStaticStore: ICondosStaticState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setBudgetRanges: (value: string[]) => void;
  setDistanceInMiles: (value: number) => void;
  setGuestRatings: (value: string[]) => void;
  setNeighbourhoods: (value: string[]) => void;
  setPlaces: (value: string[]) => void;
  setUnitAmenities: (value: string[]) => void;
  setFacilities: (value: string[]) => void;
  setActivities: (value: string[]) => void;
  setCondoTypes: (value: string[]) => void;
  setAccessibilities: (value: string[]) => void;
  setSuppliers: (value: string[]) => void;
  setBathrooms: (value: number[]) => void;
  setDays: (value: number[]) => void;
  setMonths: (value: number[]) => void;
  setAllInclusive: (value: boolean) => void;
  setKitchenTypes: (value: string[]) => void;
  resetFilters: () => void;
  setSortType: (value: SortTypes) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {
  onClose: () => void;
  onFiltersOrSortChange: () => void;
  isDisabled: boolean;
  matches?: boolean;
}

const distanceOptions = [
  {
    name: <FormattedMessage id="mile" values={{ count: 10 }} />,
    value: 10,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 20 }} />,
    value: 20,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 50 }} />,
    value: 50,
  },
  {
    name: <FormattedMessage id="mile" values={{ count: 100 }} />,
    value: 100,
  },
];

const ZERO = 0;

class CondoFiltersWrapperComponent extends React.Component<IProps> {
  onBudgetChange = (value: string[]) => {
    this.props.setBudgetRanges(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_BUDGET);
    }
  };

  onDistanceChange = (value: number) => {
    this.props.setDistanceInMiles(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_DISNTACE);
    }
  };

  onGuestRatingChange = (value: string[]) => {
    this.props.setGuestRatings(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_GUEST_RATING);
    }
  };

  onNeighborhoodsChange = (value: string[]) => {
    this.props.setNeighbourhoods(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_NEIGHBORHOODS);
    }
  };

  onPlacesChange = (value: string[]) => {
    this.props.setPlaces(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_PLACES);
    }
  };

  onAmenitiesChange = (value: string[]) => {
    this.props.setUnitAmenities(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_AMENITIES);
    }
  };

  onFacilitiesChange = (value: string[]) => {
    this.props.setFacilities(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_FACILITIES);
    }
  };

  onActivitiesChange = (value: string[]) => {
    this.props.setActivities(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_ACTIVITIES);
    }
  };

  onCondoTypesChange = (value: string[]) => {
    this.props.setCondoTypes(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_CONDO_TYPE);
    }
  };

  onAccessibilitiesChange = (value: string[]) => {
    this.props.setAccessibilities(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_ACCESSIBILITIES);
    }
  };

  onSuppliersChange = (value: string[]) => {
    this.props.setSuppliers(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_SUPPLIER);
    }
  };

  onBathroomChange = (value: string[]) => {
    this.props.setBathrooms(value.map((val) => +val));

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_BATHROOM);
    }
  };

  onDayChange = (value: string[]) => {
    this.props.setDays(value.map((val) => +val));

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_DAY);
    }
  };

  onMonthChange = (value: string[]) => {
    this.props.setMonths(value.map((val) => +val));

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_MONTH);
    }
  };

  onKitchenChange = (value: string[]) => {
    this.props.setKitchenTypes(value);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_KITCHEN);
    }
  };

  onAllInclusiveChange = (e: { target: CheckboxChangeEventTarget }) => {
    this.props.setAllInclusive(e.target.checked);

    if (this.props.matches) {
      this.handleFilterChange(C_R_FILTER_ALL_INCLUSIVE);
    }
  };

  handleFilterChange = (key: string) => {
    this.props.onFiltersOrSortChange();
    this.handleAnalytics(key);
  }

  handleAnalytics = (key: string) => {
    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account.name,
      action: `${key}_${account.name.toUpperCase()}`,
      label: `User selected filter on condo resuls`,
      nonInteraction: false,
    });
  }

  resetFilters = () => {
    this.props.resetFilters();
    this.props.onFiltersOrSortChange();
  };

  getSortOptions = (): LabeledValue[] => {
    const { condosStore, loginStore } = this.props;
    const { account } = loginStore;
    const { isB2C, walletWalletSavings } = account;
    return getCondoSortOptions(condosStore, isB2C, walletWalletSavings);
  };

  onSortChange = (value: SelectValue): void => {
    this.props.setSortType(value as SortTypes);
    this.props.onFiltersOrSortChange();
    this.props.onClose();
  };

  applyFilters = () => {
    this.props.onFiltersOrSortChange();
    this.props.onClose();
  };

  render(): React.ReactNode {
    const { onClose, loginStore, condosStaticStore, condoFiltersStore, condosStore, isDisabled, matches } = this.props;
    const {
      days,
      months,
      bathrooms,
      kitchenTypes,
      neighbourhoods,
      places,
      accessibilities,
      suppliers,
      condoTypes,
      activities,
      distanceInMiles,
      budgetRanges,
      facilities,
      unitAmenities,
      guestRatings,
      sortType,
      isAllInclusiveOnly,
    } = condoFiltersStore;
    const { user } = loginStore;
    const { counters, cachedSelectedLocation, lastRequestType } = condosStore;
    const isSpecificCondo =
      cachedSelectedLocation?.locationType === CondoLocationsEnum.GenericCondo;
    const isAnytime = lastRequestType === CondoRequestTypeEnum.Anytime;
    const suppliersList = condosStaticStore?.suppliers;

    if (!counters) {
      return null;
    }

    const showBudgetRanges = counters.budgetRanges?.length ? counters.budgetRanges.some(({ count }) => count) : false;
    const showNeighbourhoods = counters.neighbourhoods?.length ? counters.neighbourhoods?.some(({ count }) => count) : false;
    const showPlaces = counters.places?.length ? counters.places?.some(({ count }) => count) : false;
    const showAmenities = counters.unitAmenities?.length ? counters.unitAmenities?.some(({ count }) => count) : false;
    const showFacilities = counters.facilities?.length ? counters.facilities?.some(({ count }) => count) : false;
    const showActivities = counters.activities?.length ? counters.activities?.some(({ count }) => count) : false;
    const showCondoTypes = counters.condoTypes?.length ? counters.condoTypes?.some(({ count }) => count) : false;
    const showAccessibilities = counters.accessibilities?.length ? counters.accessibilities?.some(({ count }) => count) : false;
    const showGuestRatings = counters.guestRatings?.length ? counters.guestRatings?.some(({ count }) => count) : false;
    const showBathrooms = counters.bathrooms?.length ? counters.bathrooms?.some(({ count }) => count) : false;
    const showDays = counters.days?.length ? counters.days?.some(({ count }) => count) : false;
    const showMonths = counters.months?.length ? counters.months?.some(({ count }) => count) : false;
    const showKitchenTypes = counters.kitchenTypes?.length ? counters.kitchenTypes?.some(({ count }) => count) : false;    

    const showSuppliers = HasPermission(user, ViewCondoSupplierPermission);

    return (
      <div className="condo-filters-wrapper">
        <div className="condo-filters-wrapper__title">
          <div className="condo-filters-wrapper__left">
            {!matches && (
              <div className="condo-filters-wrapper__close" onClick={onClose}>
                <CloseSvg />
              </div>
            )}
            {matches ? <FormattedMessage id="filter" /> : <FormattedMessage id="sort.filter" />}
          </div>
          <div className="condo-filters-wrapper__right">
            {!isCondoFiltersEmpty(condoFiltersStore) && !isDisabled && (
              <div className="condo-filters-wrapper__reset" onClick={this.resetFilters}>
                <FormattedMessage id="reset.filter" />
              </div>
            )}
            {matches && (
              <div className="condo-filters-wrapper__close" onClick={onClose}>
                <CloseSvg />
              </div>
            )}
          </div>
        </div>
        <div className="condo-filters-wrapper__main">
          {!matches && (
            <div className="condo-filters-wrapper__sort">
              <div className="condo-result-wrapper__condos-sort">
                <div className="condo-result-wrapper__sort-select">
                  <span className="condo-result-wrapper__sort-label">
                    <FormattedMessage id="sort.by" />:{' '}
                  </span>
                  <CustomSelect
                    disabled={isDisabled || (isAnytime && !isSpecificCondo)}
                    value={sortType}
                    onChange={this.onSortChange}
                    options={this.getSortOptions()}
                  />
                </div>
              </div>
            </div>
          )}
          {counters.allInclusiveCount !== ZERO ? (
            <Checkbox
              className="condo-filters-wrapper__filter-all-inclusive"
              onChange={this.onAllInclusiveChange}
              checked={isAllInclusiveOnly}
            >
              <span className="filter__option-name">
                <FormattedMessage id="show.all.inclusive.only" />
              </span>
              <span className="filter__option-quantity">{counters.allInclusiveCount}</span>
            </Checkbox>
          ) : null}
          {showBudgetRanges && (
            <FilterCheckbox
              disabled={isDisabled || isAnytime}
              onChange={this.onBudgetChange}
              values={budgetRanges}
              options={counters.budgetRanges.map(({ count, key }) => {
                return {
                  count,
                  value: `${key.from}-${key.to}`,
                  label: getBudgetRangeLabel(key),
                };
              })}
              title="price.per.night"
            />
          )}
          {isSpecificCondo && (
            <FilterDistance
              disabled={isDisabled}
              title="distance.from.the.condo"
              onChange={this.onDistanceChange}
              value={distanceInMiles}
              options={distanceOptions}
            />
          )}
          {showGuestRatings && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onGuestRatingChange}
              values={guestRatings.map((val) => `${val}`)}
              options={counters.guestRatings?.map(({ count, key }) => {
                return {
                  count: count,
                  value: `${key.from}-${key.to}`,
                  label: key.title,
                };
              })}
              title="sort.guest"
            />
          )}
          {showNeighbourhoods && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onNeighborhoodsChange}
              values={neighbourhoods}
              options={counters.neighbourhoods?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="neighborhoods"
              withShowMore={true}
            />
          )}
          {showPlaces && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onPlacesChange}
              values={places.map((val) => `${val}`)}
              options={counters.places?.map(({ count, key }) => {
                return {
                  count,
                  value: `${key.city}|${key.state}|${key.country}|${key.title}`,
                  label: <span>{key.title}</span>,
                };
              })}
              title="filter.by.area"
              withShowMore={true}
            />
          )}
          {showAmenities && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onAmenitiesChange}
              values={unitAmenities}
              options={counters.unitAmenities?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="unit.amenities"
              withShowMore={true}
            />
          )}
          {showFacilities && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onFacilitiesChange}
              values={facilities}
              options={counters.facilities?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="condo.facilities"
              withShowMore={true}
            />
          )}
          {showActivities && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onActivitiesChange}
              values={activities}
              options={counters.activities?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="tab.activities"
              withShowMore={true}
            />
          )}
          {showCondoTypes && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onCondoTypesChange}
              values={condoTypes}
              options={counters.condoTypes?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="condo.type"
              withShowMore={true}
            />
          )}
          {showBathrooms && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onBathroomChange}
              values={bathrooms.map((b) => `${b}`)}
              options={counters.bathrooms?.map(({ count, key }) => {
                return {
                  count,
                  value: `${key}`,
                  label: (
                    <span>
                      <FormattedMessage id="bathrooms.count" values={{ count: key }} />
                    </span>
                  ),
                };
              })}
              title="number.of.bathrooms"
            />
          )}
          {showDays && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onDayChange}
              values={days.map((b) => `${b}`)}
              options={counters.days?.map(({ count, key }) => {
                return {
                  count,
                  value: `${key}`,
                  label: (
                    <span>
                      <FormattedMessage id="days.count" values={{ count: key }} />
                    </span>
                  ),
                };
              })}
              title="number.of.days"
            />
          )}
          {showMonths && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onMonthChange}
              values={months.map((b) => `${b}`)}
              options={counters.months?.map(({ count, key }) => {
                return {
                  count,
                  value: `${key}`,
                  label: (
                      <FormattedMessage id={`months.${key}`} />
                  ),
                };
              })}
              title="number.of.months"
            />
          )}
          {showKitchenTypes && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onKitchenChange}
              values={kitchenTypes}
              options={counters.kitchenTypes?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="kitchen.type"
            />
          )}
          {showAccessibilities && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onAccessibilitiesChange}
              values={accessibilities}
              options={counters.accessibilities?.map(({ count, key }) => {
                return {
                  count,
                  value: key,
                  label: <span>{key}</span>,
                };
              })}
              title="accessibility"
              withShowMore={true}
            />
          )}
          {showSuppliers && (
            <FilterCheckbox
              disabled={isDisabled}
              onChange={this.onSuppliersChange}
              values={suppliers}
              options={suppliersList.map(s => {
                return {
                  count: 0,
                  value: s,
                  label: <span><FormattedMessage id={`suppliers.${s}.name`} /></span>,
                };
              })}
              title="supplier"
              displayZero={true}
            />
          )}
        </div>
        <div className="condo-filters-wrapper__apply-button">
          <BlueButton onClick={this.applyFilters}>
            <FormattedMessage
              id="apply.filters"
              values={{ count: getCondoFiltersCount(condoFiltersStore) }}
            />
          </BlueButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condoFiltersStore: state.condoFiltersStore,
    condosStore: state.condosStore,
    condosStaticStore: state.condosStaticStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  setBudgetRanges: (value: string[]) => {
    dispatch(condoFiltersActions.setBudgetRanges(value));
  },
  setDistanceInMiles: (value: number) => {
    dispatch(condoFiltersActions.setDistanceInMiles(value));
  },
  setGuestRatings: (value: string[]) => {
    dispatch(condoFiltersActions.setGuestRatings(value));
  },
  setNeighbourhoods: (value: string[]) => {
    dispatch(condoFiltersActions.setNeighbourhoods(value));
  },
  setPlaces: (value: string[]) => {
    dispatch(condoFiltersActions.setPlaces(value));
  },
  setUnitAmenities: (value: string[]) => {
    dispatch(condoFiltersActions.setUnitAmenities(value));
  },
  setFacilities: (value: string[]) => {
    dispatch(condoFiltersActions.setFacilities(value));
  },
  setActivities: (value: string[]) => {
    dispatch(condoFiltersActions.setActivities(value));
  },
  setCondoTypes: (value: string[]) => {
    dispatch(condoFiltersActions.setCondoTypes(value));
  },
  setAccessibilities: (value: string[]) => {
    dispatch(condoFiltersActions.setAccessibilities(value));
  },
  setSuppliers: (value: string[]) => {
    dispatch(condoFiltersActions.setSuppliers(value));
  },
  setBathrooms: (value: number[]) => {
    dispatch(condoFiltersActions.setBathrooms(value));
  },
  setDays: (value: number[]) => {
    dispatch(condoFiltersActions.setDays(value));
  },
  setMonths: (value: number[]) => {
    dispatch(condoFiltersActions.setMonths(value));
  },
  setKitchenTypes: (value: string[]) => {
    dispatch(condoFiltersActions.setKitchenTypes(value));
  },
  setAllInclusive: (value: boolean) => {
    dispatch(condoFiltersActions.setAllInclusive(value));
  },
  setSortType: (value: SortTypes) => {
    dispatch(condoFiltersActions.setSortType(value));
  },
  resetFilters: () => {
    dispatch(condoFiltersActions.resetFilters());
  },
});

export const CondoFiltersWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondoFiltersWrapperComponent);
