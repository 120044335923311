
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { IBenefitsState, ILoginState, IMenuState } from '@share/store/slices';
import { getLocalURI, isExternalUrl, RootState } from '@share/utils';
import { getBenefitInfo } from '@share/utils';
import { BenefitsEnum } from '@share/common-types';
import { SkeletonItems, HomesConciergeServices } from '@components';

import HomesBackground from '@assets/images/bg-homes.png';
import HomesImage from '@assets/images/homes-main.jpg';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
  benefitsStore: IBenefitsState;
}

interface IProps extends IMapStateToProps {}

const ZERO = 0;

function HomesComponent(props: IProps) {
  const { menuStore, benefitsStore, loginStore } = props;
  const { account } = loginStore;

  const benefitInfo = useMemo(() => getBenefitInfo(benefitsStore.benefits, BenefitsEnum.Homes), [benefitsStore.benefits]);
  const benefitInfoItems = get(benefitInfo, `[${ZERO}].childItems`) as any[] | undefined;

  return (
    <div className="homes-wrapper">
      <div
        className="homes-wrapper__header-wrapper"
        style={{ backgroundImage: `url(${HomesBackground})` }}
      >
        <div className="homes-wrapper__wrapper">
          <div className="homes-wrapper__header-title">
            <FormattedMessage id="vacation.homes.title" />
          </div>
        </div>
      </div>

      <div className="homes-wrapper__wrapper">
        <div className="homes-wrapper__levels-homes">
          <p className="homes-wrapper__levels-homes-title">
            <FormattedMessage id="two.levels.of.amazing.homes" />
          </p>
          {benefitsStore.benefits?.items ? (
            <div className="homes-wrapper__levels-homes-wrapper">
              {benefitInfoItems?.map(
                (item: any, index: number) => (
                  isExternalUrl(item.url) ? (
                  <a
                    href={item.url}
                    key={`${index}${item.title}`}
                    className="homes-wrapper__level-homes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="homes-wrapper__level-homes-img-wrapper">
                      <img src={item.imageUrl} alt={item.title} />
                    </div>
                    <div className="homes-wrapper__level-homes-content">
                      <p className="homes-wrapper__level-homes-title">
                        <FormattedMessage id={item.title} />
                      </p>
                      <p className="homes-wrapper__level-homes-description">
                        <FormattedMessage id={item.description} />
                      </p>
                    </div>
                  </a>) : (
                    <Link
                      key={`${index}${item.title}`}
                      to={`/${account?.name}${getLocalURI(item.url)}`}
                      className="homes-wrapper__level-homes"
                      rel="noreferrer"
                    >
                      <div className="homes-wrapper__level-homes-img-wrapper">
                        <img src={item.imageUrl} alt={item.title} />
                      </div>
                      <div className="homes-wrapper__level-homes-content">
                        <p className="homes-wrapper__level-homes-title">
                          <FormattedMessage id={item.title} />
                        </p>
                        <p className="homes-wrapper__level-homes-description">
                          <FormattedMessage id={item.description} />
                        </p>
                      </div>
                    </Link>
                  )
                ),
              )}
            </div>
          ) : (
            <SkeletonItems />
          )}
        </div>
      </div>

      <div className="homes-wrapper__main-image">
        <img src={HomesImage} alt="HomesImage" />
      </div>

      <div className="homes-wrapper__wrapper">
        <div className="homes-wrapper__info-wrapper">
          <div className="homes-wrapper__info">
            <p className="homes-wrapper__title">
              <FormattedMessage id="home.stays" />
            </p>
            <p className="homes-wrapper__title-small">
              <FormattedMessage id="ready.for.something.different" />
            </p>
            <p className="homes-wrapper__description">
              <FormattedMessage id="renting.a.vacation.home" />
            </p>
            <p className="homes-wrapper__description">
              <FormattedMessage id="prices.are.higher" />
            </p>
          </div>
          <div className="homes-wrapper__info">
            <p className="homes-wrapper__title-small">
              <FormattedMessage id="you.like.to.do.your.own.search" />
            </p>
            <p className="homes-wrapper__description">
              <FormattedMessage id="if.you.find.a.home.on.one" />
            </p>
          </div>
        </div>
      </div>

      {menuStore.items ? <HomesConciergeServices phone={menuStore.items?.phone} /> : null}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    benefitsStore: state.benefitsStore,
    loginStore: state.loginStore,
  };
};

export const Homes = connect(mapStateToProps)(HomesComponent);
