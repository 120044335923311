import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import { VACATIONS_PAGE, getAccountUsernameFromPath } from '@share/utils';

import NotAvailable from '@assets/images/availability-status.png';

import './style.scss';

interface IProps extends RouteComponentProps {
  isFromCondo?: boolean;
  isVacationRentals?: boolean;
}

class AvailabilityErrorComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isFromCondo = false, isVacationRentals } = this.props;
    const accountName = getAccountUsernameFromPath(this.props.history);
    const url = isFromCondo
      ? `/${accountName}${Routes.CondoSearch}${location.search}`
      : isVacationRentals ? 
        `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}` :
        `/${accountName}${Routes.Search}${location.search}`;

    return (
      <div className="availability-error">
        <img src={NotAvailable} alt="" />
        <p className="availability-error__title">
          <FormattedMessage id="unit.is.no.available" />
        </p>
        <p className="availability-error__description">
          <FormattedMessage id={isFromCondo ? 'condo.unit.selected.no.longer.available' : 'unit.selected.no.longer.available'} />
        </p>
        
        <div className="availability-error__btn-wrapper">
          <Link to={url}>
            <BlueButton>
              <FormattedMessage id={isFromCondo ? 'search.condos' : 'search.hotel'} />
            </BlueButton>
          </Link>
        </div>
      </div>
    );
  }
}

export const AvailabilityError = connect()(withRouter(AvailabilityErrorComponent));
