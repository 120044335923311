import React, { useMemo } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Footer } from '@components';
import { isCondoWeeksFromPath, isCondoWorldFromPath, isThreeDSFromPath } from '@share/utils';
import { isAdminFromPath, isErrorFromPath, isWidgetFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {}

function ApplicationFooterComponent(props: IProps) {
  const { history } = props;

  const isAdminPage = useMemo(() => isAdminFromPath(history), [history]);
  const isThreeDS = useMemo(() => isThreeDSFromPath(history), [history]);
  const isErrorPage = useMemo(() => isErrorFromPath(history), [history]);
  const isWidgetPage = useMemo(() => isWidgetFromPath(history), [history]);
  const isCondoWorldMap = useMemo(() => isCondoWorldFromPath(history), [history]);
  const isCondoWeeks = useMemo(() => isCondoWeeksFromPath(history), [history]);

  if (!isAdminPage && !isErrorPage && !isWidgetPage && !isCondoWorldMap && !isThreeDS && !isCondoWeeks) {
    return <Footer />;
  }

  return null;
}

const ApplicationComponentWithRouter = withRouter(ApplicationFooterComponent);

export const ApplicationFooter = connect()(ApplicationComponentWithRouter);
