import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { CruiseLineHotLinksLogos, TreatYourselfLogos } from '@common-types';
import { CruiseLineHotLinks, TreatYourself, MasterClasses, CruisesProgramList, Membership, CruisesProgramListComponent } from '@components';
import { IBenefitsState, ILoginState } from '@share/store/slices';
import { IsImpersonating, RootState } from '@share/utils';
import { BenefitsEnum } from '@share/common-types';

import CruisesBackground from '@assets/images/cruises/bg-cruises.jpg';

import './style.scss';


interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IProps extends IMapStateToProps {}

export class CruisesWrapperComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { loginStore } = this.props;
    const { account, user } = loginStore;
    
    const isCapital = account?.isCapitalVacations;
    const isCapitalAgent = account?.isCapitalVacationsAgent;
    
    const logos = isCapital ? [...CruiseLineHotLinksLogos.filter(l => !l.excludeCapital)] : [...CruiseLineHotLinksLogos];

    return (
      <div className="cruises-wrapper">
        <div
          className="cruises-wrapper__header"
          style={{ backgroundImage: `url(${CruisesBackground})` }}
        >
          <div className="cruises-wrapper__wrapper">
            <div className="cruises-wrapper__container">
              <h1 className="cruises-wrapper__header_title">
                <FormattedMessage id="members.love.cruising" />
              </h1>
              <p className="cruises-wrapper__header_subtitle">
                <FormattedMessage id="cruising.is.the.worlds.favorite" />
              </p>
            </div>
          </div>
        </div>
        
        <div className="cruises-wrapper__wrapper">
          {!isCapital ? (
            <CruisesProgramList />) : (
            <CruisesProgramListComponent
              forceMessages
              desc3Style={{ marginTop: '20px' }}
              loginStore={loginStore}
              benefitsStore={{ 
                benefits: {
                  items: [
                    {
                      type: BenefitsEnum.Cruises,
                      childItems: [
                        {
                          description: 'our.promise',
                          description2: 'to.assist.you',
                          description3: 'service.hours',
                          description4: 'service.hours.2',
                          description5: 'service.hours.3',
                          buttonMessage: isCapitalAgent ? <FormattedMessage id="check.out.sailings" /> : 'Call: 844-777-2582',
                          title: 'Luxury Cruises',
                          titleForce: 'We promise to exceed your expectations at every turn with each cruise',
                          type: 'cruises',
                          errorMessage: (isCapitalAgent && !IsImpersonating()) ? 'please.impersonate' : null,
                          url: (isCapitalAgent && IsImpersonating()) ? `https://membergetaways.com/odtest1.php?id=${user?.keyid}&sso=https://cruises.membergetaways.com/swift/cruise?siid=1116066` : null
                        }
                      ]
                    }
                  ]
                }
              } as IBenefitsState}
            />)}
        </div>

        {!isCapital ? (
          <>
            <div className="cruises-wrapper__background">
              <div className="cruises-wrapper__wrapper">
                <p className="cruises-wrapper__title">
                  <FormattedMessage id="we.promise.to.exceed.your.expectations" />
                </p>
                <p className="cruises-wrapper__subtitle">
                  <FormattedMessage id="our.team.of.based.travel.professionals" />
                </p>
              </div>
            </div>
        
            <TreatYourself logos={TreatYourselfLogos} isCruise />
        
            <MasterClasses />
          </>) : null}
        
        <CruiseLineHotLinks logos={logos} />
        
        <Membership />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
  };
};

export const CruisesWrapper = connect(mapStateToProps)(CruisesWrapperComponent);
