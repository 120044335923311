import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import parse from 'html-react-parser';

import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

import { ICondoBookingSummary, RefundableEnum } from '@common-types';
import { InfoSvg } from '@assets';

import './style.scss';

interface IProps {
  condoBookingSummary: ICondoBookingSummary;

  displayCheckbox: boolean;
  disable: boolean;
  
  onChangeAcceptPolicy: (e: { target: CheckboxChangeEventTarget }) => void;
}

const ZERO = 0;

export class CondoCancellationPolicies extends React.Component<IProps> {
  isWeeksResortCheckInDays = (): boolean => {
    const { daysOfCheckIn } = this.props.condoBookingSummary.bookingPolicies;

    return (
      !isUndefined(daysOfCheckIn) &&
      !isUndefined(daysOfCheckIn.weeksResortCheckInDays) &&
      !isEmpty(daysOfCheckIn.weeksResortCheckInDays)
    );
  };

  isDefaultTextWeeksResortCheckInDays = (): boolean => {
    const { daysOfCheckIn } = this.props.condoBookingSummary.bookingPolicies;

    return (
      !isUndefined(daysOfCheckIn) &&
      !isUndefined(daysOfCheckIn.pointsResortCheckInDays) &&
      !isEmpty(daysOfCheckIn.pointsResortCheckInDays) &&
      (isUndefined(daysOfCheckIn.weeksResortCheckInDays) ||
        isEmpty(daysOfCheckIn.weeksResortCheckInDays))
    );
  };

  isPointsResortCheckInDays = (): boolean => {
    const { daysOfCheckIn } = this.props.condoBookingSummary.bookingPolicies;

    return (
      !isUndefined(daysOfCheckIn) &&
      !isUndefined(daysOfCheckIn.pointsResortCheckInDays) &&
      !isEmpty(daysOfCheckIn.pointsResortCheckInDays)
    );
  };

  isCheckInTime = (): boolean => {
    const { checkInCheckOutInfo } = this.props.condoBookingSummary.bookingPolicies;

    return !isUndefined(checkInCheckOutInfo) && !isUndefined(checkInCheckOutInfo.checkInTime);
  };

  isCheckOutTime = (): boolean => {
    const { checkInCheckOutInfo } = this.props.condoBookingSummary.bookingPolicies;

    return !isUndefined(checkInCheckOutInfo) && !isUndefined(checkInCheckOutInfo.checkOutTime);
  };

  isPossibleApplicableFees = (): boolean => {
    const { bookingBeforeYouGo } = this.props.condoBookingSummary;

    return (
      !isEmpty(bookingBeforeYouGo.mandatoryFees) ||
      !isEmpty(bookingBeforeYouGo.fees) ||
      !isEmpty(bookingBeforeYouGo.housekeepingFees)
    );
  };

  isKnowBeforeYouGo = (): boolean => {
    const { bookingBeforeYouGo } = this.props.condoBookingSummary;

    return (
      !isEmpty(bookingBeforeYouGo.urgentInfo) || !isEmpty(bookingBeforeYouGo.constructionDetails)
    );
  };

  getExpressCheckInCheckOut = (): boolean => {
    const { checkInCheckOutInfo } = this.props.condoBookingSummary.bookingPolicies;

    return (
      !isUndefined(checkInCheckOutInfo.expressCheckInCheckOut) &&
      !isEmpty(checkInCheckOutInfo.expressCheckInCheckOut) &&
      checkInCheckOutInfo.expressCheckInCheckOut
    );
  };

  render(): React.ReactNode {
    const { condoBookingSummary, disable, displayCheckbox, onChangeAcceptPolicy } = this.props;
    const { bookingPolicies, bookingBeforeYouGo, refundability } = condoBookingSummary;

    const { checkInCheckOutInfo } = bookingPolicies;

    const isRefundable = refundability === RefundableEnum.Refundable;

    return (
      <div className="condo-cancellation-policies">
        <h4 className="condo-cancellation-policies__title">
          <FormattedMessage id="important.information" />
        </h4>
        {this.isCheckInTime() ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="check.in" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <span className="condo-cancellation-policies__info-text bold">
                {checkInCheckOutInfo.checkInTime}
              </span>
              {!isUndefined(checkInCheckOutInfo.arrivalCheckInSpecialInstructions) ||
                (!isEmpty(checkInCheckOutInfo.arrivalCheckInSpecialInstructions) && (
                  <span className="condo-cancellation-policies__info-text">
                    {checkInCheckOutInfo.arrivalCheckInSpecialInstructions}
                  </span>
                ))}
              {this.getExpressCheckInCheckOut() && (
                <span className="condo-cancellation-policies__info-text">
                  <FormattedMessage id="express.check.in.check.out" />
                </span>
              )}
            </div>
          </div>
        ) : null}
        {this.isCheckOutTime() ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="check.out" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <span className="condo-cancellation-policies__info-text bold">
                {checkInCheckOutInfo.checkOutTime}
              </span>
              {!isUndefined(checkInCheckOutInfo.afterHoursInstructions) ||
                (!isEmpty(checkInCheckOutInfo.afterHoursInstructions) && (
                  <span className="condo-cancellation-policies__info-text">
                    {checkInCheckOutInfo.afterHoursInstructions}
                  </span>
                ))}
              {!isUndefined(checkInCheckOutInfo.description) ||
                (!isEmpty(checkInCheckOutInfo.description) && (
                  <span className="condo-cancellation-policies__info-text">
                    {checkInCheckOutInfo.description}
                  </span>
                ))}
            </div>
          </div>
        ) : null}
        {!isUndefined(bookingPolicies.ageRestriction) &&
        !isEmpty(bookingPolicies.ageRestriction) ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="age.restriction" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <span className="condo-cancellation-policies__info-text">
                {bookingPolicies.ageRestriction}
              </span>
            </div>
          </div>
        ) : null}
        {this.isWeeksResortCheckInDays() ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="weekly.stays" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <div className="condo-cancellation-policies__info-text-wrapper">
                {bookingPolicies.daysOfCheckIn.weeksResortCheckInDays.map((item, index) => (
                  <span key={index} className="condo-cancellation-policies__info-text bold">
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {this.isDefaultTextWeeksResortCheckInDays() ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="weekly.stays" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <div className="condo-cancellation-policies__info-text-wrapper">
                <FormattedMessage id="not.returned.days.of.check.in.for.weekly" />
              </div>
            </div>
          </div>
        ) : null}
        {this.isPointsResortCheckInDays() ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="short.stays" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <div className="condo-cancellation-policies__info-text-wrapper">
                {bookingPolicies.daysOfCheckIn.pointsResortCheckInDays.map((item, index) => (
                  <span key={index} className="condo-cancellation-policies__info-text bold">
                    {item}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : null}
        {bookingPolicies.minimumNights > ZERO && (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="minimum.night.stay" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <span className="condo-cancellation-policies__info-text bold">
                {bookingPolicies.minimumNights}
              </span>
            </div>
          </div>
        )}
        {!isUndefined(bookingPolicies.frontDeskInfo) && !isEmpty(bookingPolicies.frontDeskInfo) ? (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="front.desk.info" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <span className="condo-cancellation-policies__info-text bold">
                {bookingPolicies.frontDeskInfo.hoursOfOperationStartTime} -{' '}
                {bookingPolicies.frontDeskInfo.hoursOfOperationEndTime}
              </span>
              {bookingPolicies.frontDeskInfo.resortFrontDeskInfoIs24Hours && (
                <span className="condo-cancellation-policies__info-text bold">
                  <FormattedMessage id="24.hours" />
                </span>
              )}
              {bookingPolicies.frontDeskInfo.resortFrontdeskInfoIsOnsite && (
                <span className="condo-cancellation-policies__info-text bold">
                  <FormattedMessage id="onsite" />
                </span>
              )}
            </div>
          </div>
        ) : null}
        {!isEmpty(bookingPolicies.pets) && (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="pets" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              <span className="condo-cancellation-policies__info-text">{bookingPolicies.pets}</span>
            </div>
          </div>
        )}
        {!isEmpty(bookingPolicies.parking) && (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="parking" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              {bookingPolicies.parking.map((item, index) => (
                <span key={index} className="condo-cancellation-policies__info-text">
                  {item}
                </span>
              ))}
            </div>
          </div>
        )}
        {!isEmpty(bookingPolicies.smoking) && (
          <div className="condo-cancellation-policies__info">
            <span className="condo-cancellation-policies__point">
              <FormattedMessage id="smoking" />:
            </span>
            <div className="condo-cancellation-policies__info-text-wrapper">
              {bookingPolicies.smoking.map((item, index) => (
                <span key={index} className="condo-cancellation-policies__info-text">
                  {item}
                </span>
              ))}
            </div>
          </div>
        )}
        {this.isKnowBeforeYouGo() || this.isPossibleApplicableFees() ? (
          <div className="condo-cancellation-policies__know-wrapper">
            {!isEmpty(bookingBeforeYouGo.urgentInfo) && (
              <div className="condo-cancellation-policies__know-block">
                <h4 className="condo-cancellation-policies__know-title">
                  <InfoSvg />
                  <FormattedMessage id="know.before.you.go" />
                </h4>
                {!isEmpty(bookingBeforeYouGo.urgentInfo.generalUrgentInfoMessages) && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    {bookingBeforeYouGo.urgentInfo.generalUrgentInfoMessages.map((item, index) => (
                      <span key={index} className="condo-cancellation-policies__know--text">
                        {parse(item)}
                      </span>
                    ))}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo.urgentInfo.generalUrgentInfoForNonMembersMessages) && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    {bookingBeforeYouGo.urgentInfo.generalUrgentInfoForNonMembersMessages.map(
                      (item, index) => (
                        <span key={index} className="condo-cancellation-policies__know--text">
                          {parse(item)}
                        </span>
                      ),
                    )}
                  </div>
                )}
                {bookingBeforeYouGo.urgentInfo.occupancyRestrictions && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      {parse(bookingBeforeYouGo.urgentInfo.occupancyRestrictions)}
                    </span>
                  </div>
                )}
                {bookingBeforeYouGo.urgentInfo.oneInXRuleApplies && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      {parse(bookingBeforeYouGo.urgentInfo.oneInXRuleApplies)}
                    </span>
                  </div>
                )}
                {bookingBeforeYouGo.urgentInfo.separateSwimmingPoolForGenders && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      <FormattedMessage id="separate.swimming.pool.for.genders" />
                    </span>
                  </div>
                )}
                {bookingBeforeYouGo.urgentInfo.alcoholRestricted && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      <FormattedMessage id="alcohol.restricted" />
                    </span>
                  </div>
                )}
                {bookingBeforeYouGo.urgentInfo.alcoholServedOnsite && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      <FormattedMessage id="alcohol.served.onsite" />
                    </span>
                  </div>
                )}
                {bookingBeforeYouGo.urgentInfo.resortStayRestrictionsInfo && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      {parse(bookingBeforeYouGo.urgentInfo.resortStayRestrictionsInfo)}
                    </span>
                  </div>
                )}
              </div>
            )}
            {this.isPossibleApplicableFees() && (
              <div className="condo-cancellation-policies__know-block">
                <h4 className="condo-cancellation-policies__know-title">
                  <FormattedMessage id="possible.applicable.fees" />
                </h4>
                {!isEmpty(bookingBeforeYouGo.mandatoryFees) && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    {bookingBeforeYouGo.mandatoryFees.map((item, index) => (
                      <div key={index} className="condo-cancellation-policies__know-text-block">
                        {item.infoMessage && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.infoMessage)}
                          </span>
                        )}
                        {item.additionalInfoMessage && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.additionalInfoMessage)}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo.fees) && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    {bookingBeforeYouGo.fees.map((item, index) => (
                      <div key={index} className="condo-cancellation-policies__know-text-block">
                        {item.infoMessage && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.infoMessage)}
                          </span>
                        )}
                        {item.additionalInfoMessage && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.additionalInfoMessage)}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {!isEmpty(bookingBeforeYouGo.housekeepingFees) && (
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    {bookingBeforeYouGo.housekeepingFees.map((item, index) => (
                      <div key={index} className="condo-cancellation-policies__know-text-block">
                        {item.feeSchedule && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.feeSchedule)}
                          </span>
                        )}
                        {item.infoMessage && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.infoMessage)}
                          </span>
                        )}
                        {item.additionalInfoMessage && (
                          <span className="condo-cancellation-policies__know--text">
                            {parse(item.additionalInfoMessage)}
                          </span>
                        )}
                        {item.mandatoryFee && (
                          <span className="condo-cancellation-policies__know--text">
                            <FormattedMessage id="mandatory.fee" />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {!isEmpty(bookingBeforeYouGo.constructionDetails) && (
              <div className="condo-cancellation-policies__know-block">
                <h4 className="condo-cancellation-policies__know-title">
                  <FormattedMessage id="resort.construction.restrictions" />
                </h4>
                <div className="condo-cancellation-policies__know-text-wrapper">
                  {bookingBeforeYouGo.constructionDetails.map((item, index) => (
                    <div key={index}>
                      {item.startDate && item.endDate && (
                        <span className="condo-cancellation-policies__know--text">
                          {item.startDate} - {item.endDate}
                        </span>
                      )}
                      {item.infoMessage && (
                        <span className="condo-cancellation-policies__know--text">
                          {item.infoMessage}
                        </span>
                      )}
                      {item.additionalInfoMessage && (
                        <span className="condo-cancellation-policies__know--text">
                          {item.additionalInfoMessage}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!isEmpty(bookingBeforeYouGo.amenityClosures) && (
              <div className="condo-cancellation-policies__know-block">
                <h4 className="condo-cancellation-policies__know-title">
                  <FormattedMessage id="resort.construction.restrictions" />
                </h4>
                <div className="condo-cancellation-policies__know-text-wrapper">
                  {bookingBeforeYouGo.amenityClosures.map((item, index) => (
                    <div key={index}>
                      {item.startDate && item.endDate && (
                        <span className="condo-cancellation-policies__know--text">
                          {item.startDate} - {item.endDate}
                        </span>
                      )}
                      {item.infoMessage && (
                        <span className="condo-cancellation-policies__know--text">
                          {item.infoMessage}
                        </span>
                      )}
                      {item.additionalInfoMessage && (
                        <span className="condo-cancellation-policies__know--text">
                          {item.additionalInfoMessage}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!isUndefined(bookingBeforeYouGo.specialNotesPrecautions) &&
              !isEmpty(bookingBeforeYouGo.specialNotesPrecautions) && (
                <div className="condo-cancellation-policies__know-block">
                  <h4 className="condo-cancellation-policies__know-title">
                    <FormattedMessage id="special.notes.precautions" />
                  </h4>
                  <div className="condo-cancellation-policies__know-text-wrapper">
                    <span className="condo-cancellation-policies__know--text">
                      {bookingBeforeYouGo.specialNotesPrecautions}
                    </span>
                  </div>
                </div>
              )}
          </div>
        ) : null}

        {!isEmpty(condoBookingSummary.cancellationPolicyText) ? (
          <div className="condo-cancellation-policies__policies-text-wrapper">
            <div className="condo-cancellation-policies__policies-header">
              <h4 className="condo-cancellation-policies__title-text">
                <FormattedMessage id="cancellation.policy" />
              </h4>
              <div className="unit-main-info__refundable" style={{ display: 'inline-block' }}>
                <div className="unit-main-info__refundable" style={{ display: 'inline-block' }}>
                  <div className={isRefundable ? 'unit-main-info__refundable-ref' : 'unit-main-info__refundable-nonref'} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                      <FormattedMessage id={isRefundable ? 'refundable' : 'non.refundable'} />
                    </span>
                    <InfoSvg />
                  </div>
                </div>);
              </div>
            </div>

            <span className="condo-cancellation-policies__policies-text" style={{ marginTop: '10px' }}>
              {condoBookingSummary.cancellationPolicyText}
            </span>

            <span className="condo-cancellation-policies__policies-text">
              <FormattedMessage id="no-refundble.acknowledge" />
            </span>

            {displayCheckbox ? (
              <div className="review-book__wrapper" style={{ flexDirection: 'row' }}>
                <Checkbox onChange={onChangeAcceptPolicy} disabled={disable} />
                <span className="review-book__accept-text">
                  <FormattedMessage id="by.checking.this.box.you.acknowledge.cancellation.policy" />
                </span>
              </div>) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
