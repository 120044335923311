import React, { useState } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';

import { BigMap } from '@components';
import { ICarDetails, ICarLocation, IGeoLocation } from '@share/common-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCity, faClock, faLocation, faPlane, faPlaneDeparture, faVanShuttle } from '@fortawesome/free-solid-svg-icons';
import { AIRPORT, AIRPORT_SHUTTLE } from '@constants';

import EmptyMarker from '@assets/images/map-empty-marker.svg';

import './style.scss';

const INCOMING_DATE_FORMAT = 'yyyy-MM-DDTHH:mm:ss';
const MAX_HOURS = 2;
const DATE_FORMAT_UNIT_ROW_SHORT = 'D MMM HH:mm, yyyy';
const DATE_FORMAT_UNIT_ROW_NO_YEAR_SHORT = 'D MMM HH:mm';

interface IProps {
  car: ICarDetails;

  isCheckout?: boolean;
}

function CarsLocationsComponent(props: IProps) {
  const [position, setPosition] = useState(null);
  const [isMapModalOpenPickUp, setIsMapModalOpenPickUp] = useState(false);
  const [isMapModalOpenDropOff, setIsMapModalOpenDropOff] = useState(false);
  const [isHoursFiltered, setIsHoursFiltered] = useState(true);

  const { car, isCheckout } = props;

  const vendor = car?.vendor;
  const pickUpLocationDetail = car?.pickUpLocationDetail;
  const dropOffLocationDetail = car?.dropOffLocationDetail;
  const isSameDropOff = pickUpLocationDetail?.locationCode === dropOffLocationDetail?.locationCode;

  const showMap = (location: IGeoLocation, setIsMapModalOpen: (value: boolean) => void) => {
    setPosition(location);
    setIsMapModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeMap = (setIsMapModalOpen: (value: boolean) => void) => {
    setPosition(null);
    setIsMapModalOpen(false);
    document.body.style.overflow = 'initial';
  };

  const renderFirstRow = (location: ICarLocation, isMultipleLine: boolean, className: string, setIsMapModalOpen: (value: boolean) => void) => {
    const pickUpDateMoment = moment(pickUpLocationDetail?.dateTime, INCOMING_DATE_FORMAT);
    const dropOffDateMoment = moment(dropOffLocationDetail?.dateTime, INCOMING_DATE_FORMAT);
    const locationDate = moment(location?.dateTime, INCOMING_DATE_FORMAT).format(DATE_FORMAT_UNIT_ROW_SHORT);

    const isSameYear = pickUpDateMoment?.year === dropOffDateMoment?.year;
    const pickUpDate = pickUpDateMoment.format(isSameYear ? DATE_FORMAT_UNIT_ROW_NO_YEAR_SHORT : DATE_FORMAT_UNIT_ROW_SHORT);
    const dropOffDate = dropOffDateMoment.format(DATE_FORMAT_UNIT_ROW_SHORT);

    const whereType = location?.whereType;

    const isShuttle = whereType === AIRPORT_SHUTTLE;
    const pickUpName = location?.name;
    const pickUpContext = location?.assocAirportLocList?.length ? get(location?.assocAirportLocList, '[0]', location?.codeContext) : location?.codeContext;

    return (
      <>
        <div className={className}>
          <FontAwesomeIcon icon={faCalendar} />
          {isMultipleLine ? (<span>{pickUpDate} - {dropOffDate}</span>) : (<span>{locationDate}</span>)}
        </div>

        <div className={className}>
          <FontAwesomeIcon icon={['ShuttleOffAirport', 'Airport'].includes(whereType) ? faPlaneDeparture : faCity} />
          <span>
            {`${pickUpName}${(isShuttle && pickUpContext?.length === 3 && !pickUpName.includes(pickUpContext)) ? ` (${pickUpContext})` : ''}`}
            {!isCheckout ? (
              <>
                <br />
                <label style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1890ff', paddingRight: '20px' }} onClick={() => showMap(location?.position, setIsMapModalOpen)}>
                  <FontAwesomeIcon style={{ marginRight: '2px' }} icon={faLocation} /> {get(location?.address, '[0]')?.streetNmbr}
                </label>
              </>) : null}
          </span>
        </div>      

      </>
    );
  }

  const renderSecondRow = (location: ICarLocation, className: string) => {
    const whereType = location?.whereType;

    const hours = location?.additionalInfo?.operationSchedules;

    const hoursFiltered = (hours?.length > MAX_HOURS && isHoursFiltered) ? hours.slice(0, 2) : hours;

    return (
      <>
        <div className={className}>
          <FontAwesomeIcon icon={whereType === AIRPORT_SHUTTLE ? faVanShuttle : whereType === AIRPORT ? faPlane : faCity} /><span></span>
          <FormattedMessage id={whereType === AIRPORT_SHUTTLE ? 'cars.location.shuttle' : whereType === AIRPORT ? 'cars.location.airport' : 'cars.location.city'} />
        </div>

        {(hours?.length && !isCheckout) ? (
          <div className={className}>
            <FontAwesomeIcon icon={faClock} />
            <span>
              <FormattedMessage id="cars.hours" /><br />
              {hoursFiltered.map((h: any, i: number) => (
                <React.Fragment key={i}><label>{h?.day}{!isEmpty(h?.day) ? ' ' : ''}{h?.start} - {h?.end}</label><br /></React.Fragment>))}
              {(hours?.length > MAX_HOURS) ? (
                <div style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1890ff' }} onClick={handleShowMoreHours}>
                  <FormattedMessage id={isHoursFiltered ? 'show.more' : 'show.less'} />
                </div>) : null}
            </span>
          </div>) : null}
      </>
    );
  }

  const handleShowMoreHours = () => {
    setIsHoursFiltered(!isHoursFiltered);
  }

  const renderLocation = (location: ICarLocation, title: string, isMultipleLine: boolean, isMapModalOpen: boolean, setIsMapModalOpen: (value: boolean) => void) => {
    const className = `car-location__message ${(isMultipleLine && !isCheckout) ? 'col-md-6' : 'col-md-12'}`;

    if (isMultipleLine) {
      return (
        <>
          <div className="col-md-12 car-location__title">
            <FormattedMessage id={title} />
          </div>

          <div className={className} style={{ display: 'flex', flexDirection: 'column' }}>
            {renderFirstRow(location, isMultipleLine, 'car-location__message', setIsMapModalOpen)}
          </div>

          <div className={className} style={{ display: 'flex', flexDirection: 'column' }}>
            {renderSecondRow(location, 'car-location__message')}
          </div>
  
          <BigMap title={vendor?.value} marker={EmptyMarker} isMapModalOpen={isMapModalOpen} location={position} onCloseMap={() => closeMap(setIsMapModalOpen)} />
        </>
      );  
    }

    return (
      <>
        <div className="col-md-12 car-location__title">
          <FormattedMessage id={title} />
        </div>

        {renderFirstRow(location, isMultipleLine, className, setIsMapModalOpen)}

        {renderSecondRow(location, className)}

        <BigMap title={vendor?.value} marker={EmptyMarker} isMapModalOpen={isMapModalOpen} location={position} onCloseMap={() => closeMap(setIsMapModalOpen)} />
      </>
    );
  }
    
  return (
    <div className="hotel-info cars-locations">
      <div className={`hotel-info__item ${isCheckout ? '' : 'border-top'}`}>
        <div className="hotel-info__item-content">
          {!isCheckout ? (
            <h4 className="hotel-info__title">
              <FormattedMessage id="cars.location" />
            </h4>) : null}

          <div className="cars-details-location container-fluid">
            <div className="row">
              {isSameDropOff ? 
                renderLocation(pickUpLocationDetail, 'cars.price.pick_up.drop_off', true, isMapModalOpenPickUp, setIsMapModalOpenPickUp) : (
                <>
                  <div className="col-md-6">
                    {renderLocation(pickUpLocationDetail, 'cars.pickup', false, isMapModalOpenPickUp, setIsMapModalOpenPickUp)}
                  </div>
                  <div className="col-md-6">
                    {renderLocation(dropOffLocationDetail, 'cars.dropoff', false, isMapModalOpenDropOff, setIsMapModalOpenDropOff)}
                  </div>
                </>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const CarsLocations = connect(null)(CarsLocationsComponent);

