import React, { useState, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { marginatorActions, IMarginatorState, ILoginState, IHotelsState } from '@share/store/slices';
import { IReviewBookState } from '@store/slices';
import { isDetailFromPath, isResultsFromPath, RootState } from '@share/utils';
import { Checkbox, Slider, Tooltip } from 'antd';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import ReactGA from 'react-ga4';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TA_D_CLICK_APPLY, TA_D_CLICK_CANCEL, TA_D_CLICK_GEAR, TA_D_CLICK_SHOW_COMM, TA_D_CLICK_SHOW_SUPPLIER, TA_D_CLICK_SLIDER, TA_R_CLICK_APPLY, TA_R_CLICK_CANCEL, TA_R_CLICK_GEAR, TA_R_CLICK_SHOW_COMM, TA_R_CLICK_SHOW_SUPPLIER, TA_R_CLICK_SLIDER } from '@share/constants';

interface IMapStateToProps {
  marginatorStore: IMarginatorState;
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
  reviewBookStore: IReviewBookState;
}

interface IMapDispatchToProps {
  setValue: (value: number) => void;
  setPercentage: (value: number) => void;
  showCommission: (value: boolean) => void;
  showSupplier: (value: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
  onOpen?: () => void;
}

function MarginatorComponent(props: IProps) {
  const [show, setShow] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const [showSupplierCheck, setShowSupplierCheck] = useState(false);
  const [showSupplier, setShowSupplier] = useState(false);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(30);
  const [defaultValue, setDefaultValue] = useState(5);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const { user, account } = props.loginStore;
    const { isSearch, hotels } = props.hotelsStore;
    const isAfterSearchLoad = isSearch || (hotels && !!hotels.length);

    const showMarginator = !!(isAfterSearchLoad && user && account.isTravelAgentPro);
    setShow(showMarginator);

    if (showMarginator) {
      const pref: any = account.preferences.find(p => p.key === 'marginator');
      if (pref) {
        const { min, max, defaultValue, showSupplier } = JSON.parse(pref.value);
        setMin(min);
        setMax(max);
        setShowSupplier(showSupplier);
        const newValue = props.marginatorStore.value !== 0 ? props.marginatorStore.value : defaultValue;
        setDefaultValue(newValue);
        props.setValue(newValue);
        props.setPercentage(newValue);
      }
    }
  }, [props.hotelsStore.isSearch])

  useEffect(() => {
    const { user, account } = props.loginStore;
    if (props.reviewBookStore.bookingSummary) {
      setShow(false);
    }

    const { isSearch, hotels } = props.hotelsStore;
    if (user && account.isTravelAgentPro && !props.reviewBookStore.bookingSummary && (isSearch || (hotels && !!hotels.length))) {
      setShow(true);
    }
  }, [props.reviewBookStore.bookingSummary])

  const handleChange = (value: number) => {
    setHasChanged(true);
    props.setValue(value);
  }

  const useMarginator = (apply: boolean) => {
    if (apply) {
      props.setPercentage(Number(props.marginatorStore.value));
    }
    else {
      if (hasChanged) {
        const value = Math.round(props.marginatorStore.percentage * 100);
        props.setPercentage(value);
        props.setValue(value);
        setDefaultValue(value);
      }
    }
    setHasChanged(false);
    setShowDrop(false);
  }

  const handleSendEvent = (resutlCode: string, detailsCode: string, label: string) => {
    const { loginStore, history } = props;
    const { account } = loginStore;

    const isResultPage = isResultsFromPath(history);
    const isDetailsPage = isDetailFromPath(history);
    const action = isResultPage ? resutlCode : isDetailsPage ? detailsCode : null;
    if (action) {
      ReactGA.event({
        category: account.name,
        action: `${action}_${account.name.toUpperCase()}`,
        label,
        nonInteraction: false,
      });
    }
  }

  const handleShowDrop = (isOpen: boolean) => {
    handleSendEvent(TA_R_CLICK_GEAR, TA_D_CLICK_GEAR, `Travel agent clicked gear`);

    setShowDrop(isOpen);

    if (props.onOpen) {
      props.onOpen();
    }
  }

  const handleShowCommission = () => {
    handleSendEvent(TA_R_CLICK_SHOW_COMM, TA_D_CLICK_SHOW_COMM, `Travel agent clicked show commission`);

    setShowCommission(!showCommission);
    props.showCommission(!showCommission);
  }

  const handleShowSupplier = () => {
    handleSendEvent(TA_R_CLICK_SHOW_SUPPLIER, TA_D_CLICK_SHOW_SUPPLIER, `Travel agent clicked show supplier`);

    setShowSupplierCheck(!showSupplierCheck);
    props.showSupplier(!showSupplierCheck);
  }

  const handleCancel = () => {
    handleSendEvent(TA_R_CLICK_CANCEL, TA_D_CLICK_CANCEL, `Travel agent clicked cancel button`);

    useMarginator(false);
  }

  const handleApply = () => {
    handleSendEvent(TA_R_CLICK_APPLY, TA_D_CLICK_APPLY, `Travel agent clicked cancel button`);

    useMarginator(true);
  }

  const handleAfterChange = () => {
    handleSendEvent(TA_R_CLICK_SLIDER, TA_D_CLICK_SLIDER, `Travel agent clicked the slider`);
  }

  return (
    <>
      {
        show &&
        <>
          <Dropdown align={'start'} drop={'down'} show={showDrop} onToggle={(isOpen: boolean) => handleShowDrop(isOpen)} autoClose={false}>
            <Dropdown.Toggle className="btn-link btn-lg">
              <FontAwesomeIcon icon={faGear} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className='box'>
                <div className="row mt-2 mb-3">
                  <div className="col">
                    <p className="text-title"><FormattedMessage id="adjust.earnings" /></p>
                  </div>
                  <div className="col">
                    <Tooltip title={<small><FormattedMessage id="remember.adjust.earnings" /></small>} placement="topLeft">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </div>
                </div>
                <div className="mb-3">
                  <Checkbox checked={props.marginatorStore.showCommission} onChange={handleShowCommission}>
                    <span className="text-title">{showCommission ? <FormattedMessage id="hide.commission" /> : <FormattedMessage id="show.commission" />}</span>
                  </Checkbox>
                  {showSupplier &&
                    <Checkbox checked={props.marginatorStore.showSupplier} onChange={handleShowSupplier}>
                      <span className="text-title">{showSupplierCheck ? <FormattedMessage id="hide.provider" /> : <FormattedMessage id="show.provider" />}</span>
                    </Checkbox>
                  }
                </div>
                <div className="mt-3 mb-3">
                  <Slider
                    min={min}
                    max={max}
                    defaultValue={defaultValue}
                    tooltip={{ open: false }}
                    value={props.marginatorStore.value}
                    onAfterChange={handleAfterChange}
                    onChange={handleChange} />

                  <div className="row percentage">
                    <div className="col text-left">%{min}</div>
                    <div className="col text-center">%{props.marginatorStore.value}</div>
                    <div className="col text-end">%{max}</div>
                  </div>
                </div>
                <div className="row buttons">
                  <div className="col">
                    <button type="button" className="btn btn-block btn-sm btn-link w-100" onClick={() => handleCancel()}><FormattedMessage id={'cancel.button'} /></button>
                  </div>
                  <div className="col">
                    <button type="button" className="btn btn-block btn-sm btn-primary w-100" onClick={() => handleApply()}><FormattedMessage id={'apply'} /></button>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </>
      }
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    marginatorStore: state.marginatorStore,
    loginStore: state.loginStore,
    hotelsStore: state.hotelsStore,
    reviewBookStore: state.reviewBookStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setValue: (value: number) => {
    dispatch(marginatorActions.setValue(value));
  },
  setPercentage: (value: number) => {
    dispatch(marginatorActions.setPercentage(value));
  },
  showCommission: (value: boolean) => {
    dispatch(marginatorActions.showCommission(value));
  },
  showSupplier: (value: boolean) => {
    dispatch(marginatorActions.showSupplier(value));
  }
});

export const Marginator = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(MarginatorComponent)));
