import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { IReviewBookState } from '@store/slices';
import { RoomInfo } from '../room-info';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  reviewBookStore: IReviewBookState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
  isCheckMainGuest: boolean;
}

class GuestInfoComponent extends React.Component<IProps> {
  mainGuestData = (item: string): string => {
    const guest = this.props.reviewBookStore.guests.find((item) => item.isMainGuest === true);

    return guest ? (guest[item] as string) : '';
  };

  onFocusPhone = (): void => {
    this.props.formRef.current.setFieldsValue({
      mainGuestPhone: this.mainGuestData('phone'),
    });
  };

  render(): React.ReactNode {
    const { bookingSummary } = this.props.reviewBookStore;

    return (
      <div className="guest-info">
        <div className="guest-info__header">
          <h4 className="guest-info__title">
            <FormattedMessage id="guest.info" />
          </h4>
          <p className="guest-info__description">
            <FormattedMessage id="guest.checking" />
          </p>
        </div>
        <Form name="guest-info-rule" ref={this.props.formRef}>
          {bookingSummary.bookingCard.package.rooms.map((room, index) => (
            <RoomInfo
              key={index}
              room={room}
              indexRoom={index}
              isCheckMainGuest={this.props.isCheckMainGuest}
              onFocusPhone={this.onFocusPhone}
            />
          ))}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reviewBookStore: state.reviewBookStore,
  };
};

export const GuestInfo = connect(mapStateToProps)(injectIntl(GuestInfoComponent));
