import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Responsive } from '@share/utils';
import { IAmenities, IHotelDetails } from '@common-types';
import { AmenityComponent } from '@components';

import './style.scss';
import { chunk } from 'lodash';

interface IProps {
  hotel: IHotelDetails;
  refAnchor: React.RefObject<HTMLDivElement>;
}

class AmenitiesComponent extends React.Component<IProps> {
  getAmenitiesByCategory = (nameGroup: string): IAmenities[] => {
    return (this.props.hotel.amenities || []).filter(
      (amenity) => amenity.categoryName === nameGroup,
    );
  };

  hasAmenities = (amenities: IAmenities[]) => {
    return (!!amenities && amenities.length)? 1 : 0;
  }

  getSplitedList = (amenitiesList: IAmenities[]) => {
    return chunk(amenitiesList, Math.floor(amenitiesList.length / 3) + 1);
  }

  render(): React.ReactNode {
    const { hotel, refAnchor } = this.props;
    const { amenityDescriptions, isVacationRental } = hotel;

    const general = this.getAmenitiesByCategory('General');
    const foodDrink = this.getAmenitiesByCategory('Food & Drink');
    const wellness = this.getAmenitiesByCategory('Wellness Facilities');
    const entertainment = this.getAmenitiesByCategory('Entertainment & Activities');
    const safety = this.getAmenitiesByCategory('Safety');
    const services = this.getAmenitiesByCategory('Services');
    const accessibility = this.getAmenitiesByCategory('Accessibility');
    const parking = this.getAmenitiesByCategory('Parking');
    const other = this.getAmenitiesByCategory('Other');

    const amenitiesTypeCount = this.hasAmenities(general) + this.hasAmenities(foodDrink) +
                          this.hasAmenities(wellness) + this.hasAmenities(entertainment) +
                          this.hasAmenities(safety) + this.hasAmenities(services) +
                          this.hasAmenities(accessibility) + this.hasAmenities(parking) +
                          this.hasAmenities(other);

    const fullAmenitiesList: IAmenities[] = [...general, ...foodDrink, ...wellness, ...entertainment, ...safety, ...services, ...accessibility, ...parking, ...other ].map(a => ({ ...a, categoryName: '' }));
    const splitedList = this.getSplitedList(fullAmenitiesList);

    return (
      <div className="amenities" ref={refAnchor}>
        {isVacationRental
          ?
            <div className="rooms-search__list-text vacation">
              <div className="rooms-search__header" style={{ fontSize: '24px' }}><FormattedMessage id="tab.amenities" /></div>
            </div>
          : <h4 className="amenities__title"><FormattedMessage id="tab.amenities" /></h4>
        }
        
        {!!(amenityDescriptions && amenityDescriptions.length) &&
          amenityDescriptions.map((description, index) => (
            <p key={index} className="amenities__description">
              {description}
            </p>
          ))}
        
        {amenitiesTypeCount > 1 && (<div className="amenities__amenities-list">
          <Responsive>
            <AmenityComponent amenities={general} />
          </Responsive>
          <div className="amenities__items amenities__default-markup">
            <Responsive>
              <AmenityComponent amenities={foodDrink} />
            </Responsive>
            <Responsive>
              <AmenityComponent amenities={wellness} />
            </Responsive>
            <Responsive>
              <AmenityComponent amenities={entertainment} />
            </Responsive>
          </div>
          <div className="amenities__items">
            <Responsive>
              <AmenityComponent amenities={safety} />
            </Responsive>
            <Responsive>
              <AmenityComponent amenities={services} />
            </Responsive>
            <Responsive>
              <AmenityComponent amenities={accessibility} />
            </Responsive>
            <div className="amenities__tablet-markup">
              <Responsive>
                <AmenityComponent amenities={foodDrink} />
              </Responsive>
              <Responsive>
                <AmenityComponent amenities={wellness} />
              </Responsive>
              <Responsive>
                <AmenityComponent amenities={entertainment} />
              </Responsive>
            </div>
          </div>
          <div className="amenities__items">
            <Responsive>
              <AmenityComponent amenities={parking} />
            </Responsive>
            <Responsive>
              <AmenityComponent amenities={other} />
            </Responsive>
          </div>
        </div>)}

        {amenitiesTypeCount === 1 && (<div className="amenities__amenities-list desktop">
          {splitedList.map((amenities, index) => (<div key={index} className="amenities__items">
            <Responsive>
              <AmenityComponent amenities={amenities} />
            </Responsive>
          </div>))}
        </div>)}

        {amenitiesTypeCount === 1 && (<div className="amenities__amenities-list mobile">
          <Responsive>
            <AmenityComponent amenities={fullAmenitiesList} />
          </Responsive>
        </div>)}
      </div>
    );
  }
}

export const Amenities = AmenitiesComponent;
