import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ConfirmedSvg } from '@assets';
import { Routes } from '@share/constants';
import { BlueButton } from '@share/components';
import './style.scss';
import { connect } from 'react-redux';
import { getAccountUsernameFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {
  isMemberType: boolean;
  isExternal: boolean;
  city: string;
  name: string;
  state: string;
}

class CancelledBookingComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isMemberType, isExternal, city, name, state } = this.props;
    const accountName = getAccountUsernameFromPath(this.props.history);

    return (
      <div className="cancelled-booking ">
        <ConfirmedSvg />
        <p className="cancelled-booking__title">
          <FormattedMessage
            id="you.cancelled.your.booking"
            values={{ city: city, name: name, state: state }}
          />
        </p>
        {(isMemberType && !isExternal) ? (
          <div className="cancelled-booking__btn-wrapper">
            <Link to={`/${accountName}${Routes.Search}`}>
              <BlueButton>
                <FormattedMessage id="start.searching" />
              </BlueButton>
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

export const CancelledBooking = connect()(withRouter(CancelledBookingComponent));
