import React, { RefObject } from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RootState } from '@share/utils';
import { setGuests, IReviewBookState, ICarsReviewBookState, setCarsDriver } from '@store/slices';
import { IMenuState } from '@share/store/slices';
import { ICarsDriver, IGuestsBooking } from '@common-types';
import { mailFormat } from '@share/utils';
import './style.scss';

interface IMapStateToProps {
  carsReviewBookStore: ICarsReviewBookState;
  menuStore: IMenuState;
}

interface IMapDispatchToProps {
  setDriver: (driver: ICarsDriver) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  formRef: RefObject<FormInstance>;
}

class CarsConfirmationEmailComponent extends React.Component<IProps> {

  setEmailAddress = (value: string = null) => {
    const item = { ...this.props.carsReviewBookStore.driver, email: value };
    this.props.setDriver(item);
  };

  validateEmail = (e: { target: HTMLInputElement }) => {
    this.setEmailAddress(e.target.value);
  };

  onFocus = (e: { target: HTMLInputElement }) => {
    this.props.formRef.current.setFieldsValue({ email: e.target.value });
  };

  componentDidUpdate() {
    if (this.props.carsReviewBookStore.errorsField) {
      this.props.formRef.current.validateFields().then();
    }
  }

  render(): React.ReactNode {
    const { intl, carsReviewBookStore } = this.props;
    const { loadingBooking, driver } = carsReviewBookStore;

    return (
      <div className="hotel-info cars-driver-email">
        <div className="cars-driver-email__container" style={{ width: '100%' }}>
          <div className="confirmation-email">
            <div className="confirmation-email__header">
              <h4 className="confirmation-email__title">
                <FormattedMessage id="confirmation.email" />
              </h4>
              <p className="confirmation-email__description">
                <FormattedMessage id="enter.email.address" />
              </p>
            </div>
            <div className="confirmation-email__main-guest-info">
              <Form
                name="confirmation-email-rule"
                className="confirmation-email__rule"
                ref={this.props.formRef}
              >
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(mailFormat),
                      message: intl.formatMessage({ id: 'error.message.email.validation' }),
                    },
                  ]}
                >
                  <div className="confirmation-email__name-input-wrapper">
                    <span className="confirmation-email__input-label">
                      <FormattedMessage id="email" />
                    </span>
                    <div className="confirmation-email__input">
                      <Input
                        placeholder={intl.formatMessage({ id: 'email.address' })}
                        value={driver?.email}
                        maxLength={255}
                        onChange={this.validateEmail}
                        onFocus={this.onFocus}
                        disabled={loadingBooking}
                      />
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsReviewBookStore: state.carsReviewBookStore,
    menuStore: state.navigationMenuStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = { 
  setDriver: setCarsDriver,
 };

export const CarsConfirmationEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CarsConfirmationEmailComponent));
