
import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { RootState } from '@share/utils';

import { IDashboardReportState } from '@store/slices';

import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { Loading, LoadingSise } from '@share/components';

import './style.scss';
import { DashboardReportEmpty } from '../dashboard-report-empty';

const MONTHLY = 'MONTHLY';
const DAILY = 'DAILY';

interface IMapStateToProps {
  dashboardReportsStore: IDashboardReportState;
}

interface IProps extends IMapStateToProps {}

function DashboardReportRoomsBookedComponent(props: IProps) {
  const [period, setPeriod] = useState(MONTHLY);

  const { dashboardReportsStore } = props;
  const { loading } = dashboardReportsStore;
  
  const roomsBookedDays = dashboardReportsStore.dashBoardData?.roomsBookedDays;
  const roomsBookedMonths = dashboardReportsStore.dashBoardData?.roomsBookedMonths;

  const isMonthlyPeriod = useMemo(() => period === MONTHLY, [period]);
  const roomsBooked = useMemo(() => period === MONTHLY ? roomsBookedMonths : roomsBookedDays, [period, roomsBookedMonths, roomsBookedDays]);

  const data = roomsBooked?.map(r => ({ type: (isMonthlyPeriod ? moment(r?.date, 'yyyy-MM-DD').format('MMM') : moment(r?.date, 'yyyy-MM-DD').format('MM/DD')), rooms: r?.numberOfRooms }));
  
  return (
    <div className="dasshboard-reports-rooms-booked-wrapper">
      <div className="dasshboard-reports-rooms-booked-wrapper__title">
        <h1><FormattedMessage id="rooms.booked" /></h1>
        <Select value={period} onChange={v => setPeriod(v)}>
          <Select.Option value={MONTHLY}><FormattedMessage id="monthly" /></Select.Option>
          <Select.Option value={DAILY}><FormattedMessage id="daily" /></Select.Option>
        </Select>
      </div>
      <div className="dasshboard-reports-rooms-booked-wrapper__content centered">
        {loading ?
          <Loading size={LoadingSise.Normal} /> : 
          data?.length ?
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="type" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="rooms" fill="#1d2e67" label={{ fill: 'white', fontSize: 16, fontWeight: 'bold' }} />
              </BarChart>
            </ResponsiveContainer> : 
            <DashboardReportEmpty />}

      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    dashboardReportsStore: state.dashboardReportsStore
  };
};

export const DashboardReportRoomsBooked = connect(mapStateToProps)(DashboardReportRoomsBookedComponent);
