import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  getLocalURI,
  isBackofficeFromPath,
  isExternalUrl,
  isReservationFromPath,
  isChangePasswordFromPath,
  RootState,
  isCondoDealsFromPath,
  isCondoEngineFromPath,
} from '@share/utils';
import { IMenuState } from '@share/store/slices';
import { ILoginState } from '@share/store/slices';

import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { ICondosState } from '@share/store/slices';

import CapitalLogo from '@assets/images/capital_logo.png';
import CondoresLogo from '@assets/images/tpi-white.png';

import { Tooltip } from 'antd';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
  loginStore: ILoginState;
  isGetawaysListPage: boolean;
  condosStore: ICondosState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps {}

const ZERO = 0;

class FooterComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { menuStore, isGetawaysListPage, loginStore, condosStore, history, intl } = this.props;
    const { items, loading } = menuStore;
    const { account } = loginStore;
    const { condos } = condosStore;

    const footerMenu = items?.footer;

    const footerBackgroundColorDB = account?.footerBackgroundColorDB;
    const styleBackground: any = {};
    if (!isEmpty(footerBackgroundColorDB)) {
      styleBackground.backgroundColor = footerBackgroundColorDB;
    }

    const footerFontsColorDB = account?.footerFontsColorDB;
    const styleFonts: any = {};
    if (!isEmpty(footerFontsColorDB)) {
      styleFonts.color = footerFontsColorDB;
    }

    const isBackoffice = isBackofficeFromPath(history);
    const isReservation = isReservationFromPath(history);
    const isChangePassword = isChangePasswordFromPath(history);
    const isCondo = isCondoEngineFromPath(history);
    const isCondoDeal = isCondoDealsFromPath(history);

    if (account?.forceIframe) {
      return null;
    }

    const isRsi = account?.isRSITemplate && account?.isPrivateTokenAccountType;

    const logoDB = account?.logoDB;

    return menuStore.error.length === ZERO && !loading ? (
      <div
        className={`footer ${
          isGetawaysListPage || isBackoffice || isReservation || isChangePassword
            ? 'footer__blue'
            : ''
        } footer__print`}
      >
        {!isBackoffice &&
        !isReservation &&
        !isChangePassword &&
        ((isCondo && !isCondoDeal) || (isCondoDeal && !condos)) &&
        (items?.essentials || items?.useful) ? (
          <div className={`footer__trip-useful-wrapper`}>
            {items?.essentials && (
              <div className="footer__trip">
                <p className="footer__title">
                  <FormattedMessage id="trip.essentials" />
                </p>
                <div className="footer__trip-wrapper">
                  {items?.essentials.map((item, index) => (
                    <div className="footer__trip-navigation" key={`${item.title}-${index}`}>
                      {isExternalUrl(item.url) ? (
                        <a
                          className="footer__useful-link-nav"
                          href={item.url}
                          target={account.disableNewTabOpen ? '_self' : '_blank'}
                          rel="noreferrer"
                        >
                          <FormattedMessage id={item.title} />
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </a>
                      ) : (
                        <Link
                          className="footer__useful-link-nav"
                          to={`/${account?.name}${getLocalURI(item.url)}`}
                        >
                          <FormattedMessage id={item.title} />
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {items?.useful && (
              <div className="footer__useful">
                <p className="footer__title">
                  <FormattedMessage id="useful.information" />
                </p>
                <div className="footer__useful-wrapper">
                  {items?.useful.map((item, index) => (
                    <div className="footer__userful-navigation" key={`${item.title}-${index}`}>
                      {item.isPopup || isExternalUrl(item.url) ? (
                        <a
                          className="footer__useful-link-nav"
                          target={account.disableNewTabOpen ? '_self' : '_blank'}
                          rel="noreferrer"
                          href={item.url}
                        >
                          <FormattedMessage id={item.title} />
                          {item.isPopup ? (
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          ) : null}
                        </a>
                      ) : (
                        <Link
                          className="footer__useful-link-nav"
                          to={`/${account?.name}${getLocalURI(item.url)}`}
                        >
                          <FormattedMessage id={item.title} />
                          {item.isPopup ? (
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                          ) : null}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}

        {footerMenu?.length > 0 && !account?.isCapitalVacations ? (
          <div
            className={`footer__footer ${account?.forceFooterVertical ? 'vertical' : ''}`}
            style={styleBackground}
          >
            {account?.isCapitalVacations ? (
              <img src={CapitalLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />
            ) : null}

            {footerMenu.map((item, index) => (
              <div className="footer__navigation" key={`${item.title}-${index}`}>
                {isEmpty(item.url) || item?.url === 'disabled' ? (
                  <div className="footer__link-nav disabled">
                    {item?.url === 'disabled' ? (
                      <Tooltip
                        title={intl.formatMessage({ id: '"menu.link.disable' })}
                        placement="top"
                      >
                        {item.title}
                      </Tooltip>
                    ) : (
                      item.title
                    )}
                  </div>
                ) : isExternalUrl(item.url) ? (
                  <a
                    className="footer__link-nav"
                    target={account.disableNewTabOpen ? '_self' : '_blank'}
                    rel="noreferrer"
                    href={item.url}
                    style={styleFonts}
                  >
                    {isRsi ? <FormattedMessage id={item.title} /> : item.title}
                  </a>
                ) : (
                  <Link
                    className="footer__link-nav"
                    to={`/${account?.name}${getLocalURI(item.url)}`}
                    style={styleFonts}
                  >
                    {isRsi ? <FormattedMessage id={item.title} /> : item.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        ) : null}

        {account?.isCapitalVacations ? (
          <div className={`footer__footer capital-vacations`} style={styleBackground}>
            <div className="footer__footer-condores-top"></div>
            <div className="footer__footer-condores-container">
              <div className="footer__footer-condores-image-container">
                <div
                  className={`footer__footer-condores-image ${
                    account?.isCapitalVacationsCondoRes && account?.name !== 'condores'
                      ? 'condores'
                      : ''
                  }`}
                >
                  {!account?.isCapitalVacationsCondoRes ? (
                    <img src={CapitalLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />
                  ) : null}
                  {account?.isCapitalVacationsCondoRes && account?.name === 'condores' ? (
                    <img src={CondoresLogo} style={{ marginTop: '20px', marginBottom: '20px' }} />
                  ) : null}
                  {account?.isCapitalVacationsCondoRes &&
                  account?.name !== 'condores' &&
                  !isEmpty(logoDB) ? (
                    <img src={logoDB} style={{ marginTop: '20px', marginBottom: '20px' }} />
                  ) : null}
                </div>
                <label>2024 Corporate Centre Drive</label>
                <label>Myrtle Beach, SC 29577</label>
                <label>844-777-Club</label>
              </div>
              <div className="footer__footer-condores-content-container">
                <label style={{ fontSize: '16px' }}>Follow Us</label>
                <div className="footer__footer-condores-content-socials">
                  <a
                    href="https://www.facebook.com/groups/225740804571396"
                    target="_blank"
                    className="footer__footer-condores-content-socials-item"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/capitalvacations"
                    target="_blank"
                    className="footer__footer-condores-content-socials-item"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                  <a
                    href="https://www.instagram.com/capital.vacations/"
                    target="_blank"
                    className="footer__footer-condores-content-socials-item"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </div>
                <label>
                  Capital Vacations and Capital Vacations logo are registered trademarks,
                  OWNERewards is a trademark, and Capital Vacation Club and Home to A Million
                  Perfect Moments, Capital Lifestyles and Distinct Differences are service marks of
                  Capital Resorts Group, LLC. All other marks are registered marks of their
                  respective owners. Void where prohibited by law and where registration and/or
                  licensing requirements have not been met
                </label>
                <label>&#169; 2024 Capital Vacations Club. All Rights Reserved</label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    condosStore: state.condosStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
    isGetawaysListPage: state.getawaysStore.premierescapes.isGetawaysListPage,
  };
};

const FooterComponentIntl = injectIntl(FooterComponent);

export const Footer = connect(mapStateToProps)(withRouter(FooterComponentIntl));
