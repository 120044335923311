import React from 'react';

import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { ILoginState, ResetCarsFull } from '@share/store/slices';

import { SEARCH_TYPES_FIELD_KEY } from '@share/common-types';
import { AppThunk, getPreferenceFromAccount, GetHomeParams, RootState, VACATIONS_PAGE } from '@share/utils';
import { CARS_SELECTION_TYPE, CONDOS_SELECTION_TYPE, HOTELS_SELECTION_TYPE, Routes, VACATIONS_SELECTION_TYPE } from '@share/constants';
import { resetHotelsFull, resetCondosFull } from '@share/store/slices';

import { RedirectModal } from '../redirect-modal';

import './style.scss';

const ALL_SELECTION_TYPES = [VACATIONS_SELECTION_TYPE, HOTELS_SELECTION_TYPE, CONDOS_SELECTION_TYPE, CARS_SELECTION_TYPE];

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  resetHotelsFull: () => AppThunk;
  resetCondosFull: () => AppThunk;
  resetCarsFull: () => AppThunk;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
  type: string;
  widgetOriginalType?: string;
  isWidget?: boolean;

  excludeCondosSelection?: boolean;
  excludeHotelsSelection?: boolean;
  excludeVacationsSelection?: boolean;
  excludeCarsSelection?: boolean;

  onWidgetSelectionChange?: (value: string) => void;
}

interface IState {
  displayRedirect: boolean;
  redirect: string;
  pageData: any;
}

class SearchSelectionComponent extends React.Component<IProps, IState> {
  state: IState = {
    displayRedirect: false,
    redirect: null,
    pageData: {
      [VACATIONS_SELECTION_TYPE]: {
        reset: this.props.resetHotelsFull,
        route: `${Routes.Search}/${VACATIONS_PAGE}`,
        otherTypes: ALL_SELECTION_TYPES.filter(t => t !== VACATIONS_SELECTION_TYPE)
      },
      [HOTELS_SELECTION_TYPE]: {
        reset: this.props.resetHotelsFull,
        route: Routes.Search,
        otherTypes: ALL_SELECTION_TYPES.filter(t => t !== HOTELS_SELECTION_TYPE)
      },
      [CONDOS_SELECTION_TYPE]: {
        reset: this.props.resetCondosFull,
        route: Routes.CondoSearch,
        otherTypes: ALL_SELECTION_TYPES.filter(t => t !== CONDOS_SELECTION_TYPE)
      },
      [CARS_SELECTION_TYPE]: {
        reset: this.props.resetCarsFull,
        route: Routes.CarsSearch,
        otherTypes: ALL_SELECTION_TYPES.filter(t => t !== CARS_SELECTION_TYPE)
      },
    }
  };
  
  handleSearchTypeSelect = (searchTypeSelected: string) => {
    const { pageData } = this.state;
    const { loginStore, type, isWidget } = this.props;
    const { account } = loginStore;

    const homeParams = GetHomeParams(account);

    const typeSelected = get(pageData, searchTypeSelected);
    const hasOtherPreviousTypeSelected = typeSelected?.otherTypes?.includes(type);
    
    if (searchTypeSelected === 'Flights') {
      const redirect = 'https://secure.rezserver.com/flights/home/?refid=9127';
      this.setState({ displayRedirect: true, redirect });
    } else if (typeSelected && hasOtherPreviousTypeSelected) {
      this.setState({ redirect: null, displayRedirect: false }, () => {
        if (isWidget) {
          this.props.onWidgetSelectionChange(searchTypeSelected);
        } else {
          typeSelected.reset();
          this.props.history.push(`/${account?.name}${typeSelected.route}${homeParams}`);
        }
      });
    /*
    } else if ((type === CONDOS_SELECTION_TYPE || type === HOTELS_SELECTION_TYPE || type === CARS_SELECTION_TYPE) && searchTypeSelected === VACATIONS_SELECTION_TYPE) {
      this.setState({ redirect: null, displayRedirect: false }, () => {
        if (isWidget) {
          this.props.onWidgetSelectionChange(searchTypeSelected);
        } else {
          this.props.resetHotelsFull();
          this.props.history.push(`/${account?.name}${Routes.Search}/${VACATIONS_PAGE}${homeParams}`);
        }
      });
    } else if ((type === CONDOS_SELECTION_TYPE || type === VACATIONS_SELECTION_TYPE || type === CARS_SELECTION_TYPE) && searchTypeSelected === HOTELS_SELECTION_TYPE) {
      this.setState({ redirect: null, displayRedirect: false }, () => {
        if (isWidget) {
          this.props.onWidgetSelectionChange(searchTypeSelected);
        } else {
          this.props.resetHotelsFull();
          this.props.history.push(`/${account?.name}${Routes.Search}${homeParams}`);
        }
      });
    } else if ((type === VACATIONS_SELECTION_TYPE || type === HOTELS_SELECTION_TYPE || type === CARS_SELECTION_TYPE) && searchTypeSelected === CONDOS_SELECTION_TYPE) {
      this.setState({ redirect: null, displayRedirect: false }, () => {
        if (isWidget) {
          this.props.onWidgetSelectionChange(searchTypeSelected);
        } else {
          this.props.resetCondosFull();
          this.props.history.push(`/${account?.name}${Routes.CondoSearch}${homeParams}`);
        }
      });
    } else if ((type === VACATIONS_SELECTION_TYPE || type === HOTELS_SELECTION_TYPE || type === CONDOS_SELECTION_TYPE) && searchTypeSelected === CARS_SELECTION_TYPE) {
      this.setState({ redirect: null, displayRedirect: false }, () => {
        if (isWidget) {
          this.props.onWidgetSelectionChange(searchTypeSelected);
        } else {
          this.props.resetCarsFull();
          this.props.history.push(`/${account?.name}${Routes.CarsSearch}${homeParams}`);
        }
      });
    */
    } else {
      this.setState({ redirect: null, displayRedirect: false });
    }
  }

  render(): React.ReactNode {
    const { loginStore, type, isWidget, excludeCondosSelection, excludeHotelsSelection, excludeVacationsSelection, excludeCarsSelection, widgetOriginalType } = this.props;
    const { account } = loginStore;
    const { displayRedirect, redirect } = this.state;

    const searchTypes = getPreferenceFromAccount(account, SEARCH_TYPES_FIELD_KEY);

    if (isEmpty(searchTypes)) {
      return null;
    }

    let searchTypesList = JSON.parse(searchTypes);

    if (!searchTypesList || searchTypesList.length <= 1 || !searchTypesList.includes(type)) {
      return null;
    }

    if (isWidget) {
      searchTypesList = searchTypesList.filter((s: string) => [VACATIONS_SELECTION_TYPE, HOTELS_SELECTION_TYPE, CONDOS_SELECTION_TYPE, CARS_SELECTION_TYPE].includes(s))
    }

    if (excludeCondosSelection && widgetOriginalType !== CONDOS_SELECTION_TYPE) {
      searchTypesList = searchTypesList.filter((s: string) => ![CONDOS_SELECTION_TYPE].includes(s))
    }
    if (excludeHotelsSelection && widgetOriginalType !== HOTELS_SELECTION_TYPE) {
      searchTypesList = searchTypesList.filter((s: string) => ![HOTELS_SELECTION_TYPE].includes(s))
    }
    if (excludeVacationsSelection && widgetOriginalType !== VACATIONS_SELECTION_TYPE) {
      searchTypesList = searchTypesList.filter((s: string) => ![VACATIONS_SELECTION_TYPE].includes(s))
    }

    if (excludeCarsSelection && widgetOriginalType !== CARS_SELECTION_TYPE) {
      searchTypesList = searchTypesList.filter((s: string) => ![CARS_SELECTION_TYPE].includes(s))
    }

    if (account?.forceDisableVacationRentals) {
      searchTypesList = searchTypesList.filter((s: string) => ![VACATIONS_SELECTION_TYPE].includes(s))
    }

    if (isWidget && searchTypesList?.length <= 1) {
      return null
    }
    
    return (
      <div className="search-selection__wrapper">
        {searchTypesList.map((searchTypeSelected: string, index: number) => (
          <div key={index} className={`search-selection__item ${type === searchTypeSelected ? 'selected' : ''}`} onClick={() => this.handleSearchTypeSelect(searchTypeSelected)}>
            <FormattedMessage id={searchTypeSelected} />
          </div>
        ))}

        <RedirectModal visible={displayRedirect} redirect={redirect} onRedirect={() => this.setState({ displayRedirect: false })} />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = { 
  resetHotelsFull,
  resetCondosFull,
  resetCarsFull: ResetCarsFull
};

export const SearchSelection = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(SearchSelectionComponent)));
