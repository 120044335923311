import React from 'react';

import { FormattedMessage } from 'react-intl';
import { HomesTypes } from '@share/constants';
import { GoTo, GoToType } from '@components';
import { IAccount } from '@share/common-types';

import NoResults from '@assets/images/no_results.png';
import NoHotelResults from '@share/assets/images/no_hotel_results.png';
import NoCarsResults from '@share/assets/images/empty_cars_results.png';
import NoVacationResults from '@assets/images/no_vacation_results.png';

import './style.scss';


const CARS_CODE_TODISPLAY = ['4', '5', '9', '24', '25', '27', '51', '62', '64', '87', '105'];

interface IProps {
  label: string;
  unitsSearch?: boolean;
  customerServiceNumber?: string;
  filterHomes?: string;
  location?: string;

  isVacationRentals?: boolean;
  isCars?: boolean;

  account?: IAccount;
}

export class EmptySearchMessage extends React.Component<IProps> {
  render(): React.ReactNode {
    const { label, account, isVacationRentals, isCars, unitsSearch = false, customerServiceNumber, filterHomes = '', location = '' } = this.props;
    let newLabel = '';
    let title = 'no.result.oops';
    let msg = "change.search.criteria";
    let image = NoResults;

    const isCapitalVacations = account?.isCapitalVacations;

    if (label !== null && !isCars) {
      newLabel = label;
    } else if (filterHomes === HomesTypes.VacationRentalOnly) {
      newLabel = 'no.vacation.filter';
      image = NoVacationResults;
    } else if (filterHomes === HomesTypes.HotelOnly) {
      newLabel = 'no.hotel.filter';
      image = NoHotelResults;
    } else {

      
      if (isCars) {
        newLabel = CARS_CODE_TODISPLAY.includes(label) ? `error.code.${label}` : 'cars.no.all.filter';
        title = null;
        msg = null;
      } else if (isCapitalVacations) {
        newLabel = 'no.all.filter.capital';
        title = 'no.result.title.capital';
        msg = 'no.search.capital';
      } else {
        newLabel = 'no.all.filter';
        title = 'no.result.title';
        msg = 'no.search';
      }

      if (isCars) {
        image = NoCarsResults;
      } else {
        image = NoHotelResults;
      }
    }

    return (
      <div className="no-result-msg">
        <img src={image} alt="" />
        <div className="no-result-msg__info text-center">
          {title ? (<h3><strong><FormattedMessage id={title} /></strong></h3>) : null}

          <p className="no-result-msg__title">
            {filterHomes === HomesTypes.All ? <FormattedMessage id={newLabel} values={{ location: location }} /> : <FormattedMessage id={newLabel} values={{ location: location }} />}
          </p>
          <p className="no-result-msg__text">
            {msg ? (<FormattedMessage id={msg} />) : null}
            {unitsSearch ? (
              <p className="no-result-msg__text-support-phone">
                <FormattedMessage
                  id="or.call.your.agent.at"
                  values={{ customerServiceNumber: customerServiceNumber }}
                />
              </p>
            ) : null}
          </p>

          {(!isCapitalVacations && !isCars) ? (<GoTo type={isVacationRentals ? GoToType.VACATION_RENTALS : null} />) : null}
        </div>
      </div>
    );
  }
}
