import { ThunkAction } from 'redux-thunk';

import { routerMiddleware as reduxRouterMiddleware } from 'react-router-redux';
import { History } from 'history';
import { createBrowserHistory } from 'history';

import { configureStore, Reducer, Middleware, Action } from '@reduxjs/toolkit';
import { rootReducer } from 'src/store/reducers';

export const initStore = (history: History, rootReducer: Reducer, urlMiddleware?: Middleware) => {
  const routerMiddleware = reduxRouterMiddleware(history);

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(routerMiddleware);
      if (urlMiddleware) {
        middleware = middleware.concat(urlMiddleware);
      }
      return middleware;
    },
  });
  return store;
};

export const history = createBrowserHistory();

export type RootState = ReturnType<Reducer>;
export type FunctionalRootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
