import React from 'react';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';

import Icon from '@ant-design/icons/lib/components/Icon';

import { DATE_FORMAT_ISO } from '@constants';
import { DATE_ONLY_MONTH_FORMAT, DATE_FORMAT } from '@share/constants';
import { HotelSearchArgs } from '@common-types';
import {
  IAccount,
  IHotelsRecentSearches,
  IRoomResponse,
  LocationsTypes,
} from '@share/common-types';
import { formatDateCheckInOut } from '@share/utils';

import { HotelSvg, PlaneSvg } from '@assets';
import { LocationGreySvg } from '@share/assets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './style.scss';

interface IProps {
  data: IHotelsRecentSearches;
  account: IAccount;
  disabled: boolean;
  onSearchHandler: HotelSearchArgs;
}

const ZERO = 0;

export class RecentSearches extends React.Component<IProps> {
  adultsCount = (): number => {
    return this.props.data?.rooms.reduce((res: number, { adultsCount }: IRoomResponse) => {
      return res + adultsCount;
    }, ZERO);
  };

  childrenCount = (): number => {
    return this.props.data?.rooms.reduce((res: number, { kidsAges }: IRoomResponse) => {
      const kidsCount = kidsAges ? kidsAges?.length : ZERO;
      return res + kidsCount;
    }, ZERO);
  };

  render(): React.ReactNode {
    const { data, disabled, account, onSearchHandler } = this.props;
    const { location, rooms } = data;
    let { checkIn, checkOut } = data;

    checkIn = moment(checkIn).utc().format(DATE_FORMAT_ISO);
    checkOut = moment(checkOut).utc().format(DATE_FORMAT_ISO);

    const locale = account?.locale;
    const formattedStartDate = checkIn && formatDateCheckInOut(checkIn, DATE_ONLY_MONTH_FORMAT, locale);
    const formattedEndDate = checkOut && formatDateCheckInOut(checkOut, DATE_FORMAT, locale);

    return (
      <div
        role="button"
        className={`search-recents-picker ${disabled ? 'disabled' : ''}`}
        onClick={() => onSearchHandler(location, checkIn, checkOut, rooms)}
      >
        {location.type === LocationsTypes.Destination && (<Icon component={LocationGreySvg} />)}
        {location.type === LocationsTypes.Hotel && <Icon component={HotelSvg} />}
        {location.type === LocationsTypes.Airport && <Icon component={PlaneSvg} />}
        <div className="search-recents-picker__info">
          <p className="search-recents-picker__title">{location.name}</p>
          <p className="search-recents-picker__subtitle ttl">
            {checkIn && (
              <span>
                {formattedStartDate} - {formattedEndDate}
              </span>
            )}
          </p>
          <p className="search-recents-picker__subtitle">
            {this.adultsCount()}{' '}
            <FormattedMessage id="adult" values={{ count: this.adultsCount() }} />,{' '}
            {!!this.childrenCount() && (
              <>
                {this.childrenCount()}{' '}
                <FormattedMessage id="child" values={{ count: this.childrenCount() }} />,{' '}
              </>
            )}
            {rooms.length} <FormattedMessage id="room" values={{ count: rooms.length }} />
          </p>
          <div className={`search-again ${disabled ? 'disabled' : ''}`}>
              <FormattedMessage id="availability.status.modal.search_again" />
              <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
      </div>
    );
  }
}
