import React from 'react';
import { FormattedMessage } from 'react-intl';
import {  Tooltip } from 'antd';

import { InfoGraySvg } from '@assets';

import './style.scss';

interface IProps {
  accountName: string;
}

class DisclaimerComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const message = <FormattedMessage id="detail.disclaimer" values={{accountName: this.props.accountName}} />  
    return (
      <div className="disclaimer">
        <Tooltip title={message} placement="topLeft" overlayStyle={{maxWidth: '500px'}}>
          <span>
            <div className="disclaimer__title">
                <InfoGraySvg />
                <span className='disclaimer__message'>
                  <FormattedMessage id="detail.disclaimer.title" />
                </span>
            </div>
          </span>
        </Tooltip>
      </div>

    )
  }
}

export const Disclaimer = DisclaimerComponent;
