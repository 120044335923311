import React, { useState, useMemo } from 'react';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { LabeledValue } from 'antd/lib/select';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  IWeekState,
  IWeeksFiltersState,
  IWeeksLocationsState,
  weeksActions,
  weeksFiltersActions,
  GetWeeks,
} from '@share/store/slices';
import { ILoginState } from '@share/store/slices';
import { IWeek, SearchTypeEnum } from '@share/common-types';
import { RootState } from '@share/utils';
import { IBudgetRange, ICounterItem } from '@share/common-types';
import { WhiteButton } from '@components';
import { FilterSvg, MapSvg } from '@assets';
import {
  getWeeksSortOptions,
  getWeeksFiltersCount,
  HasPermission,
  isWeeksFiltersEmpty,
  ViewCondoSupplierPermission,
} from '@utils';
import { UrlUtils, getBudgetRangeLabel } from '@share/utils';
import {
  WEEKS_FILTERS_LABEL,
  C_R_FILTER_BUDGET,
  C_R_FILTER_DAY,
  C_R_FILTER_MONTH,
  SortTypes,
  getCondoSortByCodeByValue,
  C_R_FILTER_SUPPLIER,
} from '@share/constants';

import {
  Aligment,
  CondoResultFilter,
} from '../../../../../condo/condo-result/condo-result-wrapper/condo-result-filter';

import './style.scss';

interface IMapStateToProps {
  weeksStore: IWeekState;
  weeksFiltersStore: IWeeksFiltersState;
  weeksLocationsStore: IWeeksLocationsState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSortType: (sortBy: SortTypes) => void;
  getWeeks: (searchType: SearchTypeEnum) => void;
  setSelectedWeek: (week: IWeek) => void;

  resetFilters: () => void;
  setBudgetRanges: (value: string[]) => void;
  setDays: (value: number[]) => void;
  setMonths: (value: number[]) => void;
  setSuppliers: (value: string[]) => void;
  setPageNumber: (pageNumber: number) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {
  matches: boolean;

  visibleFilters: boolean;
  visibleMap: boolean;

  setVisibleMap: (value: boolean) => void;
  setVisibleFilters: (value: boolean) => void;

  setIsNoAvailableCondosNotification: (value: boolean) => void;
  showBodyScroll: (show: boolean, withScroll: boolean) => void;

  onFiltersOrSortChange: () => void;
}

const debounceTime = 300;
const zero = 0;
const ONE = 1;

function WeeksResultFiltersHeaderComponent(props: IProps) {
  const {
    weeksLocationsStore,
    loginStore,
    weeksStore,
    weeksFiltersStore,
    matches,
    visibleFilters,
    visibleMap,
  } = props;
  const { account } = loginStore;
  const { weeks, isSessionExpired, counters, suppliersList } = weeksStore;
  const { sortBy, budgetRanges, days, months, suppliers } = weeksFiltersStore;
  const { isB2C, walletDisplayB2CSavings } = account;

  let prevFilters: IWeeksFiltersState = null;

  const toggleFilters = () => {
    props.setVisibleFilters(!visibleFilters);
    props.showBodyScroll(!visibleFilters && !visibleMap, false);
  };

  const showMap = () => {
    props.setVisibleMap(!visibleMap);
    props.showBodyScroll(!visibleMap, false);
  };

  const onSortChange = (value: string[]): void => {
    props.setSortType(value[0] as SortTypes);
    props.setPageNumber(ONE);
    if (props.matches) {
      props.onFiltersOrSortChange();

      const valueStr = value[0] as string;
      const action = getCondoSortByCodeByValue(valueStr);
      if (action) {
        ReactGA.event({
          category: account.name,
          action: `${action}_${account.name.toUpperCase()}`,
          label: `User selected ${action} sort on result`,
          nonInteraction: false,
        });
      }
    }
  };

  const handleFilterChanged = (key: string) => {
    props.onFiltersOrSortChange();
    props.setPageNumber(ONE);

    ReactGA.event({
      category: account.name,
      action: `${key}_${account.name.toUpperCase()}`,
      label: `User selected filter on condo resuls`,
      nonInteraction: false,
    });
  };

  const resetFilters = () => {
    props.resetFilters();
    props.onFiltersOrSortChange();
  };

  const onBudgetChange = (value: string[]) => {
    props.setBudgetRanges(value);
  };
  const onDaysChange = (value: string[]) => {
    props.setDays(value.map((v) => parseInt(v)));
  };
  const onMonthsChange = (value: string[]) => {
    props.setMonths(value.map((v) => parseInt(v)));
  };
  const onSuppliersChange = (value: string[]) => {
    props.setSuppliers(value);
  };

  const isFiltersEmpty = useMemo(() => isWeeksFiltersEmpty(weeksFiltersStore), [weeksFiltersStore]);
  const sortOptions = useMemo(
    (): LabeledValue[] => getWeeksSortOptions(weeksLocationsStore, isB2C, walletDisplayB2CSavings),
    [weeksLocationsStore, isB2C, walletDisplayB2CSavings],
  );

  const HasViewCondoSupplierPermission = useMemo(
    () => HasPermission(loginStore?.user, ViewCondoSupplierPermission),
    [loginStore?.user],
  );

  const showDays = counters?.days?.length ? counters.days?.some(({ count }) => count) : false;
  const showMonths = counters?.months?.length ? counters.months?.some(({ count }) => count) : false;

  return (
    <>
      {weeks?.length ? (
        <div
          className={`condo-result-wrapper__btn-view-wrapper ${
            isSessionExpired ? 'disabled' : ''
          } ${budgetRanges?.length ? 'selected' : ''}`}
          style={{ position: 'relative' }}
        >
          <WhiteButton onClick={toggleFilters} disabled={isSessionExpired}>
            {isFiltersEmpty ? (
              <FilterSvg />
            ) : (
              <span className="condo-result-wrapper__filters-count">
                {getWeeksFiltersCount(weeksFiltersStore)}
              </span>
            )}
            <FormattedMessage id="all.filters" />
          </WhiteButton>

          <CondoResultFilter
            display={!isSessionExpired && counters?.budgetRanges?.length > 0}
            title={'price'}
            dropdownDisable={isSessionExpired}
            dropdownTitle={'any.price'}
            values={budgetRanges}
            counters={counters?.budgetRanges}
            counterConverter={(counter: IBudgetRange) => {
              return {
                count: counter.count,
                value: `${counter.key.from}-${counter.key.to}`,
                label: getBudgetRangeLabel(counter.key),
              };
            }}
            onChange={onBudgetChange}
            onDoneClick={() => handleFilterChanged(C_R_FILTER_BUDGET)}
          />

          {days?.length > 1 && (
            <CondoResultFilter
              display={!isSessionExpired && showDays}
              title={'number.days'}
              dropdownDisable={isSessionExpired}
              dropdownTitle={'any.day'}
              values={days?.map((d) => d.toString())}
              counters={counters?.days}
              counterConverter={(counter: ICounterItem) => {
                return {
                  count: counter.count,
                  value: `${counter.key}`,
                  label: <FormattedMessage id="days.count" values={{ count: counter.key }} />,
                };
              }}
              onChange={onDaysChange}
              onDoneClick={() => handleFilterChanged(C_R_FILTER_DAY)}
            />
          )}

          {months?.length > 1 && (
            <CondoResultFilter
              display={!isSessionExpired && showMonths}
              title={'number.of.months'}
              dropdownDisable={isSessionExpired}
              dropdownTitle={'any.month'}
              values={months?.map((d) => d.toString())}
              counters={counters?.months}
              counterConverter={(counter: ICounterItem) => {
                return {
                  count: counter.count,
                  value: `${counter.key}`,
                  label: <FormattedMessage id={`months.${counter.key}`} />,
                };
              }}
              onChange={onMonthsChange}
              onDoneClick={() => handleFilterChanged(C_R_FILTER_MONTH)}
            />
          )}

          <CondoResultFilter
            display={!isSessionExpired}
            title={'supplier'}
            dropdownDisable={isSessionExpired}
            dropdownTitle={'supplier'}
            values={suppliers?.map((d) => d.toString())}
            counters={suppliersList?.map((s) => ({ count: 0, key: s }))}
            counterConverter={(counter: ICounterItem) => {
              return {
                count: counter.count,
                value: `${counter.key}`,
                label: <FormattedMessage id={`suppliers.${counter.key}`} />,
              };
            }}
            onChange={onSuppliersChange}
            displayZero={true}
            onDoneClick={() => handleFilterChanged(C_R_FILTER_SUPPLIER)}
          />

          {!isFiltersEmpty && (
            <div className="condo-result-wrapper__reset-all" onClick={resetFilters}>
              <FormattedMessage id="reset.all.filters" />
            </div>
          )}

          {matches && (
            <div
              className="condo-result-wrapper__condos-sort"
              style={{ position: 'absolute', right: '0' }}
            >
              <CondoResultFilter
                display={true}
                title={'sort.by'}
                dropdownDisable={isSessionExpired}
                dropdownTitle={null}
                values={[sortBy]}
                counters={sortOptions}
                counterConverter={(counter: any) => {
                  return {
                    count: -1,
                    value: `${counter.value}`,
                    label: counter.label,
                  };
                }}
                onChange={onSortChange}
                onDoneClick={null}
                aligment={Aligment.Right}
                singleValue={true}
              />
            </div>
          )}
        </div>
      ) : null}

      <div
        className={`condo-result-wrapper__btn-view-wrapper mobile ${
          isSessionExpired ? 'disabled' : ''
        }`}
      >
        <WhiteButton onClick={toggleFilters} disabled={isSessionExpired}>
          <FilterSvg />
          <FormattedMessage id="sort.filter" />
        </WhiteButton>
        <WhiteButton onClick={showMap} disabled={isSessionExpired}>
          <MapSvg />
          <FormattedMessage id="view.map" />
        </WhiteButton>
      </div>
    </>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksStore: state.weeksStore,
    weeksFiltersStore: state.weeksFiltersStore,
    weeksLocationsStore: state.weeksLocationsStore,

    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSortType: weeksFiltersActions.setSortBy,
  setBudgetRanges: weeksFiltersActions.setBudgetRanges,
  setDays: weeksFiltersActions.setDays,
  setMonths: weeksFiltersActions.setMonths,
  setSuppliers: weeksFiltersActions.setSuppliers,
  resetFilters: weeksFiltersActions.resetFilters,

  setSelectedWeek: weeksActions.setSelectedWeeks,

  getWeeks: GetWeeks,
  setPageNumber: weeksActions.setPageNumber,
};

export const WeeksResultFiltersHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WeeksResultFiltersHeaderComponent));
