import React from 'react';
import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import ReactGA from 'react-ga4';

import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import {
  IHotelsState,
  ILoginState,
  ITrustYouReviewState,
  resetHotelsFullWithParams,
} from '@share/store/slices';
import { getHotelDetails, IHotelDetailsState } from '@store/slices';
import {
  getAccountUsernameFromPath,
  GetHomeParams,
  getNormalizedRooms,
  RootState,
  VACATIONS_PAGE,
} from '@share/utils';
import { Responsive, UrlUtils, getHotelIdFromPath } from '@share/utils';
import {
  ALL_INCLUSIVE_KEY,
  D_VIEW_MAP_TEXT,
  DATES_LABEL,
  DEAL_HOME_LABEL,
  DEALID_LABEL,
  GETAWAYS_KEY,
  QUOTE_LABEL,
  ROOMS_LABEL,
  ROOMS_SEARCH_LABEL,
  SESSION_KEY_LABEL,
} from '@share/constants';
import {
  BookingErrorsEnum,
  GetawaysLocationEnum,
  IRoom,
  ISessionKey,
  IUrlRoom,
} from '@share/common-types';
import { Routes } from '@share/constants';
import {
  GeneralActivities,
  AdditionalDescription,
  MobileSlider,
  AvailabilityStatusModal,
  BigMap,
} from '@components';
import { IAnchor, IHotelDetailsParams } from '@common-types';
import { CustomErrorPage } from '@share/components';
import { getRoomsDetails, roomsSearchActions } from '@store/slices';

import { HotelInfo } from '../hotel-info';
import { HotelImagesDescription } from '../hotel-images-description';
import { Amenities } from '../amenities';
import { Policies } from '../policies';
import { Disclaimer } from '../disclaimer';
import { Location } from '../location';
import { SkeletonComponent } from '../skeleton';
import { RoomsSearch } from '../rooms-search';
import { HotelDetailsServerError } from '../hotel-details-server-error';
import { OtherImportantInformation } from '../other-important-information';
import { Reviews } from '../reviews';
import { HotelDetailsPriceChangeModal } from '../price-change-modal';
import { VacationRentalInfo } from '../vacation-rental-content/vacation-prop-info';
import { Activities } from '../vacation-rental-content/activities';

import './style.scss';

interface IMapStateToProps {
  hotelDetailsStore: IHotelDetailsState;
  trustYouReviewStore: ITrustYouReviewState;
  loginStore: ILoginState;
  hotelsStore: IHotelsState;
}

interface IMapDispatchToProps {
  getRoomsDetails: (hotelId: number, sessionKey: ISessionKey, isFullLoading: boolean) => void;
  getHotelDetails: (
    numberId: number,
    sessionKey: ISessionKey | undefined,
    dealId: number,
    quote: string,
  ) => void;
  setRooms: (roms: IRoom[]) => void;
  setDatesString: (dates: { startDate: string; endDate: string }) => void;
  resetHotelsFull: () => void;
}

interface IParams {
  id: string;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps<IParams> {
  isFromAdminPage: boolean;
  isVacationRentals: boolean;
}

interface IState {
  active: number;
  isMapModalOpen: boolean;
  dealId: number;
  backHomeType: string;
}

const ZERO = 0;
const DEFAULT_ANCHOR = 0;
const TAB_POLICIES = 'tab.policies';
const TAB_REVIEWS = 'tab.reviews';
const TAB_OTHER_IMPORTANT_INFO = 'other.important.information';
const TAB_ACTIVITY = 'tab.activity';
const TAB_GRAL_ACTIVITIES = 'tab.activities';
const TAB_AMENITIES = 'tab.amenities';
const TAB_OVERVIEW = 'tab.overview';
const TAB_ADDITIONAL_DESCRIPTION = 'additional.descriptions';
const BIG_MAP_ZOOM = 15;
const DEBOUNCE_TIME = 100;

class HotelDetailsWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    active: DEFAULT_ANCHOR,
    isMapModalOpen: false,
    dealId: null,
    backHomeType: null,
  };

  overviewRef: React.RefObject<HTMLDivElement> = React.createRef();
  roomsRef: React.RefObject<HTMLDivElement> = React.createRef();
  amenitiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  locationRef: React.RefObject<HTMLDivElement> = React.createRef();
  generalActivitiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  activityRef: React.RefObject<HTMLDivElement> = React.createRef();
  policiesRef: React.RefObject<HTMLDivElement> = React.createRef();
  otherImportantRef: React.RefObject<HTMLDivElement> = React.createRef();
  reviewsRef: React.RefObject<HTMLDivElement> = React.createRef();
  additionalDescriptionRef: React.RefObject<HTMLDivElement> = React.createRef();

  ITEMS_ANCHOR: IAnchor[] = [
    { id: 'rooms', ref: this.roomsRef },
    { id: TAB_OVERVIEW, ref: this.overviewRef },
    { id: TAB_AMENITIES, ref: this.amenitiesRef },
    { id: 'tab.location', ref: this.locationRef },
    { id: TAB_GRAL_ACTIVITIES, ref: this.generalActivitiesRef },
    { id: TAB_ACTIVITY, ref: this.activityRef },
    { id: 'tab.reviews', ref: this.reviewsRef },
    { id: TAB_ADDITIONAL_DESCRIPTION, ref: this.additionalDescriptionRef },
    { id: 'tab.policies', ref: this.policiesRef },
    { id: 'other.important.information', ref: this.otherImportantRef },
  ];

  scrollView = (e: React.MouseEvent<HTMLParagraphElement>, ref: React.RefObject<HTMLElement>) => {
    this.setState({ active: +e.currentTarget.dataset.index });
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' });
  };

  getAnchors = (): IAnchor[] => {
    const { hotel } = this.props.hotelDetailsStore;

    const allDataPolicies = [
      ...(hotel.hotelDetails.checkInInformation || []),
      ...(hotel.hotelDetails.checkOutInformation || []),
      ...(hotel.hotelDetails.pets || []),
    ];

    return this.ITEMS_ANCHOR.filter((item) => {
      if (
        (item.id === TAB_POLICIES && allDataPolicies.length === ZERO) ||
        (item.id === TAB_OTHER_IMPORTANT_INFO &&
          hotel.hotelDetails.otherImportantInformation.length === ZERO) ||
        (item.id === TAB_REVIEWS &&
          (!hotel.hotelDetails?.guestRatings || hotel.hotelDetails?.isVacationRental)) ||
        (item.id === TAB_GRAL_ACTIVITIES &&
          (isUndefined(hotel.hotelDetails?.activities) ||
            !hotel.hotelDetails?.isVacationRental ||
            hotel.hotelDetails?.activities?.length === 0)) ||
        (item.id === TAB_ACTIVITY &&
          (!hotel.hotelDetails?.isVacationRental ||
            hotel.hotelDetails?.recreationalActivities?.length === 0)) ||
        (item.id === TAB_AMENITIES && hotel.hotelDetails?.amenities?.length === ZERO) ||
        (item.id === TAB_OVERVIEW &&
          hotel.roomsContent?.length !== 1 &&
          !hotel.hotelDetails?.facilities &&
          !hotel.hotelDetails?.specialInstructions &&
          !hotel.hotelDetails?.other) ||
        (item.id === TAB_ADDITIONAL_DESCRIPTION &&
          hotel.hotelDetails?.additionalDescriptions?.length === 0)
      ) {
        return;
      }
      return item;
    });
  };

  filtersAnchor = () => {
    const { hotel } = this.props.hotelDetailsStore;
    const isVacationRental = hotel.hotelDetails?.isVacationRental;
    return this.getAnchors().map((itemAnchor, i) => (
      <p
        key={i}
        className={`hotel-details-wrapper__tab-anchors ${i === this.state.active ? 'active' : ''}`}
        onClick={(e) => this.scrollView(e, itemAnchor.ref)}
        data-index={i}
      >
        <FormattedMessage
          id={itemAnchor.id === 'rooms' && isVacationRental ? 'units' : itemAnchor.id}
        />
      </p>
    ));
  };

  onClickScrollToRooms = (e: React.MouseEvent<HTMLDivElement>) => {
    this.scrollView(e, this.roomsRef);
  };

  onClickScrollToReviews = (e: React.MouseEvent<HTMLDivElement>) => {
    this.scrollView(e, this.reviewsRef);
  };

  getDetailsData = (): IHotelDetailsParams => {
    const { search } = this.props.history.location;
    const checkIn = new URLSearchParams(search).get('checkIn');
    const checkOut = new URLSearchParams(search).get('checkOut');

    return { checkIn, checkOut };
  };

  componentDidMount() {
    const values = UrlUtils.getValues();

    let dealId;
    if (values[DEALID_LABEL]) {
      dealId = parseInt(values[DEALID_LABEL] as string);
      this.setState({ dealId });
    }

    if (values[DEAL_HOME_LABEL]) {
      this.setState({ backHomeType: values[DEAL_HOME_LABEL] as string });
    }

    if (values[ROOMS_SEARCH_LABEL]) {
      const { startDate, endDate, rooms } = values[ROOMS_SEARCH_LABEL] as {
        startDate: string;
        endDate: string;
        rooms: IUrlRoom[];
      };

      const { hotelsStore } = this.props;
      const { sessionKey } = hotelsStore;

      this.props.setDatesString({ startDate, endDate } as { startDate: string; endDate: string });
      this.props.setRooms(getNormalizedRooms(rooms));
      this.props.getRoomsDetails(getHotelIdFromPath(this.props.history), sessionKey, true);
    } else {
      if (values[DATES_LABEL]) {
        this.props.setDatesString(values[DATES_LABEL] as { startDate: string; endDate: string });
      }

      if (values[ROOMS_LABEL]) {
        this.props.setRooms(getNormalizedRooms(values[ROOMS_LABEL] as Array<IUrlRoom>));
      }
      this.getHotelDetails(dealId);
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { user } = this.props.loginStore;
    if (!prevProps.loginStore.user && !!user) {
      const values = UrlUtils.getValues();

      if (values[DEAL_HOME_LABEL]) {
        this.setState({ backHomeType: values[DEAL_HOME_LABEL] as string });
      }

      const dealId = values[DEALID_LABEL] ? parseInt(values[DEALID_LABEL] as string) : null;
      this.getHotelDetails(dealId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  getHotelDetails = (dealId: number) => {
    const { account, user } = this.props.loginStore;

    if (account && user) {
      const values = UrlUtils.getValues();
      const hotelId = getHotelIdFromPath(this.props.history);
      let quote;
      if (values[QUOTE_LABEL]) {
        quote = values[QUOTE_LABEL] as string;
      }
      this.props.getHotelDetails(hotelId, values[SESSION_KEY_LABEL] as ISessionKey, dealId, quote);

      window.addEventListener('scroll', this.onScroll);
    }
  };

  getActiveAnchor = (): IAnchor => {
    const anchors: IAnchor[] = this.getAnchors();
    const { pageYOffset } = window;
    const activeRef: IAnchor = anchors[this.state.active];

    // VERIFICAR
    // anchors.forEach((anchor: IAnchor) => {
    //   const { ref } = anchor;

    //   if (ref && ref.current) {
    //     const { current } = ref;
    //     const height = current.getBoundingClientRect()?.height;
    //     const offsetTop = current.offsetTop;
    //     const offsetBottom = offsetTop + height;

    //     if (height && offsetTop && pageYOffset >= offsetTop && pageYOffset <= offsetBottom) {
    //       activeRef = anchor;
    //     }
    //   }
    // });

    return activeRef;
  };

  onScroll = debounce(() => {
    const { hotel, loading } = this.props.hotelDetailsStore;

    if (hotel && !loading) {
      const anchor = this.getActiveAnchor();

      if (anchor && anchor.id) {
        const index = this.getAnchors().findIndex(({ id }) => id === anchor.id);

        if (index >= ZERO && this.state.active !== index) {
          this.setState({ active: index });
        }
      }
    }
  }, DEBOUNCE_TIME);

  showMap = () => {
    this.setState({ isMapModalOpen: true });
    document.body.style.overflow = 'hidden';

    const { loginStore } = this.props;
    const { account } = loginStore;

    ReactGA.event({
      category: account.name,
      action: `${D_VIEW_MAP_TEXT}_${account.name.toUpperCase()}`,
      label: `User clicked view map`,
      nonInteraction: false,
    });
  };

  closeMap = () => {
    this.setState({ isMapModalOpen: false });
    document.body.style.overflow = 'initial';
  };

  render(): React.ReactNode {
    const { isFromAdminPage, isVacationRentals, loginStore } = this.props;
    const { isMapModalOpen, dealId, backHomeType } = this.state;
    const { hotel, loading, isServerError, error } = this.props.hotelDetailsStore;
    const { reviewModule } = this.props.trustYouReviewStore;
    const { account } = loginStore;

    const isVacation = hotel?.hotelDetails?.isVacationRental || false;
    const isAllInclusive = backHomeType === ALL_INCLUSIVE_KEY;

    const accountName = getAccountUsernameFromPath(this.props.history);
    const filterdImages = hotel?.hotelDetails?.images?.length
      ? hotel?.hotelDetails?.images.filter((i) => i?.width >= 400)
      : [];

    const backToList = (): string => {
      const isAllGetaway =
        localStorage.getItem('isAllGetaway') && JSON.parse(localStorage.getItem('isAllGetaway'));

      if (isAllGetaway) {
        return `${Routes.Getaways}`;
      }
      return `${Routes.Getaway}/${GetawaysLocationEnum.Staycation}`;
    };

    return (
      <div className="hotel-details-wrapper">
        {isServerError ? (
          <HotelDetailsServerError isVacationRentals={isVacationRentals} />
        ) : (
          <>
            <div className="hotel-details-wrapper__back-link desktop">
              {isVacationRentals ? (
                <Link to={`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}`}>
                  <FormattedMessage id="see.all.properties" />
                </Link>
              ) : !dealId && !isAllInclusive ? (
                <Link to={`/${accountName}${Routes.Search}${location.search}`}>
                  <FormattedMessage id="see.all.properties" />
                </Link>
              ) : !isEmpty(backHomeType) ? (
                backHomeType === 'HOME' ? (
                  <Link to={`/${accountName}${Routes.Home}`}>
                    <FormattedMessage id="confirmation.go.back_home" />
                  </Link>
                ) : backHomeType === GETAWAYS_KEY ? (
                  <Link to={`/${accountName}${Routes.Getaways}`}>
                    <FormattedMessage id="getaways.back" />
                  </Link>
                ) : isAllInclusive ? (
                  !dealId ? (
                    <Link to={`/${accountName}${Routes.Search}${location.search}`}>
                      <FormattedMessage id="go.back.search" />
                    </Link>
                  ) : (
                    <Link
                      to={null}
                      onClick={() => {
                        const homeParams = GetHomeParams(account);
                        this.props.resetHotelsFull();
                        this.props.history.push(
                          `/${accountName}${Routes.AllInclusive}${homeParams}`,
                        );
                      }}
                    >
                      <FormattedMessage id="back.to.all_inclusive" />
                    </Link>
                  )
                ) : (
                  <Link
                    to={null}
                    onClick={() => {
                      const homeParams = GetHomeParams(account);
                      this.props.resetHotelsFull();
                      this.props.history.push(`/${accountName}${Routes.Search}${homeParams}`);
                    }}
                  >
                    <FormattedMessage id="confirmation.go.back_home" />
                  </Link>
                )
              ) : (
                <Link to={`/${accountName}${backToList()}`}>
                  <FormattedMessage id="getaways.back" />
                </Link>
              )}
            </div>
            {loading ? <SkeletonComponent isVacationRentals={isVacationRentals} /> : null}
            {!loading && hotel && isEmpty(error) ? (
              <div className="hotel-details-wrapper__wrapper">
                {!isVacation && <div ref={this.overviewRef} />}
                <div className="hotel-details-wrapper__back-link mobile">
                  {isVacationRentals ? (
                    <Link
                      to={`/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}`}
                    >
                      <FormattedMessage id="see.all.properties" />
                    </Link>
                  ) : !dealId ? (
                    <Link to={`/${accountName}${Routes.Search}${location.search}`}>
                      <FormattedMessage id="see.all.properties" />
                    </Link>
                  ) : !isEmpty(backHomeType) ? (
                    backHomeType === 'HOME' ? (
                      <Link to={`/${accountName}${Routes.Home}`}>
                        <FormattedMessage id="confirmation.go.back_home" />
                      </Link>
                    ) : backHomeType === GETAWAYS_KEY ? (
                      <Link to={`/${accountName}${Routes.Getaways}`}>
                        <FormattedMessage id="getaways.back" />
                      </Link>
                    ) : (
                      <Link
                        to={null}
                        onClick={() => {
                          const homeParams = GetHomeParams(account);
                          this.props.resetHotelsFull();
                          this.props.history.push(`/${accountName}${Routes.Search}${homeParams}`);
                        }}
                      >
                        <FormattedMessage id="confirmation.go.back_home" />
                      </Link>
                    )
                  ) : (
                    <Link to={`/${accountName}${backToList()}`}>
                      <FormattedMessage id="getaways.back" />
                    </Link>
                  )}
                </div>
                <div className="hotel-details-wrapper__desktop-hotel-info">
                  <HotelInfo
                    showBigMap={this.showMap}
                    hotel={hotel.hotelDetails}
                    onClickScrollToRooms={this.onClickScrollToRooms}
                    reviewModule={reviewModule}
                    roomsRef={this.roomsRef}
                    onClickScrollToReviews={this.onClickScrollToReviews}
                  />
                </div>
                <MobileSlider images={filterdImages} />
                <div className="hotel-details-wrapper__details-anchor">
                  {hotel.hotelDetails && this.filtersAnchor()}
                </div>
                <div className="hotel-details-wrapper__mobile-hotel-info">
                  <HotelInfo
                    hotel={hotel.hotelDetails}
                    showBigMap={this.showMap}
                    reviewModule={reviewModule}
                    roomsRef={this.roomsRef}
                    onClickScrollToReviews={this.onClickScrollToReviews}
                  />
                </div>
                <HotelImagesDescription
                  account={account}
                  images={filterdImages}
                  hotelName={hotel.hotelDetails.title}
                  hotelDescription={hotel.hotelDetails.descriptions || []}
                  additionalDescriptions={hotel.hotelDetails.additionalDescriptions || []}
                  hotelRooms={hotel.hotelDetails.roomsCount}
                  onClickScrollToRooms={this.onClickScrollToRooms}
                />

                <Responsive>
                  <RoomsSearch
                    isFromAdminPage={isFromAdminPage}
                    isVacationRentals={isVacationRentals}
                    hotel={hotel}
                    refAnchor={this.roomsRef}
                  />
                </Responsive>

                {isVacation && (
                  <VacationRentalInfo
                    hotel={hotel.hotelDetails}
                    rooms={hotel.roomsContent}
                    refAnchor={this.overviewRef}
                  />
                )}

                {hotel.hotelDetails.amenities.length ? (
                  <Amenities hotel={hotel.hotelDetails} refAnchor={this.amenitiesRef} />
                ) : null}

                <Location
                  hotel={hotel.hotelDetails}
                  refAnchor={this.locationRef}
                  showBigMap={this.showMap}
                  isVacation={isVacation}
                />

                {isVacation ? (
                  <>
                    {hotel.hotelDetails?.activities?.length ? (
                      <GeneralActivities
                        hotelDetail={hotel.hotelDetails}
                        refAnchor={this.generalActivitiesRef}
                      />
                    ) : null}
                    <Activities hotel={hotel.hotelDetails} refAnchor={this.activityRef} />
                  </>
                ) : null}

                {!isVacation && <Reviews hotel={hotel.hotelDetails} refAnchor={this.reviewsRef} />}

                <AdditionalDescription
                  hotel={hotel.hotelDetails}
                  refAnchor={this.additionalDescriptionRef}
                />

                <Policies hotel={hotel.hotelDetails} refAnchor={this.policiesRef} />

                <OtherImportantInformation
                  hotel={hotel.hotelDetails}
                  refAnchor={this.otherImportantRef}
                />

                <Disclaimer accountName={accountName} />

                <BigMap
                  title={hotel.hotelDetails.title}
                  isMapModalOpen={isMapModalOpen}
                  location={hotel.hotelDetails.location}
                  onCloseMap={this.closeMap}
                />
              </div>
            ) : null}
            {!loading && !isEmpty(error) ? (
              BookingErrorsEnum.RoomsUnavailable === error ? (
                <AvailabilityStatusModal />
              ) : (
                <CustomErrorPage message={error} />
              )
            ) : null}
          </>
        )}

        <HotelDetailsPriceChangeModal
          isDeal={!loading && !!dealId}
          isVacationRentals={isVacationRentals}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    hotelDetailsStore: state.hotelDetailsStore,
    trustYouReviewStore: state.trustYouReviewStore,
    loginStore: state.loginStore,
    hotelsStore: state.hotelsStore,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getHotelDetails: (
    numberId: number,
    sessionKey: ISessionKey | undefined,
    dealId: number,
    quote: string,
  ) => {
    dispatch(getHotelDetails(numberId, sessionKey, dealId, quote));
  },
  getRoomsDetails: (hotelId: number, sessionKey: ISessionKey, isFullLoading: boolean) => {
    dispatch(getRoomsDetails(hotelId, sessionKey, isFullLoading));
  },
  setRooms: (rooms: IRoom[]) => {
    dispatch(roomsSearchActions.setRooms(rooms));
  },
  setDatesString: (dates: { startDate: string; endDate: string }) => {
    dispatch(
      roomsSearchActions.setDates({
        startDate: dates.startDate,
        endDate: dates.endDate,
      }),
    );
  },
  resetHotelsFull: () => {
    dispatch(resetHotelsFullWithParams());
  },
});

export const HotelDetailsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HotelDetailsWrapperComponent));
