import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICondoBookingSummary,
  ICondoGuestBooking,
  IErrorField,
  ICondoBookPackageWithCard,
  ICondoBookingComplete,
  ICondoCardBooking,
  ICardBooking,
  IInsuranceRequestTravelers,
} from '@common-types';
import {
  ISessionKey,
  BookingErrorsEnum,
} from '@share/common-types';
import { get, isString } from 'lodash';
import { getHeaders, axiosInstance, AppThunk, UrlUtils } from '@share/utils';
import ReactGA from 'react-ga4';
import {
  ERROR,
  EXTERNAL_ERROR,
  PRICE_CHANGED,
  STATUS_SUCCEEDED,
  VALIDATION_ERROR,
  STATUS_FAILED,
  STATUS_PENDING,
  CONDO_BOOKING_GUEST_INFO_LABEL,
  CONDO_BOOKING_CARD_INFO_LABEL,
  CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL,
  SAVING_MISMATCH,
  CHECK_OUT_PAYMENT_ERROR,
  POSTAL_CODE_PAYMENT_ERROR,
  STATE_PAYMENT_ERROR,
  DECLINE_PAYMENT_ERROR,
  USD_CURRENCY,
  USA_STATES,
} from '@constants';
import {
  SESSION_EXPIRED_STATUS,
  SESSION_SEARCH_EXPIRED_MESSAGE,
  Urls,
  CONDO_UNITS_SESSION_KEY_LABEL,
  CONDO_SESSION_KEY,
  CONDO_UNITS_SEARCH_LABEL,
  C_C_BOOKING_CONFIRMED, 
} from '@share/constants';
import { setThreeDSId, setThreeDSLoading, setThreeDSModalVisible, setThreeDSUrl } from './review-book';
import { getUserWallet, condosActions } from '@share/store/slices';
import { getInsuranceQuote, insuranceActions, InsuranceSelection } from './insurance';
import moment from 'moment';
import { getCondoAddressFromStorage } from '@utils';

export interface ICondoReviewBookState {
  condoBookingSummary: ICondoBookingSummary;
  loading: boolean;
  error: string;
  isUpdatePrice: boolean;
  guest: ICondoGuestBooking;
  card: ICondoCardBooking;
  errorsField: IErrorField[];
  loadingBooking: boolean;
  bookingComplete: boolean;
  isBookingInProgress: boolean;
  booking: ICondoBookingComplete;
  errorBooking: string;
  isExternalError: boolean;
  isPriceChangedError: boolean;
  bookingErrorCode: BookingErrorsEnum;
  errorsBookingMessage: IErrorField[];
  errorCountries: string;
  expiredSession: boolean;
  isBookingPending: boolean;
  isAlternativeUnitsAvailable: boolean;
  isErrorMessageExpirationDate: boolean;
  isErrorMessageCardNumber: boolean;
  isErrorMessageZipCode: boolean;
  isSavingsMismatch: boolean;
  selectedCondoReviewClientCash?: number;
  selectedCondoReviewClientCashStr?: string;
}

const initialState: ICondoReviewBookState = {
  condoBookingSummary: null,
  loading: false,
  error: '',
  isUpdatePrice: false,
  guest: null,
  errorsField: null,
  card: null,
  loadingBooking: false,
  bookingComplete: false,
  isBookingInProgress: false,
  booking: null,
  errorBooking: '',
  isExternalError: false,
  isPriceChangedError: false,
  errorsBookingMessage: null,
  errorCountries: '',
  expiredSession: false,
  bookingErrorCode: null,
  isBookingPending: false,
  isAlternativeUnitsAvailable: false,
  isErrorMessageExpirationDate: true,
  isErrorMessageCardNumber: true,
  isErrorMessageZipCode: true,
  isSavingsMismatch: false,
  selectedCondoReviewClientCash: null,
  selectedCondoReviewClientCashStr: ''
};

const zeroItem = 0;

const condoReviewBookSlice = createSlice({
  name: 'condo-review-book',
  initialState,
  reducers: {
    setCondoReviewBook: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<ICondoBookingSummary>,
    ) => {
      state.condoBookingSummary = payload;
    },
    setLoadingCondoBook: (state: ICondoReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setIsBookingPending: (state: ICondoReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isBookingPending = payload;
    },
    setIsAlternativeUnitsAvailable: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isAlternativeUnitsAvailable = payload;
    },
    setErrorCondoBook: (state: ICondoReviewBookState, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setUpdateCondoPriceReview: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isUpdatePrice = payload;
    },
    setCondoGuest: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<ICondoGuestBooking>,
    ) => {
      state.guest = payload;
    },
    setCondoCard: (state: ICondoReviewBookState, { payload }: PayloadAction<ICondoCardBooking>) => {
      state.card = payload;
    },
    setLoadingCondoBooking: (state: ICondoReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.loadingBooking = payload;
    },
    setCondoBookingComplete: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.bookingComplete = payload;
    },
    setCondoBookingInProgress: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isBookingInProgress = payload;
    },
    setCondoBooking: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<ICondoBookingComplete>,
    ) => {
      state.booking = payload;
    },
    setCondoErrorBooking: (state: ICondoReviewBookState, { payload }: PayloadAction<string>) => {
      state.errorBooking = payload;
    },
    setErrorsCondoField: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<IErrorField[]>,
    ) => {
      state.errorsField = payload;
    },
    setCondoExternalError: (state: ICondoReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isExternalError = payload;
    },
    setCondoSavingsMismatch: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isSavingsMismatch = payload;
    },
    setCondoPriceChangedError: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isPriceChangedError = payload;
    },
    setBookingErrorCodeError: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<BookingErrorsEnum>,
    ) => {
      state.bookingErrorCode = payload;
    },
    setCondoErrorsBookingMessage: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<IErrorField[]>,
    ) => {
      state.errorsBookingMessage = payload;
    },
    setErrorCountries: (state: ICondoReviewBookState, { payload }: PayloadAction<string>) => {
      state.errorCountries = payload;
    },
    setCondoExpiredSession: (state: ICondoReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.expiredSession = payload;
    },
    setSelectedCondoReviewClientCash: (state: ICondoReviewBookState, { payload }: PayloadAction<number>) => {
      state.selectedCondoReviewClientCash = payload;
    },
    setSelectedCondoReviewClientCashStr: (state: ICondoReviewBookState, { payload }: PayloadAction<string>) => {
      state.selectedCondoReviewClientCashStr = payload;
    },
    condoBookingResetState: () => {
      return initialState;
    },
    setErrorMessageExpirationDate: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isErrorMessageExpirationDate = payload;
    },
    setErrorMessageCardNumber: (
      state: ICondoReviewBookState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isErrorMessageCardNumber = payload;
    },
    setErrorMessageZipCode: (state: ICondoReviewBookState, { payload }: PayloadAction<boolean>) => {
      state.isErrorMessageZipCode = payload;
    },
  },
});

export const {
  setCondoReviewBook,
  setLoadingCondoBook,
  setErrorCondoBook,
  setUpdateCondoPriceReview,
  setCondoGuest,
  setCondoCard,
  setLoadingCondoBooking,
  setCondoBookingComplete,
  setCondoBookingInProgress,
  setCondoBooking,
  setCondoErrorBooking,
  setErrorsCondoField,
  setCondoExternalError,
  setCondoPriceChangedError,
  setCondoErrorsBookingMessage,
  setErrorCountries,
  setCondoExpiredSession,
  setBookingErrorCodeError,
  setIsBookingPending,
  setIsAlternativeUnitsAvailable,
  condoBookingResetState,
  setErrorMessageExpirationDate,
  setErrorMessageCardNumber,
  setErrorMessageZipCode,
  setCondoSavingsMismatch,
  setSelectedCondoReviewClientCash,
  setSelectedCondoReviewClientCashStr,
} = condoReviewBookSlice.actions;

export const condoReviewBookReducer = condoReviewBookSlice.reducer;

export const getCondoReviewBook = (
    condoId: number,
    availabilityId: string,
    sessionKey: ISessionKey,
    isGetCoupon?: false,
    quote?: string): AppThunk => {
  return async (dispatch, getState) => {
    const { condoRedeemCodeStore, condoReviewBookStore, loginStore } = getState();
    const { account } = loginStore;
    const { card } = condoReviewBookStore;

    if (!(condoRedeemCodeStore.isGetCoupon || isGetCoupon)) {
      dispatch(setLoadingCondoBook(true));
    }

    dispatch(setUpdateCondoPriceReview(true));
    dispatch(setCondoExpiredSession(false));

    try {
      const res = await axiosInstance.post(
        Urls.CondoBookingSummary,
        { condoId, availabilityId, sessionKey, quote },
        { ...getHeaders() },
      );

      const { loginStore, condoReviewBookStore, condosStore, navigationMenuStore } = getState();
      const { userWalletData } = loginStore;
      const { selectedCondoReviewClientCash } = condoReviewBookStore;
      const { selectedCondoSearchClientCash } = condosStore;
      const { items } = navigationMenuStore;

      if (!!selectedCondoReviewClientCash) {
        const walletNoDecimals = account?.walletNoDecimals;
        const maxWalletClientCashAmount = items?.isMLM ? res.data?.bookingCard?.bookingPrice?.savings : res.data?.bookingCard?.bookingPrice?.maxWalletClientCash;
        const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
        const maxWalletClientCashCalculated = maxWalletClientCashAmount / convertionRate;
        const maxWalletClientCash = walletNoDecimals ? Math.floor(maxWalletClientCashCalculated) : maxWalletClientCashCalculated;
        
        if (maxWalletClientCash < selectedCondoReviewClientCash) {
          dispatch(setSelectedCondoReviewClientCash(maxWalletClientCash));
          dispatch(setSelectedCondoReviewClientCashStr(maxWalletClientCash ? maxWalletClientCash.toString() : ''));
          dispatch(condosActions.setSelectedCondoSearchClientCash({ ...selectedCondoSearchClientCash, selectedPropertyReviewClientCash: maxWalletClientCash }));
        }
      }

      dispatch(setCondoReviewBook({ ...res.data }));
      
      dispatch(setUpdateCondoPriceReview(false));
      dispatch(setLoadingCondoBook(false));

      const balance = res.data?.bookingCard?.balance;
      const checkIn = res.data?.bookingCard?.checkIn ? moment(res.data?.bookingCard?.checkIn, 'yyyy-MM-DD').format('yyyy-MM-DD') : null;
      const checkOut = res.data?.bookingCard?.checkOut ? moment(res.data?.bookingCard?.checkOut, 'yyyy-MM-DD').format('yyyy-MM-DD') : null;
      const travelers = res.data?.bookingCard?.totalGuests ? res.data?.bookingCard?.totalGuests : 1;
      const destinationCountry = res.data?.bookingCard?.countryCode ? res.data?.bookingCard?.countryCode : null;

      const addressFromStorage: any = getCondoAddressFromStorage();
      const country = addressFromStorage ? addressFromStorage.country ? addressFromStorage.country : card?.country : card?.country;
      const state = addressFromStorage ? addressFromStorage.state ? addressFromStorage.state : card?.state : card?.state;

      dispatch(insuranceActions.setBaseRequest({
        sessionId: sessionKey?.value,
        totalPrice: {
          isoCurrencyCode: USD_CURRENCY,
          value: balance
        },
        booking: {
          startDateTime: checkIn,
          endDateTime: checkOut,
          destinationIsoCountryCode: 'US'
        }  
      }));
      dispatch(insuranceActions.setTravelersRequest({ numberOfTravelers: 1 } as IInsuranceRequestTravelers));
  
      if (country === 'US' && !items?.isRemoveInsurance) {
        const isUSState = USA_STATES.map(s => s.postalCode).includes(state);

        dispatch(getInsuranceQuote(balance, USD_CURRENCY, checkIn, checkOut, isUSState ? state : null, travelers, destinationCountry, sessionKey?.value));
      }


    } catch (error) {
      const errorObject = JSON.parse(error?.response?.data);
      const errorCode = get(errorObject, 'code', null);
      const errorReason = get(errorObject, 'reason', error.toString());

      dispatch(setErrorCondoBook(errorReason));
      dispatch(setLoadingCondoBook(false));
      dispatch(setUpdateCondoPriceReview(false));

      dispatch(setCondoExternalError(errorCode === 'error'));

      if (BookingErrorsEnum.SoldOut === errorCode) {
        dispatch(setBookingErrorCodeError(BookingErrorsEnum.SoldOut));
      }
      if (BookingErrorsEnum.RoomsUnavailable === errorCode) {
        dispatch(setBookingErrorCodeError(BookingErrorsEnum.RoomsUnavailable));
      }

      if (
        error?.response?.status === SESSION_EXPIRED_STATUS &&
        error?.response?.data[zeroItem] === SESSION_SEARCH_EXPIRED_MESSAGE
      ) {
        dispatch(setCondoExpiredSession(true));
      }
    }
  };
};

export const getCondoBookPackageWithCard = (bookWithCard: ICondoBookPackageWithCard, onErrorCallback: (errorCode: string) => void): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingCondoBooking(true));
    dispatch(setBookingErrorCodeError(null));
    dispatch(setIsBookingPending(false));
    dispatch(setIsAlternativeUnitsAvailable(false));
    dispatch(setCondoExpiredSession(false));
    dispatch(setThreeDSModalVisible(false));
    dispatch(setThreeDSUrl(null));
    dispatch(setThreeDSId(null));
    dispatch(setThreeDSLoading(false));

    const { insuranceStore } = getState();
    const { selection, insurance } = insuranceStore;

    const postData: ICondoBookPackageWithCard = {
      ...bookWithCard,
      guests: bookWithCard.guests.map((guest: ICondoGuestBooking) => {
        return {
          ...guest,
          givenName: guest.givenName.trim(),
          surname: guest.surname.trim(),
        };
      }),
    };

    if (selection === InsuranceSelection.YES && insurance?.quoteId) {
      postData.insuranceQuoteId = insurance?.quoteId;
    }

    try {
      const res = await axiosInstance.post(Urls.CondoBook, postData, {
        ...getHeaders(),
      });
      dispatch(setLoadingCondoBooking(false));

      const bookingResponse = res?.data;
      if (bookingResponse?.chargeStatus === 'Pending3DS') {
        dispatch(setLoadingCondoBooking(false));
        dispatch(setThreeDSModalVisible(true));
        dispatch(setThreeDSId(bookingResponse?.bookingGuid));
        dispatch(setThreeDSUrl(bookingResponse?.urlRedirectTo3DS));
        dispatch(setThreeDSLoading(false));
        return;
      }

      dispatch(handleProcessBookingComplete(bookingResponse));

    } catch (error) {
      dispatch(handleErrorBookingComplete(error, onErrorCallback));
    }
  };
};

export const getCondoBookPackageWithCard3DS = (sessionKey: ISessionKey): AppThunk => {
  return async (dispatch, getState) => {
    const { reviewBookStore } = getState();
    const { threeDSId } = reviewBookStore;

    dispatch(setThreeDSLoading(true));

    try {
      const res = await axiosInstance.post(Urls.CondoBookResume, { bookingId: threeDSId, sessionKey }, { ...getHeaders() });
      dispatch(handleProcessBookingComplete(res?.data));

      dispatch(setThreeDSModalVisible(false));
      dispatch(setThreeDSUrl(null));
      dispatch(setThreeDSId(null));
      dispatch(setThreeDSLoading(false));
    } catch (error) {
      dispatch(handleErrorBookingComplete(error, null));
    } finally {
      dispatch(setThreeDSModalVisible(false));
      dispatch(setThreeDSUrl(null));
      dispatch(setThreeDSId(null));
      dispatch(setThreeDSLoading(false));

      window.scrollTo(0, 0);
    }
  };
};

const handleProcessBookingComplete = (data: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingCondoBooking(true));

    const { navigationMenuStore, loginStore, condoReviewBookStore } = getState();
    const selectedClientCash = condoReviewBookStore?.selectedClientCash;

    if (data.status === STATUS_SUCCEEDED) {
      dispatch(setCondoBookingComplete(true));
      localStorage.setItem(CONDO_BOOKING_GUEST_INFO_LABEL, '');
      localStorage.setItem(CONDO_BOOKING_CARD_INFO_LABEL, '');
      localStorage.setItem(CONDO_BOOKING_SAME_ADDRESS_INFO_LABEL, '');

      const { account, userWallet } = loginStore;

      if (navigationMenuStore?.items?.promo) {
        UrlUtils.removeFromUrl(CONDO_UNITS_SESSION_KEY_LABEL);
        UrlUtils.removeFromUrl(CONDO_SESSION_KEY);
        UrlUtils.removeFromUrl(CONDO_UNITS_SEARCH_LABEL);
      }

      dispatch(setSelectedCondoReviewClientCash(null));
      dispatch(setSelectedCondoReviewClientCashStr(''));
      dispatch(condosActions.setSelectedCondoSearchClientCash(null));
      dispatch(getUserWallet(userWallet));

      ReactGA.event({
        category: account.name,
        action: `${C_C_BOOKING_CONFIRMED}_${account.name.toUpperCase()}`,
        label: `Condo Booking confirmed on book`,
        nonInteraction: false,
      });

    } else if (data.status === STATUS_FAILED) {
      if (data.error?.errorCode) {
        const { errorCode } = data.error;

        dispatch(setBookingErrorCodeError(errorCode));
      } else {
        dispatch(setBookingErrorCodeError(BookingErrorsEnum.UnexpectedResponse));
      }

      dispatch(setIsAlternativeUnitsAvailable(!!data?.isAlternativeUnitsAvailable));
    } else if (data.status === STATUS_PENDING) {
      dispatch(setIsBookingPending(true));
    } else {
      dispatch(setCondoBookingInProgress(true));
    }

    const booking = data;
    if (booking) {
      booking.clientCash = selectedClientCash;
    }
    dispatch(setCondoBooking(booking));
    dispatch(setLoadingCondoBooking(false));

    window.scrollTo(0, 0);
  }
}

const handleErrorBookingComplete = (error: any, onErrorCallback: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setLoadingCondoBooking(false));
    dispatch(setThreeDSModalVisible(false));
    dispatch(setThreeDSUrl(null));
    dispatch(setThreeDSId(null));
    dispatch(setThreeDSLoading(false));

    if (error?.response?.data && error?.response?.data[zeroItem].errorType === VALIDATION_ERROR) {
      dispatch(setErrorsCondoField(error.response.data));
    } else if (
      error?.response?.data &&
      error?.response?.data[zeroItem].errorType === EXTERNAL_ERROR
    ) {
      dispatch(setCondoExternalError(true));
    } else if (
      error?.response?.data &&
      error?.response?.data[zeroItem].errorCode === PRICE_CHANGED
    ) {
      dispatch(setCondoPriceChangedError(true));
    } else if (error?.response?.data && error?.response?.data[zeroItem].errorType === ERROR) {
      dispatch(setCondoErrorsBookingMessage(error.response.data));
    } else if (error?.response?.data[zeroItem].errorCode === SAVING_MISMATCH) {
      dispatch(setCondoExpiredSession(true));
      dispatch(setCondoSavingsMismatch(true));
    } else {
      const errorObject = isString(error?.response?.data) ? JSON.parse(error?.response?.data) : error?.response?.data;
      const errorMessage = get(errorObject, 'code', null);
      const errorReason = get(errorObject, 'reason', error.toString());

      const paymentErrors = [CHECK_OUT_PAYMENT_ERROR, DECLINE_PAYMENT_ERROR, STATE_PAYMENT_ERROR, POSTAL_CODE_PAYMENT_ERROR];
      const headerError = [BookingErrorsEnum.General, BookingErrorsEnum.RoomsUnavailable, BookingErrorsEnum.SoldOut];
      const errors = [BookingErrorsEnum.InvalidPayload, BookingErrorsEnum.CheckoutError]; 
      if (paymentErrors.includes(errorMessage)) {
        dispatch(setBookingErrorCodeError(errorReason));
        onErrorCallback ? onErrorCallback(errorMessage) : null;
      } else if (headerError.includes(errorMessage)) {
        dispatch(setBookingErrorCodeError(errorMessage));
        onErrorCallback ? onErrorCallback(errorMessage) : null;
      } else if (errors.includes(errorMessage)) {
          dispatch(setBookingErrorCodeError(errorReason));
      } else {
        dispatch(setCondoErrorBooking(errorMessage ? `error.code.${errorMessage}` : errorReason));
      }
    }
  }
}