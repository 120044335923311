import React, { useMemo } from 'react';
import ReactGA from 'react-ga4';

import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { RootState, UrlUtils } from '@share/utils';
import { SearchTypeEnum, ICondoLocation } from '@share/common-types';
import {
  weeksFiltersActions,
  weeksLocationsActions,
  weeksDatesActions,
  GetWeeks,
  weeksActions,
} from '@share/store/slices';
import { ILoginState, LoginType } from '@share/store/slices';
import { CARS_FILTERS_LABEL, WEEKS_RECENT_SEARCHES_LABEL, C_H_CLICK_RECENT_SEARCH, DEFAULT_PAGE_NUMBER } from '@share/constants';

import { WeeksRecentSearchesItem } from '../weeks-recent-searches/component';

import './style.scss';
import moment from 'moment';

export type WeeksSearchHandlerArgs = (
  location: ICondoLocation,
  checkIn: string,
  dateRange: string,
) => void;

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getWeeks: () => void;

  setIsSearch: () => void;
  resetFilters: () => void;
  setPageNumber: () => void;

  setDate: (date: string) => void;
  setPeriod: (period: string) => void;

  setSelectLocation: (location: ICondoLocation) => void;
  setSelectLocationLabel: (label: string) => void;

  resetErrors: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps {}

function WeeksRecentSearchesComponent(props: IProps) {

  const {
    loginStore,
    getWeeks,
    setIsSearch,
    resetFilters,
    setPageNumber,
    setDate,
    setPeriod,
    setSelectLocation,
    setSelectLocationLabel,
    resetErrors,
  } = props;

  const { loading, account } = loginStore;

  const onSearchHandler: WeeksSearchHandlerArgs = (location, checkIn, dateRange) => {

    setPageNumber();
    setIsSearch();

    resetFilters();
    resetErrors();

    UrlUtils.setUrl(CARS_FILTERS_LABEL, null);

    setSelectLocationLabel(location.name);
    setSelectLocation(location);

    setDate(moment(checkIn, 'yyyy-MM-DD').format('MM/DD/yyyy'));
    setPeriod(dateRange);

    getWeeks();
   
    ReactGA.event({
      category: account.name,
      action: `${C_H_CLICK_RECENT_SEARCH}_${account.name.toUpperCase()}`,
      label: `User clicked recent search`,
      nonInteraction: false,
    });
  };

  const recentSearchesStorage = localStorage.getItem(WEEKS_RECENT_SEARCHES_LABEL);

  const recentSearches = useMemo(() => {
    let recentSearches = !isEmpty(recentSearchesStorage) ? JSON.parse(recentSearchesStorage) : [];
    if (recentSearches?.length) {
      const leadTime = account?.leadTime;
      if (leadTime) {
        recentSearches = recentSearches.filter((s: any) => {
          return (moment(s?.propertyRequest?.checkIn, 'yyyy-MM-DD')).isSameOrAfter(moment().add(leadTime, 'days'))
        });
      }
      recentSearches = recentSearches.slice(0, 4);
    }
    return recentSearches;
  }, [recentSearchesStorage]);

  const hasPublicAccessToken = account?.hasPublicAccessToken;
  const hasPublicValidToken = account?.type !== LoginType.Public || !hasPublicAccessToken || !isEmpty(loginStore.accessToken);

  if (!hasPublicValidToken || !recentSearches.length) {
    return null;
  }

  return (
    <div className="cars-recents">
      <h2 className="cars-recents__title">
        <FormattedMessage id="recents.title" />
      </h2>
      <div className="cars-recents__wrapper">
        {recentSearches?.map((item: any, index: number) => (
          <WeeksRecentSearchesItem
            data={item}
            account={account}
            disabled={loading || !hasPublicValidToken}
            key={`${item.propertyRequest.location.id} ${index}`}
            onSearchHandler={onSearchHandler}
          />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  resetFilters: () => {
    dispatch(weeksFiltersActions.resetFilters());
  },

  setSelectLocation: (location: ICondoLocation) => {
    dispatch(weeksLocationsActions.setSelectLocation(location));
  },
  setSelectLocationLabel: (label: string) => {
    dispatch(weeksLocationsActions.setSelectLocationLabel(label));
  },

  setDate: (date: string) => {
    dispatch(weeksDatesActions.setDateSelected(date));
  },
  setPeriod: (period: string) => {
    dispatch(weeksDatesActions.setPeriodSelected(period));
  },

  resetErrors: () => {
    dispatch(weeksLocationsActions.setError(''));    
    dispatch(weeksDatesActions.setErrorDate(''));
    dispatch(weeksDatesActions.setErrorPeriod(''));
  },


  getWeeks: () => {
    dispatch(GetWeeks(SearchTypeEnum.NewSearch));
  },
  setPageNumber: () => {
    dispatch(weeksActions.setPageNumber(DEFAULT_PAGE_NUMBER));
  },
  setIsSearch: () => {
    dispatch(weeksActions.setIsSearch(true));
  },
});

export const WeeksRecentSearches = connect(mapStateToProps, mapDispatchToProps)(WeeksRecentSearchesComponent);
