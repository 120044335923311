import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import { SortTypes, Urls } from '@share/constants';
import {
  IGetaway,
  IGetawaysLocations,
  GetawaysDefaultLocation,
  GetawaysDefaultLocationCountry,
  GetawaysCondoSubCategoryEnum,
  GetawaysLocationEnum,
  defaultLocationValue,
} from '@share/common-types';
import { getHeaders, axiosInstance, getSelectedCurrency } from '@share/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER, USD_CURRENCY } from '@constants';
import { AppThunk } from '@share/utils';
import { GetGetawaysLocationsByIso } from '@utils';
import { get } from 'lodash';

const ZERO = 0;
const ONE = 1;

export interface IGateway {
  getaways: IGetaway[];
  errorGetaways: string;
  loadingGetaways: boolean;
  loadingMoreGetaways: boolean;
  getawaysLocations: IGetawaysLocations[];
  getawaysLocationsOriginal: IGetawaysLocations[];
  getawaysLocationsCountries: IGetawaysLocations[];
  errorGetawaysLocations: string;
  loadingGetawaysLocations: boolean;
  prevGetaways: IGetaway[];
  pageNumber: number;
  pageSize: number | undefined;
  getawaysTotalCount: number;
  sortType: SortTypes;
  filterType: IGetawaysLocations | undefined;
  filterTypeCountry: IGetawaysLocations | undefined;
  promoId: string;
  isGetawaysListPage: boolean;
  activeGetawaySubcategory: string;
  isRequestPopulated: boolean;
}

export interface IGetawaysState {
  newCondos: IGateway;
  premierescapes: IGateway;
  valuegetaways: IGateway;
  staycation: IGateway;
  cruise: IGateway;
  fantasy: IGateway;
  allinclusive: IGateway;
}

export interface GetawayItem {
  getaway: GetawaysLocationEnum;
  subCategory: GetawaysCondoSubCategoryEnum | null;
  show: boolean;
  isInternal: boolean;
}

const initialGewatay: IGateway = {
  getaways: [],
  errorGetaways: '',
  loadingGetaways: false,
  loadingMoreGetaways: false,
  getawaysLocations: [],
  getawaysLocationsOriginal: [],
  getawaysLocationsCountries: [],
  errorGetawaysLocations: '',
  loadingGetawaysLocations: false,
  prevGetaways: [],
  pageNumber: DEFAULT_PAGE_NUMBER,
  pageSize: 20,
  getawaysTotalCount: ZERO,
  sortType: SortTypes.PriceAsc,
  filterType: undefined,
  filterTypeCountry: undefined,
  promoId: '',
  isGetawaysListPage: false,
  activeGetawaySubcategory: '',
  isRequestPopulated: false,
};

export const getawaysInitialState: IGetawaysState = {
  newCondos: { ...initialGewatay, isRequestPopulated: true, activeGetawaySubcategory: GetawaysLocationEnum.NewCondos },
  premierescapes: initialGewatay,
  valuegetaways: initialGewatay,
  staycation: { ...initialGewatay, isRequestPopulated: true, activeGetawaySubcategory: GetawaysLocationEnum.Staycation },
  cruise: { ...initialGewatay, isRequestPopulated: true, activeGetawaySubcategory: GetawaysLocationEnum.Cruise },
  fantasy: { ...initialGewatay, isRequestPopulated: true, activeGetawaySubcategory: GetawaysLocationEnum.Fantasy },
  allinclusive: initialGewatay,
};

// Posible Values for Getaway Type.
export type SubCategory =
  | GetawaysCondoSubCategoryEnum.PremierEscapes
  | GetawaysCondoSubCategoryEnum.ValueGetaways
  | GetawaysLocationEnum.NewCondos
  | GetawaysLocationEnum.Staycation
  | GetawaysLocationEnum.Cruise
  | GetawaysLocationEnum.Fantasy
  | GetawaysLocationEnum.AllInclusive;

const getawaysSlice = createSlice({
  name: 'getaways',
  initialState: getawaysInitialState,
  reducers: {
    setGetaways: (
      state: IGetawaysState,
      {
        payload,
      }: PayloadAction<{ getaways: IGetaway[]; isLoadMore: boolean; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;

      if (!state[subCategory]) {
        return;
      }

      state[subCategory].prevGetaways = payload.getaways;

      if (payload.isLoadMore) {
        state[subCategory].getaways = [...(state[subCategory].getaways || []), ...payload.getaways];
      } else {
        state[payload.subCategory].getaways = payload.getaways;
      }
    },
    setGetawaysTotalCount: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ count: number; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].getawaysTotalCount = payload.count;
    },
    setLoadingGetaways: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ loading: boolean; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].loadingGetaways = payload.loading;
    },
    setLoadingMoreGetaways: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ loading: boolean; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].loadingMoreGetaways = payload.loading;
    },
    setErrorGetaways: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ error: string; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].errorGetaways = payload.error;
    },
    setPageNumber: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ page: number | undefined; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].pageNumber = payload.page
        ? payload.page
        : state[subCategory].pageNumber + ONE;
    },
    setPageSize: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ pageSize: number | undefined; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].pageSize = payload.pageSize;
    },
    setGetawaysLocations: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ location: IGetawaysLocations[]; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].getawaysLocations = payload.location;
    },
    setGetawaysLocationsOriginal: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ location: IGetawaysLocations[]; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].getawaysLocationsOriginal = payload.location;
    },
    setGetawaysLocationsCountries: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ location: IGetawaysLocations[]; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].getawaysLocationsCountries = payload.location;
    },
    setLoadingGetawaysLocations: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ loading: boolean; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].loadingGetawaysLocations = payload.loading;
    },
    setErrorGetawaysLocations: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ error: string; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].errorGetawaysLocations = payload.error;
    },
    setSortType: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ sort: SortTypes; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].sortType = payload.sort;
    },
    setPromoId: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ promo: string; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].promoId = payload.promo;
    },
    setGetawaysListPage: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ listPage: boolean; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }

      state[subCategory].isGetawaysListPage = payload.listPage;
    },
    setFiltrationType: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ type: IGetawaysLocations; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].filterType = payload.type;
    },
    setFiltrationTypeCountry: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ country: IGetawaysLocations; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].filterTypeCountry = payload.country;

      state[subCategory].getawaysLocations = GetGetawaysLocationsByIso(
        payload.country,
        state[subCategory].getawaysLocationsOriginal,
      );
    },
    resetFilters: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].filterType = undefined;
      state[subCategory].promoId = '';
    },
    setDefaultValues: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }

      state[subCategory] = {
        ...getawaysInitialState[subCategory],
        isRequestPopulated: state[subCategory].isRequestPopulated,
        activeGetawaySubcategory: subCategory,
      };
    },
    setActiveGetawaySubCategory: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].activeGetawaySubcategory = subCategory;
    },
    setRequestWithData: (
      state: IGetawaysState,
      { payload }: PayloadAction<{ isRequestPopulated: boolean; subCategory: SubCategory }>,
    ) => {
      const subCategory = payload.subCategory;
      if (!state[subCategory]) {
        return;
      }
      state[subCategory].isRequestPopulated = payload.isRequestPopulated;
    },
  },
});

export const getawaysActions = getawaysSlice.actions;

export const getawaysReducer = getawaysSlice.reducer;

export const ChooseGetaway = (getaway: string, subCategory: SubCategory) =>
  getaway === GetawaysLocationEnum.Condo ? subCategory : (getaway as SubCategory);

export const getGetaways = (
  getaway: string,
  subCategory: SubCategory,
  isLoadMore = false,
  _isSearch = false,
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      if (isLoadMore) {
        dispatch(
          getawaysActions.setLoadingMoreGetaways({
            loading: true,
            subCategory: ChooseGetaway(getaway, subCategory),
          }),
        );
      } else {
        dispatch(
          getawaysActions.setLoadingGetaways({
            loading: true,
            subCategory: ChooseGetaway(getaway, subCategory),
          }),
        );
      }

      const { getawaysStore, loginStore, navigationMenuStore } = getState();

      const { userCoords, account } = loginStore;
      const { items } = navigationMenuStore;

      const isRSITemplate = account?.isRSITemplate;
      const isMLM = items?.isMLM;
      const hasCord = !!userCoords?.latitude && !!userCoords?.longitude;
      const isMLMDeals =
        isMLM && isRSITemplate && (hasCord || getawaysStore.filterTypeCountry?.isoCountry);

      const currency = getSelectedCurrency(account);
      let store = getawaysStore;

      let getawayRequest;
      if ([GetawaysCondoSubCategoryEnum.PremierEscapes, GetawaysCondoSubCategoryEnum.ValueGetaways].includes(subCategory as GetawaysCondoSubCategoryEnum)) {
        store = getawaysStore[subCategory];
      } else if ([GetawaysLocationEnum.AllInclusive].includes(getaway as GetawaysLocationEnum)) {
        store = getawaysStore[getaway];
      } else if ([GetawaysLocationEnum.NewCondos, GetawaysLocationEnum.Staycation, GetawaysLocationEnum.Cruise, GetawaysLocationEnum.Fantasy].includes(getaway as GetawaysLocationEnum)) {
        store = getawaysStore[getaway];
        getawayRequest = getaway;
      }

      let getaways: IGetaway[] = [];
      let totalCount = 0;

      const isSameGetaways = store.filterType?.getaway === getawayRequest;

      if (!store.filterType?.getaway || !getawayRequest || isSameGetaways) {
        const locationRequest = store.filterType ? {
          city: store.filterType?.city ? store.filterType.city : undefined,
          country: store.filterType?.country ? store.filterType?.country : undefined,
          state: store.filterType?.state ? store.filterType.state : undefined,
        } : undefined;

        const payload: any = {
          getaway: getaway === GetawaysLocationEnum.NewCondos ? GetawaysLocationEnum.Condos : getaway,
          subCategory: subCategory,
          sortBy: store.sortType,
          location: locationRequest,
          promoId: store.promoId,
          currency: isEmpty(currency) ? USD_CURRENCY : currency,
          page: {
            size: store.pageSize,
            number: store.pageNumber,
          },
        };

        if (isMLMDeals) {
          if (hasCord) {
            payload.longitude = userCoords?.longitude;
            payload.latitude = userCoords?.latitude;
          }
          if (!isEmpty(getawaysStore[subCategory ?? getaway]?.filterTypeCountry?.isoCountry)) {
            payload.isoCountry = getawaysStore[subCategory ?? getaway].filterTypeCountry?.isoCountry;
          }
        }

        const url = Urls.Getaways;

        const { data } = await axiosInstance.post(url, payload, { ...getHeaders() });

        getaways = data.deals;
        totalCount = data.totalCount;

        const validPromo = (!isEmpty(store.promoId) && getaway === GetawaysLocationEnum.Staycation) ? false: true;
        dispatch(
          getawaysActions.setRequestWithData({
            isRequestPopulated: getaways.length > 0 && validPromo ? true : false,
            subCategory: ChooseGetaway(getaway, subCategory),
          }),
        );
      }

      dispatch(
        getawaysActions.setGetaways({
          getaways,
          isLoadMore,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );

      if (!isLoadMore) {
        dispatch(
          getawaysActions.setGetawaysTotalCount({
            count: totalCount,
            subCategory: ChooseGetaway(getaway, subCategory),
          }),
        );
      }

      dispatch(
        getawaysActions.setLoadingGetaways({
          loading: false,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );

      dispatch(
        getawaysActions.setLoadingMoreGetaways({
          loading: false,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );
    } catch (error: any) {
      dispatch(
        getawaysActions.setErrorGetaways({
          error: error.toString(),
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );
      dispatch(
        getawaysActions.setLoadingGetaways({
          loading: false,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );
      dispatch(
        getawaysActions.setLoadingMoreGetaways({
          loading: false,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );
    }
  };
};

export const resetGetaway = (
  getaway: string,
  subCategory: SubCategory,
  isLoadMore = false,
): AppThunk => {
  return async (dispatch) => {
    const getaways: IGetaway[] = [];
    dispatch(
      getawaysActions.setGetaways({
        getaways,
        isLoadMore,
        subCategory: ChooseGetaway(getaway, subCategory),
      }),
    );

    dispatch(
      getawaysActions.setRequestWithData({
        isRequestPopulated: false,
        subCategory: ChooseGetaway(getaway, subCategory),
      }),
    );

    dispatch(
      getawaysActions.setGetawaysTotalCount({
        count: 0,
        subCategory: ChooseGetaway(getaway, subCategory),
      }),
    );
  };
};

export const allGetaways: GetawayItem[] = [
  {
    getaway: GetawaysLocationEnum.NewCondos,
    subCategory: null,
    show: true,
    isInternal: true,
  },
  {
    getaway: GetawaysLocationEnum.Staycation,
    subCategory: null,
    show: true,
    isInternal: true,
  },
  {
    getaway: GetawaysLocationEnum.Cruise,
    subCategory: null,
    show: true,
    isInternal: true,
  },
  {
    getaway: GetawaysLocationEnum.Fantasy,
    subCategory: null,
    show: true,
    isInternal: true,
  },
];

export const resetAllFilters = (): AppThunk => {
  return async (dispatch) => {
    try {
      //Map for clean and charge the state to the first call loop
      allGetaways.map((getaway) => {
        dispatch(
          getawaysActions.setDefaultValues({
            subCategory: ChooseGetaway(getaway.getaway, getaway.subCategory as SubCategory),
          }),
        );

        dispatch(getGetaways(getaway.getaway, getaway.subCategory as SubCategory, false, false));

        dispatch(getGetawaysLocations(getaway.getaway, getaway.subCategory as SubCategory));
      });
    } catch (error: any) {
      allGetaways.map((getaway) => {
        dispatch(
          getawaysActions.setErrorGetaways({
            error: error.toString(),
            subCategory: ChooseGetaway(getaway.getaway, getaway.subCategory as SubCategory),
          }),
        );
        dispatch(
          getawaysActions.setLoadingGetaways({
            loading: false,
            subCategory: ChooseGetaway(getaway.getaway, getaway.subCategory as SubCategory),
          }),
        );
        dispatch(
          getawaysActions.setLoadingMoreGetaways({
            loading: false,
            subCategory: ChooseGetaway(getaway.getaway, getaway.subCategory as SubCategory),
          }),
        );
      });
    }
  };
};

export const getGetawaysLocations = (getaway: string, subCategory: SubCategory): AppThunk => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        getawaysActions.setLoadingGetawaysLocations({
          loading: false,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );

      await handleGetawaysLocations(dispatch, getState, getaway, subCategory, false);

      dispatch(
        getawaysActions.setLoadingGetawaysLocations({
          loading: false,
          subCategory: ChooseGetaway(getaway, subCategory),
        }),
      );
    } catch (error: any) {
      const code = get(error, 'response.data.code');
      if (['LOCATIONS_NOT_FOUND', 'RSI_IS_NOT_MLM'].includes(code)) {
        await handleGetawaysLocations(dispatch, getState, getaway, subCategory, true);
      } else {
        dispatch(
          getawaysActions.setErrorGetawaysLocations({
            error: error.toString(),
            subCategory: ChooseGetaway(getaway, subCategory),
          }),
        );
        dispatch(
          getawaysActions.setLoadingGetawaysLocations({
            loading: false,
            subCategory: ChooseGetaway(getaway, subCategory),
          }),
        );
      }
    }
  };
};

export const handleGetawaysLocations = async (
  dispatch: any,
  getState: any,
  getaway: string,
  subCategory: SubCategory,
  excludeCountries: boolean,
) => {
  const { loginStore, navigationMenuStore } = getState();

  const { userCoords, account } = loginStore;
  const { items } = navigationMenuStore;

  const isRSITemplate = account?.isRSITemplate;
  const isMLM = items?.isMLM;
  const isMLMDeals = isMLM && isRSITemplate && userCoords?.latitude && userCoords?.longitude;
  const isNewCondo = getaway === GetawaysLocationEnum.NewCondos;
  const selectedGetaway = isNewCondo ? GetawaysLocationEnum.Condos : getaway;

  const url =
    isMLMDeals && !excludeCountries
      ? `${Urls.GetawaysMLM}?longitude=${userCoords?.longitude}&latitude=${userCoords?.latitude}`
      : `${Urls.Getaways}/${selectedGetaway}/locations`;

  const params: any = {};
  if (!isNewCondo) {
    params.subCategory = ChooseGetaway(selectedGetaway, subCategory);
  }

  const { data } = await axiosInstance.get(url, {
    params,
    ...getHeaders(),
  });

  if (data) {
    const filtrationList = [GetawaysDefaultLocation, ...data];
    dispatch(
      getawaysActions.setGetawaysLocations({
        location: filtrationList,
        subCategory: ChooseGetaway(getaway, subCategory),
      }),
    );
    dispatch(
      getawaysActions.setGetawaysLocationsOriginal({
        location: filtrationList,
        subCategory: ChooseGetaway(getaway, subCategory),
      }),
    );

    if (!excludeCountries) {
      dispatch(handleGetawaysCountries(data, ChooseGetaway(getaway, subCategory)));
    }
  }
};

export const handleGetawaysCountries = (data: any[], subCategory: SubCategory): AppThunk => {
  return async (dispatch) => {
    const hasSpecifiedCountry = data?.filter((c: any) => !isEmpty(c?.country)).length > 0;

    var dataUnique = uniqBy(
      data?.filter((c: any) => !isEmpty(c?.country)),
      'isoCountry',
    );

    const countryList = dataUnique.map((c: any) => ({
      country: c?.country,
      isoCountry: c?.isoCountry,
    }));

    let filtrationCountryList: any =
      countryList?.length && hasSpecifiedCountry
        ? Array.from(new Set(countryList)).map((c: any) => ({
            country: c?.country,
            isoCountry: c?.isoCountry,
          }))
        : [];

    if (filtrationCountryList?.length > 1) {
      filtrationCountryList = [GetawaysDefaultLocationCountry, ...filtrationCountryList];
      dispatch(
        getawaysActions.setGetawaysLocationsCountries({
          location: filtrationCountryList,
          subCategory,
        }),
      );
    }
  };
};
