import React, { useEffect, useRef } from 'react';

import { scrollTop } from '@share/utils';

import './style.scss';
import ArrowDownBanner from '@assets/images/mlm-levalti/new-agents/arrow-down-banner.svg';
import CertifiedSpecialist from '@assets/images/mlm-levalti/training/certified-specialist.png';
import CruiseImg from '@assets/images/mlm-levalti/training/image-asset.jpeg';
import SpecialityLogoImg from '@assets/images/mlm-levalti/training/speciality-travel-brand-logos.png';
import WorkingImg from '@assets/images/mlm-levalti/training/working-from-home.png';
import AgentDeskImg from '@assets/images/mlm-levalti/training/home-based-travel-agent-at-desk.png';
import { FormattedMessage } from 'react-intl';

export default function MLMTraining() {
  useEffect(() => {
    scrollTop();
  }, []);

  const startedSectionRef = useRef<HTMLDivElement>(null);
  //Smooth Scroll Click
  const scrollToSection = () => {
    if (startedSectionRef.current) {
      startedSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="training">
      {/*Header*/}
      <header className="training__header">
        <section className="training__header-title">
          <h1 className="training__header-title-main">
            <span className="training__header-title-main-subtitle1">
              <FormattedMessage id="training.agent.title" />
            </span>{' '}
            <span className="training__header-title-main-subtitle2">
              <FormattedMessage id="training.agent.subtitle" />
            </span>
          </h1>
          <img
            src={CertifiedSpecialist}
            alt="Certified Header Img"
            className="training__header-title-main-img"
          />
        </section>
        <section className="training__header-arrow">
          <img
            src={ArrowDownBanner}
            alt="arrow down"
            onClick={scrollToSection}
            className="move-down-on-hover"
          />
        </section>
      </header>
      <section className="training__main-section" ref={startedSectionRef}>
        {/*First Section*/}
        <section className="training__main-section-agent">
          <section className="training__main-section-agent-one">
            <h2>Successful Agent Training & Tools </h2>
            <p>
              TravNow Advisors offers a wide array of training and certification opportunities to
              help you sell like a pro. Training includes a variety of topics like personal sales
              and marketing, techniques on booking travel, and more. Whether you are new to the
              business, or a seasoned professional, TravNow Advisors is here to insure that you have
              what it takes to be successful.
            </p>
          </section>
          <section className="training__main-section-agent-two">
            <section className="training__main-section-agent-two-img">
              <img src={CruiseImg} alt="Cruise Img" />
            </section>
            <section className="training__main-section-agent-two-content">
              <section className="training__main-section-agent-two-content-text">
                <h2 className="training__main-section-agent-two-content-text-title">
                  <span>Become</span>{' '}
                  <span className="training__main-section-agent-two-content-text-title-bold">
                    Certified To Sell Top Brands
                  </span>
                </h2>
                <p>
                  It is time to change your life with the best Home Based Business Opportunity
                  anywhere. Make yourself and your clients happy while making money! When you do
                  something you love, it isn't work! TravNow Advisors receive exclusive access to
                  specialty training to become certified to sell a number of big-name travel
                  companies like Disney, Carnival, Sandals, and more.{' '}
                </p>
              </section>
              <section className="training__main-section-agent-two-content-img">
                <img src={SpecialityLogoImg} alt="Speciality Logo Img" />
              </section>
            </section>
          </section>
        </section>
        {/*Second Section*/}
        <section className="training__main-section-training">
          <section className="training__main-section-training-container">
            <section className="training__main-section-training-container-img">
              <img src={WorkingImg} alt="Working Img" />
            </section>
            <section className="training__main-section-training-container-content">
              <h2 className="training__main-section-training-container-content-title">
                <span className="training__main-section-training-container-content-title-bold">
                  New Agent
                </span>{' '}
                <span>Training</span>
              </h2>
              <p>
                As a travel agent, you are a home-based entrepreneur, and as such, you need to have
                proper training in how to run your business. Our TravNow Advisors training and
                support programs don’t just focus on providing you sales and marketing assistance,
                but really teach you how to build value in your travel products as well as real
                travel marketing skills.{' '}
              </p>
              <p>
                TravNow Advisors provides in-depth supplier training that is customized to your
                agency and to what type of travel your business wants to sell, not just generic
                programs that don’t fit your business needs. For those needing individual support,
                our dedicated and knowledgeable support staff can help you with any issue that you
                may encounter.{' '}
              </p>
              <ul>
                <li>
                  We offer training in general business skills, business operational skills, and
                  educational opportunities around the use of technologies that our platform
                  provides for you.{' '}
                </li>
                <li>
                  While TravNow Advisors does offer the option of in-person one-on-one coaching and
                  online agent forums, most of our agent training is available to you 24/7 through a
                  series of in-depth training videos on our award-winning travel platform.
                </li>
              </ul>
              <button>LEARN MORE</button>
            </section>
          </section>
        </section>
      </section>
    </div>
  );
}
