import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { IMenuState } from '@share/store/slices';
import { BlueButton } from '@share/components';
import { RootState } from '@share/utils';
import { MY_VC_ACCOUNT_LINK } from '@constants';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

const ZERO = 0;

class ShortUserInfoWrapperComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { menuStore } = this.props;

    return menuStore?.items ? (
      <div className="short-user-info">
        <div className="short-user-info__wrapper">
          <div className="short-user-info__user-info">
            <div className="short-user-info__user-info-icon-wrapper">
              <p className="short-user-info__user-info-icon-text">
                {menuStore.items.firstName[ZERO]}
              </p>
            </div>
            <div className="short-user-info__user-info-text-wrapper">
              <p className="short-user-info__user-info-text-name">
                {menuStore.items.firstName} {menuStore.items.lastName}
              </p>
              <p className="short-user-info__user-info-text-id">
                <FormattedMessage id="id" /> {menuStore.items.rsiId}
              </p>
            </div>
          </div>
          <div className="short-user-info__user-info">
            <a
              href={MY_VC_ACCOUNT_LINK}
              className="short-user-info__btn-wrapper"
              target="_blank"
              rel="noreferrer"
            >
              <BlueButton>
                <FormattedMessage id="renew.membership" />
              </BlueButton>
            </a>
          </div>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const ShortUserInfoWrapper = connect(mapStateToProps)(ShortUserInfoWrapperComponent);

