import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ProductsEnum } from '@constants';
import { Routes } from '@share/constants';
import { CheckCircleSvg } from '@assets';
import { WhiteButton } from '@components';
import { BlueButton } from '@share/components';
import { IAdminPresentationRulesState, IAdminPriceRulesState } from '@store/slices';
import { RootState } from '@share/utils';

import './style.scss';

interface IMapStateToProps {
  adminPriceRules: IAdminPriceRulesState;
  adminPresentationRules: IAdminPresentationRulesState;
}

interface IProps extends IMapStateToProps, RouteComponentProps, WrappedComponentProps {
  createAnotherRule: () => void;
  isPresentationRule?: boolean;
}

class SuccessfullyNewRuleComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { intl, createAnotherRule, adminPriceRules, adminPresentationRules, isPresentationRule } =
      this.props;

    return (
      <div className="successfully-rule">
        <CheckCircleSvg />
        <p className="successfully-rule__title">
          <FormattedMessage id="successfully" />
        </p>
        <p className="successfully-rule__description">
          <FormattedMessage
            id="rule.been.created"
            values={{
              ruleId: isPresentationRule
                ? adminPresentationRules.savingNewPresentationRule.id
                : adminPriceRules.savingNewPriseRule.id,
              product:
                adminPriceRules.product === ProductsEnum.Condo
                  ? intl.formatMessage({ id: 'condos.title' })
                  : intl.formatMessage({ id: 'hotels.title' }),
            }}
          />
        </p>
        <div className="successfully-rule__btn-wrapper">
          <Link to={isPresentationRule ? Routes.PresentationRules : Routes.PriceRules}>
            <BlueButton>
              <FormattedMessage id="go.to.all" />
            </BlueButton>
          </Link>
          <WhiteButton onClick={createAnotherRule}>
            <FormattedMessage id="create.one.more.rule" />
          </WhiteButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    adminPriceRules: state.adminPriceRulesStore,
    adminPresentationRules: state.adminPresentationRulesStore,
  };
};

export const SuccessfullyNewRule = connect(
  mapStateToProps,
  null,
)(withRouter(injectIntl(SuccessfullyNewRuleComponent)));

