import React, { useEffect } from 'react';

import { scrollTop } from '@share/utils';

import './style.scss';

export default function MLMBecomeTravelAgent() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className="mlm-become-travel-agent-page">
      <div className="mlm-become-travel-agent-page__header">

      </div>

      <div className="Site-inner Site-inner--index" data-controller="HeaderOverlay">
        <div className="Content-outer">
          <main className="Index" data-collection-id="6143628ed9faad641927e532" data-controller="IndexFirstSectionHeight, Parallax, IndexNavigation">
            <section id="welcome" className="Index-page Index-page--has-image" data-parallax-original-element data-collection-id="5e6d4250fd82d42cb24dbdaf" data-parallax-id="5e6d4250fd82d42cb24dbdaf" data-edit-main-image="Background">
              <div className="Index-page-scroll-indicator hidden" data-controller="ScrollIndicator">
                <div className="Index-page-scroll-indicator-text">Scroll</div>
                <svg className="Index-page-scroll-indicator-arrow Icon Icon--caretLarge--down" viewBox="0 0 48 23">
                  {/*<use xlink:href="/assets/ui-icons.svg#caret-down-large-icon"/>*/}
                </svg>
                <div className="Index-page-scroll-indicator-line"/>
              </div>

              <div className="Index-page-content  sqs-alternate-block-style-container">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1587403330469" id="page-5e6d4250fd82d42cb24dbdaf">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="17.006802721088434" data-block-type="21" id="block-yui_3_17_2_1_1585664991618_4978">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>

                      <div className="row sqs-row">
                        <div className="col sqs-col-5 span-5">
                          <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-985df222299ec0a7ef96">
                            <div className="sqs-block-content">
                              <div className="sqs-html-content">
                                <h1 style={{ whiteSpace: 'pre-wrap' }}>
                                  <strong>Conquer the World. </strong>Become an Independent Travel Agent</h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col sqs-col-5 span-5">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1584381700004_5159">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>

                        <div className="col sqs-col-2 span-2">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1587403125374_10293">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>
                      </div>

                      <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="22.58503401360544" data-block-type="21" id="block-yui_3_17_2_1_1585664991618_5626">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <figure className="Index-page-image" data-parallax-image-wrapper>
                <div data-controller="VideoBackground">
                  <div className="sqs-video-background content-fill" data-config-url="https://vimeo.com/497753632" data-config-playback-speed="1" data-config-filter="1" data-config-filter-strength="50">
                    <img data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586525403218-FLOTJMTH1XGWEZ6C7OFG/ban-hands-up.jpg" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586525403218-FLOTJMTH1XGWEZ6C7OFG/ban-hands-up.jpg" data-image-dimensions="1920x832" data-image-focal-point="0.5,0.5" alt="ban-hands-up.jpg" className="custom-fallback-image" data-load="false"/>
                    <div id="player"/>
                  </div>
                </div>
              </figure>
            </section>

            <section id="why-become-an-agent" className="Index-page" data-collection-id="5e7caf78470b794462618c87" data-parallax-id="5e7caf78470b794462618c87" data-edit-main-image="Background">
              <div className="Index-page-content ">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1632507375594" id="page-5e7caf78470b794462618c87">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="row sqs-row">
                        <div className="col sqs-col-1 span-1">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1586469950881_14026">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>
                        <div className="col sqs-col-7 span-7">
                          <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1586467860773_56475">
                            <div className="sqs-block-content">
                              <div className="sqs-html-content">
                                <h1 style={{ whiteSpace: 'pre-wrap' }}>
                                  <strong>Travel Further</strong> with TravNow</h1>
                                <p className="" style={{ whiteSpace: 'pre-wrap' }}>There has never been&nbsp;a better time to be a professional travel agent. TravNow Advisors has made it easy and more fulfilling to sell travel, make money, and earn amazing travel benefits as a <strong>Home-Based Travel Advisor</strong> (Travel Agent).</p>
                                <p className="" style={{ whiteSpace: 'pre-wrap' }}>For decades, the team behind TravNow Advisors has been immersed in travel benefits and empowering entrepreneurs with industry-leading education, marketing, and technology. Whether you are an experienced travel agent, or a new to the travel business, TravNow offers you the tools and support to <strong>start your own home-based travel agency</strong>.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col sqs-col-3 span-3">
                          <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1609966830387_24207">
                            <div className="sqs-block-content">
                              <div className="sqs-html-content">
                                <h3 style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>Reach Us<br />
                                    <strong>1-866-723-6975</strong>
                                  </h3>
                                </div>
                              </div>
                            </div>
                            <div className="sqs-block button-block sqs-block-button" data-block-type="53" id="block-yui_3_17_2_1_1609941651161_12177">
                              <div className="sqs-block-content">
                                <div className="sqs-block-button-container sqs-block-button-container--center" data-animation-role="button" data-alignment="center" data-button-size="medium" data-button-type="primary">
                                  <a href="https://travadvisors.com" className="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element">
  Get Started
  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col sqs-col-1 span-1">
                            <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1586467860773_57457">
                              <div className="sqs-block-content">&nbsp;</div>
                            </div>
                          </div>
                        </div>
                        <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585257269734_9751">
                          <div className="sqs-block-content">&nbsp;</div>
                        </div>
                        <div className="row sqs-row">
                          <div className="col sqs-col-5 span-5">
                            <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-yui_3_17_2_1_1585247690230_8510">
                              <div className="sqs-block-content">
                                <div className="
  image-block-outer-wrapper
  layout-caption-below
  design-layout-inline
  combination-animation-none
  individual-animation-none
  individual-text-animation-none
  " data-test="image-block-inline-outer-wrapper">
                                  <figure className="
    sqs-block-image-figure
    intrinsic
  " style={{ maxWidth: '845px' }}>
                                    <div className="image-block-wrapper" data-animation-role="image">
                                      <div className="sqs-image-shape-container-element
    


    has-aspect-ratio
  " style={{ position: 'relative', paddingBottom: '84.73373413085938%', overflow: 'hidden' }}>
                                        <img data-stretch="false" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1612885589268-ZW8F1TEP6LU7F8DT8OPK/home-based-travel-agent-at-desk.JPG" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1612885589268-ZW8F1TEP6LU7F8DT8OPK/home-based-travel-agent-at-desk.JPG" data-image-dimensions="845x716" data-image-focal-point="0.5,0.5" alt="home-based-travel-agent-at-desk.JPG" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1612885589268-ZW8F1TEP6LU7F8DT8OPK/home-based-travel-agent-at-desk.JPG" width="845" height="716" sizes="(max-width: 640px) 100vw, (max-width: 767px) 41.66666666666667vw, 41.66666666666667vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />

  </div>
                                      </div>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col sqs-col-7 span-7">
                              <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1585243397543_10564">
                                <div className="sqs-block-content">
                                  <div className="sqs-html-content">
                                    <h2 style={{ whiteSpace: 'pre-wrap' }}>Start Earning an <em>At-Home</em>
                                      <strong> Income</strong>
                                    </h2>
                                    <p className="" style={{ whiteSpace: 'pre-wrap' }}>Owning your own business is rewarding, and TravNow Advisors is here every step of the way. TravNow Advisors has made it easy and enjoyable to sell travel, earn an income, and experience travel as a <strong>Home-Based Travel Advisor</strong>. Our Host Agency program is great for the experienced agent, or someone just starting out in the business.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="sqs-block code-block sqs-block-code" data-block-type="23" id="block-yui_3_17_2_1_1585593119587_10069">
                                <div className="sqs-block-content">
                                  <ul style={{ lineHeight: '26px' }}>
                                    <li>Top Agent Commissions and Incentives</li>
                                    <li>Set Your Own Commission Margins on most Bookings</li>
                                    <li>Access to Top Travel Suppliers</li>
                                    <li>Training & Agent Support</li>
                                    <li>Marketing & Lead Generation Tools</li>
                                    <li>Agent Dashboard and Accounting</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="row sqs-row">
                                <div className="col sqs-col-2 span-2">
                                  <div className="sqs-block button-block sqs-block-button" data-block-type="53" id="block-yui_3_17_2_1_1585246555713_30332">
                                    <div className="sqs-block-content">
                                      <div className="sqs-block-button-container sqs-block-button-container--center" data-animation-role="button" data-alignment="center" data-button-size="small" data-button-type="tertiary">
                                        <a href="/new-agents" className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element">
  New Agents
  </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col sqs-col-3 span-3">
                                  <div className="sqs-block button-block sqs-block-button" data-block-type="53" id="block-yui_3_17_2_1_1586469950881_20555">
                                    <div className="sqs-block-content">
                                      <div className="sqs-block-button-container sqs-block-button-container--center" data-animation-role="button" data-alignment="center" data-button-size="small" data-button-type="tertiary">
                                        <a href="/experienced-agents" className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element">
  Experienced Agents
  </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col sqs-col-2 span-2">
                                  <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1586469950881_28536">
                                    <div className="sqs-block-content">&nbsp;</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="0.1360544217687075" data-block-type="21" id="block-yui_3_17_2_1_1585243397543_10501">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                          <div className="row sqs-row">
                            <div className="col sqs-col-1 span-1">
                              <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1586469950881_15796">
                                <div className="sqs-block-content">&nbsp;</div>
                              </div>
                            </div>
                            <div className="col sqs-col-6 span-6">
                              <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1585243397543_7565">
                                <div className="sqs-block-content">
                                  <div className="sqs-html-content">
                                    <h2 style={{ whiteSpace: 'pre-wrap' }}>Rewarding <strong>Agent Lifestyle Benefits</strong>
                                    </h2>
                                    <p className="" style={{ whiteSpace: 'pre-wrap' }}>As part of your membership, TravNow Advisors can also take part in travel and leisure vacations. Host Agents receive a Lifestyle Benefits Package including hotel, cruise, and flight perks, PLUS discounts on many dining and shopping favorites.</p>
                                  </div>
                                </div>
                              </div>
                              <div className="sqs-block code-block sqs-block-code" data-block-type="23" id="block-yui_3_17_2_1_1585593119587_26489">
                                <div className="sqs-block-content">
                                  <ul style={{ lineHeight: '26px' }}>
                                    <li>Exclusive Agent Travel Discounts</li>
                                    <li>Host Agent Upgrades & Incentives</li>
                                    <li>Familiarization Tours</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="sqs-block button-block sqs-block-button" data-block-type="53" id="block-yui_3_17_2_1_1588096143381_11386">
                                <div className="sqs-block-content">
                                  <div className="sqs-block-button-container sqs-block-button-container--center" data-animation-role="button" data-alignment="center" data-button-size="small" data-button-type="tertiary">
                                    <a href="/exclusive-access" className="sqs-block-button-element--small sqs-button-element--tertiary sqs-block-button-element">
  Learn more
  </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col sqs-col-5 span-5">
                              <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-yui_3_17_2_1_1585247690230_33783">
                                <div className="sqs-block-content">
                                  <div className="
  image-block-outer-wrapper
  layout-caption-below
  design-layout-inline
  combination-animation-none
  individual-animation-none
  individual-text-animation-none
  " data-test="image-block-inline-outer-wrapper">
                                    <figure className="
    sqs-block-image-figure
    intrinsic
  " style={{ maxWidth: '542px' }}>
                                      <div className="image-block-wrapper" data-animation-role="image">
                                        <div className="sqs-image-shape-container-element
    


    has-aspect-ratio
  " style={{ position: 'relative', paddingBottom: '91.88191986083984%', overflow: 'hidden' }}>
                                          <img data-stretch="false" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586899345780-ZZTRJ99OD28SOJYGVKFX/couple-on-vacatio-holding-map.png" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586899345780-ZZTRJ99OD28SOJYGVKFX/couple-on-vacatio-holding-map.png" data-image-dimensions="542x498" data-image-focal-point="0.5,0.5" alt="couple-on-vacatio-holding-map.png" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586899345780-ZZTRJ99OD28SOJYGVKFX/couple-on-vacatio-holding-map.png" width="542" height="498" sizes="(max-width: 640px) 100vw, (max-width: 767px) 41.66666666666667vw, 41.66666666666667vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />

  </div>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
            </section>

            <section id="get-started-1" className="Index-page Index-page--has-image" data-parallax-original-element data-collection-id="5e8f93c07755817539bd6be0" data-parallax-id="5e8f93c07755817539bd6be0" data-edit-main-image="Background">
              <div className="Index-page-content  sqs-alternate-block-style-container">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1611088882050" id="page-5e8f93c07755817539bd6be0">
                  <div className="row sqs-row">
                    <div className="col sqs-col-6 span-6">
                      <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-8998a1d0fc7501766b37">
                        <div className="sqs-block-content">
                          <figure className="
sqs-block-image-figure
image-block-outer-wrapper
image-block-v2
design-layout-poster
combination-animation-none
individual-animation-none
individual-text-animation-none
image-position-left
image-linked
" data-scrolled data-test="image-block-v2-outer-wrapper">
                            <div className="intrinsic">
                              <div className="

image-inset" data-animation-role="image" data-description="">
                                <div className="sqs-image-shape-container-element

content-fill has-aspect-ratio

" style={{ position: 'relative', paddingBottom: '55.40287399291992%', overflow: 'hidden' }}>
                                    <img data-stretch="false" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586473301214-7FJKNYHLDVTZO3AFJV5C/new-agent-starting.jpg" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586473301214-7FJKNYHLDVTZO3AFJV5C/new-agent-starting.jpg" data-image-dimensions="1601x887" data-image-focal-point="0.5,0.5" alt="new-agent-starting.jpg" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586473301214-7FJKNYHLDVTZO3AFJV5C/new-agent-starting.jpg" width="1601" height="887" sizes="100vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />
                                    <div className="image-overlay" style={{ overflow: 'hidden' }}/>
                                  </div>
                                </div>
                              </div>
                              <figcaption className="image-card-wrapper" data-width-ratio>
                              <div className="image-card sqs-dynamic-text-container">
                                <div className="image-button-wrapper">
                                  <div className="image-button sqs-dynamic-text">
                                    <div className="image-button-inner">
                                      <a href="/new-agents" className="sqs-button-element--primary">I am new to this</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                      <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-a8f7161be04120688ea1">
                        <div className="sqs-block-content">
                          <div className="sqs-html-content">
                            <h3 style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                              <a href="/new-agents">Just Getting Started as an Agent</a>
                            </h3>
                            <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }} className="">We are very excited about the life and opportunities that await you. Trained Travel Agents are in great need today. The internet allows people to read about and find travel; however, customers need insight, knowledge, and help to create truly great vacations at the right price.</p>
                          </div>
                        </div>
                      </div>
                      <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="3.1654676258992804" data-block-type="21" id="block-2aafd21122acaa8c4cd8">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>
                    </div>
                    <div className="col sqs-col-6 span-6">
                      <div className="sqs-block image-block sqs-block-image" data-aspect-ratio="55.19031141868512" data-block-type="5" id="block-acb4d6c4ff0298aaaf75">
                        <div className="sqs-block-content">
                          <figure className="
sqs-block-image-figure
image-block-outer-wrapper
image-block-v2
design-layout-poster
combination-animation-none
individual-animation-none
individual-text-animation-none
image-position-left
image-linked
" data-scrolled data-test="image-block-v2-outer-wrapper">
                            <div className="intrinsic">
                              <div className="

image-inset" data-animation-role="image" data-description="">
                                <div className="sqs-image-shape-container-element

content-fill has-aspect-ratio

" style={{ position: 'relative', paddingBottom: '55.190311431884766%', overflow: 'hidden' }}>
                                    <img data-stretch="false" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1611088850502-EIN8AEZVHC77XTIE0BQT/blue-outfit-woman-at-white-desk.PNG" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1611088850502-EIN8AEZVHC77XTIE0BQT/blue-outfit-woman-at-white-desk.PNG" data-image-dimensions="826x589" data-image-focal-point="0.5,0.5" alt="blue-outfit-woman-at-white-desk.PNG" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1611088850502-EIN8AEZVHC77XTIE0BQT/blue-outfit-woman-at-white-desk.PNG" width="826" height="589" sizes="100vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />
                                    <div className="image-overlay" style={{ overflow: 'hidden' }}/>
                                  </div>
                                </div>
                              </div>
                              <figcaption className="image-card-wrapper" data-width-ratio>
                              <div className="image-card sqs-dynamic-text-container">
                                <div className="image-button-wrapper">
                                  <div className="image-button sqs-dynamic-text">
                                    <div className="image-button-inner">
                                      <a href="/experienced-agents" className="sqs-button-element--primary">I am an experienced Agent</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                      <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-a56b914c7f725f5dc4d1">
                        <div className="sqs-block-content">
                          <div className="sqs-html-content">
                            <h3 style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                              <a href="/experienced-agents">Experienced Agent Ready to Start</a>
                            </h3>
                            <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }} className="">You owe it to yourself to check out a new alternative with TravNow Advisors. Better agent offerings for your personal vacations. Unique offerings include a wide array of condo and villa availability with unmatched pricing.&nbsp;TravNow Advisors allows you to set your own commission margins on most bookings.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figure className="Index-page-image" data-parallax-image-wrapper>
              <img data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1587154443899-8X3FQA017D29OVC3GUKB/bg-blue2.jpg" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1587154443899-8X3FQA017D29OVC3GUKB/bg-blue2.jpg" data-image-dimensions="1920x832" data-image-focal-point="0.5,0.5" alt="bg-blue2.jpg" data-load="false" />
            </figure>
            </section>

            <section id="vacation-life-video" className="Index-page Index-page--has-image" data-parallax-original-element data-collection-id="5e712e2a387956601cc86496" data-parallax-id="5e712e2a387956601cc86496" data-edit-main-image="Background">
              <div className="Index-page-content  sqs-alternate-block-style-container">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1632507389099" id="page-5e712e2a387956601cc86496">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="0.1360544217687075" data-block-type="21" id="block-719f0c82fb1f919f3c72">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>
                      <div className="row sqs-row">
                        <div className="col sqs-col-6 span-6">
                          <div className="sqs-block video-block sqs-block-video" data-block-json="&#123;&quot;blockAnimation&quot;:&quot;none&quot;,&quot;layout&quot;:&quot;caption-hidden&quot;,&quot;overlay&quot;:true,&quot;description&quot;:&#123;&quot;html&quot;:&quot;&quot;&#125;,&quot;hSize&quot;:null,&quot;floatDir&quot;:null,&quot;customThumb&quot;:&quot;5e946c4472f3495f040ed564&quot;,&quot;html&quot;:&quot;&lt;iframe src=\&quot;https://player.vimeo.com/video/406332676?app_id=122963&amp;amp;wmode=opaque\&quot; width=\&quot;426\&quot; height=\&quot;240\&quot; frameborder=\&quot;0\&quot; allow=\&quot;autoplay; fullscreen\&quot; allowfullscreen=\&quot;\&quot; title=\&quot;TravAdvisors\&quot;&gt;&lt;/iframe&gt;&quot;,&quot;url&quot;:&quot;https://vimeo.com/406332676&quot;,&quot;thumbnailUrl&quot;:&quot;https://i.vimeocdn.com/video/876784406_295x166.jpg&quot;,&quot;resolvedBy&quot;:&quot;vimeo&quot;&#125;" data-block-type="32" id="block-yui_3_17_2_1_1585228264069_7372">
                            <div className="sqs-block-content">
                              <div className="sqs-video-wrapper" data-provider-name="" data-html="&lt;iframe src=&quot;https://player.vimeo.com/video/406332676?app_id=122963&amp;wmode=opaque&quot; width=&quot;426&quot; height=&quot;240&quot; frameborder=&quot;0&quot; allow=&quot;autoplay; fullscreen&quot; allowfullscreen=&quot;&quot; title=&quot;TravAdvisors&quot;&gt;&lt;/iframe&gt;">
                                <div className="sqs-video-overlay" style={{ opacity: 0 }}>
                                  <img data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586785348991-CFKFIT7MCXCLRXVQE2PD/video-cover.JPG" data-load="false" data-image-focal-point="0.5,0.5"/>
                                  <div className="sqs-video-opaque"/>
                                  <div className="sqs-video-icon"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sqs-block code-block sqs-block-code" data-block-type="23" id="block-yui_3_17_2_1_1587485252935_10933">
                            <div className="sqs-block-content">
                              <p style={{ fontSize: '24px', lineHeight: '30px', color: '#ffffff', fontWeight: 500, letterSpacing: '-0.01px' }}>Tap Video to Watch</p>
                            </div>
                          </div>
                        </div>
                        <div className="col sqs-col-6 span-6">
                          <div className="sqs-block code-block sqs-block-code" data-block-type="23" id="block-yui_3_17_2_1_1585243397543_38816">
                            <div className="sqs-block-content">
                              <h1 style={{ fontSize: '50px', lineHeight: '50px', color: '#ffffff', fontWeight: 400, letterSpacing: '-0.01px' }}>
                                Looking for <b>a change?</b>
                              </h1>
                              <p style={{ fontSize: '30px', lineHeight: '24px', color: '#ffffff', fontWeight: 500, letterSpacing: '-0.01px' }}>
                                Raise your level of success and achieve your goals, both <b>personal and business.</b>
                              </p>
                              <p style={{ fontSize: '22px', lineHeight: '24px', color: '#ffffff', fontWeight: 500, letterSpacing: '-0.01px' }}>
                                Do you want to be your own boss? <br /><br />
                                Do you want to build on your current career?<br /><br />
                                Do you dream of traveling the World, or at least helping others to?
                              </p>
                            </div>
                          </div>
                          <div className="sqs-block button-block sqs-block-button" data-block-type="53" id="block-yui_3_17_2_1_1587154415196_32606">
                            <div className="sqs-block-content">
                              <div className="sqs-block-button-container sqs-block-button-container--center" data-animation-role="button" data-alignment="center" data-button-size="medium" data-button-type="primary">
                                <a href="https://travadvisors.com" className="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element">
Get Started
</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sqs-block spacer-block sqs-block-spacer" data-aspect-ratio="0.1360544217687075" data-block-type="21" id="block-yui_3_17_2_1_1585228531063_9188">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <figure className="Index-page-image" data-parallax-image-wrapper>
                      <img data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586472329306-GJP916LEPRDMKF7S1PTU/ban-achievement.jpg" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586472329306-GJP916LEPRDMKF7S1PTU/ban-achievement.jpg" data-image-dimensions="1920x832" data-image-focal-point="0.7079599580205054,0.47623476885871086" alt="ban-achievement.jpg" data-load="false" />
                    </figure>
            </section>

            <section id="travel-partner-logos" className="Index-page" data-collection-id="5e6ec3e6f26ba22b9994b8c8" data-parallax-id="5e6ec3e6f26ba22b9994b8c8" data-edit-main-image="Background">
              <div className="Index-page-content ">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1611105192552" id="page-5e6ec3e6f26ba22b9994b8c8">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="row sqs-row">
                        <div className="col sqs-col-2 span-2">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1584317124195_39362">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>
                        <div className="col sqs-col-8 span-8">
                          <div className="sqs-block html-block sqs-block-html" data-block-type="2" id="block-yui_3_17_2_1_1584317124195_38651">
                            <div className="sqs-block-content">
                              <div className="sqs-html-content">
                                <h3 style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>ACCESS TO OUR</h3>
                                <h1 style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                                  <strong>1,000+ TravNow Suppliers</strong>
                                </h1>
                                <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }} className="">
                                  <em>Thanks to our strong supplier relationships, members can take advantage of top tier commissions, exclusive promotions and amenity programs, and superior customer service. Below is just an abbreviated list of suppliers.</em>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col sqs-col-2 span-2">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1584317124195_40603">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                      <div className="row sqs-row">
                        <div className="col sqs-col-2 span-2">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585585868096_7724">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>
                        <div className="col sqs-col-8 span-8">
                          <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-yui_3_17_2_1_1585255289302_32116">
                            <div className="sqs-block-content">
                              <div className="
image-block-outer-wrapper
layout-caption-below
design-layout-inline
combination-animation-none
individual-animation-none
individual-text-animation-none
" data-test="image-block-inline-outer-wrapper">
                                <figure className="
sqs-block-image-figure
intrinsic
" style={{ maxWidth: '100%' }}>
                                  <div className="image-block-wrapper" data-animation-role="image">
                                    <div className="sqs-image-shape-container-element



has-aspect-ratio
" style={{ position: 'relative', paddingBottom: '10.454545021057129%', overflow: 'hidden' }}>
                                      <img data-stretch="true" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256761906-I0OGB33VSUPYVIISL66H/resort-logo-bar.JPG" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256761906-I0OGB33VSUPYVIISL66H/resort-logo-bar.JPG" data-image-dimensions="1100x115" data-image-focal-point="0.5,0.5" alt="resort-logo-bar.JPG" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256761906-I0OGB33VSUPYVIISL66H/resort-logo-bar.JPG" width="1100" height="115" sizes="(max-width: 640px) 100vw, (max-width: 767px) 66.66666666666666vw, 66.66666666666666vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />

</div>
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col sqs-col-2 span-2">
                            <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585585868096_12622">
                              <div className="sqs-block-content">&nbsp;</div>
                            </div>
                          </div>
                        </div>
                        <div className="row sqs-row">
                          <div className="col sqs-col-2 span-2">
                            <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585585868096_8866">
                              <div className="sqs-block-content">&nbsp;</div>
                            </div>
                          </div>
                          <div className="col sqs-col-8 span-8">
                            <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-yui_3_17_2_1_1585256165831_37120">
                              <div className="sqs-block-content">
                                <div className="
image-block-outer-wrapper
layout-caption-hidden
design-layout-inline
combination-animation-none
individual-animation-none
individual-text-animation-none
" data-test="image-block-inline-outer-wrapper">
                                  <figure className="
sqs-block-image-figure
intrinsic
" style={{ maxWidth: '100%' }}>
                                    <div className="image-block-wrapper" data-animation-role="image">
                                      <div className="sqs-image-shape-container-element



has-aspect-ratio
" style={{ position: 'relative', paddingBottom: '16.51705551147461%', overflow: 'hidden' }}>
                                        <img data-stretch="true" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256741804-23B3O9R01A527U2NYIYJ/cruise-logo-bar.JPG" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256741804-23B3O9R01A527U2NYIYJ/cruise-logo-bar.JPG" data-image-dimensions="1114x184" data-image-focal-point="0.5,0.5" alt="cruise-logo-bar.JPG" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256741804-23B3O9R01A527U2NYIYJ/cruise-logo-bar.JPG" width="1114" height="184" sizes="(max-width: 640px) 100vw, (max-width: 767px) 66.66666666666666vw, 66.66666666666666vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />

</div>
                                      </div>
                                    </figure>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col sqs-col-2 span-2">
                              <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585585868096_13889">
                                <div className="sqs-block-content">&nbsp;</div>
                              </div>
                            </div>
                          </div>
                          <div className="row sqs-row">
                            <div className="col sqs-col-2 span-2">
                              <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585585868096_10072">
                                <div className="sqs-block-content">&nbsp;</div>
                              </div>
                            </div>
                            <div className="col sqs-col-8 span-8">
                              <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-yui_3_17_2_1_1585256165831_78924">
                                <div className="sqs-block-content">
                                  <div className="
image-block-outer-wrapper
layout-caption-below
design-layout-inline
combination-animation-none
individual-animation-none
individual-text-animation-none
" data-test="image-block-inline-outer-wrapper">
                                    <figure className="
sqs-block-image-figure
intrinsic
" style={{ maxWidth: '100%' }}>
                                      <div className="image-block-wrapper" data-animation-role="image">
                                        <div className="sqs-image-shape-container-element



has-aspect-ratio
" style={{ position: 'relative', paddingBottom: '23.545454025268555%', overflow: 'hidden' }}>
                                          <img data-stretch="true" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256708375-HLLPO8RSXLW1MXPPSBD5/hotel-logo-bar.JPG" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256708375-HLLPO8RSXLW1MXPPSBD5/hotel-logo-bar.JPG" data-image-dimensions="1100x259" data-image-focal-point="0.5,0.5" alt="hotel-logo-bar.JPG" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1585256708375-HLLPO8RSXLW1MXPPSBD5/hotel-logo-bar.JPG" width="1100" height="259" sizes="(max-width: 640px) 100vw, (max-width: 767px) 66.66666666666666vw, 66.66666666666666vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />

</div>
                                        </div>
                                      </figure>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col sqs-col-2 span-2">
                                <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1585585868096_15203">
                                  <div className="sqs-block-content">&nbsp;</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
            </section>

            <section id="host-agency-review" className="Index-page Index-page--has-image" data-parallax-original-element data-collection-id="5e9a0ad8976da9539c4146a4" data-parallax-id="5e9a0ad8976da9539c4146a4" data-edit-main-image="Background">
              <div className="Index-page-content  sqs-alternate-block-style-container">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1587485470772" id="page-5e9a0ad8976da9539c4146a4">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1587153655749_4478">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>
                      <div className="sqs-block code-block sqs-block-code" data-block-type="23" id="block-yui_3_17_2_1_1587153598652_4632">
                        <div className="sqs-block-content">
                          <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '28px', lineHeight: '34px', fontFamily: 'semplicita-pro', fontWeight: 400 }}>"If you are an experienced agent or new to the travel industry, the difference is clear. Becoming a <b>TravNow, TravAdvisor</b> is the best business decision you can make."</p>
                          <p style={{ color: '#ffffff', textAlign: 'center', fontSize: '18px', lineHeight: '34px', fontFamily: 'semplicita-pro', fontWeight: 400 }}> - <em>B. West</em>
                          </p>
                        </div>
                      </div>
                      <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-yui_3_17_2_1_1587153655749_5002">
                        <div className="sqs-block-content">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figure className="Index-page-image" data-parallax-image-wrapper>
              <img data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586525403218-FLOTJMTH1XGWEZ6C7OFG/ban-hands-up.jpg" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1586525403218-FLOTJMTH1XGWEZ6C7OFG/ban-hands-up.jpg" data-image-dimensions="1920x832" data-image-focal-point="0.5,0.5" alt="ban-hands-up.jpg" data-load="false" />
            </figure>
            </section>

            <section id="sell-like-a-professional" className="Index-page" data-collection-id="5eb2c9b55c1a0472060ab6ec" data-parallax-id="5eb2c9b55c1a0472060ab6ec" data-edit-main-image="Background">
              <div className="Index-page-content ">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on="1590595985005" id="page-5eb2c9b55c1a0472060ab6ec">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="row sqs-row">
                        <div className="col sqs-col-2 span-2">
                          <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-8507338783ddaff91a7a">
                            <div className="sqs-block-content">&nbsp;</div>
                          </div>
                        </div>

                        <div className="col sqs-col-8 span-8">
                          <div className="sqs-block code-block sqs-block-code" data-block-type="23" id="block-3df338f5f2fc5e835e25">
                            <div className="sqs-block-content">
                              <p style={{ fontSize: '36px', color: '#001f3f', lineHeight: '30px', fontWeight: 600 }}>Become a Certified TravNow Advisor</p>
                              <span style={{ fontSize: '20px', color: '#89af40', lineHeight: '15%', fontWeight: 500 }}>
                                Sell like a Pro with Certifications & Training on the Leading Travel Brands!
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col sqs-col-2 span-2">
                        <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-block-type="21" id="block-448e962ae67dc2222c10">
                          <div className="sqs-block-content">&nbsp;</div>
                        </div>
                      </div>
                    </div>

                    <div className="sqs-block image-block sqs-block-image" data-block-type="5" id="block-yui_3_17_2_1_1588881894085_25723">
                      <div className="sqs-block-content">
                        <div className="image-block-outer-wrapper layout-caption-below design-layout-inline combination-animation-none individual-animation-none individual-text-animation-none" data-test="image-block-inline-outer-wrapper">
                          <figure className="sqs-block-image-figure intrinsic" style={{ maxWidth: '1275px' }}>
                            <div className="image-block-wrapper" data-animation-role="image">
                              <div className="sqs-image-shape-container-element has-aspect-ratio" style={{ position: 'relative', paddingBottom: '10.90196037292480%', overflow: 'hidden' }}>
                                <img data-stretch="false" data-src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1588882498610-P0STSCLBXC2G0ZXWMB4F/supplier-list-of-logos.png" data-image="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1588882498610-P0STSCLBXC2G0ZXWMB4F/supplier-list-of-logos.png" data-image-dimensions="1275x139" data-image-focal-point="0.5,0.5" alt="supplier-list-of-logos.png" data-load="false" src="https://images.squarespace-cdn.com/content/v1/5e6d3cc98679733a83a2b913/1588882498610-P0STSCLBXC2G0ZXWMB4F/supplier-list-of-logos.png" width="1275" height="139" sizes="(max-width: 640px) 100vw, (max-width: 767px) 100vw, 100vw" style={{ display: 'block', objectFit: 'cover', width: '100%', height: '100%', objectPosition: '50% 50%' }} loading="lazy" decoding="async" data-loader="sqs" />
                              </div>
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>

                    <div className="sqs-block button-block sqs-block-button" data-block-type="53" id="block-yui_3_17_2_1_1588881894085_27355">
                      <div className="sqs-block-content">
                        <div className="sqs-block-button-container sqs-block-button-container--center" data-animation-role="button" data-alignment="center" data-button-size="medium" data-button-type="primary">
                          <a href="/agent-certifications" className="sqs-block-button-element--medium sqs-button-element--primary sqs-block-button-element">
                            Learn more
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="sqs-block spacer-block sqs-block-spacer sized vsize-1" data-aspect-ratio="0.14388489208633093" data-block-type="21" id="block-9e5ed5b9bc2e2f1a1869">
                      <div className="sqs-block-content">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <nav className="Index-nav">
              <div className="Index-nav-inner">
                <a href="#welcome" className="Index-nav-item active">
                  <div className="Index-nav-indicator"/>
                  <div className="Index-nav-text">
                    <span>Welcome</span>
                  </div>
                </a>
                <a href="#why-become-an-agent" className="Index-nav-item">
                  <div className="Index-nav-indicator"/>
                  <div className="Index-nav-text">
                    <span>Why Become an Agent</span>
                  </div>
                </a>
                <a href="#get-started-1" className="Index-nav-item">
                  <div className="Index-nav-indicator"/>
                  <div className="Index-nav-text">
                    <span>Get Started</span>
                  </div>
                </a>
                <a href="#vacation-life-video" className="Index-nav-item">
                  <div className="Index-nav-indicator"/>
                  <div className="Index-nav-text">
                    <span>Vacation Life</span>
                  </div>
                </a>
                <a href="#travel-partner-logos" className="Index-nav-item">
                  <div className="Index-nav-indicator"/>
                  <div className="Index-nav-text">
                    <span>Travel Partners</span>
                  </div>
                </a>
                <a href="#host-agency-review" className="Index-nav-item">
                  <div className="Index-nav-indicator" />
                  <div className="Index-nav-text">
                    <span>Host Agency Review</span>
                  </div>
                </a>
                <a href="#sell-like-a-professional" className="Index-nav-item">
                  <div className="Index-nav-indicator" />
                  <div className="Index-nav-text">
                    <span>Sell Like a Pro Travel Agent</span>
                  </div>
                </a>
              </div>
            </nav>
          </main>
        </div>
      </div>
    </div>
  );
}
