
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';

import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { faqMarkDownContent, MarkDownEnum } from '@common-types';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps, WrappedComponentProps {
  messageId: MarkDownEnum;
}

function MarkdownComponent(props: IProps) {
  const { intl, messageId, menuStore } = props;
  const phone = menuStore.items?.phone;
  const isMLM = menuStore.items?.isMLM;

  const id = (messageId === MarkDownEnum.PriceMatch && menuStore.items?.isRemovePriceMatch) ? MarkDownEnum.PriceMatchNoData : messageId;

  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
      {intl.formatMessage(
        { id },
        {
          phone: (phone || isMLM) && faqMarkDownContent(phone as string),
        },
      )}
    </ReactMarkdown>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const Markdown = connect(mapStateToProps)(injectIntl(MarkdownComponent));

