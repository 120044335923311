import React, { ChangeEvent, RefObject } from 'react';
import omit from 'lodash/omit';

import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Checkbox, Form, Input, Select } from 'antd';
import { OptionData } from 'rc-select/lib/interface';
import { SelectValue } from 'antd/lib/select';

import { getOptionsMonths, isValidMonth, onlyLettersNumsRegExp, RootState, Toaster } from '@share/utils';
import { CheckMarkSvg, MaestroCardSvg, MasterCardSvg, VisaCardSvg, AmexCardSvg } from '@assets';
import { ICardBooking, IErrorField } from '@common-types';
import { countriesCode } from '@share/constants';
import { HOTEL_BOOKING_ADDRESS_INFO_LABEL, USA_STATES } from '@constants';
import {
  detectCardType,
  setValidator,
  showCardNumber,
  getHiddenFields,
  getStates,
  GetCreditCardImage,
  getHotelAddressFromStorage,
} from '@utils';
import {
  hideNumbers,
  insertSpacesInNumber,
  checkForEmptyCharacter,
  creatingArrayWithNumber,
  checkInLatinLetters,
  onlyNumsRegExp,
  getOptionsYear,
} from '@share/utils';
import { number } from 'card-validator';
import { isEmpty } from 'lodash';

import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';

import { IInsuranceState, insuranceActions, refreshInsuranceQuote } from '@store/slices';

import './style.scss';

interface IMapStateToProps {
  insuranceStore: IInsuranceState;
}

interface IMapDispatchToProps {
  resetInsurance: () => void;
  refreshInsuranceQuote: (state: string, priceChangeHandler?: () => void) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  form: FormInstance;
  formRef: RefObject<FormInstance>;
  isRequired: boolean;
  display: boolean;

  card: ICardBooking;
  errorsField: IErrorField[];
  loading: boolean;

  useFormLabel?: boolean;
  roundBorders?: boolean;
  fullWidthForm?: boolean;
  includePhone?: boolean;
  skipStorage?: boolean;
  refreshData?: boolean;
  displaySave?: boolean;
  disableCountrySelection?: boolean;
  disableInsurance?: boolean;

  setCard: (card: ICardBooking) => void;
  onDataRefreshed?: () => void;
  onChangeCountryState?: () => void;
}

interface IState {
  creditCard: string;
  codeCVV: string;
  expirationMonth: string;
  expirationYear: string;
  isErrorMessageCardNumber: boolean;
  isErrorMessageExpirationDate: boolean;
}

const ZERO = 0;
const THREE = 3;
const FOUR = 4;
const MAX_LENGTH_NAME = 100;
const MAX_POSTAL_CODE = 50;
const MIN_LENGTH_CARD_NUMBER = 13;
const MAX_LENGTH_CARD_NUMBER = 23;
const START_HIDE_NUMBER = 5;
const LENGTH_HIDE_NUMBER = 8;

class PaymentMethodBaseComponent extends React.Component<IProps, IState> {
  state: IState = {
    creditCard: '',
    codeCVV: '',
    expirationMonth: undefined,
    expirationYear: undefined,
    isErrorMessageCardNumber: true,
    isErrorMessageExpirationDate: true,
  };

  setCardData = (value: string | boolean, key: string) => {
    const updateCard = {
      ...this.props.card,
      [key]: value,
    } as any;

    if (key !== 'cvv') {
      updateCard.id = null;
    }

    this.props.setCard(updateCard);

    if (!this.props.skipStorage) {
      localStorage.setItem(
        HOTEL_BOOKING_ADDRESS_INFO_LABEL,
        JSON.stringify(
          omit(updateCard, ['cvv', 'cardNumber', 'cardType', 'expireDate', 'holderName', 'id']),
        ),
      );
    }
  };

  onChangeCardName = (e: { target: HTMLInputElement }): void => {
    this.setCardData(e.target.value, 'holderName');
  };

  onChangeAddressLine = (e: { target: HTMLInputElement }): void => {
    this.setCardData(e.target.value, 'addressLine');
  };

  onChangePhone = (e: { target: HTMLInputElement }): void => {
    this.setCardData(e.target.value, 'phone');
  };

  onChangeCity = (e: { target: HTMLInputElement }): void => {
    this.setCardData(e.target.value, 'city');
  };

  onChangeCountry = (value: string): void => {
    const { card, disableInsurance, resetInsurance, refreshInsuranceQuote, onChangeCountryState } = this.props;

    if (!disableInsurance) {
      if (card?.country === 'US' && value !== 'US') {
        resetInsurance();
        this.handleToaster('insurance.policy.not_valid');
      } else if (card?.country !== 'US' && value === 'US') {
        const isUSState = USA_STATES.map(s => s.postalCode).includes(card?.state);
        refreshInsuranceQuote(isUSState ? card?.state : null);
        this.handleToaster('insurance.policy.us_resident');
      }
    }

    this.setCardData(value, 'country');
    onChangeCountryState();
  };

  handleToaster = (message: string) => {
    const { intl } = this.props;
    Toaster.info(intl.formatMessage({ id : message }), {
      position: 'bottom-center',
      icon: false,
      hideProgressBar: true,  
    });
  }

  onChangeSave = (e: { target: CheckboxChangeEventTarget }): void => {
    this.setCardData(e.target.checked, 'addPaymentMethod');
  };

  onChangePostalCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const { card } = this.props;

    if (onlyLettersNumsRegExp.test(value) || value === '') {
      this.setCardData(value, 'zipCode');
    } else {
      e.preventDefault();
      e.stopPropagation();
      this.setCardData(card && card.zipCode ? card.zipCode : '', 'zipCode');
    }
  };

  onChangeMonth = (value: SelectValue) => {
    this.setState({ expirationMonth: value.toString() as string }, () => {
      this.setExpireDate();
      this.getFormCurrent().setFieldsValue({ months: this.state.expirationMonth });
    });

    if (this.props.errorsField) {
      this.setState({ isErrorMessageExpirationDate: false });
    }
  };

  onChangeYear = (value: string) => {
    const expirationYear = value as string;
    this.setState({ expirationYear }, () => {
      this.setExpireDate();
      this.getFormCurrent().setFieldsValue({ years: expirationYear });
    });

    if (!isValidMonth(this.state.expirationMonth, expirationYear)) {
      this.setState({ expirationMonth: null }, () => {
        this.setExpireDate();
        this.getFormCurrent().setFieldsValue({ months: null });
      });
    }

    if (this.props.errorsField) {
      this.setState({ isErrorMessageExpirationDate: false });
    }
  };

  setExpireDate = () => {
    const { expirationYear, expirationMonth } = this.state;
    if (isEmpty(expirationYear) || isEmpty(expirationMonth)) {
      return;
    }

    const expireDate = `${expirationMonth}${expirationYear}`;

    this.setCardData(expireDate, 'expireDate');
  };

  onChangeCardNumber = (e: { target: HTMLInputElement }): void => {
    const { cardNumber } = this.props.card;
    const value = checkForEmptyCharacter(e.target.value);
    const numberCard = insertSpacesInNumber(value);

    if (this.props.errorsField) {
      this.setState({ isErrorMessageCardNumber: false });
    }
 
    if (numberCard !== null) {
      this.setCardData(numberCard.join('').trim(), 'cardNumber');
      this.setState({ creditCard: numberCard.join(' ').trim() });
    } else if (value.length === ZERO) {
      this.setCardData('', 'cardNumber');
      this.setState({ creditCard: value });
    } else if (!Number(value)) {
      this.setCardData(cardNumber, 'cardNumber');
      this.setState({ creditCard: cardNumber });
    }
  };

  hideCardNumber = () => {
    const matches = creatingArrayWithNumber(LENGTH_HIDE_NUMBER, START_HIDE_NUMBER);
    const cardType = detectCardType(this.props.card.cardNumber);

    this.setCardData(cardType, 'cardType');
    this.setState({ creditCard: hideNumbers(this.state.creditCard, matches) });
  };

  onFocusCardNumber = () => {
    this.setState({ creditCard: showCardNumber(({ card: this.props.card }) as any) });
  };

  onChangeCVV = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (onlyNumsRegExp.test(value) || value === '') {
      const value = checkForEmptyCharacter(e.target.value);
      this.setCardData(value, 'cvv');
      this.setState({ codeCVV: value });
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  hideCVVCode = () => {
    const { codeCVV } = this.state;
    this.setState({ codeCVV: getHiddenFields(codeCVV) });
  };

  showCVVCode = (): void => {
    const { card } = this.props;

    if (!card.cvv) {
      return;
    }

    this.setState({ codeCVV: card.cvv });
  };

  componentDidUpdate(): void {
    const { card, errorsField, refreshData, onDataRefreshed } = this.props;
    const { isErrorMessageExpirationDate, isErrorMessageCardNumber } = this.state;

    if (errorsField && isErrorMessageExpirationDate && isErrorMessageCardNumber) {
      this.getFormCurrent().validateFields().then();
    }

    if (refreshData) {
      onDataRefreshed();

      if (card) {
        const years = card.expireDate?.substring(card.expireDate.length - 2);
        const months = card.expireDate?.substring(0, 2);
      
        this.getFormCurrent().setFieldsValue({ 
          holderName: card?.holderName,
          cardNumber: card?.cardNumber,
          code: null,
          months,
          years,
          saveCard: false,

          address: card?.addressLine,
          city: card?.city,
          country: card?.country,
          state: card?.state,
          phone: card?.phone,
          'postal-code': card?.zipCode
        });

        this.setState({ creditCard: card?.cardNumber, codeCVV: null, expirationMonth: months, expirationYear: years });
      }
    }
  }

  onChangeState = (value: SelectValue): void => {
    const { card, disableInsurance, refreshInsuranceQuote, onChangeCountryState } = this.props;

    if (!disableInsurance && card?.country === 'US') {
      refreshInsuranceQuote(value as string, () => this.handleToaster('insurance.policy.state_change'));
    }

    this.setCardData(value as string, 'state');
    this.getFormCurrent().setFieldsValue({ state: value });
    onChangeCountryState();
  };

  getFormCurrent = () => {
    if (this.props.form) {
      return this.props.form;
    }
    if (this.props.formRef?.current) {
      return this.props.formRef?.current;
    }
    return null;
  }

  componentDidMount() {
    const { skipStorage, card } = this.props;

    if (!skipStorage) {
      const addressFromStorage: ICardBooking = getHotelAddressFromStorage();

      const resultData: ICardBooking = addressFromStorage ? { ...addressFromStorage, id: undefined, addPaymentMethod: false } : card ? { ...card, addPaymentMethod: false } : { country: 'US' } as ICardBooking;

      this.props.setCard(resultData);
  
      this.getFormCurrent().setFieldsValue({ 
        address: resultData?.addressLine,
        city: resultData?.city,
        country: resultData?.country,
        state: resultData?.state,
        phone: resultData?.phone,
        'postal-code': resultData?.zipCode
      });
    }
  }

  render(): React.ReactNode {
    const { Option } = Select;
    const { form, formRef, intl, isRequired, display, card, disableCountrySelection, errorsField, loading, useFormLabel, roundBorders, fullWidthForm, includePhone, displaySave, insuranceStore } = this.props;
    const {
      creditCard,
      codeCVV,
      expirationMonth,
      expirationYear,
      isErrorMessageCardNumber,
      isErrorMessageExpirationDate,
    } = this.state;

    const isSavedCard = !isEmpty(card?.id);
    const cardNumber = (card?.cardNumber)? card?.cardNumber : null;
    const cardValidation = cardNumber ? number(cardNumber) : null;
    const cardIsValid = isSavedCard ? 
                            true : 
                            !isEmpty(cardNumber) ? 
                                true :
                                false;
    const cardType = isSavedCard ? 
                        card?.cardType :
                        cardValidation ? 
                            cardValidation.card?.type :
                            null;
    
    const cardNumberValidators = [
      {
        required: isRequired,
        message: intl.formatMessage({ id: 'error.message.card.number' }),
      }
    ];
    
    if (!isSavedCard) {
      cardNumberValidators.push({
        min: MIN_LENGTH_CARD_NUMBER,
        message: intl.formatMessage({ id: 'error.message.card.number.long' }),
      } as any);
      cardNumberValidators.push({
        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
          if (isEmpty(value)) {
            callback();
          } else {
            if (!number(value).isValid) {
              callback(intl.formatMessage({ id: 'error.message.card.number' }));
            } else {
              callback();
            }
          }
        }
      } as any);
      cardNumberValidators.push({
        validator: async () => {
          await setValidator(errorsField, 'card.cardNumber', isErrorMessageCardNumber);
        },
      } as any);
    };

    const loadingQuote = insuranceStore?.loading;

    return (
      <div className={`payment-method ${!display ? 'hide' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
        <div className="payment-method__header">
          <h4 className="payment-method__title">
            <FormattedMessage id="payment.method" />
          </h4>
          <div className="payment-method__description-wrapper">
            <CheckMarkSvg />
            <p className="payment-method__description">
              <FormattedMessage id="secure.transmission" />
            </p>
          </div>
          <div className="payment-method__description-wrapper">
            <CheckMarkSvg />
            <p className="payment-method__description">
              <FormattedMessage id="personal.information" />
            </p>
          </div>
          <div className="payment-method__cards">
            <MaestroCardSvg />
            <MasterCardSvg />
            <VisaCardSvg />
            <AmexCardSvg />
          </div>
        </div>
        <div>
          <Form
            name="payment_method_rule"
            className={`payment-method__rule ${fullWidthForm ? 'full-width' : ''}`}
            form={form}
            ref={formRef}
          >
            {(displaySave && !isSavedCard) ? (
              <Form.Item name="saveCard" >
                <div className="payment-method__input-wrapper no-border no-heigth checkbox-save">
                  <div className="payment-method__input">
                    <Checkbox
                      checked={card.addPaymentMethod}
                      onChange={this.onChangeSave}
                      disabled={loading}
                    >
                      <FormattedMessage id="save.card" />
                    </Checkbox>
                  </div>
                </div>
              </Form.Item>) : null}

            <Form.Item
              name="holderName"
              label={useFormLabel ? intl.formatMessage({ id: 'name.on.card' }) : null}
              rules={[
                {
                  required: isRequired,
                  message: intl.formatMessage({ id: 'error.message.name.card' }),
                },
              ]}
            >
              <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                {!useFormLabel ? (
                  <span className="payment-method__input-label">
                    <FormattedMessage id="name.on.card" />
                  </span>) : null}
                <div className="payment-method__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'debit.credit.card' })}
                    maxLength={MAX_LENGTH_NAME}
                    value={card.holderName}
                    onChange={this.onChangeCardName}
                    disabled={loading}
                  />
                </div>
              </div>
            </Form.Item>

            <Form.Item
              name="cardNumber"
              label={useFormLabel ? intl.formatMessage({ id: 'card.number' }) : null}
              validateTrigger="onBlur"
              rules={cardNumberValidators}
            >
              <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                {!useFormLabel ? (
                  <span className="payment-method__input-label">
                    <FormattedMessage id="debit.credit.card" />
                  </span>) : null}
                <div className="payment-method__input">
                  <Input
                    placeholder={intl.formatMessage({ id: 'card.number' })}
                    maxLength={MAX_LENGTH_CARD_NUMBER}
                    value={creditCard}
                    onChange={this.onChangeCardNumber}
                    onBlur={this.hideCardNumber}
                    onFocus={this.onFocusCardNumber}
                    disabled={loading}
                  />
                  <div className="input-card">
                    {GetCreditCardImage(cardType, cardIsValid)}
                  </div>
                </div>
              </div>
            </Form.Item>

            <div className={`payment-method__cards-info ${isSavedCard ? 'saved-card' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
              <Form.Item
                name="months"
                rules={[
                  {
                    required: isRequired,
                    message: intl.formatMessage({ id: 'error.message.expiration.date' }),
                  },
                  {
                    validator: async () => {
                      await setValidator(
                        errorsField,
                        'card.expireDate',
                        isErrorMessageExpirationDate,
                      );
                    },
                  },
                ]}
              >
                <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="expiration.months" />
                    </span>) : null}
                  <div className="payment-method__input">
                    <Select
                      placeholder={intl.formatMessage({ id: 'expiration.months' })}
                      value={expirationMonth}
                      onChange={this.onChangeMonth}
                      disabled={loading}
                      popupClassName="payment-month-select"
                    >
                      {getOptionsMonths(this.state.expirationYear).map((month, index) => (
                        <Option key={index} value={month.number}>
                          {month.number}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name="years"
                rules={[
                  {
                    required: isRequired,
                    message: intl.formatMessage({ id: 'error.message.expiration.date' }),
                  },
                  {
                    validator: async () => {
                      await setValidator(
                        errorsField,
                        'card.expireDate',
                        isErrorMessageExpirationDate,
                      );
                    },
                  },
                ]}
              >
                <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="expiration.year" />
                    </span>) : null}
                  <div className="payment-method__input">
                    <Select
                      placeholder={intl.formatMessage({ id: 'expiration.year' })}
                      value={expirationYear}
                      onChange={this.onChangeYear}
                      disabled={loading}
                      popupClassName="payment-year-select"
                    >
                      {getOptionsYear().map((year, index) => (
                        <Option key={index} value={year.toString()}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name="code"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: isRequired,
                    message: intl.formatMessage({ id: 'error.message.security.code' }),
                  },
                  {
                    min: THREE,
                    message: intl.formatMessage({ id: 'error.message.security.code.long' }),
                  },
                ]}
              >
                <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="security.code" />
                    </span>) : null}
                  <div className="payment-method__input cvv">
                    <Input
                      placeholder={intl.formatMessage({ id: 'security.code' })}
                      maxLength={FOUR}
                      value={codeCVV}
                      onChange={this.onChangeCVV}
                      onBlur={this.hideCVVCode}
                      onFocus={this.showCVVCode}
                      disabled={loading}
                    />
                  </div>
                </div>
              </Form.Item>
            </div>

            <div className={`payment-method__billing-address-wrapper ${useFormLabel ? 'no-labels' : ''}`}>
              <p className="payment-method__address-title">
                <FormattedMessage id="billing.address" />:
              </p>

              {includePhone ? (
                <Form.Item
                  name="phone"
                  className="phone-number"
                  label={useFormLabel ? intl.formatMessage({ id: 'phone' }) : null}
                  rules={[
                    { required: isRequired, message: intl.formatMessage({ id: 'error.message.phone' }) },
                  ]}
                >
                  <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                    {!useFormLabel ? (
                      <span className="payment-method__input-label">
                        <FormattedMessage id="phone" />
                      </span>) : null}
                    <div className="payment-method__input">
                      <Input
                        placeholder={intl.formatMessage({ id: 'phone' })}
                        value={card.phone}
                        onChange={this.onChangePhone}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </Form.Item>) : null}

              <Form.Item
                name="address"
                className="address-line"
                label={useFormLabel ? intl.formatMessage({ id: 'address' }) : null}
                rules={[
                  { required: isRequired, message: intl.formatMessage({ id: 'error.message.address' }) },
                ]}
              >
                <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="address" />
                    </span>) : null}
                  <div className="payment-method__input">
                    <Input
                      placeholder={intl.formatMessage({ id: 'address' })}
                      maxLength={MAX_LENGTH_NAME}
                      value={card.addressLine}
                      onChange={this.onChangeAddressLine}
                      disabled={loading}
                    />
                  </div>
                </div>
              </Form.Item>

              <Form.Item
                name="city"
                className={`billing-address ${!(card?.country === 'US' || card?.country === 'CA') ? 'non-us-country' : ''}`}
                rules={[
                  {
                    required: isRequired,
                    message: intl.formatMessage({ id: 'error.message.city' }),
                    pattern: checkInLatinLetters,
                  },
                ]}
              >
                <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="city" />
                    </span>) : null}
                  <div className="payment-method__input">
                    <Input
                      placeholder={intl.formatMessage({ id: 'city' })}
                      maxLength={MAX_LENGTH_NAME}
                      value={card.city}
                      onChange={this.onChangeCity}
                      disabled={loading}
                    />
                  </div>
                </div>
              </Form.Item>

              <Form.Item
                name="country"
                className={`billing-address ${!(card?.country === 'US' || card?.country === 'CA') ? 'non-us-country' : ''}`}
              >
                <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="country" />
                    </span>) : null}
                  <div className="payment-method__input">
                    <Select 
                        value={card?.country}
                        popupClassName="payment-country-select"
                        onChange={this.onChangeCountry}
                        disabled={loading || disableCountrySelection || loadingQuote}
                      >
                      {countriesCode.map((code, index) => (
                        <Option key={index} value={code.isoCode}>
                          {code.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Form.Item>

              {(card?.country === 'US' || card?.country === 'CA') && (
                <Form.Item
                  name="state"
                  className={`billing-address ${!(card?.country === 'US' || card?.country === 'CA') ? 'non-us-country' : ''}`}
                  rules={[
                    {
                      required: isRequired,
                      message: intl.formatMessage({ id: 'error.message.state' }),
                      pattern: checkInLatinLetters,
                    },
                  ]}
                >
                  <div className={`payment-method__input-wrapper ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                    {!useFormLabel ? (
                      <span className="payment-method__input-label">
                        <FormattedMessage id="state" />
                      </span>) : null}
                    <div className="payment-method__input">
                      <Select
                        showSearch={true}
                        value={card?.state || ''}
                        placeholder={intl.formatMessage({ id: 'state' })}
                        onChange={this.onChangeState}
                        disabled={loading || loadingQuote}
                        popupClassName="payment-state-select"
                        filterOption={(inputValue: string, option: OptionData) => {
                          const inputLower = inputValue.toLowerCase();

                          return (
                            option.children.toString().toLowerCase().includes(inputLower) ||
                            option.value.toString().toLowerCase().includes(inputLower)
                          );
                        }}
                      >
                        {getStates(({ card }) as any).map(({ postalCode, name }) => {
                          return (
                            <Select.Option key={postalCode} value={postalCode}>
                              {name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </Form.Item>)}

              <Form.Item
                name="postal-code"
                className={`billing-address ${!(card?.country === 'US' || card?.country === 'CA') ? 'non-us-country' : ''}`}
                rules={[
                  {
                    required: isRequired,
                    message: intl.formatMessage({ id: 'error.message.postal.code' }),
                  },
                ]}
              >
                <div className={`payment-method__input-wrapper postal-code-input ${roundBorders ? 'rounded-border' : ''} ${useFormLabel ? 'no-labels' : ''}`}>
                  {!useFormLabel ? (
                    <span className="payment-method__input-label">
                      <FormattedMessage id="postal-code" />
                    </span>) : null}
                  <div className="payment-method__input">
                    <Input
                      placeholder={intl.formatMessage({ id: 'postal.code.label' })}
                      maxLength={MAX_POSTAL_CODE}
                      onChange={this.onChangePostalCode}
                      value={card.zipCode}
                      disabled={loading}
                    />
                  </div>
                </div>
              </Form.Item>

            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    insuranceStore: state.insuranceStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  refreshInsuranceQuote,
  resetInsurance: insuranceActions.reset,
};

export const PaymentMethodBase = connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaymentMethodBaseComponent));
