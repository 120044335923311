import React, { useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';

import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { Tabs } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { MOBILE_MEDIA_POINT, Routes } from '@share/constants';
import { IQuoteState, getQuotes } from '@share/store/slices';
import { RootState, getKeyQuotesFromPath } from '@share/utils';
import { ILoginState, LoginType } from '@share/store/slices';
import { QuoteModalCompare, SearchTable } from '@components';

import { QuoteCondosModalCompare } from '../quote-condos-modal-compare';
import { GetMyQuotesTabs, GetQuoteDatasourceData, HISTORICAL_KEY, HOTELS_KEY } from './utils';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  quoteStore: IQuoteState;
}

interface IMapDispatchToProps {
  get: (id: any) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function MyQuotesComponent(props: IProps) {
  const { loginStore, quoteStore } = props;
  const { account } = loginStore;
  const { loading, result } = quoteStore;
  const { quotesHotels, quotesCondos, quotesHistorical } = result;
  const [visibleHotels, setVisibleHotels] = useState(false);
  const [visibleCondos, setVisibleCondos] = useState(false);
  const [isHistorical, setIsHistorical] = useState(false);
  const [quoteBase, setQuoteBase] = useState(null);
  
  const isMobile = document.body.offsetWidth <= MOBILE_MEDIA_POINT;

  if (!account || account.type === LoginType.Public) {
    props.history.push(Routes.NotAuthorized);
  }

  useEffect(() => {
    if (!loginStore.loading) {
      const param = getKeyQuotesFromPath();
      props.get(getKeyQuotesFromPath());
      setVisibleHotels(!!param);
    }
  }, [loginStore.loading])


  const handleOnRefresh = () => {
    const { loginStore } = props;
    const { user } = loginStore;
    if (user) {
      props.get(getKeyQuotesFromPath());
    }
  };

  let dataSourceHotels = [];
  let dataSourceCondos = [];
  let dataSourceHistorical = [];

  if (quotesHotels.length) {
    const grouped = groupBy(quotesHotels, quote => quote.quoteBaseId);
    dataSourceHotels = Object.entries(grouped).map(([key, quote]) => GetQuoteDatasourceData(quote[0]));
  }

  if (quotesCondos.length) {
    const grouped = groupBy(quotesCondos, quote => quote.quoteBaseId);
    dataSourceCondos = Object.entries(grouped).map(([key, quote]) => GetQuoteDatasourceData(quote[0], quotesCondos));
  }

  if (quotesHistorical.length) {
    const grouped = groupBy(quotesHistorical, quote => quote.quoteBaseId);
    dataSourceHistorical = Object.entries(grouped).map(([key, quote]) => GetQuoteDatasourceData(quote[0], quotesHistorical));
  }

  const tabs = GetMyQuotesTabs(isMobile, dataSourceHotels, dataSourceCondos, dataSourceHistorical, setVisibleHotels, setVisibleCondos, setQuoteBase);
  
  return (
    <div className={`quotes-wrapper ${isMobile ? 'mobile' : ''}`}>
      <div className="quotes-wrapper__refresh" onClick={handleOnRefresh}>
        <div className="action-button">
          <FontAwesomeIcon icon={faSyncAlt} size="1x"/>
          <span>Refresh</span>
        </div>
      </div>

      <Tabs defaultActiveKey={HOTELS_KEY} onChange={(key: string) => setIsHistorical(key === HISTORICAL_KEY)}>
        {tabs.map(({ title, columns, datasource, key }, index) => (
          <Tabs.TabPane key={key} tab={<FormattedMessage id={title} />}>
            <SearchTable
              title={null}
              loading={loading}
              actions={null}
              allColumns={columns}
              dataSource={datasource}
              pagination={{
                showSizeChanger: false,
                showQuickJumper: false
              }}
              filters={null}
              totalOrderCount={datasource.length}
              rowKey={(row: any) => row.key ? `${row.key}` : `${row.quoteBase}`}
              hideRefresh={true}
              isFilterApplied={false}
              onFiltersChange={null}
              onTableChange={null}
              onResetFilters={null}
              onApplyFilters={null}
              onRefresh={handleOnRefresh}
            ></SearchTable>
          </Tabs.TabPane>
        ))}
      </Tabs>

      {(quotesHotels.length || quotesHistorical.length) ? <QuoteModalCompare isHistorical={isHistorical} visible={visibleHotels} quoteBase={quoteBase} onCancel={() => { setVisibleHotels(false); setQuoteBase(null) }} /> : null}
      {(quotesCondos.length || quotesHistorical.length) ? <QuoteCondosModalCompare isHistorical={isHistorical} visible={visibleCondos} quoteBase={quoteBase} onCancel={() => { setVisibleCondos(false); setQuoteBase(null) }} /> : null}
    </div>

  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    quoteStore: state.quoteStore,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  get: (id: any) => {
    dispatch(getQuotes(id));
  },
});

const ComponentIntl = injectIntl(MyQuotesComponent);

export const MyQuotes = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
