
import * as H from 'history';
import moment from 'moment';
import groupBy from 'lodash/groupBy';

import { getCarRoutePath } from '@share/utils';
import { getWalletPrices, WalletPriceType } from './hotel';
import { IClientCash } from '@share/common-types';

export const openCarPage = (carId: string, history: H.History<H.LocationState>, disableNewTabOpen: boolean, isForceIframe?: boolean): void => {
  if (isForceIframe || disableNewTabOpen) {
    history.push(getCarRoutePath(carId, history));
  } else {
    window.open(getCarRoutePath(carId, history), '_blank');
  }
};

export const getMinPublicPrice = (car: any): number => {
  const { publicPrices } = car;
  
  if (publicPrices && publicPrices.length) {
    return Math.min(...publicPrices.map((p: any) => p?.price));
  }

  return 0;
};


export const GetDetailsPickUpPrices = (extras: any[], notCovered: any[], currency: string) => {
  const extraListInitial = extras?.length ? extras.map(c => ({ 
    ...c,
    charge: {
      ...c?.charge,
      currencyCode: c?.charge?.currencyCode ? c?.charge?.currencyCode : currency 
    }
  })) : [];
  const expraObj = groupBy(extraListInitial, (d: any) => d?.charge?.currencyCode);
  const expraList = Object.values(expraObj);
  const hasSameCurrencyExtra = (expraList?.length === 1 && !!expraObj[currency]) || expraList.length === 0;
  const expraAmount = hasSameCurrencyExtra ? extras.reduce((acc: any, curr: any) => acc + curr.charge.amount, 0) : 0;

  const notCoveredListInitial = notCovered?.length ? notCovered.map(c => ({ ...c, currencyCode: c?.currencyCode ? c?.currencyCode : currency })) : [];
  const notCoveredObj = groupBy(notCovered, (d: any) => d?.currencyCode ? d?.currencyCode : currency);
  const notCoveredList = Object.values(notCoveredObj);
  const hasSameCurrencyNotCovered = (notCoveredList?.length === 1 && !!notCoveredObj[currency]) || notCoveredList.length === 0;
  const notCoveredAmount = hasSameCurrencyNotCovered ? notCovered.reduce((acc: any, curr: any) => acc + curr.amount, 0) : 0;

  const pickUpPricesInitial = extraListInitial.concat(notCoveredListInitial);
  const pickUpPricesObj = groupBy(pickUpPricesInitial, (d: any) => { return d?.currencyCode ? d?.currencyCode : d?.charge?.currencyCode; });
  const pickUpPrices = Object.values(pickUpPricesObj);
  
  const pickUpPrice = expraAmount + notCoveredAmount;
  const hasAllChargesSameCurrency = hasSameCurrencyExtra && hasSameCurrencyNotCovered;

  return { pickUpPrices, pickUpPrice, hasAllChargesSameCurrency, expraList };
}

const INCOMING_DATE_FORMAT = 'yyyy-MM-DDTHH:mm:ss';

export const GetCarDetails = (car: any, isCapitalVacations: boolean) => {
  const rental = car?.rental;
  const info = car?.info;
  const pickUpLocationDetail = car?.pickUpLocationDetail;
  const dropOffLocationDetail = car?.dropOffLocationDetail;
  const currency = car?.currency;
  const price = car?.price;
  const payNowPrice = car?.payNowPrice;
  const savings = car?.saving;
  const taxes = car?.taxes ? Math.floor(car?.taxes * 100) / 100  : null;
  const maxWalletClientCash = car?.maxWalletClientCash;

  const pickUpDate = moment(pickUpLocationDetail?.dateTime, INCOMING_DATE_FORMAT);
  const dropOffDate = moment(dropOffLocationDetail?.dateTime, INCOMING_DATE_FORMAT);

  const charges = rental?.rentalRate?.charges?.filter((c: any) => c?.description?.length > 1);
  const pricedCoverages = info?.pricedCoverages;
  const notCovered = pricedCoverages?.filter((p: any) => !p?.included  && p?.required);

  const days = Math.ceil(dropOffDate.diff(pickUpDate, 'hours') / 24);
  const pricePerDay = (isCapitalVacations ? price : payNowPrice) / (days ? days :  1);

  const pubPrice = getMinPublicPrice(car);

  return {
    currency,
    maxWalletClientCash,
    charges,
    notCovered,
    savings,
    taxes,
    price,
    payNowPrice,
    pricePerDay,
    days,
    pubPrice
  }
}

export const GetCarDetailsWalletPrice = (account: any, userWalletData: any, marginatorPrice: number, maxWalletClientCash: number, marginatorPriceCapital: number, selectedCarsSearchClientCash: IClientCash, isCheckout: boolean, isMLM: boolean) => {
  const walletPrices = getWalletPrices(account, userWalletData, selectedCarsSearchClientCash, marginatorPrice, maxWalletClientCash, isCheckout ? WalletPriceType.Book : WalletPriceType.Details);

  const displayWalletSavings = (account?.walletWalletSavings && (walletPrices?.priceSavings > 0 || walletPrices?.maxClientCashAmountAllow > 0) && !!walletPrices?.maxClientCashAmountAllow)// || isMLM;

  const marginatorPriceFinal = account?.isCapitalVacations ? marginatorPriceCapital : displayWalletSavings ? walletPrices?.priceFinalMax : walletPrices?.priceFinal;
  const payNowMarginatorPriceFinal = displayWalletSavings ? walletPrices?.priceFinalMax : walletPrices?.priceFinal;

  const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;

  const maxWalletClientCashValueInt = maxWalletClientCash / convertionRate;
  const maxWalletClientCashValue = account?.walletNoDecimals ? Math.floor(maxWalletClientCashValueInt) : maxWalletClientCashValueInt;
  const maxWalletClientCashService = walletPrices?.maxClientCashAllow ? walletPrices?.maxClientCashAllow : 0;
  const maxWalletClientCashFinal = (maxWalletClientCashValue && maxWalletClientCashValue <= maxWalletClientCashService) ? maxWalletClientCashValue : maxWalletClientCashService;
  const maxWalletClientCashCalculated = account?.walletNoDecimals ? Math.floor(maxWalletClientCashFinal) : maxWalletClientCashFinal;

  return { displayWalletSavings, walletPrices, marginatorPriceFinal, payNowMarginatorPriceFinal, maxWalletClientCashCalculated };
}
