import React from 'react';

import { CondoDetailsWrapper } from '@components';
import { Responsive } from '@share/utils';
import { getTimeout, UrlUtils, scrollTop, getAccountUsernameFromPath } from '@share/utils';
import { ISessionKey } from '@share/common-types';
import { CONDO_SESSION_KEY, DEALID_LABEL, QUOTE_LABEL } from '@share/constants';
import { Routes } from '@share/constants';

import './style.scss';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  isFromAdminPage: boolean;
}

const zero = 0;

class CondoDetailPageComponent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);

    scrollTop();

    if (!props.isFromAdminPage) {
      const values = UrlUtils.getValues();
      const session: ISessionKey = values[CONDO_SESSION_KEY] as ISessionKey;

      const isDealOrQuote = (values[DEALID_LABEL] as string) || (values[QUOTE_LABEL] as string);

      if (!!isDealOrQuote && (session && session.expireDate && getTimeout(session.expireDate) <= zero) || !session) {
        const accountName = getAccountUsernameFromPath(this.props.history);
        history.replaceState(null, null, `/${accountName}${Routes.CondoSearch}${location.search}`);
        location.reload();
      }
    }
  }

  render(): React.ReactNode {
    return (
      <div className="condo-details-page">
        <Responsive>
          <CondoDetailsWrapper isFromAdminPage={this.props.isFromAdminPage} />
        </Responsive>
      </div>
    );
  }
}

export const CondoDetailPage = connect()(withRouter(CondoDetailPageComponent));
