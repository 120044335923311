import React from 'react';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { get } from 'lodash';

import {
  datesActions,
  filtersActions,
  getHotels,
  ILoginState,
  locationActions,
  roomsActions
} from '@share/store/slices';
import { getNormalizedRoomsResponse, RootState, UrlUtils } from '@share/utils';
import { ILocation, IRoom } from '@share/common-types';

import { FILTERS_LABEL } from '@share/constants';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import './style.scss';
import moment from 'moment';

interface IMapStateToProps {
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setRooms: (rooms: IRoom[]) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  getHotels: () => void;
  resetFilters: () => void;
  clearDatesError: () => void;
  clearLocationError: () => void;
  setSelectLocation: (location: ILocation) => void;
  setSelectLocationLabel: (label: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

class FeaturedDestinationsComponent extends React.Component<IProps> {

  handleSearch = (destination: any) => {
    const {
      setDatesSelected,
      setRooms,
      getHotels,
      resetFilters,
      clearDatesError,
      clearLocationError,
      setSelectLocation,
      setSelectLocationLabel,
      loginStore
    } = this.props;
    const { loading, account } = loginStore;

    if (!loading) {
      clearDatesError();
      clearLocationError();
      setSelectLocationLabel(destination?.name);
      setSelectLocation({
        code: destination?.code,
        geoLocation: destination?.geoLocation,
        name: destination?.name,
        type: destination?.type
      } as ILocation);
  
      resetFilters();
      
      UrlUtils.setUrl(FILTERS_LABEL, null);
  
      const accountLeadTime = account?.leadTime;
      const destinationLeadTime = destination?.leadDays ? destination?.leadDays : 1;
      const leadDays = accountLeadTime + destinationLeadTime;

      const startDateMoment = moment().add(leadDays, 'days');
      const startDate = startDateMoment.format('yyyy-MM-DD');
      const endDate = startDateMoment.add(7, 'days').format('yyyy-MM-DD');
      setDatesSelected({ startDate, endDate });
      
      setRooms(getNormalizedRoomsResponse([{ adultsCount: 2, kidsAges: [] }]));
  
      getHotels();

      window.scrollTo(0, 0);
    }
  };

  render() {
    const { loginStore } = this.props;
    const { account, loading, accountFeaturedDestinations } = loginStore;

    if (!account?.featuredDestinations?.length || !accountFeaturedDestinations?.length || loading) {
      return null;
    }
    
    const featuredDestinationsFinal = account?.featuredDestinations.map(d => {
      const image = get(accountFeaturedDestinations.filter(aFD => aFD?.destinationId?.toString() === d?.code), '[0].urlImage');
      return image ? { ...d, image } : null;
    }).filter(d => !!d);

    if (!featuredDestinationsFinal?.length) {
      return null;
    }

    return (
      <div className="featured-destinations">
        <p className="featured-destinations__title">
          <FormattedMessage id="featured.destinations.title" />
        </p>
        <div className="container-fluid">
          <div className="row">
            {featuredDestinationsFinal.map((destination, index) => (
              <div key={index} className="col-lg-4">
                <div className={`featured-destinations__item index_${index % 3}`} onClick={() => this.handleSearch(destination)}>
                  <img width="100%" height="100%" src={destination?.image} />
                  <div className="featured-destinations__item-label-background"></div>
                  <div className="featured-destinations__item-label">
                    <label>{get(destination?.name.split(','), '[0]')}</label>
                  </div>
                </div>
              </div>))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, Action>,
): IMapDispatchToProps => ({
  getHotels: () => {
    dispatch(getHotels());
  },
  resetFilters: () => {
    dispatch(filtersActions.resetFilters());
  },
  setSelectLocation: (location: ILocation) => {
    dispatch(locationActions.setSelectLocation(location));
  },
  setSelectLocationLabel: (label: string) => {
    dispatch(locationActions.setSelectLocationLabel(label));
  },
  setDatesSelected: (dates: { startDate: string; endDate: string }) => {
    dispatch(datesActions.setDatesSelected(dates));
  },
  setRooms: (rooms: IRoom[]) => {
    dispatch(roomsActions.setRooms(rooms));
  },
  clearLocationError: () => {
    dispatch(locationActions.setError(''));
  },
  clearDatesError: () => {
    dispatch(datesActions.setError(''));
  },
});

export const FeaturedDestinations = connect(mapStateToProps, mapDispatchToProps)(withRouter(FeaturedDestinationsComponent));
