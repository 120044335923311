import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { HotelDetailsWrapper } from '@components';
import { getTimeout, UrlUtils, scrollTop, getAccountUsernameFromPath, VACATIONS_PAGE } from '@share/utils';
import { ISessionKey } from '@share/common-types';
import { Routes, SESSION_KEY_LABEL } from '@share/constants';

import './style.scss';

interface IProps extends RouteComponentProps {
  isFromAdminPage?: boolean;
  isVacationRentals?: boolean;
}

const zero = 0;

class HotelDetailPageComponent extends React.Component<IProps> {
  componentDidMount(): void {
    scrollTop();
    const values = UrlUtils.getValues();
    const session: ISessionKey = values[SESSION_KEY_LABEL] as ISessionKey;
    const accountName = getAccountUsernameFromPath(this.props.history);

    if ((session && session.expireDate && getTimeout(session.expireDate) <= zero)) {
      if(this.props.isVacationRentals) {
        history.replaceState(null, null, `/${accountName}${Routes.Search}/${VACATIONS_PAGE}${location.search}`);
      } else {
        history.replaceState(null, null, `/${accountName}${Routes.Search}${location.search}`);
      }

      location.reload();
    }
  }

  render(): React.ReactNode {
    return (
      <div className="hotel-details-page">
        <HotelDetailsWrapper isFromAdminPage={this.props.isFromAdminPage} isVacationRentals={this.props.isVacationRentals} />
      </div>
    );
  }
}

export const HotelDetailPage = connect()(withRouter(HotelDetailPageComponent));
