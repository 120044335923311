import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ErrorAlertSvg } from '@assets';
import { BlueButton } from '@share/components';
import { Routes } from '@share/constants';
import './style.scss';
import { connect } from 'react-redux';
import { VACATIONS_PAGE, getAccountUsernameFromPath } from '@share/utils';

interface IProps extends RouteComponentProps {
  isMemberType: boolean;
  isExternal: boolean;
  isExpired?: boolean;
  isVacationRentals?: boolean;
}

class CancellationNotAvailableComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isMemberType, isVacationRentals, isExternal, isExpired = false } = this.props;
    const accountName = getAccountUsernameFromPath(this.props.history);

    return (
      <div className="cancellation-not-available">
        <div>
          <ErrorAlertSvg />
          <p className="cancellation-not-available__title">
            <FormattedMessage id="cancellation.is.not.available" />
          </p>
          <p className="cancellation-not-available__description">
            {isExpired ? (
              <FormattedMessage id="cancellation.link.has.expired" />
            ) : (
              <FormattedMessage id="you.can.not.cancel" />
            )}
          </p>
        </div>
        {isMemberType || isExpired ? (
          <div className={`cancellation-not-available__footer ${!isExpired ? 'flex-end' : ''}`}>
            {isExpired ? (
              <p className="cancellation-not-available__footer-text">
                <FormattedMessage id="case.link.has.expired" />
              </p>
            ) : null}
            {(isMemberType && !isExternal) ? (
              <div className="cancellation-not-available__btn-wrapper">
                <Link to={isVacationRentals ? `/${accountName}${Routes.Search}/${VACATIONS_PAGE}` : `/${accountName}${Routes.Search}`}>
                  <BlueButton>
                    <FormattedMessage id="start.searching" />
                  </BlueButton>
                </Link>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export const CancellationNotAvailable = connect()(withRouter(CancellationNotAvailableComponent));
