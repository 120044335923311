import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { RootState } from '@share/utils';
import { IMenuState } from '@share/store/slices';
import { BlueButton } from '@share/components';
import { MY_VC_ACCOUNT_LINK } from '@constants';
import { RENEWAL_AGENCY_ASHEVILLE } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

class MembershipComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { menuStore } = this.props;
    const isRenewalMember = menuStore?.items?.renewalAgency === RENEWAL_AGENCY_ASHEVILLE;

    if (!isRenewalMember) {
      return null;
    }

    return (
      <div className="membership">
        <div className="membership__wrapper">
          <div className="membership__text">
            <p className="membership__title">
              <FormattedMessage id="renew.my.membership" />
            </p>
            <p className="membership__description">
              <FormattedMessage id="need.to.book.trip.and.think.my.membership.expired" />
            </p>
          </div>
          <a
            href={MY_VC_ACCOUNT_LINK}
            className="membership__btn-wrapper"
            target="_blank"
            rel="noreferrer"
          >
            <BlueButton>
              <FormattedMessage id="renew.membership" />
            </BlueButton>
          </a>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
  };
};

export const Membership = connect(mapStateToProps)(MembershipComponent);
