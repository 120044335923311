
import { connect } from 'react-redux';
import { RootState, Toaster } from '@share/utils';

import AIGLogo from '@assets/images/aig-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faQuestion, faSuitcase, faUsd, faUser } from '@fortawesome/free-solid-svg-icons';
import { IInsuranceState, InsuranceSelection, insuranceActions } from '@store/slices';

import { isEmpty } from 'lodash';
import { Checkbox } from 'antd';
import { CheckboxChangeEventTarget } from 'antd/lib/checkbox/Checkbox';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import './style.scss';
import { ILoginState, IMenuState } from '@share/store/slices';

const COUNTRY_CODE = 'COUNTRY_CODE';
const STATE_CODE = 'STATE_CODE';
const PRODUCT_CODE = 'PRODUCT_CODE';

const POLICY_INSURANCE_URL = `https://webservices.travelguard.com/Product/FileRetrieval.aspx?CountryCode=${COUNTRY_CODE}&StateCode=${STATE_CODE}&ProductCode=${PRODUCT_CODE}&PlanCode=NW21&FileType=PROD_PLAN_GM`;
const DISCALIMER_URL = 'https://www.travelguard.com/legal/disclaimer';

interface IMapStateToProps {
  insuranceStore: IInsuranceState;
  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelection: (selection: InsuranceSelection) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  textOnly?: boolean;

  onChange?: () => void;
}

function InsuranceComponent(props: IProps) {
  const { textOnly, insuranceStore, loginStore, menuStore, intl, setSelection, onChange } = props;
  const { insurance, travelersRequest, loading, error, selection } = insuranceStore;
  const { account } = loginStore;
  const { items } = menuStore;

  if (loading || !isEmpty(error) || !insurance || !account?.enableInsurance || items?.isRemoveInsurance) {
    return null;
  }

  const policyInsuranceUrl = POLICY_INSURANCE_URL
                                .replace(COUNTRY_CODE, travelersRequest?.residency?.isoCountry as string)
                                .replace(STATE_CODE, travelersRequest?.residency?.isoStateOrProvince as string)
                                .replace(PRODUCT_CODE, insurance?.productDetails?.productCode as string);

  const handleYesSelection = (e: { target: CheckboxChangeEventTarget }) => {
    const selection: any = e.target.checked ? InsuranceSelection.YES : null;
    setSelection(selection);


    handleToaster(e.target.checked ? 'insurance.policy.protected' : 'insurance.policy.un_protected', !e.target.checked);
  }

  const handleNoSelection = (e: { target: CheckboxChangeEventTarget }) => {
    const selection: any = e.target.checked ? InsuranceSelection.NO : null;
    setSelection(selection);

    if (onChange) {
      onChange();
    }

    if (e.target.checked) {
      handleToaster('insurance.policy.un_protected', true);
    }
  }
  
  const handleToaster = (message: string, isWarning?: boolean) => {
    if (isWarning) {
      Toaster.warning(intl.formatMessage({ id: message }), {
        position: 'bottom-center',
        icon: false,
        hideProgressBar: true,
      });
    } else {
      Toaster.info(intl.formatMessage({ id: message }), {
        position: 'bottom-center',
        icon: false,
        hideProgressBar: true,
      });
    }
  }
  
  return (
    <div className="insurance-container">
      <div className="insurance-container__header">
        <div className="header-title">Travel Guard &#174; <FormattedMessage id="insurance.travel.insurance" /></div>
        <div className="header-logo"><img src={AIGLogo} width="60" alt="aig_logo" /></div>
      </div>
      <div className="insurance-container__content">
        <div className="title"><FormattedMessage id="insurance.cover.your.trip" /></div>
        <div className="sub-title"><FormattedMessage id="insurance.ignore.unexpected" /></div>
        <div className="container-fluid" style={{ marginBottom: '10px' }}>
          <div className="row">
            <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faUser} /></div><FormattedMessage id="insurance.family.member" /></div>
            <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faPlane} /></div><FormattedMessage id="insurance.miss.connection" /></div>
            <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faQuestion} /></div><FormattedMessage id="insurance.lose.passport" /></div>
            <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faPlane} /></div><FormattedMessage id="insurance.trip.delayed" /></div>
            <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faSuitcase} /></div><FormattedMessage id="insurance.baggage.lost" />.</div>
            <div className="col-md-6 insurance-option"><div className="icon-insurance"><FontAwesomeIcon icon={faUsd} /></div><FormattedMessage id="insurance.last.minute.accomodation" /></div>
          </div>
        </div>
        <div style={{ marginBottom: '20px' }}><FormattedMessage id="insurance.message" /> <a href={policyInsuranceUrl} target="_blank"><FormattedMessage id="insurance.message.click" /></a></div>
        <div className={`yes-selection ${textOnly ? 'text-only' : ''}`}>
          {!textOnly ? (
            <Checkbox
              checked={selection === InsuranceSelection.YES}
              onChange={handleYesSelection}
            />) : null}

          <label className="selection-text"><FormattedMessage id="insurance.yes" /> ${insurance?.productDetails?.price} <small>(<FormattedMessage id="insurance.only.us" />)</small></label>
        </div>
        <div className={`yes-selection-text ${textOnly ? 'text-only' : ''}`}>
          <FormattedMessage 
            id="insurance.acknowledge"
            values={{
              ap: (msg: string) => {
                return (
                  <a target="_blank" href={policyInsuranceUrl}>
                    <FormattedMessage id="insurance.policy.insurance" />
                  </a>);
              },
              ai: (msg: string) => {
                return (
                  <a target="_blank" href={DISCALIMER_URL}>
                    <FormattedMessage id="insurance.important.disclousures" />
                  </a>);
              },
            }}
          />
        </div>

        {!textOnly ? (
          <div className="no-selection">
            <Checkbox
              checked={selection === InsuranceSelection.NO}
              onChange={handleNoSelection}
            />
            <label className="selection-text"><FormattedMessage id="insurance.no" /></label>
          </div>) : null}

        <div className="coverage-message"><FormattedMessage id="insurance.coverage" /> <a href={DISCALIMER_URL} target="_blank"><FormattedMessage id="insurance.coverage.click" /></a></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    insuranceStore: state.insuranceStore,
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelection: insuranceActions.setSelection,
};

const component = injectIntl(InsuranceComponent);

export const Insurance = connect(mapStateToProps, mapDispatchToProps)(component);
