import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Input, Tag } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { ILoginState } from '@share/store/slices';
import { createWebsite, IAccountUserSaveState, setIsWebSiteCreated } from '@store/slices';
import { RootState, Toaster } from '@share/utils';
import { BlueButton, Loading, LoadingSise } from '@share/components';
import { validateDomain } from '@utils';

import BackgroundImage from '@assets/images/master-classes.jpg';

import './style.scss';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface IMapStateToProps {
  loginStore: ILoginState;
  accountUserSaveStore: IAccountUserSaveState;
}

interface IMapDispatchToProps {
  createWebsite: (name: string) => void;
}

enum WebSiteStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {}

function DashboardSiteComponent(props: IProps) {
  const { intl, loginStore, accountUserSaveStore, createWebsite } = props;
  const { user } = loginStore;
  const { loading, isWebSiteCreated, webSite, error } = accountUserSaveStore;

  const webSiteName = user?.userAdditionalData?.webSite ?? '';

  const webSiteisActive = user?.userAdditionalData?.webSiteIsActive ?? false;

  const isNotEmptyWebSite = useMemo(() => !isEmpty(webSiteName), [webSiteName]);

  const [name, setName] = useState<string>(isNotEmptyWebSite ? webSiteName : webSite);

  const isNotEmptyName = useMemo(() => !isEmpty(name), [name]);
  const url = useMemo(() => `${import.meta.env.VITE_WIDGET_REDIRECT}${name}/search`, [name]);
  const isNameValid = useMemo(() => validateDomain(name), [name]);

  const handleCreateWebsite = () => {
    if (isNameValid) {
      createWebsite(name);
    }
  };

  const validateShow = () => {
    return isNotEmptyWebSite || isWebSiteCreated;
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (isEmpty(value) || validateDomain(value)) {
      setName(value.toLowerCase());
    } else {
      e.preventDefault();
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(url);
    Toaster.success(intl.formatMessage({ id: 'sidekick.rewards.clipboard.message' }));
  };

  const buttonDisabled = useMemo(
    () => isNotEmptyWebSite || !isNotEmptyName || !isNameValid || loading,
    [isNotEmptyWebSite, isNotEmptyName, isNameValid, loading],
  );

  const cleanText = (text: string): string => {
    // Function for clean the text ':', '"', y '}'
    return text.replace(/[:}"]/g, '');
  };

  const handleOpen = () => {
    window.open(url, '_blank');
  };

  return (
    <div className="dashboard-site-wrapper">
      <div
        className="dashboard-site-wrapper__header"
        style={{
          backgroundImage: `linear-gradient(rgba(32, 39, 77, 0.3), rgba(32, 39, 77, 0.3)), url(${BackgroundImage})`,
        }}
      >
        <div className="dashboard-site-wrapper__title">
          <FormattedMessage id="personal.web.site" />
        </div>
      </div>

      <div className="dashboard-site-wrapper__subtitle">
        <FormattedMessage id="guaranteed.rates" />
      </div>
      <div className="dashboard-site-wrapper__message">
        <FormattedMessage id="dashboard.side.message" />
      </div>

      <div className={`dashboard-site-wrapper__input ${validateShow() ? 'input-disabled' : ''}`}>
        <Input
          value={name}
          onChange={(e) => handleNameChange(e)}
          disabled={isNotEmptyWebSite || loading || isWebSiteCreated}
        />
        {!isWebSiteCreated && (
          <BlueButton disabled={buttonDisabled} onClick={handleCreateWebsite}>
            {loading && <Loading size={LoadingSise.Small} />}
            <FormattedMessage id="create" />
          </BlueButton>
        )}

        {validateShow() ? (
          <Tag
            icon={webSiteisActive ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
            color={webSiteisActive ? 'success' : 'default'}
            className="dashboard-site-wrapper__tag"
          >
            {webSiteisActive ? (
              <FormattedMessage id="approved" />
            ) : (
              <FormattedMessage id="pending" />
            )}
          </Tag>
        ) : null}
      </div>

      {!isEmpty(error) && !isWebSiteCreated && !isNotEmptyWebSite && (
        <Tag icon={<CloseCircleOutlined />} color="error" className="dashboard-site-wrapper__error">
          {cleanText(error.split('reason')[1])}
        </Tag>
      )}
      {!isEmpty(webSite) && isWebSiteCreated && (
        <Tag
          icon={<CheckCircleOutlined />}
          color="success"
          className="dashboard-site-wrapper__success"
        >
          {webSite} <FormattedMessage id="user.create" />
        </Tag>
      )}

      {(isNotEmptyName || isWebSiteCreated) && (
        <div className="dashboard-site-wrapper__contact">
          <FormattedMessage id="new.url" /> <strong className="link">{url}</strong>
          {validateShow() ? (
            <>
              <div className="dashboard-site-wrapper__contact-buttons">
                <Button className="white-button" onClick={handleOpen}>
                  <FormattedMessage id="open" />
                </Button>
                <BlueButton style={{ marginLeft: '12px' }} onClick={handleShare}>
                  <FormattedMessage id="results.hotel.card.compare.modal.share" />
                </BlueButton>
              </div>
            </>
          ) : null}
        </div>
      )}

      <div className="dashboard-site-wrapper__contact">
        <FormattedMessage id="constact.administrator" />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    accountUserSaveStore: state.accountUserSaveStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  createWebsite,
};

export const DashboardSite = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DashboardSiteComponent));
