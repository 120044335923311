import React from 'react';
import { connect } from 'react-redux';

import { CarRentalLogos } from '@common-types';
import { CarsList } from '@components';

import { CarsLogos } from '../cars-logos';

import CarsMain from '@share/assets/images/cars-main.jpg';

import './style.scss';

interface IProps {}

export class CarsWrapperComponent extends React.Component<IProps> {
  render(): React.ReactNode {

    return (
      <div className="cruises-wrapper">
        <div
          className="cruises-wrapper__header"
          style={{ backgroundImage: `url(${CarsMain})` }}
        >
          <div className="cruises-wrapper__wrapper">
            <div className="cruises-wrapper__container">
              <h1 className="cruises-wrapper__header_title">
                Car Rental
              </h1>
              <p className="cruises-wrapper__header_subtitle">
                Let our Experts and our Patented Search Engine Help You Find the Perfect Car for You and Your Loved Ones.
              </p>
            </div>
          </div>
        </div>
        
        <div className="cruises-wrapper__wrapper">
          <CarsList />
        </div>
                
        <CarsLogos logos={CarRentalLogos} />
      </div>
    );
  }
}

export const CarsWrapper = connect()(CarsWrapperComponent);
