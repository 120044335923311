import React from 'react';
import format from 'date-fns/format';
import lowerCase from 'lodash/lowerCase';
import ReactGA from 'react-ga4';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Checkbox, Tooltip } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { onCondoPage, getCondoTextRating, HasPermission, ViewQuotesSharePermission, getWalletPrices, WalletPriceType } from '@utils';
import { getRankingClass, getSelectedCurrency, renderNumber } from '@share/utils';
import { InfoSvg, RedWarningSvg, MapPinSvg } from '@assets';
import { CondoRequestTypeEnum, IClientCash, ICondo } from '@share/common-types';
import { SupplierType } from '@share/common-types';
import { CondoMatchTypeEnum } from '@share/common-types';
import { MINIMUM_RATING_TO_SHOW } from '@constants';
import { RootState } from '@share/utils';
import { ICondosState, ILoginState, IMenuState, condosActions } from '@share/store/slices';
import { Currency, HotelStars, WalletMessage } from '@components';
import { C_R_SELECT_ROOM } from '@share/constants';

import { CondoCarouselHotelImages } from '../condo-carousel-hotel-images';

import './style.scss';
import { RefundableEnum } from '@common-types';

interface IMapStateToProps {
  menuStore: IMenuState;
  condosStore: ICondosState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setSelectedCondoSearchClientCash: (clientCash: IClientCash) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps {
  condo: ICondo;
  lastRequestType: CondoRequestTypeEnum;

  disabled?: boolean;
  matches?: boolean;
  compared?: boolean;
  disableCompare?: boolean;
  displayCompare?: boolean;
  isMap?: boolean;

  onCompareSelect?: (condo: ICondo) => void;
  onMouseEnter?: (condoId: number) => void;
  onMouseLeave?: (condoId: number) => void;
  onSelect?: () => void;
}

const ZERO = 0;
const ONE = 1;
const PERCENTS = 100;
const DATE_FORMAT = 'd MMM';

class CondoHotelCardWrapper extends React.Component<IProps> {
  getMinPublicPrice = (): number => {
    const { publicPrice } = this.props.condo;

    if (publicPrice) {
      return publicPrice;
    }

    return ZERO;
  };

  onCondoPage = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { condo, loginStore, condosStore } = this.props;
    const { condoId, price } = condo;
    const { account, userWalletData } = loginStore;
    const { selectedCondoSearchClientCash } = condosStore;

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
    const maxWalletClientCashValueInt = condo?.maxWalletClientCash / convertionRate;
    const maxWalletClientCashValue = account?.walletNoDecimals ? Math.floor(maxWalletClientCashValueInt) : maxWalletClientCashValueInt;

    const prices = getWalletPrices(account, userWalletData, selectedCondoSearchClientCash, price, maxWalletClientCashValue, WalletPriceType.Search);
    const clientCashApplied = prices?.clientCashApplied;

    this.props.setSelectedCondoSearchClientCash({ ...selectedCondoSearchClientCash, selectedPropertyClientCash: clientCashApplied, selectedPropertyReviewClientCash: null });

    onCondoPage(account?.name, condoId, this.props.history);

    ReactGA.event({
      category: account.name,
      action: `${C_R_SELECT_ROOM}_${account.name.toUpperCase()}`,
      label: `User selected a condo on resuls`,
      nonInteraction: false,
    });
  };

  getCondoInfo = (): string[] => {
    const { intl, condo } = this.props;
    const { sleepsCount, apartmentType, bathroomsCount, privacyOccupancy } = condo;
    const condoInfo: string[] = [];

    if (sleepsCount && privacyOccupancy) {
      condoInfo.push(
        `${intl.formatMessage({ id: 'max.sleeps' })} ${sleepsCount} (${lowerCase(
          intl.formatMessage({
            id: 'private',
          }),
        )}: ${privacyOccupancy})`,
      );
    }

    if (apartmentType) {
      condoInfo.push(apartmentType);
    }

    if (bathroomsCount) {
      condoInfo.push(intl.formatMessage({ id: 'bathrooms.count' }, { count: bathroomsCount }));
    }

    return condoInfo;
  };

  render(): React.ReactNode {
    const {
      disabled = false,
      isMap,
      condo,
      lastRequestType,
      onMouseEnter = () => void ZERO,
      onMouseLeave = () => void ZERO,
      onSelect = () => void ZERO,
      matches = true,
      menuStore,
      loginStore,
      condosStore,
    } = this.props;
    const { items } = menuStore;
    const promo = items?.promo;
    const userCredits = items?.leisureCredits;
    const isSavingsExisted = (promo && Number(userCredits) > ZERO) || !promo;
    const {
      condoId,
      images,
      displayName,
      pricePerNight,
      fullAddress,
      publicPrice,
      savings,
      price,
      matchType,
      availableDates = [],
      totalNights = ZERO,
      rating,
      supplierIdentifier,
      isAllInclusive,
      supplierType,
      refundability,
    } = condo;
    const { account, user, userWalletData } = loginStore;
    const { selectedCondoSearchClientCash } = condosStore;

    const pubPrice = this.getMinPublicPrice();
    const save = (price * PERCENTS) / pubPrice;
    const savePercents = Math.floor(PERCENTS - save);
    const isExact = matchType === CondoMatchTypeEnum.Exact;
    const isExactDisabled = isExact && (!availableDates || availableDates.length === ZERO);
    const isFlex = lastRequestType === CondoRequestTypeEnum.Flexible;
    const isStrict = lastRequestType === CondoRequestTypeEnum.Strict;
    const isAnytime = lastRequestType === CondoRequestTypeEnum.Anytime;
    const hideRating = rating && rating.value === ZERO;

    const background: any = account? account?.buttonColor : null;
    const styleButton = !isEmpty(background)? { background } : {};  
    const isRecommended = supplierType === SupplierType.Guesty;
    const currency = getSelectedCurrency(loginStore.account);

    const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
    const maxWalletClientCashValueInt = condo?.maxWalletClientCash / convertionRate;
    const maxWalletClientCashValue = account?.walletNoDecimals ? Math.floor(maxWalletClientCashValueInt) : maxWalletClientCashValueInt;

    const prices = getWalletPrices(account, userWalletData, selectedCondoSearchClientCash, price, maxWalletClientCashValue, WalletPriceType.Search);

    const displayWalletSavings = account?.walletWalletSavings && (prices?.priceSavings > 0 || prices?.maxClientCashAmountAllow > 0) && !!prices?.maxClientCashAmountAllow;

    const finalPrice = price - (displayWalletSavings ? 
                                  (prices?.maxClientCashAmountAllow ? prices?.maxClientCashAmountAllow : 0) :
                                  (prices?.clientCashAmountApplied ? prices?.clientCashAmountApplied : 0));

    return (
      <div
        className={`condo-hotel-card ${isAnytime ? 'is-any-time' : ''}`}
        onMouseEnter={() => onMouseEnter(condoId)}
        onMouseLeave={() => onMouseLeave(condoId)}
        onClick={() => {
          if (matches) {
            onSelect();
          }
        }}
      >
        {disabled && <div className="condo-hotel-card__disabled-overlay" />}
        <div className={`condo-hotel-card__wrapper ${isRecommended ? 'recommended-condo' : ''}`}>
          <div className="condo-hotel-card__slider">

          {isRecommended
            ? (<div className="condo-hotel-card__label-recommended"><p className="condo-hotel-card__label-title"><FormattedMessage id="recommended.condo" /></p></div>) 
            : null}

            {isAllInclusive ? (
              <div className={`condo-hotel-card__label-all-inclusive ${isRecommended ? 'two-label' : ''}`}>
                <p className="condo-hotel-card__label-title">
                  <FormattedMessage id="all.inclusive" />
                </p>
              </div>) : null}

            {(savePercents && savePercents > ZERO && !disabled && !displayWalletSavings) ? (
              <div className={`condo-hotel-card__label
                ${(isRecommended && !isAllInclusive) || (!isRecommended && isAllInclusive) ? 'two-label' : isRecommended && isAllInclusive ? 'three-label' : ''}`}
                style={{ borderBottomRightRadius: '22px', borderTopRightRadius: '22px' }}>
                <p className="condo-hotel-card__label-title" style={{ fontSize: '19px' }}>
                  <FormattedMessage id={account?.forceMemberWording ? 'member.save' : 'save'} /> {savePercents}%
                </p>
              </div>) : null}
            
            {displayWalletSavings ? (
              <div className={`condo-hotel-card__label ${isAllInclusive ? 'two-label' : ''}`} style={{ borderBottomRightRadius: '22px', borderTopRightRadius: '22px' }}>
                <p className="condo-hotel-card__label-title" style={{ fontSize: '19px' }}>
                  <FormattedMessage id="save.up.to" values={{ number: prices?.priceSavings ? prices?.priceSavings : '<1' }} />
                </p>
              </div>) : null}

            {(isFlex || isStrict) && availableDates?.length ? (
              <div className="condo-hotel-card__date-label">
                <div className="condo-hotel-card__date">
                  <p className="condo-hotel-card__date-text">
                    {format(new Date(availableDates[ZERO].from), DATE_FORMAT)} -{' '}
                    {format(new Date(availableDates[ZERO].to), DATE_FORMAT)}
                  </p>
                </div>
                {availableDates.length > ONE && (
                  <div className="condo-hotel-card__date-more">
                    <p className="condo-hotel-card__date-more-text">
                      +{availableDates.length - ONE} <FormattedMessage id="more" />
                    </p>
                  </div>
                )}
              </div>
            ) : null}
            <CondoCarouselHotelImages images={images} />
          </div>
          <div className="condo-hotel-card__content">
            <div className="condo-hotel-card__text-info-wrapper">
              <div className="condo-hotel-card__info-items">
                <Tooltip title={displayName}>
                  <h4 className={`condo-hotel-card__info-title  ${isMap ? 'is-map' : ''}`} onClick={this.onCondoPage}>
                    {displayName}
                  </h4>
                </Tooltip>
                {pricePerNight && (
                  <div className="condo-hotel-card__label-info">
                    <p className="condo-hotel-card__label-text">
                      <FormattedMessage id="from" />
                    </p>
                    <p className="condo-hotel-card__label-price"><Currency currency={currency} />{renderNumber(pricePerNight)}</p>
                    <p className="condo-hotel-card__label-text">
                      <FormattedMessage id="per.night" />
                    </p>
                  </div>
                )}
              </div>
              <div className="condo-hotel-card__info-description">
                <div className="condo-hotel-card__address-wrapper">
                  <p className={`condo-hotel-card__address ${isMap ? 'is-map' : ''}`} style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      lineClamp: 2,
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                  }}>
                    {fullAddress}
                  </p>
                  <div className="condo-hotel-card__view-on-map" onClick={() => onSelect()}>
                    <MapPinSvg />
                    <FormattedMessage id="view.on.map" />
                  </div>
                </div>

                <div className="condo-hotel-card__info-apartment">
                  {this.getCondoInfo().map((item, index) => (
                    <div key={index} style={{ whiteSpace: 'nowrap' }}>
                      {item}
                      {index < this.getCondoInfo().length - ONE ? <div className="dot" /> : null}
                    </div>
                  ))}
                </div>
                {pricePerNight && price ? (
                  <div className="condo-hotel-card__map-mobile-info">
                    <div className="condo-hotel-card__label-info-map">
                      <p className="condo-hotel-card__label-text">
                        <FormattedMessage id="from" />
                      </p>
                      <p className="condo-hotel-card__label-price">
                        <Currency currency={currency} />{renderNumber(pricePerNight)}/
                        <FormattedMessage id="night" />
                      </p>
                    </div>


                    <div className="condo-hotel-card__price-total">
                      <p className="condo-hotel-card__price-total-text">
                        <Currency currency={currency} />{renderNumber(Math.floor(finalPrice))}
                      </p>
                      <div className="condo-hotel-card__for-info">
                        <p className="condo-hotel-card__for-info-text">
                          <FormattedMessage id="for" />{' '}
                          <FormattedMessage id="nights" values={{ count: totalNights }} />
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={`condo-hotel-card__rating ${isMap ? 'is-map' : ''}`}>
                  {(rating?.value || rating.reviewsCount) ? (
                    <div className="condo-hotel-card__trust-you-info">
                      <div
                        className="condo-hotel-card__trust-you-icon"
                        style={{ opacity: hideRating ? 0 : 1 }}
                      >
                        <HotelStars stars={1} />
                      </div>
                      <p
                        className="condo-hotel-card__trust-you-text"
                        style={{ opacity: hideRating ? 0 : 1 }}
                      >
                        <span className={`condo-hotel-card__bold-text ${getRankingClass(rating.value)}`}>
                          {renderNumber(rating.value, ONE)}
                        </span>
                        {rating.reviewsCount ? (
                          <>
                            ({renderNumber(rating.reviewsCount)} <FormattedMessage id="reviews" />
                            )
                          </>
                        ) : rating.value >= MINIMUM_RATING_TO_SHOW ? (
                          <FormattedMessage
                            id={getCondoTextRating(Number(renderNumber(rating.value, ONE)))}
                          />
                        ) : null}
                      </p>
                    </div>) : null}

                  <div className="condo-hotel-card__supplier">
                    {refundability && refundability !== RefundableEnum.Unknown ? (
                      <div className="condo-text-container" style={{ height: '43px' }}>
                        <div className="hotel-card-wrapper__refundable">
                          {refundability === RefundableEnum.Refundable && (
                            <div className="hotel-card-wrapper__refundable-ref">
                              <FormattedMessage id="refundable" />
                              <InfoSvg />
                            </div>)}
                          {refundability === RefundableEnum.Nonrefundable && (
                            <div className="hotel-card-wrapper__refundable-nonref">
                              <FormattedMessage id="non.refundable" />
                              <InfoSvg />
                            </div>)}
                        </div>
                      </div>) : null}
                  </div>

                  {supplierIdentifier ? <div className="condo-hotel-card__supplier"><FormattedMessage id={`suppliers.${supplierIdentifier}`} /></div> : null}
                </div>
              </div>
            </div>
            
            {!account?.isMLM ? (
              <WalletMessage
                price={price}
                currency={currency}
                type={WalletPriceType.Search}
                isOneLine
                isCondo
                style={{ marginBottom: '15px' }}
              />) : null}

            <div className="condo-hotel-card__price-content mobile">
              {isExactDisabled && !isAnytime ? (
                <div className="condo-hotel-card__exact-disabled">
                  <RedWarningSvg /> <FormattedMessage id="rental.not.available" />
                </div>
              ) : (
                <>
                  <div className="condo-hotel-card__content-left">
                    {(publicPrice && ! displayWalletSavings) ? (
                      <div className="condo-hotel-card__price-public">
                        {!isMap ? (
                          <p className="condo-hotel-card__public-text">
                            <FormattedMessage id="avg.public.price" />
                          </p>) : null}
                        <p className={`condo-hotel-card__public-text last ${isMap ? 'is-map' : ''}`}>
                          <Currency currency={currency} />{renderNumber(publicPrice, 2)}
                        </p>
                      </div>) : null}

                    {displayWalletSavings ? (
                      <div className="condo-hotel-card__price-public">
                        {!isMap ? (
                          <p className="condo-hotel-card__public-text">
                            <FormattedMessage id="avg.public.price" />
                          </p>) : null}
                        <p className={`condo-hotel-card__public-text last ${isMap ? 'is-map' : ''}`}>
                          <Currency currency={currency} />{renderNumber(price, 2)}
                        </p>
                      </div>) : null}

                    {(!!savings && savings > 0 && isSavingsExisted && !displayWalletSavings) ? (
                      <div className="condo-hotel-card__price-savings">
                        {!isMap ? (
                          <p className="condo-hotel-card__savings-text">
                            <FormattedMessage id="savings" />
                          </p>) : null}
                        <p className={`condo-hotel-card__savings-text last ${isMap ? 'is-map' : ''}`} style={{ position: 'relative' }}>
                          {savings >= 1 ? <><Currency currency={currency} />{renderNumber(savings)}</> : <>&lt; <Currency currency={currency} />{renderNumber(1)}</>}
                          {!!savePercents && savePercents > 0 && isSavingsExisted ? (
                            <span className="condo-hotel-card__savings">
                              {savePercents}% <FormattedMessage id="popular.destination.off" /> 
                            </span>) : null}
                        </p>
                      </div>) : null}

                    {displayWalletSavings ? (
                      <div className="condo-hotel-card__price-savings">
                        {!isMap ? (
                          <p className="condo-hotel-card__savings-text" style={{ fontSize: '13px' }}>
                            <FormattedMessage id="wallet.save.up.to.max" values={{ clientCashName: account?.walletClientCashName }} />
                          </p>) : null}
                        <p className={`condo-hotel-card__savings-text last ${isMap ? 'is-map' : ''}`} style={{ position: 'relative' }}>
                          {prices?.maxClientCashAmountAllow >= 1 ? <>{renderNumber(prices?.maxClientCashAmountAllow)}</> : <>&lt; {renderNumber(1)}</>}
                          {prices?.priceSavings > 0 && isSavingsExisted ? (
                            <span className="condo-hotel-card__savings">
                              {prices?.priceSavings}% <FormattedMessage id="popular.destination.off" /> 
                            </span>) : null}
                        </p>
                      </div>) : null}
                      
                  </div>
                  <div className="condo-hotel-card__content-right">
                    {price ? (
                      <div className="condo-hotel-card__price-total" style={{ paddingRight: '0px' }}>
                        <p className="condo-hotel-card__price-total-text">
                          <Currency currency={currency} />{renderNumber(Math.floor(finalPrice))}
                        </p>
                        <div className="condo-hotel-card__for-info" style={{ marginTop: '6px' }}>
                          <p className="condo-hotel-card__for-info-text">
                            <FormattedMessage id="for" />{' '}
                            <FormattedMessage id="nights" values={{ count: totalNights }} />
                          </p>
                          <Tooltip title={<FormattedMessage id="price.includes.taxes" />}>
                            <span className="condo-hotel-card__price-info-icon">
                              <InfoSvg />
                            </span>
                          </Tooltip>
                        </div>
                      </div>) : null}
                  </div>
                  <div
                    className="condo-hotel-card__price-content-bottom mobile"
                    onClick={this.onCondoPage}
                  >
                    <a className="condo-hotel-card__price-btn" style={styleButton}>
                      <FormattedMessage id="view.deal" />
                    </a>
                  </div>
                </>
              )}
            </div>
            {this.props.displayCompare ? (
              <div className="condo-hotel-card__price-content">
                <div className="condo-hotel-card__compare">
                  <Checkbox
                    checked={this.props.compared}
                    onChange={() => this.props.onCompareSelect(this.props.condo)}
                    disabled={this.props.disableCompare}
                  >
                    {HasPermission(user, ViewQuotesSharePermission) ? <FormattedMessage id="results.hotel.card.compare.share" /> : <FormattedMessage id="results.hotel.card.compare" /> }
                  </Checkbox>
                </div>

                <div
                  className="condo-hotel-card__price-content-bottom"
                  onClick={this.onCondoPage}
                >
                  <a className="condo-hotel-card__price-btn" style={styleButton}>
                    <FormattedMessage id="view.deal" />
                  </a>
                </div>

              </div>) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    condosStore: state.condosStore,
    loginStore: state.loginStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  setSelectedCondoSearchClientCash: condosActions.setSelectedCondoSearchClientCash
};

export const CondoHotelCard = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(CondoHotelCardWrapper)));
