
import { EXPIRATION_FIELD_KEY, IAccount, TOKEN_SSO_URL_FIELD_KEY, USER_MENU_SSO_URL_FIELD_KEY, USER_SSO_URL_FIELD_KEY } from "@share/common-types";
import { LoginType } from "@share/store/slices";
import { GetPreferenceValue, GetPreferenceValueParsed } from "@utils";
import { get, isEmpty, isNumber } from "lodash";

export const DOMAIN_FIELD_NAME = 'username';
export const NAME_FIELD_NAME = 'name';
export const EMAIL_FIELD_NAME = 'email';
export const SITEID_FIELD_NAME = 'siteId';
export const TYPE_FIELD_NAME = 'type';
export const PROPERTY_FIELD_NAME = 'property';
export const COUNTRY_FIELD_NAME = 'countryCode';

export const DOMAIN_VALIDATION_FIELD_NAME = 'usernameValid';
export const NAME_VALIDATION_FIELD_NAME = 'nameValid';
export const EMAIL_VALIDATION_FIELD_NAME = 'emailValid';
export const SITEID_VALIDATION_FIELD_NAME = 'siteIdValid';
export const EXPIRATION_VALIDATION_FIELD_NAME = 'expirationValid';
export const PROPERTY_VALIDATION_FIELD_NAME = 'propertyValid';
export const TYPE_VALIDATION_FIELD_NAME = 'typeValid';
export const COUNTRY_VALIDATION_FIELD_NAME = 'countryValid';
export const USER_TOKEN_URL_VALIDATION_FIELD_NAME = 'userUrlValid';
export const USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME = 'userMenuUrlValid';
export const TOKEN_URL_VALIDATION_FIELD_NAME = 'tokenUrlValid';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const SPECIAL_CHARACTERS_REGEX = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const WHITESPACE_REGEX = /\s/;
const NUMBER_REGEX = /^\d+$/;
const URL_REGEX = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
const DOMAIN_REGEX = /^([a-z0-9._-]+)$/;

const validateEmpty = (value: string) => !isEmpty(value);
const validateWhiteSpacesBasic = (value: string) => !WHITESPACE_REGEX.test(String(value));
const validateSpecialCharactersBasic = (value: string) => !SPECIAL_CHARACTERS_REGEX.test(String(value));
const validateWhiteSpaces = (value: string) => validateEmpty(value) && validateWhiteSpacesBasic(String(value));
const validateSpecialCharacters = (value: string) => validateEmpty(value) && validateSpecialCharactersBasic(String(value));
const validateBasic = (value: string) => validateSpecialCharacters(value) && validateWhiteSpaces(value);
const validateEmail = (value: string) => validateWhiteSpaces(value) && EMAIL_REGEX.test(String(value));
const validateNumber = (value: string) => validateSpecialCharactersBasic(value) && validateWhiteSpacesBasic(value) && NUMBER_REGEX.test(String(value));
const validateUrl = (value: string) => validateWhiteSpaces(value) && URL_REGEX.test(String(value));
const validateDomain = (value: string) => validateWhiteSpaces(value) && DOMAIN_REGEX.test(String(value));

export interface IValidation {
  valid: boolean;
  touched: boolean;
};

export interface IFormValidation {
  field: string;
  validation?: (value: string) => boolean;
};

export interface IFormValidations {
  [DOMAIN_VALIDATION_FIELD_NAME]: IValidation;
  [NAME_VALIDATION_FIELD_NAME]: IValidation;
  [EMAIL_VALIDATION_FIELD_NAME]: IValidation;
  [SITEID_VALIDATION_FIELD_NAME]: IValidation;
  [EXPIRATION_VALIDATION_FIELD_NAME]: IValidation;
  [PROPERTY_VALIDATION_FIELD_NAME]: IValidation;
  [TYPE_VALIDATION_FIELD_NAME]: IValidation;
  [COUNTRY_VALIDATION_FIELD_NAME]: IValidation;
  [USER_TOKEN_URL_VALIDATION_FIELD_NAME]: IValidation;
  [TOKEN_URL_VALIDATION_FIELD_NAME]: IValidation;
  [USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME]: IValidation;
};

export interface IFormFieldsValidation {
  [DOMAIN_FIELD_NAME]: IFormValidation;
  [NAME_FIELD_NAME]: IFormValidation;
  [EMAIL_FIELD_NAME]: IFormValidation;
  [SITEID_FIELD_NAME]: IFormValidation;
  [PROPERTY_FIELD_NAME]: IFormValidation;
  [TYPE_FIELD_NAME]: IFormValidation;
  [COUNTRY_FIELD_NAME]: IFormValidation;
  [USER_SSO_URL_FIELD_KEY]: IFormValidation;
  [TOKEN_SSO_URL_FIELD_KEY]: IFormValidation;
  [USER_MENU_SSO_URL_FIELD_KEY]: IFormValidation;
};

export const FORM_FIELDS_VALIDATIONS: IFormFieldsValidation = {
  [DOMAIN_FIELD_NAME]: {
    field: DOMAIN_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateDomain(value)
  },
  [NAME_FIELD_NAME]: {
    field: NAME_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateBasic(value)
  },
  [EMAIL_FIELD_NAME]: {
    field: EMAIL_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateEmail(value)
  },
  [SITEID_FIELD_NAME]: {
    field: SITEID_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateNumber(value)
  },
  [PROPERTY_FIELD_NAME]: {
    field: PROPERTY_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateEmpty(value)
  },
  [TYPE_FIELD_NAME]: {
    field: TYPE_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateEmpty(value)
  },
  [COUNTRY_FIELD_NAME]: {
    field: COUNTRY_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateEmpty(value)
  },
  [USER_SSO_URL_FIELD_KEY]: {
    field: USER_TOKEN_URL_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateUrl(value)
  },
  [USER_MENU_SSO_URL_FIELD_KEY]: {
    field: USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME,
    validation: (value: string) => isEmpty(value) || validateUrl(value)
  },
  [TOKEN_SSO_URL_FIELD_KEY]: {
    field: TOKEN_URL_VALIDATION_FIELD_NAME,
    validation: (value: string) => validateUrl(value)
  }
};

export const INITIAL_VALIDATION: IFormValidations = {
  [DOMAIN_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [NAME_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [EMAIL_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [SITEID_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [EXPIRATION_VALIDATION_FIELD_NAME]: { valid: true, touched: true },
  [PROPERTY_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [TYPE_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [COUNTRY_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
  [USER_TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: true, touched: true },
  [USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: true, touched: true },
  [TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: true, touched: true }
};

export const ValidateSiteIdForm = (account: IAccount): IFormValidations => {
  const { siteId } = account;
  
  return {
    [DOMAIN_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
    [NAME_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
    [EMAIL_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
    [SITEID_VALIDATION_FIELD_NAME]: { valid: isNumber(siteId), touched: true },
    [TYPE_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
    [PROPERTY_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
    [EXPIRATION_VALIDATION_FIELD_NAME]: { valid: true, touched: true },
    [COUNTRY_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
    [USER_TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: true, touched: true },
    [USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: true, touched: true },
    [TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: true, touched: true }
  }
}

export const GetValidateField = (field: string) => {
  const validation = get(FORM_FIELDS_VALIDATIONS, field)
  if (validation) {
    return validation;
  }

  return {
    validation: () => false
  }
}

export const ValidateForm = (account: IAccount): IFormValidations => {
  const { email, name, username, siteId, type, property, countryCode } = account;

  const prefExpiration = GetPreferenceValueParsed(account, EXPIRATION_FIELD_KEY, '{}');
  const prefUserSSOUrl = GetPreferenceValue(account, USER_SSO_URL_FIELD_KEY, '');
  const prefUserMenuSSOUrl = GetPreferenceValue(account, USER_MENU_SSO_URL_FIELD_KEY, '');
  const prefTokenSSOUrl = GetPreferenceValue(account, TOKEN_SSO_URL_FIELD_KEY, '');
  const isWithTokenLogin = type === LoginType.PrivateWithToken;

  return {
    [DOMAIN_VALIDATION_FIELD_NAME]: { valid: GetValidateField(DOMAIN_FIELD_NAME).validation(username), touched: true },
    [NAME_VALIDATION_FIELD_NAME]: { valid: GetValidateField(NAME_FIELD_NAME).validation(name), touched: true },
    [EMAIL_VALIDATION_FIELD_NAME]: { valid: GetValidateField(EMAIL_FIELD_NAME).validation(email), touched: true },
    [SITEID_VALIDATION_FIELD_NAME]: { valid: GetValidateField(SITEID_FIELD_NAME).validation(siteId), touched: true },
    [TYPE_VALIDATION_FIELD_NAME]: { valid: GetValidateField(TYPE_FIELD_NAME).validation(type), touched: true },
    [PROPERTY_VALIDATION_FIELD_NAME]: { valid: GetValidateField(PROPERTY_FIELD_NAME).validation(property), touched: true },
    [EXPIRATION_VALIDATION_FIELD_NAME]: { valid: isEmpty(prefExpiration) || (!isEmpty(prefExpiration.type) && !isEmpty(prefExpiration.value)), touched: true },
    [COUNTRY_VALIDATION_FIELD_NAME]: { valid: GetValidateField(COUNTRY_FIELD_NAME).validation(countryCode), touched: true },
    [USER_TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: !isWithTokenLogin || GetValidateField(USER_SSO_URL_FIELD_KEY).validation(String(prefUserSSOUrl)), touched: true },
    [USER_MENU_TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: !isWithTokenLogin || GetValidateField(USER_MENU_SSO_URL_FIELD_KEY).validation(String(prefUserMenuSSOUrl)), touched: true },
    [TOKEN_URL_VALIDATION_FIELD_NAME]: { valid: !isWithTokenLogin || GetValidateField(TOKEN_SSO_URL_FIELD_KEY).validation(String(prefTokenSSOUrl)), touched: true }
  }
}

export const IsFormValid = (form: IFormValidations) => {
  return form[DOMAIN_VALIDATION_FIELD_NAME].valid &&
        form[NAME_VALIDATION_FIELD_NAME].valid &&
        form[EMAIL_VALIDATION_FIELD_NAME].valid &&
        form[SITEID_VALIDATION_FIELD_NAME].valid &&
        form[PROPERTY_VALIDATION_FIELD_NAME].valid &&
        form[EXPIRATION_VALIDATION_FIELD_NAME].valid &&
        form[TYPE_VALIDATION_FIELD_NAME].valid &&
        form[COUNTRY_VALIDATION_FIELD_NAME].valid &&
        form[USER_TOKEN_URL_VALIDATION_FIELD_NAME].valid &&
        form[TOKEN_URL_VALIDATION_FIELD_NAME].valid;
}

export const IsFormTouched = (form: IFormValidations) => {
  return form[DOMAIN_VALIDATION_FIELD_NAME].touched &&
        form[NAME_VALIDATION_FIELD_NAME].touched &&
        form[EMAIL_VALIDATION_FIELD_NAME].touched &&
        form[SITEID_VALIDATION_FIELD_NAME].touched &&
        form[PROPERTY_VALIDATION_FIELD_NAME].touched &&
        form[EXPIRATION_VALIDATION_FIELD_NAME].touched &&
        form[TYPE_VALIDATION_FIELD_NAME].touched &&
        form[COUNTRY_VALIDATION_FIELD_NAME].touched &&
        form[USER_TOKEN_URL_VALIDATION_FIELD_NAME].touched &&
        form[TOKEN_URL_VALIDATION_FIELD_NAME].touched;
}

const IsFormFieldValid = (form: IValidation) => {
  return !form || !form.touched || form.valid;
}

export const IsFormFieldValidByField = (field: string, form: IFormValidations) => {
  const formField: IFormValidation = get(FORM_FIELDS_VALIDATIONS, `${field}`);
  if (formField && form) {
    const validation: any = get(form, `${formField?.field}`, null);
    return IsFormFieldValid(validation);
  }
  return true;
}

export const IsDomainFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[DOMAIN_VALIDATION_FIELD_NAME]);
}
export const IsNameFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[NAME_VALIDATION_FIELD_NAME]);
}
export const IsEmailFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[EMAIL_VALIDATION_FIELD_NAME]);
}
export const IsSiteIdFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[SITEID_VALIDATION_FIELD_NAME]);
}
export const IsExpirationFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[EXPIRATION_VALIDATION_FIELD_NAME]);
}
export const IsPropertyFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[PROPERTY_VALIDATION_FIELD_NAME]);
}
export const IsTypeFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[TYPE_VALIDATION_FIELD_NAME]);
}
export const IsCountryFormFieldValid = (form: IFormValidations) => {
  return IsFormFieldValid(form[COUNTRY_VALIDATION_FIELD_NAME]);
}
