import React, { useEffect, useMemo, useRef } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Routes, QUOTE_LABEL, WEEKS_SESSION_KEY_LABEL, WEEKS_UNITS_SESSION_KEY_LABEL } from '@share/constants';
import { getWeeksReviewBook, IWeeksReviewBookState, weeksReviewBookAction } from '@store/slices';
import { ICondosStaticState } from '@share/store/slices';
import { BookingErrorsEnum, ISessionKey } from '@share/common-types';
import { getSessionObj, getTimeout, UrlUtils } from '@share/utils';
import { RootState } from '@share/utils';
import { Notification, SkeletonReviewBook } from '@components';
import { WeeksReviewBook } from '../weeks-review-book';
import { WeeksUnitInfo } from '../weeks-unit-info';
import { ILoginState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  weeksReviewBookStore: IWeeksReviewBookState;
  condosStaticStore: ICondosStaticState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  resetBooking: () => void;
  getWeeksReviewBook: (
    numberId: number,
    packageId: string,
    sessionKey: ISessionKey | undefined,
    isGetCoupon?: false,
    quote?: string
  ) => void;
  setLoadingWeeksBook: (loadingBook: boolean) => void;
  setWeeksExpiredSession: (expiredSession: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps {}

const ZERO = 0;
const FOUR = 4;
const FIVE = 5;

function WeeksReviewBookWrapperComponent(props: IProps) {
  
  const { history, weeksReviewBookStore, condosStaticStore, resetBooking, getWeeksReviewBook } = props;
  const {
    weeksBookingSummary,
    loading,
    error,
    expiredSession,
    isExternalError,
    bookingComplete,
    isBookingPending,
    bookingErrorCode,
    loadingBooking,
    isSavingsMismatch,
  } = weeksReviewBookStore;
  const { condosCountriesCode } = condosStaticStore;
  const { account } = props.loginStore;

  const historySplit = useMemo(() => history.location.pathname.split('/'), [history.location.pathname]);
  const propertyId = Number(historySplit[FOUR]);
  const packageId = historySplit[FIVE];

  const loadingRef = useRef(loading);
  const errorRef = useRef(error);
  
  useEffect(() => {
    const values = UrlUtils.getValues();
    const sessionKey = getSessionObj(
      values[WEEKS_UNITS_SESSION_KEY_LABEL] as ISessionKey,
      values[WEEKS_SESSION_KEY_LABEL] as ISessionKey,
    );

    let quote;
    if (values[QUOTE_LABEL]) {
      quote = (values[QUOTE_LABEL] as string);
    }

    getWeeksReviewBook(propertyId, packageId, sessionKey, false, quote);
    onExpiredSession(sessionKey);
    window.scrollTo(ZERO, ZERO);

    return () => {
      resetBooking();
    }
  },[]);

  useEffect(() => {
    if (loadingRef.current !== loading && !loading && errorRef.current === BookingErrorsEnum.BookingSummeryInformationFailed) {
      history.push(`/${account?.name}${Routes.WeeksDetails}/${propertyId}${location.search}`);
      location.reload();
    }
    loadingRef.current = loading;
  },[loading]);

  useEffect(() => {
    errorRef.current = error;
  },[error]);

  const onExpiredSession = (sessionData: ISessionKey) => {
    const expireDate = getTimeout(sessionData.expireDate);
    const timeout = expireDate < ZERO ? 0 : expireDate;

    setTimeout(() => {
      props.setWeeksExpiredSession(true);
    }, timeout);
  };

  const isNotification = bookingComplete || loadingBooking || isBookingPending || bookingErrorCode === BookingErrorsEnum.SoldOut;
  const isUnavailable = bookingErrorCode === BookingErrorsEnum.SoldOut || bookingErrorCode === BookingErrorsEnum.RoomsUnavailable;
  const displayNotification = expiredSession || isExternalError || !condosCountriesCode || isUnavailable;

  return displayNotification ? (
    <Notification
      expiredSession={expiredSession}
      isExternalError={isExternalError || !condosCountriesCode}
      isFromCondo={true}
      isSavingsMismatch={isSavingsMismatch}
      errorCode={isUnavailable ? bookingErrorCode : null}
    />) : (
    <div className="review-book-wrapper print">
      {!isNotification && (
        <div className="review-book-wrapper__header print">
          <div className="review-book-wrapper__back-link">
            <Link to={`/${account?.name}${Routes.WeeksDetails}/${propertyId}${location.search}`}>
              <FormattedMessage id="back.to.condo" />
            </Link>
          </div>
          <h4 className="review-book-wrapper__title">
            <FormattedMessage id="review.book" />
          </h4>
          <p className="review-book-wrapper__description">
            <FormattedMessage id="enter.your.details" />
          </p>
        </div>
      )}
      
      {loading ? <SkeletonReviewBook /> : null}
      {weeksBookingSummary && !loading && condosCountriesCode ? (
        <div className="review-book-wrapper__content">
          <WeeksReviewBook />
          <WeeksUnitInfo />
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksReviewBookStore: state.weeksReviewBookStore,

    condosStaticStore: state.condosStaticStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getWeeksReviewBook,
  setLoadingWeeksBook: weeksReviewBookAction.setLoadingWeeksBook,
  setWeeksExpiredSession: weeksReviewBookAction.setWeeksExpiredSession,
  resetBooking: weeksReviewBookAction.weekBookingResetState,
};

export const WeeksReviewBookWrapper = connect(mapStateToProps, mapDispatchToProps)(withRouter(WeeksReviewBookWrapperComponent));
