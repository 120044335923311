import React from 'react';
import ReactGA from 'react-ga4';
import debounce from 'lodash/debounce';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Button } from 'antd';
import { get, isEmpty } from 'lodash';
import { HasPermission, isCarsFiltersChanged, isCarsFiltersEmpty, ViewQuotesSharePermission } from '@utils';

import {
  EmptyFilterMessage,
  EmptySearchMessage,
  SkeletonContent,
  WhiteButton,
  SkeletonMap,
  ExpiredSessionModal,
  Banner,
  SkeletonResult,
} from '@components';
import { CustomErrorPage } from '@share/components';
import {
  GetCars,
  GetCarsInitial,
  getMapHotels,
  ILoginState,
  ICarsState,
  ICarLocationsState,
  ICarsDatesState,
  carsActions,
  ICarsFiltersState,
  carsFiltersActions,
} from '@share/store/slices';
import { FilterSvg } from '@assets';
import { CloseSvg } from '@share/assets';
import { getAccountUsernameFromPath, RootState, GetHomeParams, scrollTop } from '@share/utils';
import {
  IBounds,
  ICar,
  ILocation,
  IRoom,
  SEARCH_BANNER_TYPE,
  SearchTypeEnum,
} from '@share/common-types';
import { APP_SELECTOR } from '@constants';
import {
  DEFAULT_PAGE_SIZE,
  CARS_FILTERS_LABEL,
  Routes,
  R_VIEW_MAP,
} from '@share/constants';
import { Responsive, UrlUtils } from '@share/utils';
import { ModalCompare } from './modal-compare';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSliders } from '@fortawesome/free-solid-svg-icons';

import { CarsCard } from '../cars-card/cars-card-wrapper';
import { CarsFilters } from './cars-result-filters';
import { CarsResultTop } from './cars-result-top';
import { CarsGoHome } from './cars-go-home';

import './style.scss';

interface IMapStateToProps {
  carsStore: ICarsState;
  carsLocationsStore: ICarLocationsState;
  carsDatesStore: ICarsDatesState;
  carsFiltersStore: ICarsFiltersState;

  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  getCars: (searchType?: SearchTypeEnum) => void;
  getCarsInitial: (searchType?: SearchTypeEnum, refreshWallet?: boolean) => void;
  setMapLoading: (isMapLoading: boolean) => void;
  setSelectedCompareCars: (selectedCompareHotels: ICar[]) => void;
  resetFilters: () => void;
  getMapHotels: (
    location: ILocation,
    startDate: string,
    endDate: string,
    rooms: IRoom[],
    hotelId?: number,
  ) => void;
  setBounds: (bounds: IBounds) => void;
  setIsMapView: (isMapView: boolean) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
}

interface IState {
  isMobileFilter: boolean;
  isMapView: boolean;
  showFilters: boolean;
  selectedCar: ICar;
  showCompareModal: boolean;
}

const debounceTime = 300;
const scrollPixels = 450;
const zero = 0;
const maxCompareHotels = 5;

class CarsResultWrapperComponent extends React.Component<IProps, IState> {
  state: IState = {
    isMobileFilter: false,
    isMapView: false,
    showFilters: true,
    selectedCar: null,
    showCompareModal: false
  };

  root: HTMLDivElement = document.querySelector(APP_SELECTOR);
  prevCarsFilters: ICarsFiltersState = this.props.carsFiltersStore;

  wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  filterButtonWrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  onScroll = debounce(() => {
    const { getCars, carsStore } = this.props;
    const { loadingMore, cars, prevCars, isSessionExpired } = carsStore;

    const rootHeight = this.root.scrollHeight ? this.root.scrollHeight : this.root.offsetHeight;
    if (!this.state.isMapView &&
        !isSessionExpired &&
        !loadingMore &&
        this.root &&
        window.innerHeight + window.scrollY + scrollPixels >= rootHeight
    ) {
      if (cars?.length % DEFAULT_PAGE_SIZE === zero && prevCars.length) {
        getCars(SearchTypeEnum.Pagination);
      }
    }
  }, debounceTime);

  componentDidMount() {
    window.history.pushState(null, null, window.location.pathname + window.location.search);
    window.addEventListener('popstate', this.onClickBackButton);
    window.addEventListener('scroll', this.onScroll); 

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('popstate', this.onClickBackButton);

    document.removeEventListener('mousedown', this.handleClickOutside);

    const footer: HTMLDivElement = document.querySelector('.footer');

    if (footer) {
      document.body.style.overflow = 'auto';
      footer.style.display = 'block';
    }
  }

  handleClickOutside = (event: any) => {
    if (this.state.isMapView && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target) &&
        this.filterButtonWrapperRef.current && !this.filterButtonWrapperRef.current.contains(event.target)) {
      this.setState({ showFilters: false });
    }
  }

  onClickBackButton = (e: any) => {
    e.preventDefault();

    const accountName = getAccountUsernameFromPath(this.props.history);

    const { loginStore } = this.props;
    const { account } = loginStore;

    const homeParams = GetHomeParams(account);

    this.props.history.push(`/${accountName}${Routes.CarsSearch}${homeParams}`);

    window.location.reload();
  }

  onResetFilters = (fromMap = false) => {
    UrlUtils.setUrl(CARS_FILTERS_LABEL, null);

    if (fromMap) {
      this.props.setMapLoading(fromMap);
    }

    this.props.resetFilters();
    return !this.state.isMobileFilter && this.onFiltersOrSortChange(false, true);
  };

  onFiltersOrSortChange = debounce((isStoreChange?: boolean, forceResetFilter?: boolean) => {
    const { getCarsInitial, carsFiltersStore } = this.props;

    if (isCarsFiltersChanged(this.prevCarsFilters, carsFiltersStore) || forceResetFilter) {
      UrlUtils.setUrl(CARS_FILTERS_LABEL, carsFiltersStore);
      this.prevCarsFilters = carsFiltersStore;
      getCarsInitial(SearchTypeEnum.SortsFilters);
      scrollTop();
    } else if (isStoreChange) {
      getCarsInitial(SearchTypeEnum.NewSearch, isStoreChange);
      scrollTop();
    }
  }, debounceTime);

  onMobileFilter = () => {
    if (!this.props.carsStore.isSessionExpired) {
      this.setState({ isMobileFilter: !this.state.isMobileFilter });
      return this.state.isMobileFilter
        ? (document.body.style.overflow = 'auto')
        : (document.body.style.overflow = 'hidden');
    }
  };

  showMap = (center: google.maps.LatLngLiteral, selectedCar: ICar = null): void => {
    const { carsStore, loginStore } = this.props;
    const { cars, isSessionExpired } = carsStore;
    const { account } = loginStore;

    if (!isSessionExpired && cars?.length) {
      this.setState({ showFilters: false });

      const footer: HTMLDivElement = document.querySelector('.footer');

      this.setState({ isMapView: true, selectedCar });
      this.props.setIsMapView(true);
      this.onMapSearchUpdate(selectedCar);

      if (footer) {
        window.scrollTo(zero, zero);
        document.body.style.overflow = 'hidden';
        footer.style.display = 'none';
      }
    }

    ReactGA.event({
      category: account.name,
      action: `${R_VIEW_MAP}_${account.name.toUpperCase()}`,
      label: `User clicked view map`,
      nonInteraction: false,
    });

  };

  showFiltersView = () => {
    this.setState({ showFilters: !this.state.showFilters });
  }

  showListView = (): void => {
    if (!this.props.carsStore.isSessionExpired) {
      this.setState({ showFilters: true });

      const footer: HTMLDivElement = document.querySelector('.footer');

      this.setState({ isMapView: false });
      this.props.setIsMapView(false);

      if (footer) {
        document.body.style.overflow = 'auto';
        footer.style.display = 'block';
      }
    }
  };

  onMapSearchUpdate = (selectedCar: ICar = null): void => {
    if (!this.props.carsStore.isSessionExpired) {
      const { getMapHotels, carsDatesStore, carsLocationsStore } = this.props;
      const { pickUp } = carsLocationsStore;
      const { startDate, endDate } = carsDatesStore;

      //getMapHotels(pickUp?.location, startDate, endDate, null, selectedCar?.id);
    }
  };

  handleCompare = (car: ICar) => {
    const { carsStore } = this.props;
    const { selectedCompareCars } = carsStore;
    const carIndex = selectedCompareCars.findIndex((h: ICar) => h.id === car.id);
    if (carIndex != -1) {
      const selectedCompareHotelsFiltered = [...selectedCompareCars];
      selectedCompareHotelsFiltered.splice(carIndex, 1);
      this.props.setSelectedCompareCars([...selectedCompareHotelsFiltered]);
    } else {
      this.props.setSelectedCompareCars([...selectedCompareCars, car]);
    }
  }

  render(): React.ReactNode {
    const { isMobileFilter, isMapView, showCompareModal, showFilters } = this.state;
    const { carsStore, carsLocationsStore, loginStore, carsFiltersStore, carsDatesStore } = this.props;
    const {
      cars,
      loading,
      loadingMore,
      isSessionExpired,
      sessionKey,
      selectedCompareCars,
      error,
      searchCodes
    } = carsStore;
    const { account, user } = loginStore;
    const { pickUp } = carsLocationsStore;
    const { startDate, endDate } = carsDatesStore;

    const isFiltersApplied = !isCarsFiltersEmpty(carsFiltersStore);
    const HasViewQuotesSharePermission = HasPermission(user, ViewQuotesSharePermission);
    const displayCompareLength = (HasViewQuotesSharePermission || isFiltersApplied) ? 0 : 1;

    const isEmptySearch = !cars.length;

    if (loading) {
      return (<SkeletonResult label={pickUp?.selectedLocationLabel} />);
    }
 
    if (!isEmpty(error)) {
      return (
        <div className={`result-wrapper ${isMapView ? 'map-view' : ''}`}>
          <div className="result-wrapper__back-link">
            <CarsGoHome />
          </div>

          <div className="result-wrapper__wrapper">
            <CustomErrorPage message={error} isCars />
          </div>
        </div>
      );
    }      

    return (
      <div className={`result-wrapper ${isMapView ? 'map-view' : ''}`}>
        
        <div className="result-wrapper__back-link">
          <CarsGoHome />

          {isMapView && (
            <div className="result-wrapper__map-header">
              <div className="result-wrapper__filters-view-wrapper" ref={this.filterButtonWrapperRef} onClick={this.showFiltersView}>
                <FontAwesomeIcon icon={faSliders} /> <FormattedMessage id="view.filters" />
              </div>
              <div className="result-wrapper__list-view-wrapper" onClick={this.showListView}>
                <FontAwesomeIcon icon={faList} /> <FormattedMessage id="view.list" />
              </div>
            </div>)}
        </div>

        <div className="result-wrapper__wrapper">
          <CarsFilters
            wrapperRef={this.wrapperRef}
            showFilters={showFilters}
            isMobileFilter={isMobileFilter}
            onShowMap={this.showMap}
            onFiltersOrSortChange={(foreceReset) => this.onFiltersOrSortChange(false, foreceReset)}
            onMobileFilter={this.onMobileFilter}
          />

          <div className={`result-wrapper__btn-view-wrapper ${isSessionExpired ? 'disabled' : ''}`}>
            <WhiteButton onClick={this.onMobileFilter} className="cars-search-sort" disabled={isSessionExpired}>
              <FilterSvg />
              <FormattedMessage id="sort.filter" />
            </WhiteButton>
            {/*<WhiteButton
              disabled={isSessionExpired}
              onClick={() => this.showMap(Map.getGoogleLocation(pickUp?.location?.geoLocation))}
            >
              <MapSvg />
              <FormattedMessage id="view.map" />
            </WhiteButton>*/}
          </div>

          {isMapView ? (
            <div className="result-wrapper__map-wrapper">
              <div className="result-wrapper__map-top">
                <div className="result-wrapper__map-close" onClick={this.showListView}>
                  <CloseSvg />
                </div>
                <div className="result-wrapper__map-location">{pickUp?.locationLabel}</div>
                <div
                  className={`result-wrapper__map-filters ${isSessionExpired ? 'disabled' : ''}`}
                  onClick={this.onMobileFilter}
                >
                  <FilterSvg />
                </div>
              </div>

              {/*mapCarsList ? (
                <MapWrapper
                  bounds={bounds}
                  disabled={isSessionExpired}
                  hotels={mapCarsList}
                  locations={mapCarsList.map(({ location, hotelId, pricePerNight, availability }) => ({
                    location,
                    id: hotelId,
                    pricePerNight,
                    availability
                  }))}
                  nights={resultCounters.totalNights}
                  mapOptions={{ disableDefaultUI: true, zoomControl: true }}
                  withEvents={true}
                  selectedHotel={selectedHotel}
                  onSearchUpdate={this.onMapSearchUpdate}
                  setBounds={setBounds}
                  isVacationRentals={false}
                />) : null*/}
            </div>
          ) : (
            <div
              className={`result-wrapper__content-wrapper ${isMobileFilter ? 'is-mobile' : ''} ${isEmptySearch ? 'is-empty-search' : ''}`}
              style={{ paddingBottom: loadingMore ? '0px' : isEmptySearch ? '100px' : '250px' }}
            >
              {selectedCompareCars?.length ? (
                <>
                  <div className="result-wrapper__compare-message">
                    {selectedCompareCars.length > 1 ? (<FormattedMessage id="results.hotel.card.compare.message" values={{ selected: selectedCompareCars.length, total: maxCompareHotels }} />) : null}
                    {selectedCompareCars.length === 1 ? (<FormattedMessage id="results.hotel.card.compare.message.select_more" />) : null}
                    <div className="compare-buttons">
                      <Button className="compare-clear-button" onClick={() => this.props.setSelectedCompareCars([])}>
                        <FormattedMessage id="results.hotel.card.compare.clear_selection" />
                      </Button>

                      <Button className="compare-button" disabled={selectedCompareCars.length <= displayCompareLength} onClick={() => this.setState({ showCompareModal: true })}>
                        {HasViewQuotesSharePermission ? <FormattedMessage id="results.hotel.card.compare.share" /> : <FormattedMessage id="results.hotel.card.compare" /> }
                      </Button>
                    </div>
                  </div>

                  <ModalCompare
                    visible={showCompareModal}
                    cars={selectedCompareCars}
                    checkIn={startDate}
                    checkOut={endDate}
                    locationCode={pickUp?.location?.code?.toString()}
                    sessionKey={sessionKey}
                    onCancel={() => this.setState({ showCompareModal: false })}
                    onRemove={this.handleCompare}
                  />
                </>
              ) : null}

              {isSessionExpired && (
                <div className="result-wrapper__expired-message">
                  <ExpiredSessionModal visible={isSessionExpired} />
                </div>
              )}

              {(isEmptySearch)? (
                isFiltersApplied ? (
                  <EmptyFilterMessage 
                    account={account} 
                    onReset={() => this.onResetFilters()}
                    isVacationRentals={false} 
                    filterHomes={null}
                    isCars
                  />) : (
                  <EmptySearchMessage
                    label={get(searchCodes, '[0].code', null)}
                    isVacationRentals={false}
                    filterHomes={null}
                    location={pickUp?.locationLabel}
                    account={account}
                    isCars
                  />)) : (
                <>
                  <CarsResultTop
                    isMobileFilter={isMobileFilter}
                    onFiltersOrSortChange={this.onFiltersOrSortChange}
                  />

                  {!loading ? (
                    cars.map((car: ICar, index: number) => {
                      const isInCompareList = selectedCompareCars?.map(h => h.id).includes(car.id);
                      return (
                        <div key={car.id}>
                          <Responsive>
                            <CarsCard
                              disabled={isSessionExpired}
                              car={car}
                              compared={isInCompareList}
                              disableCompare={!(cars?.length > displayCompareLength && (isInCompareList || selectedCompareCars?.length < maxCompareHotels))}
                              onCompareSelect={this.handleCompare}

                              displayCompare
                            />
                          </Responsive>
                          {index === 2 ? (<Banner type={SEARCH_BANNER_TYPE}></Banner>) : null}
                        </div>
                      );
                    })) : (
                    <SkeletonContent label={''} />)}

                  {loadingMore && <SkeletonContent label={''} isTitle={false} />}
                </>
              )}
            </div>
          )}
        </div>

        {loading && isMapView && <SkeletonMap />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    carsStore: state.carsStore,
    carsLocationsStore: state.carsLocationsStore,
    carsDatesStore: state.carsDatesStore,
    carsFiltersStore: state.carsFiltersStore,

    loginStore: state.loginStore,    
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  getCars: (searchType: SearchTypeEnum) => {
    dispatch(GetCars(searchType));
  },
  getCarsInitial: (searchType: SearchTypeEnum, refreshWallet?: boolean) => {
    dispatch(GetCarsInitial(searchType, refreshWallet));
  },
  
  getMapHotels: (
    location: ILocation,
    startDate: string,
    endDate: string,
    rooms: IRoom[],
    hotelId: number = null,
  ) => {
    dispatch(getMapHotels(location, startDate, endDate, rooms, hotelId));
  },


  resetFilters: () => {
    dispatch(carsFiltersActions.resetFilters());
  },


  setSelectedCompareCars: (selectedCompareCars: ICar[]) => {
    dispatch(carsActions.setSelectedCompareCars(selectedCompareCars));
  },
  setBounds: (bounds: IBounds) => {
    dispatch(carsActions.setBounds(bounds));
  },
  setMapLoading: (isMapLoading: boolean) => {
    dispatch(carsActions.setMapLoading(isMapLoading));
  },
  setIsMapView: (isMapView: boolean) => {
    dispatch(carsActions.setIsMapView(isMapView));
  },
});

export const CarsResultWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(CarsResultWrapperComponent)));
