
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import { RootState, UrlUtils, getNormalizedRoomsResponse, getWalletSliderMaxValuePosition } from '@share/utils';
import { ILoginState, IReservationsState, ITravelHistoryState, getReservations, getTravelHistory } from '@share/store/slices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBoxOpen, faChevronRight, faPlusCircle, faUser } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { FILTERS_LABEL, HOTEL_RECENT_SEARCHES_LABEL, Routes } from '@share/constants';
import { Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import { IRoom } from '@share/common-types';

import {
  getHotels,
  datesActions,
  roomsActions,
  filtersActions,
  locationActions,
} from '@share/store/slices';

interface IMapStateToProps {
  loginStore: ILoginState;
  reservationsStore: IReservationsState;
  travelHistoryStore: ITravelHistoryState;
}

interface IMapDispatchToProps {
  getReservations: () => void;
  getTravelHistory: () => void;

  setRooms: (rooms: IRoom[]) => void;
  setDatesSelected: (dates: { startDate: string; endDate: string }) => void;
  getHotels: () => void;
  resetFilters: () => void;
  clearDatesError: () => void;
  clearLocationError: () => void;
  setSelectLocation: (location: any) => void;
  setSelectLocationLabel: (label: string) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

function MyAccountComponent(props: IProps) {

  const [selectedByPoins, setSelectedByPoins] = useState(0);
  const [selectedByMoney, setSelectedByMoney] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [searchList, setSearchList] = useState([]);

  const { loginStore, reservationsStore, travelHistoryStore, history, getReservations, setDatesSelected, setRooms, getHotels, resetFilters, clearDatesError, clearLocationError, setSelectLocation, setSelectLocationLabel } = props;
  const { account, userWallet, userWalletData, lifeStyle, loading } = loginStore;
  const { result } = reservationsStore;
  const { histories } = travelHistoryStore?.result;

  const currentYear = moment().year();
  const countCurrentYear = get(histories.filter(h => h.membershipYear === currentYear.toString()), '[0].children.length', 0);

  const walletNoDecimals = account?.walletNoDecimals;
  const balance = userWalletData?.balance ? userWalletData?.balance : 0;
  const convertionRate = userWalletData?.convertionRate ? userWalletData?.convertionRate : 1;
  const background: any = account?.buttonColor ? account?.buttonColor : '#0081FE';

  const maxSliderByPoints = balance;
  const marksByPoints: SliderMarks = {
    0: {
      style: {
        transform: 'translate(-4px, -40px)'
      },
      label: '0',
    },
    [maxSliderByPoints]: {
      style: {
        transform: `translate(${walletNoDecimals ? getWalletSliderMaxValuePosition(maxSliderByPoints) :'-27'}px, -40px)`
      },
      label: `${maxSliderByPoints.toFixed(walletNoDecimals ? 0 : 2)}`,
    }
  };

  const maxSliderByMoney = balance * convertionRate;
  const marksByMoney: SliderMarks = {
    0: {
      style: {
        transform: 'translate(-4px, -40px)'
      },
      label: '$ 0.00',
    },
    [maxSliderByMoney]: {
      style: {
        transform: `translate(${getWalletSliderMaxValuePosition(maxSliderByMoney)}px, -40px)`
      },
      label: `$ ${maxSliderByMoney.toFixed(2)}`,
    }
  };

  useEffect(() => {
    getReservations();
    getTravelHistory();

    const recentSearchesStorage = localStorage.getItem(HOTEL_RECENT_SEARCHES_LABEL);
    const recentSearchesList = !isEmpty(recentSearchesStorage)? JSON.parse(recentSearchesStorage) : [];

    setSearchList(recentSearchesList);
  },[]);

  const handleByPoints = (value: any) => {
    const moneyConvertion = value * convertionRate;
    setSelectedByPoins(value);
    setSelectedByMoney(moneyConvertion);
  }

  const handleByMoney = (value: any) => {
    const moneyConvertion = value / convertionRate;
    setSelectedByMoney(value);
    setSelectedByPoins(moneyConvertion);
  }

  const handleGoToSeach = (s: any) => {
    const checkIn = moment(s?.checkIn,'yyyy-MM-DD');
    const checkOut = moment(s?.checkOut,'yyyy-MM-DD');
    const isDatePassed = checkIn.isBefore(moment());

    if (!isDatePassed) {
      if (!loading) {
        const selectedLocation = s.location;
        const rooms = s.rooms;
        const startDate = checkIn.format('yyyy-MM-DDThh:mm:ss.000Z');
        const endDate = checkOut.format('yyyy-MM-DDThh:mm:ss.000Z');

        clearDatesError();
        clearLocationError();
        setSelectLocationLabel(selectedLocation.name);
        setSelectLocation(selectedLocation);
    
        resetFilters();
        
        UrlUtils.setUrl(FILTERS_LABEL, null);
    
        setDatesSelected({ startDate, endDate });
        
        setRooms(getNormalizedRoomsResponse(rooms));

        history.push(`/${account?.name}${Routes.Search}${location.search}`);
      }  
    }
  }

  return (
    <div className="my-account-wrapper">
      <div className="container-fluid" style={{ display: 'grid', alignSelf: 'baseline' }}>
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 buttons" style={{ paddingRight: '0', position: 'relative' }}>
            <div className="my-account-wrapper__title">
              <h1><FormattedMessage id={account?.isCapitalVacations ? 'my.account.capital.title' : 'my.account.title'} /></h1>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: '30px' }}>
          <div className="col-lg-6">
            <div className="my-account-wrapper__summary">
              <div className="my-account-wrapper__summary-data no-space">
                <FontAwesomeIcon icon={faUser} size="2x" />
                <FormattedMessage id="my.account.capital.welcome" values={{ userName: userWallet?.firstName }} />
              </div>
              <div className="my-account-wrapper__summary-data">
                <label><FormattedMessage id="my.account.capital.member_name" /></label>
                <label>{`${userWallet?.firstName} ${userWallet?.lastName}`}</label>
              </div>
              <div className="my-account-wrapper__summary-data">
                <label><FormattedMessage id="my.account.capital.email" /></label>
                <label>{userWallet?.email}</label>
              </div>
              <div className="my-account-wrapper__summary-balance-title">
                <FormattedMessage id="my.account.capital.balance" values={{ clientCashName: account?.walletClientCashName }} />
              </div>
              <div className="my-account-wrapper__summary-balance">
                {(userWalletData?.balance)?.toLocaleString('en-US')}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="my-account-wrapper__data">
              <h1><FormattedMessage id="my.account.capital.total_trips" /></h1>
              <label>{result?.totalOrderCount ? result?.totalOrderCount : 0}</label>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="my-account-wrapper__data">
              <h1><FormattedMessage id="my.account.capital.trips_this_year" /></h1>
              <label>{countCurrentYear ? countCurrentYear : 0}</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="my-account-wrapper__my-reservations">
              <FontAwesomeIcon icon={faBoxOpen} size="4x" />
              <Link to={`/${account?.name}${Routes.AccountReservations}${location.search}`}>
                <FormattedMessage id="my.account.capital.my_reservations" />
              </Link>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="my-account-wrapper__calculator">
              <h1><FormattedMessage id="my.account.capital.calculator" /></h1>
              <div className="my-account-wrapper__calculator-type">
                <label className="type-title"><FormattedMessage id="my.account.capital.lifestyle_type" /></label>
                <strong>{lifeStyle?.toLocaleUpperCase()}</strong>
              </div>
              <div className="my-account-wrapper__calculator-slider">
                <label><FormattedMessage id="my.account.capital.by_point" values={{ clientCashName: account?.walletClientCashName }} /></label>
                <Slider
                  min={0}
                  max={maxSliderByPoints}
                  marks={marksByPoints}
                  defaultValue={0}
                  step={walletNoDecimals ? 1 : 0.01}
                  tooltip={{ open: false }}
                  value={selectedByPoins >= 0 ? selectedByPoins : 0}
                  onChange={(value: any) => handleByPoints(value)}
                  trackStyle={{ backgroundColor: background }}
                  handleStyle={{ borderColor: background, backgroundColor: background }}
                />
                <label className="my-account-wrapper__calculator-slider-value">{selectedByPoins ? walletNoDecimals ? Math.floor(selectedByPoins) : selectedByPoins : 0} {account?.walletClientCashName}</label>
              </div>
              <div className="my-account-wrapper__calculator-slider">
                <label><FormattedMessage id="my.account.capital.by_money" /></label>
                <Slider
                  min={0}
                  max={maxSliderByMoney}
                  marks={marksByMoney}
                  defaultValue={0}
                  step={0.01}
                  tooltip={{ open: false }}
                  value={selectedByMoney >= 0 ? selectedByMoney : 0}
                  onChange={(value: any) => handleByMoney(value)}
                  trackStyle={{ backgroundColor: background }}
                  handleStyle={{ borderColor: background, backgroundColor: background }}
                />
                <label className="my-account-wrapper__calculator-slider-value">$ {selectedByMoney ? selectedByMoney.toFixed(2) : (0).toFixed(2)}</label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="my-account-wrapper__history-search">
              <h1><FormattedMessage id="my.account.capital.my_hotel_searches" /></h1>
              <div className="my-account-wrapper__history-search-expand" onClick={() => setExpanded(!expanded)}>
                <FontAwesomeIcon icon={faPlusCircle} size="2x" />
              </div>
              {expanded ? (
                <div className="my-account-wrapper__history-search-content">
                  <div className="container-fluid">
                    <div className="row">
                      {searchList?.length ? (
                        searchList.map((s, i) => {
                          const checkIn = moment(s?.checkIn,'yyyy-MM-DD');
                          const checkOut = moment(s?.checkOut,'yyyy-MM-DD');
                          const roomsCount = s?.rooms?.length ? s?.rooms?.length : 0;
                          const name = !isEmpty(s?.location?.name) ? get(s?.location?.name?.split(','), '[0]', '') : '';
                          const adultsCount = s?.rooms?.map((r: any) => r.adultsCount)?.reduce((acc: any, val: any) => acc + val);
                          const childsCount = s?.rooms?.map((r: any) => r.kids?.length)?.reduce((acc: any, val: any) => acc + val);
                          const isDatePassed = checkIn.isBefore(moment());
                          return (
                            <div key={i} className="col-lg-4" style={{ padding: '15px' }}>
                              <div className="my-account-wrapper__history-content" onClick={() => handleGoToSeach(s)}>
                                <label className="title">{name}</label>
                                <label className="message">{`${checkIn.format('MMM DD yyyy')}, ${checkOut.diff(checkIn, 'days')}`}<FormattedMessage id="nigths" /></label>
                                <label className="message">{roomsCount} <FormattedMessage id="room" values={{ count: roomsCount }} />, {adultsCount} <FormattedMessage id="adult" values={{ count: adultsCount }} />, {childsCount} <FormattedMessage id="child" values={{ count: childsCount }} /></label>
                                <span className="image">
                                  <div className="logo">
                                    <FontAwesomeIcon icon={faBed} size="2x" />
                                  </div>
                                  {!isDatePassed ? (
                                    <div className="go-to-search">
                                      <FormattedMessage id="my.account.capital.go_to_search" />
                                      <FontAwesomeIcon icon={faChevronRight} size="1x" />
                                    </div>) : null}
                                </span>
                              </div>
                            </div>)})) : (<FormattedMessage id="my.account.capital.no_search" />)}
                    </div>
                  </div>
                </div>) : null}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reservationsStore: state.reservationsStore,
    loginStore: state.loginStore,
    travelHistoryStore: state.travelHistoryStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getReservations,
  getTravelHistory,
  getHotels,
  resetFilters: filtersActions.resetFilters,
  setSelectLocation: locationActions.setSelectLocation,
  setSelectLocationLabel: locationActions.setSelectLocationLabel,
  setDatesSelected: datesActions.setDatesSelected,
  setRooms: roomsActions.setRooms,
  clearLocationError: locationActions.setError,
  clearDatesError: datesActions.setError,

};

const ComponentIntl = injectIntl(MyAccountComponent);

export const MyAccount = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
