import { v4 } from 'uuid';
import differenceInDays from 'date-fns/differenceInDays';
import {
  IHotelsSearchRequest,
  IHotelsSearchFiltersRequest,
  IHotelsRequest,
} from '@common-types';
import {
  DateSearchTypeEnum,
  IRoomResponse,
  IUrlRoom,
} from '@share/common-types';

import {
  ILocation,
  IRoom,
} from '@share/common-types';
import { DEFAULT_PAGE_SIZE, DEFAULT_RESIDENCY } from '@share/constants';
import { IHotelsState, IFiltersState, ICondosLocationPickerState, ICondoStrictDatesState, ICarsFiltersState } from '@share/store/slices';
import { IDatesState, ILocationsState } from '@share/store/slices';
import { formatDateCheckInOut } from './dates';
import { get, isEmpty } from 'lodash';

const defaultKidsAge = 0;
const maxDaysRange = 30;
const isoFormat = 'yyyy-MM-dd';
const startTime = 'T00:00:01.000Z';

export const isDataValid = (
  locationsStore: ILocationsState,
  datesStore: IDatesState,
): {
  isDatesValid: boolean;
  isLocationValid: boolean;
  isValid: boolean;
  isDatesMaxError: boolean;
} => {
  const res = {
    isDatesValid: true,
    isLocationValid: true,
    isValid: true,
    isDatesMaxError: false,
  };

  if (!locationsStore.selectedLocation) {
    res.isLocationValid = false;
    res.isValid = false;
  }

  if (!datesStore.endDateSelected || !datesStore.startDateSelected) {
    res.isDatesValid = false;
    res.isValid = false;
  }

  if (datesStore.endDateSelected && datesStore.startDateSelected) {
    const start = new Date(datesStore.startDateSelected);
    const end = new Date(datesStore.endDateSelected);

    if (differenceInDays(end, start) > maxDaysRange) {
      res.isDatesValid = false;
      res.isValid = false;
      res.isDatesMaxError = true;
    }
  }

  return res;
};

export const isCondoDataValid = (
  locationsStore: ICondosLocationPickerState,
  datesStore: ICondoStrictDatesState,
): {
  isDatesValid: boolean;
  isLocationValid: boolean;
  isValid: boolean;
  isDatesMaxError: boolean;
} => {
  const res = {
    isDatesValid: true,
    isLocationValid: true,
    isValid: true,
    isDatesMaxError: false,
  };

  if (!locationsStore.selectedLocation) {
    res.isLocationValid = false;
    res.isValid = false;
  }

  if (datesStore.searchType !== DateSearchTypeEnum.Flexible) {
    if (!datesStore.endDate || !datesStore.startDate) {
      res.isDatesValid = false;
      res.isValid = false;
    }
  
    if (datesStore.endDate && datesStore.startDate) {
      const start = new Date(datesStore.startDate);
      const end = new Date(datesStore.endDate);
  
      if (differenceInDays(end, start) > maxDaysRange) {
        res.isDatesValid = false;
        res.isValid = false;
        res.isDatesMaxError = true;
      }
    }
  }

  return res;
};

export const getFilters = (filtersStore: IFiltersState): IHotelsSearchFiltersRequest => {
  const {
    budget,
    selectedAutocompleteItem,
    accommodationType,
    boardNames,
    refundable,
    amenities,
    distance,
    starRatings,
    neighbourhoods,
    flexibleDates,
    accessibility,
    popularLocations
  } = filtersStore;

  const filters = {
    budgetRanges: budget.map((range: string) => {
      const [from, to]: string[] = range.split('-');

      return { from: +from || null, to: +to || null };
    }),
    propertyName: selectedAutocompleteItem ? selectedAutocompleteItem.hotelName : '',
    accommodationType: accommodationType,
    boardNames: boardNames,
    refundable: refundable,
    amenities: amenities,
    starRatings: starRatings,
    distanceInMiles: distance,
    neighbourhoods: neighbourhoods,
    accessibilities: accessibility,
    popularLocations: popularLocations
  } as IHotelsSearchFiltersRequest;

  const flexibleDate = get(flexibleDates, '[0]');
  filters.exactSearch = !flexibleDate || flexibleDate === 'true';

  return filters;
};

export const getNormalizedRooms = (rooms: IUrlRoom[] = []): IRoom[] => {
  return rooms.map((value: IUrlRoom) => {
    return {
      id: v4(),
      adultsCount: value.adultsCount,
      kids: value.kids.map((age: number) => ({ id: v4(), age })),
    };
  });
};

export const getNormalizedRoomsResponse = (rooms: IRoomResponse[] = []): IRoom[] => {
  return rooms.map((value: IRoomResponse) => {
    return {
      id: v4(),
      adultsCount: value.adultsCount ? value.adultsCount : 2,
      kids: value?.kidsAges?.map((age: number) => ({ id: v4(), age })) || [],
    };
  });
};

export const getResponseRooms = (rooms: IRoom[]): IRoomResponse[] => {
  return rooms.map(({ adultsCount, kids }) => {
    return { adultsCount, kidsAges: kids?.map(({ age }) => age || defaultKidsAge) };
  });
};

export const getHotelsRequest = (
  rooms: IRoom[],
  startDate: string,
  endDate: string,
  location: ILocation
): IHotelsRequest => {
  return {
    residency: DEFAULT_RESIDENCY,
    locationCode: location?.code,
    location: location,
    checkIn: formatDateCheckInOut(startDate, isoFormat) + startTime,
    checkOut: formatDateCheckInOut(endDate, isoFormat) + startTime,
    rooms: getResponseRooms(rooms),
  };
};

export const getSearchBody = (
  location: ILocation,
  startDate: string,
  endDate: string,
  rooms: IRoom[],
  hotelsStore: IHotelsState,
  filtersStore: IFiltersState,
  isLoadMore: boolean,
  isReuseSessionKey: boolean,
  hotelId: number = null,
  quick = false,
  currency: string,
  lifeStyle: string
): IHotelsSearchRequest => {
  const { sortBy, searchHomes } = filtersStore;
  const filter = getFilters(filtersStore);

  const req: IHotelsSearchRequest = {
    hotelsRequest: getHotelsRequest(rooms, startDate, endDate, location),
    filter: filter,
    sortBy: sortBy,
    pageNumber: hotelsStore.pageNumber,
    pageSize: DEFAULT_PAGE_SIZE,
    quick: quick,
    searchHomes: searchHomes,
    currency: currency
  };

  if (hotelId) {
    req.hotelId = hotelId;
  }

  if (!isEmpty(lifeStyle)) {
    req.lifeStyle = lifeStyle;
  }

  const type: string = location?.type;
  if (type === 'Related') {
    if (hotelsStore.similarSessionKey && (isLoadMore || isReuseSessionKey)) {
      req.sessionKey = { ...hotelsStore.similarSessionKey };
    } else if (hotelsStore.sessionKey && (isLoadMore || isReuseSessionKey)) {
      req.sessionKey = { ...hotelsStore.sessionKey };
    }
  } else if (hotelsStore.sessionKey && (isLoadMore || isReuseSessionKey)) {
    req.sessionKey = { ...hotelsStore.sessionKey };
  }

  return req;
};
