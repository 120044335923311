import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'antd';
import { LazyLoadTypes } from '@ant-design/react-slick';

import { ArrowLeftSvg, ArrowRightSvg } from '@share/assets';

import './style.scss';

const SPEED = 500;
const SLIDES_TO_SHOW = 3;
const SLIDES_TO_SCROLL = 1;
const ONE = 1;
const ZERO = 0;
const LAZY_LOAD = 'ondemand';

const responsive = [
  {
    breakpoint: 1020,
    settings: {
      slidesToShow: 1,
    },
  },
];

interface IProps {
  children: React.ReactNode;
  titleId?: string;
  slidesToShow?: number;
  speed?: number;
  slidesToScroll?: number;
  lazyLoad?: LazyLoadTypes;
}

export class CarouselSlider extends React.Component<IProps> {
  render(): React.ReactNode {
    const {
      titleId,
      children,
      speed = SPEED,
      slidesToShow = SLIDES_TO_SHOW,
      slidesToScroll = SLIDES_TO_SCROLL,
      lazyLoad = LAZY_LOAD,
    } = this.props;

    const propsCarousel = {
      dots: false,
      infinite: true,
      arrows: true,
      speed,
      slidesToShow,
      slidesToScroll,
      responsive,
    };

    return (
      <div className="carousel-slider">
        <div className="carousel-slider__container">
          {titleId && (
            <h4 className="carousel-slider__title">
              <FormattedMessage id={titleId} />
            </h4>
          )}
          <Carousel
            {...propsCarousel}
            lazyLoad={lazyLoad}
            // @ts-ignore
            nextArrow={<NextArrow />}
            // @ts-ignore
            prevArrow={<PrevArrow />}
          >
            {children}
          </Carousel>
        </div>
      </div>
    );
  }
}

interface IArrowProps {
  currentSlide: number;
  slideCount: number;
}

class PrevArrow extends React.Component<IArrowProps, null> {
  render(): React.ReactNode {
    const { currentSlide, slideCount, ...props } = this.props;
    const isDisabled = currentSlide === ZERO;

    return (
      <button
        {...props}
        className={`slick-prev slick-arrow ${isDisabled ? ' slick-disabled' : ''}`}
        aria-disabled={isDisabled}
        aria-hidden
        type="button"
      >
        <ArrowLeftSvg />
      </button>
    );
  }
}

class NextArrow extends React.Component<IArrowProps, null> {
  render(): React.ReactNode {
    const { currentSlide, slideCount, ...props } = this.props;
    const isDisabled = currentSlide === slideCount - ONE;

    return (
      <button
        {...props}
        className={`slick-next slick-arrow ${isDisabled ? ' slick-disabled' : ''}`}
        aria-disabled={isDisabled}
        aria-hidden
        type="button"
      >
        <ArrowRightSvg />
      </button>
    );
  }
}