import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import upperCase from 'lodash/upperCase';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ICondoUnit } from '@share/common-types';
import { BlueButton, WeeksDatePickerComponent, WeeksPeriodPickerComponent } from '@share/components';
import { UrlUtils } from '@share/utils';
import {
  weeksUnitsSearchActions,
  IWeekDetailsState,
  IWeeksUnitsSearchState,
  getWeeksUnitsDetails,
} from '@store/slices';
import { IMenuState } from '@share/store/slices';
import { RootState } from '@share/utils';
import { UpdateSvg } from '@share/assets';
import { C_D_UPDATE_BUTTON, WEEKS_UNITS_SEARCH_LABEL, WEEKS_DATES_LABEL } from '@share/constants';

import { UnitSkeleton } from '../../../../condo/condo-details/condo-units/unit-skeleton';
import { AlternativeDatesSkeleton } from '../../../../condo/condo-details/condo-units/alternative-dates-skeleton';
import { ListUnits } from '../weeks-list-units';

import { ILoginState } from '@share/store/slices';


import './style.scss';

interface IMapStateToProps {
  weeksDetailsStore: IWeekDetailsState;
  weeksUnitsSearchStore: IWeeksUnitsSearchState;

  menuStore: IMenuState;
  loginStore: ILoginState;
}

interface IMapDispatchToProps {
  setDate: (date: string) => void;
  setPeriod: (period: string) => void;
  
  setError: (key: string) => void;

  getUnitsDetails: (condoId: number) => void;

  clearError: () => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps {
  refAnchor: React.RefObject<HTMLDivElement>;
}

const ZERO = 0;

function WeeksUnitsSearchComponent(props: IProps) {

  const {
    refAnchor,
    weeksUnitsSearchStore,
    loginStore,
    weeksDetailsStore,
    setDate,
    setPeriod,
    getUnitsDetails,
    setError,
    clearError, 
    menuStore,
    intl,
  } = props;
  const { date, period, isUnitsLoading, error, condoInfo, isSearchUnits } = weeksUnitsSearchStore;
  const { week } = weeksDetailsStore;

  const details = week?.propertyDetails;

  const [currentUnitName, setCurrentUnitName] = useState('');

  const getUnits = () => {
    const units: ICondoUnit[] = [];

    const getUnits =
      ((isSearchUnits && condoInfo) || (isEmpty(details.units) && !isUndefined(condoInfo?.units))) ? 
        weeksUnitsSearchStore.condoInfo?.units : 
        details.units;

    const condoUnits = getUnits;

    condoUnits.forEach((unit) => {
      unit.availibilities.forEach((item) => {
        units.push({
          id: unit.id,
          name: unit.name
            ? unit.name
            : `${upperCase(unit.kitchenType)}-${unit.bedroomsCount}/${unit.maxOccupancy}/${
                unit.privacyOccupancy
              }`,
          maxOccupancy: unit.maxOccupancy,
          privacyOccupancy: unit.privacyOccupancy,
          bedroomsCount: unit.bedroomsCount,
          bathroomsCount: unit.bathroomsCount,
          kitchenType: unit.kitchenType,
          unitId: item.id,
          price: item.price,
          pricePerNight: item.pricePerNight,
          publicPrice: item?.publicPrice,
          dateRange: {
            from: item.dateRange.from,
            to: item.dateRange.to,
          },
          matchType: item.matchType,
          sleepingArrangements: unit.sleepingArrangements,
          bathroom: unit.bathroom,
          cancellationPolicyText: item.cancellationPolicyText,
          refundability: item.refundability,
          bedRoomType: unit.bedRoomType,
          nonSmokingRoomsByRequest: unit.nonSmokingRoomsByRequest,
          loft: unit.loft,
          unitConfig: unit.unitConfig,
          outdoorLivingSpaceInfo: unit.outdoorLivingSpaceInfo,
          bathroomDescription: unit.bathroomDescription,
          bedroomBathLocation: unit.bedroomBathLocation,
          rollInShower: unit.rollInShower,
          bathroomAccessible: unit.bathroomAccessible,
          kitchenEquipments: unit.kitchenEquipments,
          babyFacilities: unit.babyFacilities,
          roomAmenities: unit.roomAmenities,
          roomFurnishing: unit.roomFurnishing,
          televisionServiceType: unit.televisionServiceType,
          televisionType: unit.televisionType,
          numberOfTVs: unit.numberOfTVs,
          televisionLocations: unit.televisionLocations,
          coolingSystems: unit.coolingSystems,
          accessibility: unit.accessibility,
          safebox: unit.safebox,
          safetyEquipment: unit.safetyEquipment,
          housekeeping: unit.housekeeping,
          housekeepingFeeDescription: unit.housekeepingFeeDescription,
          supplierIdentifier: unit.supplierIdentifier,
          maxWalletClientCash: item?.maxWalletClientCash
        });
      });
    });

    return units;
  };

  const updateSearch = (buttonClicked?: boolean) => {
    if (weeksUnitsSearchStore.date && weeksUnitsSearchStore.period) {
      clearError();
      getUnitsDetails(details.id);
  
      const { loginStore } = props;
      const { account } = loginStore;
  
      ReactGA.event({
        category: account.name,
        action: `${C_D_UPDATE_BUTTON}_${account.name.toUpperCase()}`,
        label: `User clicked update button on detail`,
        nonInteraction: false,
      });
  
    } else {
      if (buttonClicked) {
        setError(intl.formatMessage({ id: 'please.select.dates' }));
      }
    }
  };

  const getCurrentUnit = (value: string, index: number): void => {
    setCurrentUnitName(value);
  };

  useEffect(() => {
    const { setDate, setPeriod } = props;
    const values = UrlUtils.getValues();

    if (values[WEEKS_UNITS_SEARCH_LABEL]) {
      const { date, period } = values[WEEKS_UNITS_SEARCH_LABEL] as { date: string; period: string; };

      setDate(date);

      setPeriod(period);

      setTimeout(() => updateSearch(), 300);
    } else {
      if (values[WEEKS_DATES_LABEL]) {
        const { date, period } = values[WEEKS_DATES_LABEL] as { date: string; period: string; };

        setDate(date);

        setPeriod(period);
      }
    }
  }, [])

  const units = [...getUnits()];
  
  const weeksDatesStore = {
    dateSelected: date,
    date,
    periodSelected: period,
    period,
    errorDate: '',
    errorPeriod: '',
  };

  return (
    <div className="units-search weeks-unit-search" ref={refAnchor}>
      <h2 className="units-search__header">
        <FormattedMessage id="availability.title" values={{ name: 'Units' }} />
      </h2>

      <h3 className="list-units__title" style={{ marginBottom: '0px', marginTop: '20px' }}><FormattedMessage id="update.search.your.hotel" /></h3>

      <div className="units-search__search">
        <WeeksDatePickerComponent
          weeksDatesStore={weeksDatesStore}
          loginStore={loginStore}
          setDateSelected={setDate}
          clearError={clearError}
          intl={intl}

          isAfterSearchLoad
        />

        <WeeksPeriodPickerComponent
          weeksDatesStore={weeksDatesStore}
          loginStore={loginStore}
          setPeriodSelected={setPeriod}
          clearError={clearError}
          intl={intl}

          isAfterSearchLoad
        />

        <div className="units-search__button">
          <BlueButton onClick={() => updateSearch(true)}>
            <UpdateSvg />
            <FormattedMessage id="update.search.your.hotel" />
          </BlueButton>
        </div>
      </div>

      {error && <div className="condo-strict-date-picker__error" style={{ marginTop: '7px' }}>{error}</div>}

      <div className="units-search__list">
        {isUnitsLoading ? (
          <>
            <div className="units-search__list-skeleton">
              <UnitSkeleton />
              <UnitSkeleton />
              <UnitSkeleton />
              <UnitSkeleton />
            </div>
            <AlternativeDatesSkeleton />
          </>
        ) : (
          <>
            {error.length === ZERO ? (
              <div className="units-search__list-wrapper">
                {!isEmpty(units) && (
                  <ListUnits
                    title={<FormattedMessage id="available.dates" />}
                    alternativeUnits={[...units]}
                    condoId={details.id}
                    currentUnitName={currentUnitName}
                    getCurrentUnit={getCurrentUnit}
                    baseUrl={menuStore.items?.baseUrl}
                    isHotel={week?.propertyDetails?.category === 'Hotel'}
                  />
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    weeksDetailsStore: state.weeksDetailsStore,
    weeksUnitsSearchStore: state.weeksUnitsSearchStore,
    
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, undefined, Action>): IMapDispatchToProps => ({
  setDate: (date: string) => {
    dispatch(weeksUnitsSearchActions.setDate(date));
  },
  setPeriod: (period: string) => {
    dispatch(weeksUnitsSearchActions.setPeriod(period));
  },

  clearError: () => {
    dispatch(weeksUnitsSearchActions.setError(''));
  },
  setError: (text: string) => {
    dispatch(weeksUnitsSearchActions.setError(text));
  },

  getUnitsDetails: (propertyId: number) => {
    dispatch(getWeeksUnitsDetails(propertyId));
  },
});

export const WeeksUnitsSearch = connect(mapStateToProps, mapDispatchToProps)(injectIntl(WeeksUnitsSearchComponent));

