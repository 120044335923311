import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Routes } from '@share/constants';
import { ITravelHistoryState, getTravelHistory } from '@share/store/slices';
import { RootState } from '@share/utils';
import { ILoginState, LoginType } from '@share/store/slices';
import { ReservationType, SearchTable } from '@components';

import { InputFilterType } from '@share/common-types';
import { IColumnMenu } from '@constants';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  travelHistoryStore: ITravelHistoryState;
}

interface IMapDispatchToProps {
  getTravelHistory: () => void;
}

export enum CategoryEnum {
  Hotel = 'hotel',
  Condo = 'condo',
  Car = 'car'
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, RouteComponentProps, WrappedComponentProps { }

class MyTravelHistoryComponent extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  handleOnRefresh = () => {
    const { loginStore } = this.props;
    const { user } = loginStore;
    if (user) {
      this.props.getTravelHistory();
    }
  };

  render(): React.ReactNode {
    const { loginStore, travelHistoryStore, intl } = this.props;
    const { account } = loginStore;
    const { loading, result } = travelHistoryStore;
    const { histories } = result;

    if (!account || account.type === LoginType.Public) {
      this.props.history.push(Routes.NotAuthorized);
    }

    const columns: IColumnMenu[] = [
      { description: 'Membership Year', key: 'membershipYear', width: 170, display: true, order: 1, type: InputFilterType.Text, displayConditions: false },
      { description: 'Confirmation Code', key: 'confirmationCode', width: 170, display: true, order: 2, type: InputFilterType.IntegerNumber, displayConditions: false },
      { description: 'Creation Date', key: 'creationDate', width: 170, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 3, type: InputFilterType.Date, displayConditions: true },

      { description: 'Destination', key: 'destination', width: 300, display: true, order: 4, type: InputFilterType.Text, displayConditions: false },
      {
        description: 'Category',
        key: 'category',
        width: 120,
        type: InputFilterType.Dropdown,
        dropdowmOptions: Object.values(CategoryEnum).map(v => v?.toLocaleLowerCase()),
        display: true,
        order: 5,
        displayConditions: false,
        component: (text: string) => <ReservationType type={text} />
      },
      { description: 'Status', key: 'status', width: 120, display: true, order: 6, type: InputFilterType.Text, displayConditions: false },

      { description: 'Travel Start Date', key: 'travelStartDate', width: 170, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 7, type: InputFilterType.Date, displayConditions: true },
      { description: 'Travel End Date', key: 'travelEndDate', width: 170, incomingDateFormat: 'MM/DD/YYYY', display: true, order: 8, type: InputFilterType.Date, displayConditions: true },
    ];

    const dataSource = histories;

    return (
      <div className="travel-history-wrapper ">
        <SearchTable
          title={intl.formatMessage({ id: 'my.travel.history' })}
          loading={loading}
          actions={null}
          allColumns={[...columns]}
          dataSource={dataSource}
          pagination={{
            showSizeChanger: false,
            showQuickJumper: false
          }}
          filters={null}
          totalOrderCount={dataSource.length}
          rowKey={(row: any) => row.key ? `${row.key}` : `${row.confirmationCode}`}
          isFilterApplied={false}
          onFiltersChange={null}
          onTableChange={null}
          onResetFilters={null}
          onApplyFilters={null}
          onRefresh={this.handleOnRefresh}
        ></SearchTable>
      </div>);
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    loginStore: state.loginStore,
    travelHistoryStore: state.travelHistoryStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getTravelHistory
};

const ComponentIntl = injectIntl(MyTravelHistoryComponent);

export const MyTravelHistory = connect(mapStateToProps, mapDispatchToProps)(withRouter(ComponentIntl));
