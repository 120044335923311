
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ListCards } from '@common-types';
import { LinkSvg } from '@assets';
import { getLocalURI, isExternalUrl, RootState } from '@share/utils';

import { connect } from 'react-redux';
import { ILoginState, IMenuState } from '@share/store/slices';

import './style.scss';

interface IMapStateToProps {
  loginStore: ILoginState;
  menuStore: IMenuState;
}

interface IProps extends IMapStateToProps {}

function ListClickableCardsComponent(props: IProps) {
  const { loginStore, menuStore } = props;
  const { account, loading } = loginStore;
  const { items } = menuStore;

  const exclude = loading || menuStore?.loading || items?.isRemoveResources;

  return (
    <div className="list-clickable-cards">
      {!exclude && ListCards.filter(c => !c?.excludeMLM || !items?.isMLM).map((item, index) =>
        isExternalUrl(item.url) ? (
          <a
            href={item.url}
            key={`${item.title} ${index}`}
            className="list-clickable-cards__wrapper"
            target="_blank"
            rel="noreferrer"
          >
            <div className="list-clickable-cards__img-wrapper">
              <img src={item.image} alt={item.title} />
            </div>
            <div className="list-clickable-cards__info-wrapper">
              <p className="list-clickable-cards__title">
                <FormattedMessage id={item.title} />
              </p>
              <p className="list-clickable-cards__description">
                <FormattedMessage id={item.content} />
              </p>
            </div>
            <LinkSvg />
          </a>
        ) : (
          <Link
            to={`/${account?.name}${getLocalURI(item.url)}`}
            key={`${item.title} ${index}`}
            className="list-clickable-cards__wrapper"
          >
            <div className="list-clickable-cards__img-wrapper">
              <img src={item.image} alt={item.title} />
            </div>
            <div className="list-clickable-cards__info-wrapper">
              <p className="list-clickable-cards__title">
                <FormattedMessage id={item.title} />
              </p>
              <p className="list-clickable-cards__description">
                <FormattedMessage id={item.content} />
              </p>
            </div>
          </Link>
        ),
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    menuStore: state.navigationMenuStore,
    loginStore: state.loginStore,
  };
};

export const ListClickableCards = connect(mapStateToProps)(ListClickableCardsComponent);

