import React from 'react';
import { Skeleton } from 'antd';
import './style.scss';

const ONE_ROW = 1;
const NUMBER_OF_BLOCKS = [ONE_ROW, ONE_ROW, ONE_ROW, ONE_ROW];

interface IProps  {
  isSlider?: boolean;
  isGetaways?: boolean;
}

export class GetawayListSkeleton extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isSlider, isGetaways } = this.props;

    const windowWidth = window.innerWidth;
    const isDesktop = windowWidth >= 1200;
    const blocks = isDesktop ? 2 : 1;

    const BLOCKS = isSlider ? Array.from({length: blocks}, () => ONE_ROW) : NUMBER_OF_BLOCKS;

    return (
      <div className="getaway-list-skeleton" style={{ marginTop: (isGetaways ? '20px' : '0px')}}>
        <div className="getaway__container">
          <Skeleton
            className="getaway-list-skeleton-text"
            active
            title={false}
            paragraph={{ rows: ONE_ROW }}
          />
          <div className="getaway-list-skeleton-filters">
            <div className="getaway-list-skeleton-filters_left">
              <Skeleton
                className="getaway-list-skeleton-filters_item"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
              <Skeleton
                className="getaway-list-skeleton-filters_item"
                active
                title={false}
                paragraph={{ rows: ONE_ROW }}
              />
            </div>
            <Skeleton
              className="getaway-list-skeleton-filters_item"
              active
              title={false}
              paragraph={{ rows: ONE_ROW }}
            />
          </div>
          <Skeleton
            className="getaway-list-skeleton-text"
            active
            title={false}
            paragraph={{ rows: ONE_ROW }}
          />
          <div className="getaway-list-skeleton_list">
            {BLOCKS.map((item, index) => (
              <div key={`${index}-${item}`} className="getaway-list-skeleton_list__item">
                <Skeleton
                  className="getaway-list-skeleton_list__item-img"
                  active
                  title={false}
                  paragraph={{ rows: ONE_ROW }}
                />
                <div className="getaway-list-skeleton_list__item-info">
                  <div className="getaway-list-skeleton_list__item-text">
                    <Skeleton
                      className="getaway-list-skeleton_list__item-text_skeleton"
                      active
                      title={false}
                      paragraph={{ rows: ONE_ROW }}
                    />
                    <Skeleton
                      className="getaway-list-skeleton_list__item-text_skeleton"
                      active
                      title={false}
                      paragraph={{ rows: ONE_ROW }}
                    />
                  </div>
                  <Skeleton
                    className="getaway-list-skeleton_list__item_location"
                    active
                    title={false}
                    paragraph={{ rows: ONE_ROW }}
                  />
                  <div className="getaway-list-skeleton_list__item_details">
                    <Skeleton
                      className="getaway-list-skeleton_list__item_details_skeleton"
                      active
                      title={false}
                      paragraph={{ rows: ONE_ROW }}
                    />
                    <Skeleton
                      className="getaway-list-skeleton_list__item_details_skeleton"
                      active
                      title={false}
                      paragraph={{ rows: ONE_ROW }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
