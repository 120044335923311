import React from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { renderNumber } from '@share/utils';
import { NoImageSvg } from '@assets';
import { Currency, HotelStars } from '@components';
import { ICancelReservationHotelInfo } from '@share/common-types';
import { RootState } from '@share/utils';
import { getFullAddress } from '@utils';

import './style.scss';


interface IMapStateToProps {}

interface IProps extends IMapStateToProps {
  hotelDetails: ICancelReservationHotelInfo;
  isPromo: boolean;
}

const ZERO = 0;

export class ReservationCancellationRoomInfoComponent extends React.Component<IProps> {
  render(): React.ReactNode {
    const { isPromo = false, hotelDetails } = this.props;
    const {
      hotel,
      imageUrl,
      roomName,
      roomDescription,
      checkIn,
      checkOut,
      roomsCount,
      numberOfNights,
      totalAmount,
      rooms,
      priceDifference,
      discountAmount,
      bookingPrice,
      currency,
      bookingType
    } = hotelDetails;

    const mealBasisInfo = rooms[ZERO].basis;
    const mealBasisPrice = priceDifference;

    const isCars = bookingType === 'Car';

    return (
      <div className="reservation-cancellation-room-info">
        <div className="reservation-cancellation-room-info__wrapper">
          {imageUrl.length !== ZERO ? (
            <div className="reservation-cancellation-room-info__room-image">
              <img
                className="reservation-cancellation-room-info__room-img"
                src={imageUrl}
                alt={roomName}
              />
            </div>
          ) : (
            <div className="reservation-cancellation-room-info__room-img no-image">
              <NoImageSvg />
            </div>
          )}
          <div className="reservation-cancellation-room-info__content border-bm">
            <p className="reservation-cancellation-room-info__hotel-name">{hotel.name}</p>
            <div className="hotel-info__stars">
              <HotelStars stars={hotel.stars} />
            </div>
            <p className="room-info__address">
              {getFullAddress(hotel.address, hotel.city, hotel.state, hotel.zipCode, hotel.countryCode, hotel.countryName).join(', ')}
            </p>
            <div className="reservation-cancellation-room-info__room-info-block">
              <p className="reservation-cancellation-room-info__room-info-title">{roomName}</p>
              <p className="reservation-cancellation-room-info__room-info-description">
                {roomDescription}
              </p>
            </div>
            <div className="reservation-cancellation-room-info__period">
              <div className="reservation-cancellation-room-info__period-item">
                <p className="reservation-cancellation-room-info__period-item-title">
                  <FormattedMessage id={isCars ? 'cars.pickup' :'check.in'} />:
                </p>
                <p className="reservation-cancellation-room-info__period-item-description">
                  {checkIn}
                </p>
              </div>
              <div className="reservation-cancellation-room-info__period-item">
                <p className="reservation-cancellation-room-info__period-item-title">
                  <FormattedMessage id={isCars ? 'cars.dropoff' :'check.out'} />:
                </p>
                <p className="reservation-cancellation-room-info__period-item-description">
                  {checkOut}
                </p>
              </div>
            </div>
          </div>
          <div className="reservation-cancellation-room-info__content ">
            <h4 className="reservation-cancellation-room-info__price-title">
              <FormattedMessage id="price.details" />
            </h4>
            <div className="reservation-cancellation-room-info__price-info">
              <p className="reservation-cancellation-room-info__price-info-text">
                {!isCars ? (
                  <>
                    {roomsCount} <FormattedMessage id="room" values={{ count: roomsCount }} /> x{' '}
                    <FormattedMessage id="nights" values={{ count: numberOfNights }} />
                  </>) : (
                  <FormattedMessage id="car.price" values={{ days: numberOfNights, count: numberOfNights }} />)}
              </p>
              <p className="reservation-cancellation-room-info__price-info-text">
                <Currency currency={currency} />{renderNumber(totalAmount, 2)}
              </p>
            </div>
            {mealBasisPrice !== ZERO ? (
              <div className="reservation-cancellation-room-info__price-info">
                <p className="reservation-cancellation-room-info__price-meal-basis-text">
                  {mealBasisInfo}
                </p>
                <p className="reservation-cancellation-room-info__price-meal-basis-text">
                <Currency currency={currency} />{renderNumber(mealBasisPrice, 2)}
                </p>
              </div>
            ) : null}
            {discountAmount > 0 ? (
              <>
                <div className="reservation-cancellation-room-info__price-info">
                  <p className="reservation-cancellation-room-info__price-savings-text">
                    <FormattedMessage id={isPromo ? 'savings' : 'member.savings.label'} />
                  </p>
                  <p className="reservation-cancellation-room-info__price-savings-text">
                    -<Currency currency={currency} /> {renderNumber(discountAmount, 2)}
                  </p>
                </div>
              </>) : null}
          </div>
          <div className="reservation-cancellation-room-info__content border-bm pb-0 mt-0"></div>
          <div className="reservation-cancellation-room-info__content">
            <div className="reservation-cancellation-room-info__total-price-info-wrapper">
              <div className="reservation-cancellation-room-info__total-price-info">
                <p className="reservation-cancellation-room-info__price-info-text">
                  <FormattedMessage id="total.pay" />:
                </p>
              </div>
              <p className="reservation-cancellation-room-info__price-info-amount">
              <Currency currency={currency} />{renderNumber(bookingPrice, 2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    reservationCancellationStore: state.reservationCancellationStore,
  };
};

export const ReservationCancellationRoomInfo = connect(
  mapStateToProps,
  null,
)(ReservationCancellationRoomInfoComponent);
