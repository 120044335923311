import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Select } from 'antd';

import { RootState } from '@share/utils';
import { IUser } from '@share/common-types';
import {
  saveAccountUser,
  getSelectedAccountUserDetails,
  IUsersState,
  IAccountUserSaveState,
  AccountUserSaveStatus
} from '@store/slices';
import { ModalConfirmation } from '@components';
import { Loading, LoadingSise } from '@share/components';

import { 
  USERNAME_FIELD_NAME,
  USERNAME_VALIDATION_FIELD_NAME,
  EMAIL_FIELD_NAME,
  EMAIL_VALIDATION_FIELD_NAME,
  FORM_FIELDS_VALIDATIONS,
  IFormValidation,
  IFormValidations,
  IsFormValid,
  IsFormTouched,
  IsFormFieldValid,
  FIRSTNAME_FIELD_NAME,
  LASTNAME_FIELD_NAME,
  COUNTRY_VALIDATION_FIELD_NAME,
} from './form_utils';
import { countriesCode } from '@share/constants';

import './style.scss';

interface IMapStateToProps {
  accountUsersStore: IUsersState;
  accountUserSaveStore: IAccountUserSaveState;
}

interface IMapDispatchToProps {
  getSelectedAccountUserDetails: (userId: string) => void;
  saveAccountUser: (user: IUser) => void;
}

interface IProps extends IMapStateToProps, IMapDispatchToProps, WrappedComponentProps, RouteComponentProps {
  accountId: number;
  userId: string;
  onClose: () => void;
}

interface IState {
  formValidations: IFormValidations;
  isUpdate: boolean;
  showConfirmation: boolean;
  user: IUser;
}

class UserDetailsComponent extends React.Component<IProps, IState> {

  state: IState = {
    formValidations: {
      [USERNAME_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
      [EMAIL_VALIDATION_FIELD_NAME]: { valid: false, touched: false },
      [COUNTRY_VALIDATION_FIELD_NAME]: { valid: false, touched: false }
    },
    isUpdate: false,
    showConfirmation: false,
    user: new IUser()
  };

  componentDidMount() {
    this.props.getSelectedAccountUserDetails(this.props.userId);
    this.setState({ isUpdate: !!this.props.userId });
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.state.isUpdate &&
        !prevProps.accountUsersStore.selectedUser &&
        this.props.accountUsersStore.selectedUser?.userId) {
      const user = { ...this.props.accountUsersStore.selectedUser };
      this.setState({ user });
      this.handleValidate(user);
    }

    if (prevProps.accountUserSaveStore.status === AccountUserSaveStatus.Processing &&
        this.props.accountUserSaveStore.status === AccountUserSaveStatus.Completed) {
      this.props.onClose();
    }
  }
  
  handleValidate = (user: IUser) => {
    const { email, username, countryCode } = user;
    this.setState({ formValidations: {
      [USERNAME_VALIDATION_FIELD_NAME]: { valid: !isEmpty(username), touched: true },
      [EMAIL_VALIDATION_FIELD_NAME]: { valid: !isEmpty(email), touched: true },
      [COUNTRY_VALIDATION_FIELD_NAME]: { valid: !isEmpty(countryCode), touched: true },
    }});
  }

  handleInputChange = (value: string, field: string) => {
    const user: IUser = { ...this.state.user };
    (user as any)[field] = value;
    const formValidation: IFormValidation = (FORM_FIELDS_VALIDATIONS as any)[field];
    this.setState({ user });

    if(formValidation) {
      this.setState({ formValidations: {
        ...this.state.formValidations,
        [formValidation.field]: { valid: formValidation.validation(value), touched: true }
      }});
    }
  }

  handleCancel = () => {
    if (this.isFormTocuhed()) { 
      this.setState({ showConfirmation: true });
    } else {
      this.props.onClose();
    }
  }

  handleSaveAccountUser = () => {
    if (this.isFormValid()) {
      const user: IUser = { ...this.state.user };
      if (!this.state.isUpdate) {
        user.accountId = this.props.accountId;
      }
      this.props.saveAccountUser(user);
    }
  }
  
  handleCancelModal = (): void => {
    this.setState({ showConfirmation: false });
  };

  handleConfirmModal = () => {
    this.setState({ showConfirmation: false });
    this.props.onClose();
  }

  getFieldValue = (value: string) => {
    return value? value : '';
  } 

  isFormValid = () => {
    return IsFormTouched(this.state.formValidations) && IsFormValid(this.state.formValidations); 
  } 

  isFormTocuhed = () => {
    const accountDbStr = JSON.stringify(this.props.accountUsersStore.selectedUser);
    const accountStateStr = JSON.stringify(this.state.user);
    return accountDbStr !== accountStateStr;
  }

  render(): React.ReactNode {
    const loadingSave = this.props.accountUserSaveStore.loading;
    const loadingGet = this.props.accountUsersStore.loading;
    if (loadingGet || loadingSave) {
      return (
        <div className="save-user">
          <div className="save-user__wrapper loading-container">
            <Loading size={LoadingSise.Medium} />
          </div>
        </div>
      );
    } else {
      const saveButtonClassname = `save-button ${this.isFormValid() ? '' : 'disabled'}`;
      const { user, formValidations } = this.state;
      const { username, email, firstName, lastName, countryCode } = user;
      const domainValid = IsFormFieldValid(formValidations[USERNAME_VALIDATION_FIELD_NAME]);
      const emialValid = IsFormFieldValid(formValidations[EMAIL_VALIDATION_FIELD_NAME]);
      const isCountryValid = IsFormFieldValid(formValidations[COUNTRY_VALIDATION_FIELD_NAME]);
      
      const countries = countriesCode.map(country => ({ value: country.isoCode, title: country.name }));

      return (
        <div className="save-user">
          <div className="save-user__wrapper">
            <div className="save-user__fields container-fluid">
              <div className="row">
                <div className="col-lg-12 mandatory-message">
                  <label className="mandatory">*</label>Mandatory fields
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 save-user__title">
                  User Details
                </div>
              </div>


              <div className="row grid-row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-4">
                      <label className={`title ${domainValid? '' : 'invalid'}`}>
                        <label className="mandatory">*</label>
                        Username:
                      </label>
                    </div>
                    <div className="col-8 form-input-container">
                      <div className={`form-input-content ${domainValid? '' : 'invalid'}`}>
                        <input 
                          value={this.getFieldValue(username)}
                          className={`form-input ${domainValid? '' : 'invalid'}`}
                          placeholder="Enter Domain"
                          maxLength={30}
                          onChange={(e) => this.handleInputChange(e.target.value, USERNAME_FIELD_NAME)}
                        ></input>
                        <div className={`counter ${domainValid? '' : 'invalid'}`}>
                          {isEmpty(username) ? '0' : username.length}/30
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className={`title ${emialValid? '' : 'invalid'}`}>
                        <label className="mandatory">*</label>
                        Email:
                      </label>
                    </div>
                    <div className="col-8 form-input-container">
                      <div className={`form-input-content ${emialValid? '' : 'invalid'}`}>
                        <input
                          value={this.getFieldValue(email)}
                          className={`form-input ${emialValid? '' : 'invalid'}`}
                          placeholder="Enter Email"
                          onChange={(e) => this.handleInputChange(e.target.value, EMAIL_FIELD_NAME)}
                        ></input>
                        <div className={`counter ${emialValid? '' : 'invalid'}`}>
                          {isEmpty(email) ? '0' : email.length}/100
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row grid-row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-4">
                      <label className={`title`}>
                        First Name:
                      </label>
                    </div>
                    <div className="col-8 form-input-container">
                      <div className={`form-input-content`}>
                        <input 
                          value={this.getFieldValue(firstName)}
                          className={`form-input`}
                          placeholder="Enter First Name"
                          maxLength={30}
                          onChange={(e) => this.handleInputChange(e.target.value, FIRSTNAME_FIELD_NAME)}
                        ></input>
                        <div className={`counter`}>
                          {isEmpty(firstName) ? '0' : firstName.length}/30
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label className={`title`}>
                        Last Name:
                      </label>
                    </div>
                    <div className="col-8 form-input-container">
                      <div className={`form-input-content`}>
                        <input 
                          value={this.getFieldValue(lastName)}
                          className={`form-input`}
                          placeholder="Enter Domain"
                          maxLength={30}
                          onChange={(e) => this.handleInputChange(e.target.value, LASTNAME_FIELD_NAME)}
                        ></input>
                        <div className={`counter`}>
                          {isEmpty(lastName) ? '0' : lastName.length}/30
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row grid-row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-4">
                      <label className={`title`}>
                        <label className="mandatory">*</label>
                        Country:
                      </label>
                    </div>
                    <div className="col-8 form-input-container">
                      <div className={`form-input-content`}>
                        <Select
                          showSearch={true}
                          placeholder={'Country'}
                          value={isEmpty(countryCode) ? '' : countryCode}
                          className={`${isCountryValid? '' : 'invalid'}`}
                          style={{ width: '175px' }}
                          onChange={(e) => this.handleInputChange(e, 'countryCode')}
                        >
                          {countries.map((option, index) => <Select.Option key={index} value={option.value}>{option.title}</Select.Option>)}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 buttons">
                  <button className="cancel-button" onClick={this.handleCancel}>Cancel</button>
                  <button className={saveButtonClassname} onClick={this.handleSaveAccountUser}>Save</button>
                </div>
              </div>
            </div>
          </div>
          <ModalConfirmation
            message="There are unsave changes. If you confirm, all unsaved data will be lost. Do you want to continue? "
            title="Confirm Cancel"
            yesMessage="Confirm"
            noMessage="Cancel"
            visible={this.state.showConfirmation}
            onCancel={this.handleCancelModal}
            onConfirm={this.handleConfirmModal}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state: RootState): IMapStateToProps => {
  return {
    accountUsersStore: state.accountUsersStore,
    accountUserSaveStore: state.accountUserSaveStore,
  };
};

const mapDispatchToProps: IMapDispatchToProps = {
  getSelectedAccountUserDetails,
  saveAccountUser
};

const UserDetailsComponentWithRouter = withRouter(injectIntl(UserDetailsComponent));

export const UserDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDetailsComponentWithRouter);
