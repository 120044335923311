import Ncl from '@assets/images/company-logos/ncl.png';
import VikingRiverCruises from '@assets/images/company-logos/viking-river-cruises.png';
import Arda from '@assets/images/company-logos/arda.png';
import Beaches from '@assets/images/company-logos/beaches.png';
import DisneyHotels from '@assets/images/company-logos/disney-hotels.png';
import EmbassySuites from '@assets/images/company-logos/embassy-suites.png';
import FourSeasons from '@assets/images/company-logos/four-seasons.png';
import Hampton from '@assets/images/company-logos/hampton.png';
import Hyatt from '@assets/images/company-logos/hyatt.png';
import Hilton from '@assets/images/company-logos/hilton.png';
import HolidayInn from '@assets/images/company-logos/holiday-inn.png';
import Ihg from '@assets/images/company-logos/ihg.png';
import Interval from '@assets/images/company-logos/interval.png';
import Kimpton from '@assets/images/company-logos/kimpton.png';
import Marriott from '@assets/images/company-logos/marriott.png';
import Rci from '@assets/images/company-logos/rci.png';
import RedCarnation from '@assets/images/company-logos/red-carnation.png';
import RitzCarlton from '@assets/images/company-logos/ritz-carlton.png';
import RoyalCaribbean from '@assets/images/company-logos/royal-caribbean.png';
import Sandals from '@assets/images/company-logos/sandals.png';
import Sheraton from '@assets/images/company-logos/sheraton.png';
import TheRitzCarlton from '@assets/images/company-logos/the-ritz-carlton.png';
import TrawNow from '@assets/images/company-logos/traw-now.png';
import W from '@assets/images/company-logos/w.png';
import Westin from '@assets/images/company-logos/westin.png';
import AppleLeisureGroup from '@assets/images/treat-yourself/apple-leisure-group.png';
import CareTreatYourself from '@assets/images/treat-yourself/care.png';
import FunjetVacations from '@assets/images/treat-yourself/funjet-vacations.png';
import PrincessCruises from '@assets/images/treat-yourself/princess-cruises.png';
import TravelLeadersNetwork from '@assets/images/treat-yourself/travel-leaders-network.png';
import VikingRiverCruisesTreatYourself from '@assets/images/treat-yourself/viking-river-cruises.png';
import Funjet from '@assets/images/company-logos/funjet.png';
import Vircin from '@assets/images/company-logos/vircin.png';
import Carnival from '@assets/images/company-logos/carnival.png';
import DisneyCruise from '@assets/images/company-logos/disney-cruise.png';
import Flestamericana from '@assets/images/company-logos/flestamericana.png';
import CarnivalCruises from '@assets/images/cruises/cruises-logos/carnival.png';
import CelebrityCruises from '@assets/images/cruises/cruises-logos/celebrity-cruises.png';
import CunardCruises from '@assets/images/cruises/cruises-logos/cunard.png';
import DisneyCruiseLine from '@assets/images/cruises/cruises-logos/disney-cruise-line.png';
import NorwegianCruiseLine from '@assets/images/cruises/cruises-logos/norwegian-cruise-line.png';
import PrincessCruisesLine from '@assets/images/cruises/cruises-logos/princess-cruises.png';
import RoyalCaribbeanCruises from '@assets/images/cruises/cruises-logos/royal-caribbean.png';
import VikingCruises from '@assets/images/cruises/cruises-logos/viking-cruises.png';
import HollandAmerica from '@assets/images/cruises/cruises-logos/holland-america.png';

import Thrifty from '@assets/images/cars/thrifty.png';
import Dollar from '@assets/images/cars/dollar.png';
import Alamo from '@assets/images/cars/alamo.png';
import Avis from '@assets/images/cars/avis.png';
import Budget from '@assets/images/cars/budget.png';
import National from '@assets/images/cars/national.png';
import Hertz from '@assets/images/cars/hertz.png';
import Enterprise from '@assets/images/cars/enterprise.png';

export const LandingCompanyLogos = [
  {
    name: 'Ncl',
    image: Ncl,
  },
  {
    name: 'VikingRiverCruises',
    image: VikingRiverCruises,
  },
  {
    name: 'Arda',
    image: Arda,
  },
  {
    name: 'Rci',
    image: Rci,
  },
  {
    name: 'TrawNow',
    image: TrawNow,
  },
  {
    name: 'RoyalCaribbean',
    image: RoyalCaribbean,
  },
  {
    name: 'W',
    image: W,
  },
  {
    name: 'RedCarnation',
    image: RedCarnation,
  },
  {
    name: 'Sheraton',
    image: Sheraton,
  },
  {
    name: 'RitzCarlton',
    image: RitzCarlton,
  },
  {
    name: 'FourSeasons',
    image: FourSeasons,
  },
  {
    name: 'Hilton',
    image: Hilton,
  },
  {
    name: 'Interval',
    image: Interval,
  },
  {
    name: 'Marriott',
    image: Marriott,
  },
  {
    name: 'TheRitzCarlton',
    image: TheRitzCarlton,
  },
  {
    name: 'DisneyHotels',
    image: DisneyHotels,
  },
];

export const AllInclusivesCompanyLogos = [
  {
    name: 'Vircin',
    image: Vircin,
  },
  {
    name: 'Carnival',
    image: Carnival,
  },
  {
    name: 'Funjet',
    image: Funjet,
  },
  {
    name: 'DisneyCruise',
    image: DisneyCruise,
  },
  {
    name: 'Sandals',
    image: Sandals,
  },
  {
    name: 'DisneyHotels',
    image: DisneyHotels,
  },
  {
    name: 'Flestamericana',
    image: Flestamericana,
  },
  {
    name: 'Beaches',
    image: Beaches,
  },
];

export const HotelsCompanyLogos = [
  {
    name: 'Marriott',
    image: Marriott,
  },
  {
    name: 'Hilton',
    image: Hilton,
  },
  {
    name: 'Hyatt',
    image: Hyatt,
  },
  {
    name: 'Hampton',
    image: Hampton,
  },
  {
    name: 'HolidayInn',
    image: HolidayInn,
  },
  {
    name: 'EmbassySuites',
    image: EmbassySuites,
  },
  {
    name: 'Sheraton',
    image: Sheraton,
  },
  {
    name: 'Beaches',
    image: Beaches,
  },
  {
    name: 'RedCarnation',
    image: RedCarnation,
  },
  {
    name: 'TheRitzCarlton',
    image: TheRitzCarlton,
  },
  {
    name: 'FourSeasons',
    image: FourSeasons,
  },
  {
    name: 'Sandals',
    image: Sandals,
  },
  {
    name: 'Westin',
    image: Westin,
  },
  {
    name: 'DisneyHotels',
    image: DisneyHotels,
  },
  {
    name: 'Kimpton',
    image: Kimpton,
  },
  {
    name: 'Ihg',
    image: Ihg,
  },
];

export const CondosCompanyLogos = [
  {
    name: 'Marriott',
    image: Marriott,
  },
  {
    name: 'Hilton',
    image: Hilton,
  },
  {
    name: 'Hyatt',
    image: Hyatt,
  },
  {
    name: 'Hampton',
    image: Hampton,
  },
  {
    name: 'HolidayInn',
    image: HolidayInn,
  },
  {
    name: 'EmbassySuites',
    image: EmbassySuites,
  },
  {
    name: 'Sheraton',
    image: Sheraton,
  },
  {
    name: 'Beaches',
    image: Beaches,
  },
  {
    name: 'RedCarnation',
    image: RedCarnation,
  },
  {
    name: 'TheRitzCarlton',
    image: TheRitzCarlton,
  },
  {
    name: 'FourSeasons',
    image: FourSeasons,
  },
  {
    name: 'Sandals',
    image: Sandals,
  },
  {
    name: 'W',
    image: W,
  },
  {
    name: 'DisneyHotels',
    image: DisneyHotels,
  },
  {
    name: 'Kimpton',
    image: Kimpton,
  },
];

export interface ICompanyLogos {
  name: string;
  image: string;
}

export const TreatYourselfLogos = [
  {
    name: 'apple.leisure.group',
    reward: 'elite.diamond.award',
    image: AppleLeisureGroup,
  },
  {
    name: 'viking.cruises',
    reward: 'platinum.circle',
    image: VikingRiverCruisesTreatYourself,
  },
  {
    name: 'princess.cruises',
    reward: 'sales.excellence',
    image: PrincessCruises,
  },
  {
    name: 'care',
    reward: 'sapphire.sponsor',
    image: CareTreatYourself,
  },
  {
    name: 'travel.leaders.networks',
    reward: 'presidents.circle',
    image: TravelLeadersNetwork,
  },
  {
    name: 'funjet',
    reward: 'club',
    image: FunjetVacations,
  },
];

export interface ITreatYourselfLogos {
  name: string;
  reward: string;
  image: string;
  link?: string;
}


export const CruiseLineHotLinksLogos = [
  {
    name: 'carnival.title',
    reward: 'carnival.reward',
    image: CarnivalCruises,
    link: 'https://www.carnival.com/',
  },
  {
    name: 'viking.cruises.title',
    reward: 'viking.cruises.reward',
    image: VikingCruises,
    link: 'https://www.vikingcruises.com/',
    excludeCapital: true,
  },
  {
    name: 'royal.caribbean.title',
    reward: 'royal.caribbean.reward',
    image: RoyalCaribbeanCruises,
    link: 'https://www.royalcaribbean.com/',
  },
  {
    name: 'cunard.title',
    reward: 'cunard.reward',
    image: CunardCruises,
    link: 'https://www.cunard.com/en-gb',
  },
  {
    name: 'celebrity.cruises.title',
    reward: 'celebrity.cruises.reward',
    image: CelebrityCruises,
    link: 'https://www.cunard.com/en-gb',
  },
  {
    name: 'princess.cruises.title',
    reward: 'princess.cruises.reward',
    image: PrincessCruisesLine,
    link: 'https://www.princess.com/',
  },
  {
    name: 'norwegian.cruise.line.title',
    reward: 'norwegian.cruise.line.reward',
    image: NorwegianCruiseLine,
    link: 'https://www.ncl.com/fr/en/',
  },
  {
    name: 'disney.cruise.line.title',
    reward: 'disney.cruise.line.reward',
    image: DisneyCruiseLine,
    link: 'https://disneycruise.disney.go.com/',
  },
  {
    name: 'holland.america.title',
    reward: 'holland.america.reward',
    image: HollandAmerica,
    link: 'https://www.hollandamerica.com/en_US.html',
  },
];

export const CarRentalLogos = [
  {
    name: 'thrifty',
    image: Thrifty,
  },
  {
    name: 'dollar',
    image: Dollar,
  },
  {
    name: 'alamo',
    image: Alamo,
  },
  {
    name: 'budget',
    image: Budget,
  },
  {
    name: 'avis',
    image: Avis,
  },
  {
    name: 'national',
    image: National,
  },
  {
    name: 'hertz',
    image: Hertz,
  },
  {
    name: 'enterprise',
    image: Enterprise,
  },
];